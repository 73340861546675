import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  ListSubheader,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import MDBox from "components/MDBox";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Label from "components/InputLabel";
import MDTypography from "components/MDTypography";

const EditSupplier = ({
  open,
  setOpen,
  getTableData,
  getVendors,
  vendorsList,
  survey,
  supplierTable,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vendorsSearch, setVendorsSearch] = useState("");
  const [searchVendorsList, setSearchVendorsList] = useState([]);
  let { enqueueSnackbar } = useSnackbar();
  const [supplierModeList, setSupplierModeList] = useState([]);
  const [supplierType, setSupplierType] = useState(-1);
  const [filteredSupplierTable, setFilteredSupplierTable] = useState([]);
  const [supplierSearch, setSupplierSearch] = useState("");
  const [searchSupplierList, setSearchSupplierList] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: {
      supplierIds: [],
      allocation: "",
      vcpi: "",
      supplierMode: "",
    },
  });

  let { id } = useParams();

  useEffect(() => {
    console.log(filteredSupplierTable);
    let searchSupplierData = filteredSupplierTable.filter((data) => {
      return data.supplierName?.toLowerCase().includes(supplierSearch?.toLowerCase());
    });
    setSearchSupplierList(searchSupplierData);
  }, [supplierSearch, filteredSupplierTable]);

  useEffect(() => {
    if (supplierTable && open) {
      console.log(supplierTable);
      let data;
      if (supplierType === -1) {
        data = supplierTable;
      } else if (supplierType === 1) {
        data = supplierTable.filter((data) => data.isApi === true);
      } else if (supplierType === 2) {
        data = supplierTable.filter((data) => data.isApi === false);
      }
      setFilteredSupplierTable(data);
      setValue(
        "supplierIds",
        data.map((data) => data.supplierId)
      );
    }
  }, [supplierTable, open, supplierType, setValue]);

  let getSupplierModeData = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/suppliermode`);
      setSupplierModeList(result.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getSupplierModeData();
    }
  }, [id]);

  const save = async (data) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.patch(`/survey/${id}/suppliers`, data);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      setValue("supplierIds", []);
      setValue("allocation", "");
      setValue("vcpi", "");
      setValue("supplierMode", "");
      handleClose();
      getTableData();
      getVendors();
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let searchVendorsData = vendorsList.filter((data) => {
      return data.name.toLowerCase().includes(vendorsSearch.toLowerCase());
    });
    setSearchVendorsList(searchVendorsData);
  }, [vendorsSearch, vendorsList]);

  const handleClose = () => {
    setValue("supplierIds", []);
    setValue("allocation", "");
    setValue("vcpi", "");
    setValue("supplierMode", "");
    setOpen(false);
  };

  const selectStyle = {
    height: "36.5px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <span> {"Update Supplier"}</span>
            <Stack direction="row" alignItems="center" spacing={2}>
              <MDButton
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  handleClose();
                }}
              >
                &nbsp;cancel
              </MDButton>

              <MDButton variant="gradient" color="info" size="small" type="submit">
                Save
              </MDButton>
            </Stack>
          </Stack>
          <hr style={{ border: `1px solid #CCC`, width: "100%" }} />
        </DialogTitle>

        <MDBox p={2} pt={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Label value={"Supplier Type"} />
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={supplierType}
                  onChange={(e) => {
                    setSupplierType(Number(e.target.value));
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Supplier List"
                  sx={selectStyle}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  <MenuItem value={1}>Programmatic</MenuItem>
                  <MenuItem value={2}>Manual</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Label value={"Supplier Name"} required={true} />
              <Controller
                name="supplierIds"
                control={control}
                rules={{
                  required: "supplierIds is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors?.supplierIds ? true : false}
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                    size="small"
                  >
                    <Select
                      multiple
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder="Supplier List"
                      sx={selectStyle}
                      onClose={() => setSupplierSearch("")}
                    >
                      <ListSubheader sx={{ marginBottom: "1rem" }}>
                        <TextField
                          sx={{
                            position: "sticky",
                            marginTop: "-16px",
                            paddingTop: "1rem",
                            zIndex: 1,
                            background: "white",
                          }}
                          size="small"
                          autoFocus
                          required
                          value={supplierSearch}
                          placeholder="Type to search..."
                          fullWidth
                          onChange={(e) => setSupplierSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {searchSupplierList?.map((supplier, i) => {
                        return (
                          <MenuItem value={supplier.supplierId} key={i}>
                            {supplier.supplierName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.supplierIds && (
                      <FormHelperText>
                        {errors?.supplierIds ? errors.supplierIds.message : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value={"vcpi"} required={true} />
              <Controller
                name="vcpi"
                control={control}
                rules={{
                  required: "vcpi is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    {...register("vcpi", {
                      min: 0,
                    })}
                    required
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="vcpi"
                    error={errors?.vcpi ? true : false}
                    helperText={
                      errors?.vcpi && errors.vcpi.message !== ""
                        ? errors.vcpi.message
                        : errors?.vcpi?.type === "min"
                        ? "vcpi should not be less then 0"
                        : errors?.vcpi?.type === "max"
                        ? "vcpi should not be more then 100"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value={"Supplier Mode"} required={true} />
              <Controller
                name="supplierMode"
                control={control}
                rules={{
                  required: "Supplier Mode is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors?.supplierMode ? true : false}
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                    size="small"
                  >
                    <Select
                      value={value}
                      onChange={onChange}
                      placeholder="Supplier Mode"
                      sx={selectStyle}
                      MenuProps={{
                        style: {
                          width: "150px",
                          height: "250px",
                        },
                      }}
                    >
                      {supplierModeList?.map((mode, i) => {
                        return (
                          <MenuItem value={mode.id} key={i}>
                            {mode.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.supplierMode && (
                      <FormHelperText>
                        {errors?.supplierMode ? errors.supplierMode.message : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value={"Allocation (%)"} required={true} />
              <Controller
                name="allocation"
                control={control}
                rules={{
                  required: "Allocation is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    required
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    type="number"
                    {...register("allocation", {
                      min: 0,
                      max: 100,
                    })}
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Allocation %"
                    error={errors?.allocation ? true : false}
                    helperText={
                      errors?.allocation && errors.allocation.message !== ""
                        ? errors.allocation.message
                        : errors?.allocation?.type === "min"
                        ? "Allocation should not be less than 0"
                        : errors?.allocation?.type === "max"
                        ? "Allocation should not be more than 100"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value={"Allocation"} />
              <MDInput
                disabled
                fullWidth
                value={(survey?.sampleRequired * watch("allocation")) / 100}
                required
                type="number"
                autoComplete="off"
                variant="outlined"
                placeholder="Allocation"
              />
            </Grid>
          </Grid>
        </MDBox>
      </form>
    </Dialog>
  );
};

export default EditSupplier;

import { Grid, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import "./performance.css";
import PerformanceOverall from "./component/PerformanceOverall";
import PerformanceTab from "./component/PerformanceTab";
import Preformance from "../../../../assets/images/performance.svg";

const Performance = () => {
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [apiCall, setApiCall] = useState(true);

  const handleScroll = (e) => {
    let bottom = e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop === 0;

    if (bottom) {
      if (!loader && apiCall) {
        setLoader(true);
        let pages = page + 1;
        setTimeout(() => {
          setPage(pages);
        }, 1000);
      }
    }
  };

  return (
    <MDBox sx={{ height: "calc(100vh - 262px)", overflowY: "auto" }} onScroll={handleScroll}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDBox className="box">
            <Stack direction="row" alignItems="center" spacing={2} mb={1}>
              <img src={Preformance} />
              <Typography variant="h5" color="primary">
                Project Performance
              </Typography>
            </Stack>
            <PerformanceOverall />
            <PerformanceTab
              page={page}
              setPage={setPage}
              loader={loader}
              setLoader={setLoader}
              setApiCall={setApiCall}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Performance;

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import TLinkApi from "services/tlink.api";
import GetRoutingStatus from "hooks/RoutingStatusList/GetRoutingStatusList";

const RoutingConfig = () => {
  const [addRouting, setAddRouting] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { routingStatusValue } = GetRoutingStatus();

  let { enqueueSnackbar } = useSnackbar();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      routingEnable: false,
      routingTime: "",
      noOfAttempts: "",
      startRouting: false,
      routingStatus: [],
    },
  });

  let routingEnable = watch("routingEnable");
  let startRouting = watch("startRouting");

  let { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [id]);

  const getData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        let result = await TLinkApi.get(`/vendors/${id}/routing`);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setAddRouting(false);
          setIsEdit({
            disabled: true,
            edit: false,
          });
          setValue("routingEnable", result.data.routingEnable);
          setValue("noOfAttempts", result.data.noOfAttempts);
          setValue("routingTime", result.data.routingTime);
          setValue("startRouting", result.data.startRouting);
          setValue("routingStatus", result.data.routingStatus);
          setIsLoading(false);
        } else {
          setAddRouting(true);
          setIsEdit({
            disabled: false,
            edit: false,
          });
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {};
    if (data.routingEnable) {
      payload = {
        noOfAttempts: parseInt(data.noOfAttempts),
        routingEnable: data.routingEnable,
        routingTime: parseInt(data.routingTime),
        vendorId: parseInt(id),
        startRouting: data.startRouting,
        routingStatus: data.routingStatus,
      };
    } else {
      payload = {
        noOfAttempts: null,
        routingEnable: data.routingEnable,
        routingTime: null,
        vendorId: parseInt(id),
        startRouting: false,
        routingStatus: [],
      };
    }
    try {
      if (addRouting !== true) {
        let result = await TLinkApi.put(`/vendors/${id}/routing`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        getData();
        setIsLoading(false);
      } else {
        let result = await TLinkApi.post(`/vendors/routing`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        getData();
        setIsLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };
  const label = (value) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={1}>
        {value} <span>* &nbsp;</span>
      </MDBox>
    );
  };

  const ingerNum = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      return;
    } else {
      e.preventDefault();
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <MDBox px={30}>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox pb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Routing</Typography>
          <MDBox>
            {isEdit.disabled === true ? (
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (addRouting) {
                      reset({
                        routingEnable: false,
                        routingTime: "",
                        noOfAttempts: "",
                        startRouting: false,
                        routingStatus: [],
                      });
                    } else {
                      getData();
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>

        <MDBox>
          <FormControlLabel
            control={
              <Switch
                size="small"
                disabled={isEdit.disabled}
                checked={routingEnable}
                onChange={(e, value) => {
                  setValue("routingEnable", value);
                }}
              />
            }
            label="Routing Enabled"
          />
          {routingEnable === true &&
            (isEdit.edit || addRouting ? (
              <Grid container spacing={3} py={2}>
                <Grid item xs={12} md={2}>
                  {label("Start Routing")}
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        disabled={isEdit.disabled}
                        checked={startRouting}
                        onChange={(e, value) => {
                          setValue("startRouting", value);
                        }}
                      />
                    }
                    label={
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Start Routing&nbsp;
                      </MDTypography>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{ position: "relative" }}>
                    {label("Routing Time")}
                    <Controller
                      name="routingTime"
                      control={control}
                      rules={{
                        required: "Routing Time is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          onKeyPress={(e) => {
                            ingerNum(e);
                          }}
                          type="number"
                          lineHeight={2}
                          inputProps={{ min: 0 }}
                          autoComplete="off"
                          variant="outlined"
                          placeholder="Routing Time"
                          disabled={isEdit.disabled}
                          error={errors?.routingTime ? true : false}
                          helperText={errors?.routingTime ? errors.routingTime.message : null}
                        />
                      )}
                    />
                    <span
                      style={{
                        position: "absolute",
                        bottom: errors?.routingTime ? "26px" : "8px",
                        right: "23px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: " 400",
                        fontSize: "14px",
                        lineWeight: "22px",
                      }}
                    >
                      sec
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  {label("Number of Attempt")}
                  <Controller
                    name="noOfAttempts"
                    control={control}
                    rules={{
                      required: "Number of Attempt is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="number"
                        inputProps={{ min: 0 }}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          ingerNum(e);
                        }}
                        variant="outlined"
                        placeholder="Attempts"
                        disabled={isEdit.disabled}
                        error={errors?.noOfAttempts ? true : false}
                        helperText={errors?.noOfAttempts ? errors.noOfAttempts.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  {label("Routing Status")}
                  <Controller
                    name="routingStatus"
                    control={control}
                    rules={{
                      required: "Routing Status is required!",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: "rgba(0, 0, 0, 0.25)",
                            fontWeight: "400",
                          },
                        }}
                        required
                        error={errors?.routingStatus ? true : false}
                      >
                        <Select
                          multiple
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          onChange={onChange}
                          placeholder="Enycription Type"
                        >
                          {routingStatusValue?.map((value, i) => (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.routingStatus && (
                          <FormHelperText>
                            {errors?.routingStatus ? errors.routingStatus.message : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3} py={2}>
                <Grid item xs={12} md={2}>
                  {label("Start Routing")}
                  <FormControlLabel
                    control={
                      <Switch size="small" disabled={isEdit.disabled} checked={startRouting} />
                    }
                    label={
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Start Routing&nbsp;
                      </MDTypography>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div style={{ position: "relative" }}>
                    {label("Routing Time")}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("routingTime")}
                      &nbsp;<span>sec</span>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  {label("Number of Attempt")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("noOfAttempts")}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  {label("Routing Status")}
                  <Typography fontSize="14px" fontWeight="600">
                    {routingStatusValue
                      .filter((val) => watch("routingStatus")?.includes(val.id))
                      .map((value, index) => (
                        <span>
                          {index > 0 && ", "}
                          {value.name}
                        </span>
                      ))}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </MDBox>
      </form>
    </MDBox>
  );
};

export default RoutingConfig;

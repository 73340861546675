import {
  AppBar,
  Avatar,
  InputBase,
  styled,
  Table,
  TableContainer,
  Box,
  Typography,
  TableRow,
} from "@mui/material";

export const StyledTableRow = styled(TableRow)(() => ({
  padding: "16px",
  background: "initial",
  borderBottom: "1px solid #fff",
  "&:hover": {
    paddingBottom: "15px",
    background: "rgba(200, 250, 205, 0.25)",
    borderBottom: "1px solid #005249",
  },
}));
export const StyledAppBar = styled(AppBar)(({}) => ({
  width: "100vw",
  padding: "24px 32px 0px 20px",
  background: "rgba(255, 255, 255, 1)",
  backdropFilter: "blur(10px)",
  position: "fixed",
  zIndex: 1201,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "140%",
  color: theme.palette.grey[500],
  "&::placeholder": {
    opacity: 1,
    color: "inherit",
  },
}));

export const StyledAvatar = styled(Avatar)(({}) => ({
  width: "48px",
  height: "48px",
  background: " #D9D9D9",
  padding: "0px",
  margin: "0px",
}));

export const ScrollableTableContainer = styled(TableContainer)`
  max-height: "500px";
  min-height: "500px";
  overflow: auto;
  scrollbar-width: none;
  box-shadow: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const BorderTable = styled(Table)`
  /* border: 2px solid #DFE3E8; */
  border-radius: 0.5rem;
  box-shadow: none;
`;

export const Avatars = styled(Box)(({ theme }) => ({
  width: "2.5rem",
  height: "2.5rem",
  background: theme.palette.grey[300],
  borderRadius: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  margin: "0.5rem 1.5rem",
}));

export const NameTag = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1.1375rem",
  lineHeight: "140%",
  color: theme.palette.primary.dark,
}));

export const StyledModalListName = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontStyle: "normal",
  fonteWight: 400,
  lineeiHght: "140%",
  color: theme.palette.grey[800],
  // gap: "1rem",
}));

export const StyledModalListClientName = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontStyle: "normal",
  fonteWight: 400,
  lineeiHght: "140%",
  // letterSpacing: "0.28px",
  color: theme.palette.grey[500],
}));

export const PrimaryBox = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0.225rem 0.5rem",
  // background: "#FAF6CF",
  borderRadius: "1rem",
  height: "20px",
}));
// #FAF6CF
export const ContactType = styled(Typography)(({}) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "140%",
  // color: "#9A8C00",
}));

export const StyledNewTab = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontStyle: "normal",
  fonteWight: 400,
  lineeiHght: "140%",
  color: theme.palette.grey[500],
}));

export const StyledMatchWords = styled(Typography)(({}) => ({
  fontSize: "1rem",
  fontStyle: "normal",
  fonteWight: 700,
  lineeiHght: "140%",
  color: "#005249",
}));

export const CenteredContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "9999",
}));

export const PageWrapper = styled("section")(({ alignType }) => ({
  // height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: alignType || "center",
  marginTop: alignType ? "8%" : 0,
  margin: " 5% 15% 5% 15%",
}));

export const Content = styled("main")(({}) => ({
  height: "fit-content",
  // width: "1300px",
  // left: "40rem",
  // top: "7.8125rem",
  borderRadius: "1.5rem",
  // padding: "24px",
  backgroundColor: "#fff",
}));

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetTopSupplierList = () => {
  const [topSupplier, setTopSupplier] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTopSupplier = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("dashboards/list/top-vendor");
      setTopSupplier(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getTopSupplier();
    // eslint-disable-next-line
  }, []);
  return { topSupplier, getTopSupplier, isLoading };
};

export default GetTopSupplierList;

import React, { useState } from "react";
import NestedQuota from "../NestedQuota";
import SimpleQuota from "../SimpleQuota";

const QuotaContainer = () => {
  const [nestedQuota, setNestedQuota] = useState(false);
  return (
    <div>
      {!nestedQuota ? (
        <NestedQuota setNestedQuota={setNestedQuota} nestedQuota={nestedQuota} />
      ) : (
        <SimpleQuota setNestedQuota={setNestedQuota} nestedQuota={nestedQuota}/>
      )}
    </div>
  );
};

export default QuotaContainer;

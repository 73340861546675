import React, { useEffect, useState } from "react";
import NotesList from "./NotesList";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import NotesOverview from "./NotesOverview";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";

const Notes = ({objectTypeId}) => {
  const [selectedNote, setSelectedNote] = useState(null);
  const [addNode, setAddNode] = useState(false);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  let getListData = async () => {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/notes/${objectTypeId}/${id}`);
        if (Array.isArray(result?.data)) {
          setNotes(result?.data);
        }
        setIsLoading(false);
        return Array.isArray(result?.data) ? result?.data : [];
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
        return null;
      }
    }
  };

  let callApi = async () => {
    let list = await getListData();
    if (list) setSelectedNote(list.length > 0 ? list[0] : null);
  };

  useEffect(() => {
    if (id) {
      callApi();
    }
  }, [id]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if(addNode === false) {
      setSelectedNote(notes[0])
    }
  },[addNode]); 

  useEffect(() => {
    if (selectedNote) {
      scrollToElement(selectedNote?.id);
    }
  }, [selectedNote]);

  const deleteNote = async () => {
    setIsLoading(true);
    try {
      await TLinkApi.del(`/notes/${selectedNote.id}`);
      setIsLoading(false);
      callApi();
      enqueueSnackbar("Note Successfully deleted", {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDBox
            style={{ borderBottom: "1px solid #E4E4E4" }}
            pb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Notes</Typography>
            <MDButton
              disabled={addNode}
              size="small"
              variant="contained"
              color="info"
              onClick={() => {
                setAddNode(true);
                let payload = {
                  id: "-1",
                  htmlData: "",
                };
                setSelectedNote(payload);
                let notesPayload = [...notes];
                notesPayload.push(payload);
                setNotes(notesPayload);
              }}
            >
              + Note
            </MDButton>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              style={{
                borderRight: "1px solid #E4E4E4",
                height: "calc(100vh - 300px)",
                overflowY: "auto",
              }}
            >
              <NotesList
                selectedNote={selectedNote}
                setSelectedNote={setSelectedNote}
                notes={notes}
              />
            </Grid>
            <Grid item xs={9}>
              {selectedNote ? (
                <NotesOverview
                  selectedNote={selectedNote}
                  setSelectedNote={setSelectedNote}
                  setAddNode={setAddNode}
                  addNode={addNode}
                  getListData={getListData}
                  deleteNote={deleteNote}
                  objectTypeId={objectTypeId}
                />
              ) : (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                  textAlign="center"
                  fontSize="13px"
                  fontWeight="500"
                >
                  Please select a note to view its
                  <br /> content here or create a new one
                </MDBox>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Notes;
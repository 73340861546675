import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Backdrop, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import "react-quill/dist/quill.bubble.css";
import "../../../global.css";
import { Controller, useForm } from "react-hook-form";
import MDTypography from "components/MDTypography";
import moment from "moment";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const ConditionOverview = ({
  setSelectedNote,
  selectedNote,
  addNode,
  setAddNode,
  getListData,
  deleteNote,
  objectTypeId,
}) => {
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  const ref = useRef(null);
  const [fullScreen, setFullScreen] = useState(false);
  const fullScreenStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "white",
  };

  const normalStyle = {
    height: "calc(100vh - 420px)",
    overflowY: "auto",
    border: "none",
    paddingLeft: "55px",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      htmlData: "",
    },
  });

  useEffect(() => {
    if (selectedNote.id !== "-1") {
      setValue(
        "htmlData",
        selectedNote?.htmlData
          ? `
           ${selectedNote.htmlData}
          `
          : ""
      );
      setEdit(false);
    } else if (selectedNote.id === "-1") {
      setValue(
        "htmlData",
        `<h1> </h1>
        <p> </p>`
      );
    }
  }, [selectedNote]);

  useEffect(() => {
    if (addNode === true) {
      ref.current.focus();
    }
  }, [addNode]);

  function ValidateDesc(description) {
    var str = description;
    var arr_str = str.split(/<.+?>(.*?)<\/.+?>/g).filter((data) => {
      return data.trim() != "";
    });
    if (arr_str.length >= 2) {
      return true;
    } else {
      return "Please provide title and description both!";
    }
  }

  const save = async (obj) => {
    setIsLoading(true);
    let payload = { ...obj };
    payload.objectId = id;
    payload.objectTypeId = parseInt(objectTypeId);
    if (addNode) {
      try {
        let result = await TLinkApi.post(`/notes`, payload);
        setAddNode(false);
        setEdit(false);
        let list = await getListData();
        if (list) {
          let localnode = list.find((value) => result.data.id === value.id);
          setSelectedNote(localnode);
        }
        setIsLoading(false);
        enqueueSnackbar("Notes created successfully", {
          variant: "success",
        });
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    } else {
      try {
        let result = await TLinkApi.put(`/notes/${selectedNote?.id}`, payload);
        enqueueSnackbar("Notes saved successfully", {
          variant: "success",
        });
        let list = await getListData();
        if (list) {
          let localnode = list.find((value) => result.data.id === value.id);
          setSelectedNote(localnode);
        }
        setIsLoading(false);
        setAddNode(false);
        setEdit(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    }
  };

  const modules = {
    toolbar: true,
  };

  return (
    <div style={{ height: "calc(100vh - 300px)", overflowY: "auto" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {edit || addNode ? (
        <form onSubmit={handleSubmit(save)} noValidate style={{ padding: "10px" }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" marginBottom="1rem">
            <Stack direction="row" alignItems="center" spacing={2}>
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setEdit(false);
                  if (addNode) {
                    let list = await getListData();
                    setSelectedNote(list[0]);
                    setAddNode(false);
                  } else {
                    let list = await getListData();
                    if (list) {
                      let localnode = list.find((value) => selectedNote.id === value.id);
                      setSelectedNote(localnode);
                    }
                  }
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                size="small"
                variant="contained"
                color="info"
                type="submit"
                onClick={() => {
                  setEdit(true);
                }}
              >
                Save
              </MDButton>
              <IconButton
                onClick={() => {
                  setFullScreen(!fullScreen);
                }}
              >
                <FullscreenIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Controller
            name="htmlData"
            style={{ border: "none" }}
            control={control}
            rules={{
              required: "Description is required!",
              validate: { ValidateDesc },
            }}
            render={() => (
              <ReactQuill
                modules={modules}
                ref={ref}
                theme="bubble"
                value={watch("htmlData")}
                style={fullScreen ? fullScreenStyle : normalStyle}
                onChange={(value, delta, context, editor) => {
                  if (value === "<p><br></p>") {
                    setValue("htmlData", "");
                  } else {
                    setValue("htmlData", value);
                  }
                }}
              />
            )}
          />
          <MDBox color="error" fontSize="12px">
            {errors?.htmlData && watch("htmlData") === ""
              ? errors.htmlData.message
              : errors?.htmlData
              ? errors?.htmlData.message
              : null}
          </MDBox>
          {fullScreen && (
            <IconButton
              onClick={() => {
                setFullScreen(false);
              }}
              style={{
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: "1001",
              }}
            >
              <FullscreenExitIcon />
            </IconButton>
          )}
        </form>
      ) : (
        <MDBox>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" marginBottom="1rem">
            <Stack direction="row" alignItems="center" spacing={2}>
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<EditOutlined />}
                onClick={() => {
                  setEdit(true);
                }}
              >
                Edit
              </MDButton>
              <IconButton
                sx={{ padding: "5px !important" }}
                onClick={() => {
                  deleteNote();
                }}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <MDTypography style={{ fontSize: "14px", color: "#8c8c8c" }}>
              {`Updated by ${selectedNote.userName}: ${
                selectedNote?.updatedAt
                  ? moment(moment(selectedNote.updatedAt).local().format()).fromNow()
                  : "NA"
              }`}
            </MDTypography>
          </Stack>
          <Typography
            fontSize="14px"
            color="dark"
            fontWeight="400"
            dangerouslySetInnerHTML={{ __html: watch("htmlData") ? watch("htmlData") : "" }}
          ></Typography>
        </MDBox>
      )}
    </div>
  );
};

export default ConditionOverview;

import React from "react";
import { useState, useEffect } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BackIcon from "../../assets/images/Back.svg";
import { Select, MenuItem } from "@mui/material";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

const TitleCard = ({ name, newData, survey, getData, project }) => {
  let navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const { surveyStatusList } = GetSurveyStatusList();
  const [surveyStatus, setSurveyStatus] = useState("");
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);

  useEffect(() => {
    let id = surveyStatusList?.find((data) => data.name === survey?.status)?.id;
    setSurveyStatus(id);
  }, [survey]);

  const changeStatus = async (value) => {
    if (survey) {
      try {
        await TLinkApi.patch(`/survey/${survey?.id}/status`, {
          surveyStatusId: value,
        });
        await getData();
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Oops! Something went wrong", {
          variant: "error",
        });
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Awarded":
        return "#FF9999";
      case "Live":
        return "#26bd51";
      case "Pause / On hold ":
        return "#fff363";
      case "Completed":
        return "#c29191";
      case "Reconciled":
        return "#ADD8E6";
      case "Ready to invoice":
        return "#4682B4";
      case "Closed":
        return "#D3D3D3";
      case "Permanent Closed":
        return "#A9A9A9";
      case "System Closed":
        return "#696969";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Stack spacing={0} minHeight="85px" marginBottom="6px">
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Stack spacing={2} direction="row" alignItems="center" marginTop={newData ? "2px" : ""}>
          <IconButton
            sx={{
              padding: 0,
              borderRadius: "4px !important",
            }}
            onClick={() => {
              if (project && project !== -1) {
                navigate(`/projects/${project}/surveyList`);
              } else {
                navigate(`/surveys`);
              }
            }}
          >
            <img src={BackIcon} alt="Back" />
          </IconButton>
          <Typography variant="h4">{name}</Typography>
        </Stack>
        {!newData && (
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack alignItems="flex-end">
              <Typography variant="h6">{survey?.starts}</Typography>
              <Typography sx={{ color: `#7A7A7A`, fontWeight: "500", fontSize: "14px" }}>
                Starts
              </Typography>
            </Stack>
            <Stack alignItems="flex-end">
              <Typography variant="h6">{survey?.completes}</Typography>
              <Typography sx={{ color: `#7A7A7A`, fontWeight: "500", fontSize: "14px" }}>
                Completes
              </Typography>
            </Stack>
            <Stack alignItems="flex-end">
              <Typography variant="h6">
                {String(
                  Math.ceil(survey?.starts <= 0 ? 0 : (survey?.completes / survey?.starts) * 100)
                ).concat(" %")}
              </Typography>
              <Typography sx={{ color: `#7A7A7A`, fontWeight: "500", fontSize: "14px" }}>
                Conversion
              </Typography>
            </Stack>
            <Stack alignItems="flex-end">
              <Typography variant="h6">{survey?.clientConversion}%</Typography>
              <Typography sx={{ color: `#7A7A7A`, fontWeight: "500", fontSize: "14px" }}>
                Client Conversion
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      {!newData && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginLeft={"30px"}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            {!newData ? (
              <MDBox
                sx={{
                  backgroundColor: isDropdownHovered ? "#F0F0F0" : "transparent",
                  color: getStatusColor(survey?.status),
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 2px !important",
                  borderRadius: "8px",
                }}
              >
                <FiberManualRecordIcon
                  sx={{
                    color: getStatusColor(survey?.status),
                    height: "10px",
                  }}
                />
                <Select
                  value={parseInt(surveyStatus)}
                  onChange={(event) => {
                    changeStatus(event.target.value);
                  }}
                  sx={{
                    fontSize: "0.7rem",
                    height: "25px",
                    border: "none",
                    "& fieldset": { border: "none" },
                  }}
                  onMouseEnter={() => setIsDropdownHovered(true)}
                  onMouseLeave={() => setIsDropdownHovered(false)}
                >
                  {surveyStatusList.map((value, i) => (
                    <MenuItem key={i} value={parseInt(value.id)}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            ) : (
              ""
            )}

            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox
                sx={{ fontSize: "0.7rem", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/projects/${survey?.projectId}`);
                }}
              >
                {survey?.projectName}
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox
                sx={{ fontSize: "0.7rem", cursor: "pointer" }}
                onClick={() => {
                  navigate("/surveys");
                }}
              >
                Surveys
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox sx={{ fontSize: "0.7rem" }}>{survey?.id}</MDBox>
            </MDBox>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox sx={{ fontSize: "0.7rem" }}>
                {survey?.userName}
                {/* Johnathan Doe (Alt. PM) */}
              </MDBox>
            </MDBox>
            <MDBox sx={{ fontSize: "0.7rem", color: "#7A7A7A" }}>
              {moment(survey?.surveyStartDate).format("YYYY-MM-DD")} -{" "}
              {moment(survey?.surveyEndDate).format("YYYY-MM-DD")}
            </MDBox>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default TitleCard;

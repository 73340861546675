import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import GetIndustryList from "hooks/IndustryList/GetIndustryList";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import GetSurveyTypeList from "hooks/SurveyList/GetSurveyTypeList";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import GetListOfProjects from "hooks/ListOfProjects/GetListOfProjects";
import GetCalculationList from "hooks/Calculation/GetCalculationList";
import CloneModal from "../CloneModal";
import DetailIcon from "assets/images/Detail.svg";

export const deviceType = [
  {
    value: 1,
    text: "Mobile",
  },
  {
    value: 2,
    text: "Tablet",
  },
  {
    value: 3,
    text: "Desktop",
  },
];

export const piiType = [
  {
    value: 1,
    text: "Enabled",
  },
  {
    value: 2,
    text: "Disabled",
  },
];

function BasicDetails() {
  const { industry } = GetIndustryList();
  const { language, languageMap } = GetLanguageList();
  const { surveyType } = GetSurveyTypeList();
  const { surveyStatusList } = GetSurveyStatusList();
  const [statusSearch, setStatusSearch] = useState("");
  const [searchStatusList, setSearchStatusList] = useState([]);
  const { calculationList } = GetCalculationList();
  const navigate = useNavigate();
  const { projects } = GetListOfProjects();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [searchIndustry, setSearchIndustry] = useState("");
  const [searchIndustryList, setSearchIndustryList] = useState([]);
  const [surveyStatusSearch, setSurveyStatusSearch] = useState("");
  const [searchSurveyStatusList, setSearchSurveyStatusList] = useState([]);
  const [projectsSearch, setProjectsSearch] = useState("");
  const [searchProjectsList, setSearchProjectsList] = useState([]);
  const {state} = useLocation();

  let { isEdit, setIsEdit, survey, setLangId, getData, project, setProject } = useOutletContext();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: {
      surveyName: "",
      projectId: "",
      surveyStatus: "",
      loi: "",
      cpi: "",
      industryId: "",
      surveyTypeId: "",
      ir: "",
      surveyStartDate: new Date().toISOString().slice(0, 10),
      surveyEndDate: "",
      languageId: "",
      liveDate: "",
      sampleRequired: "",
      estimatedRevenue: "",
      sampleCalculationType: "",
      surveyLink: "",
      surveyTestLink: "",
      device: [1, 2, 3],
      countryCode: "",
      pii: "2",
    },
  });

  let surveyStartDate = watch("surveyStartDate");
  let sampleRequired = watch("sampleRequired");
  let cpi = watch("cpi");
  let surveyLink = watch("surveyLink");
  let surveyTestLink = watch("surveyTestLink");
  let languageId = watch("languageId");

  useEffect(() => {
    setProject(state?.id);
  },[state]); 

  useEffect(() => {
    if (Object.keys(survey).length <= 0) {
      if (industry.length > 0) setValue("industryId", "30");
      if (language.length > 0) setValue("languageId", "");
      if (calculationList.length > 0) setValue("sampleCalculationType", 1);
      if (surveyStatusList.length > 0) setValue("surveyStatus", 1);
      if (surveyType.length > 0) setValue("surveyTypeId", 1);
    }
  }, [industry, calculationList, language, surveyType, surveyStatusList]);

  useEffect(() => {
    if (Object.keys(survey).length > 0) {
      let payload = [];
      if (survey.isMobileAllowed) {
        payload.push(1);
      }
      if (survey.isTabletAllowed) {
        payload.push(2);
      }
      if (survey.isDesktopAllowed) {
        payload.push(3);
      }

      setValue("surveyName", survey?.surveyName);
      setValue("surveyStartDate", moment(survey?.surveyStartDate).format("YYYY-MM-DD"));
      setValue("surveyEndDate", moment(survey?.surveyEndDate).format("YYYY-MM-DD"));
      setValue("projectId", survey?.projectId);
      setValue("surveyStatus", survey?.surveyStatusId);
      setValue("industryId", survey?.industryId);
      setValue("ir", survey?.ir);
      setValue("loi", survey?.loi);
      setValue("cpi", survey?.cpi);
      setValue("surveyLink", survey?.surveyLink);
      setValue("surveyTestLink", survey?.surveyTestLink);
      setValue("sampleCalculationType", survey?.sampleCalculationType);
      setValue("pii", survey?.collectPii ? 1 : 2);
      setValue("estimatedRevenue", survey?.estimatedRevenue);
      setValue("sampleRequired", survey?.sampleRequired);
      setValue("surveyTypeId", survey?.surveyTypeId);
      setValue("languageId", survey?.languageId?.toString());
      setValue("device", payload);
      setValue("countryCode", survey?.countryCode);
      setValue("estimatedRevenue", survey?.sampleRequired * survey?.cpi);
    }
  }, [survey]);

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let params = Object.fromEntries(searchParams);
  const [paramsSearch, setParamsSearch] = useState();

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setParamsSearch(true);
    }
  }, [params]);

  useEffect(() => {
    if (paramsSearch) {
      setValue("projectId", params.project_id);
    }
  }, [paramsSearch]);

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      projectId: parseInt(data.projectId),
      surveyStatusId: parseInt(data.surveyStatus),
      loi: parseFloat(data.loi),
      cpi: parseFloat(data.cpi),
      surveyName: data.surveyName,
      industryId: parseInt(data.industryId),
      surveyTypeId: parseInt(data.surveyTypeId),
      ir: parseFloat(data.ir),
      surveyStartDate: data.surveyStartDate,
      surveyEndDate: data.surveyEndDate,
      languageId: parseInt(data.languageId),
      sampleRequired: parseInt(data.sampleRequired),
      sampleCalculationType: parseInt(data.sampleCalculationType),
      collectPii: data.pii === 1 ? true : false,
      isDesktopAllowed: data.device.includes(3) ? true : false,
      isMobileAllowed: data.device.includes(1) ? true : false,
      isTabletAllowed: data.device.includes(2) ? true : false,
      surveyLink: data.surveyLink,
      surveyTestLink: data.surveyTestLink,
    };

    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/survey/${survey.id}`, payload);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setIsLoading(false);
        setLangId(data.languageId);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/surveys/${result.data.id}`,{state: { id: project }});
      } else {
        let result = await TLinkApi.post("/survey", payload);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setLangId(data.languageId);
        navigate(`/surveys/${result.data.id}/securityConfig/edit`);
      }
      getData();
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let data = sampleRequired * cpi;
    setValue("estimatedRevenue", data);
  }, [cpi, sampleRequired]);

  const ingerNum = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      return;
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setSearchIndustryList(industry);
  }, [industry]);

  useEffect(() => {
    let searchData = industry?.filter((data) => {
      return data?.name.toLowerCase().includes(searchIndustry?.toLowerCase());
    });
    setSearchIndustryList(searchData);
  }, [searchIndustry]);

  useEffect(() => {
    let searchStatusData = surveyStatusList.filter((data) => {
      return data.name.toLowerCase().includes(statusSearch.toLowerCase());
    });
    setSearchStatusList(searchStatusData);
  }, [statusSearch, surveyStatusList]);

  useEffect(() => {
    let searchSurveyStatusData = surveyType.filter((data) => {
      return data.name.toLowerCase().includes(surveyStatusSearch.toLowerCase());
    });
    setSearchSurveyStatusList(searchSurveyStatusData);
  }, [surveyStatusSearch, surveyType]);

  useEffect(() => {
    let searchProjectsData = projects.filter((data) => {
      return data.name.toLowerCase().includes(projectsSearch.toLowerCase());
    });
    setSearchProjectsList(searchProjectsData);
  }, [projectsSearch, projects]);

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  useEffect(() => {
    if (location.pathname === "/surveys/new") {
      setValue("surveyName", "New Survey");
      if (location?.state?.id) {
        setValue("projectId", location?.state?.id);
      }
    }
  }, [location]);

  const handleClose = () => {
    setCloneOpen(false);
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CloneModal
        open={cloneOpen}
        handleClose={handleClose}
        defaultLanguageId={watch("languageId")}
        language={language}
        languageMap={languageMap}
      />
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          p={2}
          px={20}
          style={{
            width: "100%",
            height: `calc(100vh - 252px)`,
            overflowY: "auto",
          }}
          mt={2}
        >
          <Stack
            justifyContent={"space-between"}
            alignItems="flex-end"
            sx={{ width: "100%" }}
            mb={1}
            direction={isEdit.edit ? "row" : ""}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <img src={DetailIcon} />
              <Typography fontWeight="600" color="primary">
                Basic Details
              </Typography>
            </Stack>

            {isEdit.disabled !== false ? (
              <MDBox display="flex" alignItems="center" gap="10px">
                <IconButton
                  color="dark"
                  size="small"
                  onClick={() => {
                    navigate(`/surveys/${id}/edit`,{state: { id: project }});
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
                <Tooltip title="Clone Survey" placement="top">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => {
                      setCloneOpen(true);
                    }}
                  >
                    clone
                  </MDButton>
                </Tooltip>
              </MDBox>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (window.location.pathname === "/surveys/new") {
                      navigate(`/surveys`);
                    } else {
                      getData();
                      navigate(`/surveys/${id}`);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </Stack>
          {isEdit.edit || window.location.pathname === "/surveys/new" ? (
            <Grid container spacing={1.8}>
              <Grid item xs={12} md={4}>
                {label("Project")}
                <Controller
                  name="projectId"
                  control={control}
                  rules={{
                    required: "Project Id is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.projectId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        placeholder="Project Id"
                        sx={selectStyle}
                        disabled={isEdit.disabled || isEdit.edit}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                        onClose={() => setProjectsSearch("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={projectsSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setProjectsSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchProjectsList?.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.projectId && (
                        <FormHelperText>
                          {errors?.projectId ? errors.projectId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Survey Title")}
                <Controller
                  name="surveyName"
                  control={control}
                  rules={{
                    required: "Survey Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      size="small"
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      placeholder="Survey Name"
                      error={errors?.surveyName ? true : false}
                      helperText={errors?.surveyName ? errors.surveyName.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Survey Type")}
                <Controller
                  name="surveyTypeId"
                  control={control}
                  rules={{
                    required: "Survey Type is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.surveyTypeId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="surveyTypeId"
                        sx={selectStyle}
                        onClose={() => setSurveyStatusSearch("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={surveyStatusSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSurveyStatusSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchSurveyStatusList?.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.surveyTypeId && (
                        <FormHelperText>
                          {errors?.surveyTypeId ? errors.surveyTypeId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Market")}
                <Controller
                  name="languageId"
                  control={control}
                  rules={{
                    required: "Market Field is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth required error={errors?.languageId ? true : false}>
                      <Autocomplete
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={(event, newValue) => {
                          onChange(newValue?.id);
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            minHeight: "20px",
                          },
                        }}
                        isOptionEqualToValue={(option, value) => option?.id == value}
                        options={language}
                        getOptionLabel={(option) =>
                          option.name
                            ? option.name
                            : languageMap[languageId]
                            ? languageMap[languageId]
                            : ""
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="outlined"
                            size="small"
                            required
                            disabled={isEdit.disabled}
                          />
                        )}
                      />
                      {errors?.languageId && (
                        <FormHelperText>
                          {errors?.languageId ? errors.languageId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Industry")}
                <Controller
                  name="industryId"
                  control={control}
                  rules={{
                    required: "Industry Field is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.industryId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="industry"
                        sx={selectStyle}
                        onClose={() => setSearchIndustry("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            value={searchIndustry}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearchIndustry(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchIndustryList.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.industryId && (
                        <FormHelperText>
                          {errors?.industryId ? errors.industryId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Survey Status")}
                <Controller
                  name="surveyStatus"
                  control={control}
                  rules={{
                    required: "Survey Status is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.surveyStatus ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Survey Status"
                        sx={selectStyle}
                        onClose={() => setStatusSearch("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={statusSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setStatusSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchStatusList.map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.surveyStatus && (
                        <FormHelperText>
                          {errors?.surveyStatus ? errors.surveyStatus.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Sample Required")}
                <Controller
                  name="sampleRequired"
                  control={control}
                  rules={{
                    required: "Sample Required is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      disabled={isEdit.disabled}
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      onKeyPress={(e) => {
                        ingerNum(e);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      placeholder="Sample Required"
                      error={errors?.sampleRequired ? true : false}
                      helperText={errors?.sampleRequired ? errors.sampleRequired.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("CPI($)")}
                <Controller
                  name="cpi"
                  control={control}
                  rules={{
                    required: "CPI is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      {...register("cpi", {
                        min: 0,
                      })}
                      type="number"
                      placeholder="CPI"
                      error={errors?.cpi ? true : false}
                      helperText={
                        errors?.cpi && errors.cpi.message !== ""
                          ? errors.cpi.message
                          : errors?.cpi?.type === "min"
                          ? "Cpi should not be less then 0"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Incident Rate(IR)%")}
                <Controller
                  name="ir"
                  control={control}
                  rules={{
                    required: "Incident Rate(IR) is required!",
                    max: {
                      value: 100,
                      message: "IR should not be more than 100!",
                    },
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      {...register("ir", {
                        min: 0,
                      })}
                      placeholder="Incident Rate(IR)"
                      error={errors?.ir ? true : false}
                      helperText={
                        errors?.ir && errors.ir.message !== ""
                          ? errors.ir.message
                          : errors?.ir?.type === "min"
                          ? "IR should not be less then 0!"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("LOI(mins)")}
                <Controller
                  name="loi"
                  control={control}
                  rules={{
                    required: "LOI is required!",
                    max: {
                      value: 60,
                      message: "Loi should not be more than 60!",
                    },
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="number"
                      placeholder="LOI"
                      {...register("loi", {
                        min: 0,
                      })}
                      error={errors?.loi ? true : false}
                      helperText={
                        errors?.loi && errors.loi.message !== ""
                          ? errors.loi.message
                          : errors?.loi?.type === "min"
                          ? "Loi should not be less then 0!"
                          : null
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Start Date")}
                <Controller
                  name="surveyStartDate"
                  control={control}
                  rules={{
                    required: "Start Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Start Date"
                      error={errors?.surveyStartDate ? true : false}
                      helperText={errors?.surveyStartDate ? errors.surveyStartDate.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("End Date")}
                <Controller
                  name="surveyEndDate"
                  control={control}
                  rules={{
                    required: "End Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled || surveyStartDate === ""}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("surveyEndDate", {
                        min: surveyStartDate
                          ? new Date(surveyStartDate).toISOString().split("T")[0]
                          : "",
                      })}
                      placeholder="End Date"
                      error={errors?.surveyEndDate ? true : false}
                      helperText={
                        errors?.surveyEndDate && errors.surveyEndDate.message !== ""
                          ? errors.surveyEndDate.message
                          : errors?.surveyEndDate?.type === "min"
                          ? "End date should not be less than start date"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Estimated Revenue")}
                <Controller
                  name="estimatedRevenue"
                  control={control}
                  rules={{
                    required: "Estimated Revenue is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled
                      placeholder="Estimated Revenue ($)"
                      error={errors?.estimatedRevenue ? true : false}
                      helperText={errors?.estimatedRevenue ? errors.estimatedRevenue.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("PII")}
                <Controller
                  name="pii"
                  control={control}
                  rules={{
                    required: "Pii is required!",
                  }}
                  render={({ field: { onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.pii ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={watch("pii")}
                        onChange={onChange}
                        placeholder="Pii"
                        sx={selectStyle}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                      >
                        {piiType?.map((value, i) => {
                          return (
                            <MenuItem value={value.value} key={i}>
                              {value.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.pii && (
                        <FormHelperText>{errors?.pii ? errors.pii.message : null}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Device")}
                <Controller
                  name="device"
                  control={control}
                  rules={{
                    required: "Device is required!",
                  }}
                  render={({ field: { onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.device ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        multiple
                        value={watch("device")}
                        onChange={onChange}
                        placeholder="Project Id"
                        sx={selectStyle}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                      >
                        {deviceType?.map((value, i) => {
                          return (
                            <MenuItem value={value.value} key={i}>
                              {value.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.device && (
                        <FormHelperText>
                          {errors?.device ? errors.device.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} style={{ position: "relative" }}>
                {label("Survey Url")}
                <Controller
                  name="surveyLink"
                  control={control}
                  rules={{
                    required: "Survey Link is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      type="url"
                      variant="outlined"
                      placeholder="Survey Url"
                      {...register("surveyLink", {
                        pattern: {
                          value:
                            // eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid Link",
                        },
                      })}
                      error={errors?.surveyLink ? true : false}
                      helperText={errors?.surveyLink ? errors.surveyLink.message : null}
                    />
                  )}
                />
                <Icon
                  style={{
                    position: "absolute",
                    right: 6,
                    bottom: errors?.surveyLink ? 27 : 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copy(surveyLink);
                  }}
                >
                  copy
                </Icon>
              </Grid>
              <Grid item xs={12} md={12} style={{ position: "relative" }}>
                {label("Survey Test Url ", true)}

                <Controller
                  name="surveyTestLink"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      type="url"
                      variant="outlined"
                      placeholder="Survey Test Url"
                      {...register("surveyTestLink", {
                        pattern: {
                          value:
                            // eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid Link",
                        },
                      })}
                      error={errors?.surveyTestLink ? true : false}
                      helperText={errors?.surveyTestLink ? errors.surveyTestLink.message : null}
                    />
                  )}
                />
                <Icon
                  style={{
                    position: "absolute",
                    right: 6,
                    bottom: errors?.surveyTestLink ? 27 : 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copy(surveyTestLink);
                  }}
                >
                  copy
                </Icon>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1.8}>
              <Grid item xs={12} md={4}>
                {label("Project")}
                <Typography fontSize="14px" fontWeight="600">
                  {projects?.find((value) => Number(value.id) === Number(watch("projectId")))?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Survey Title")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("surveyName")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Survey Type")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    surveyType?.find((value) => Number(value.id) === Number(watch("surveyTypeId")))
                      ?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Market")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    language?.find((value) => Number(value.id) === Number(watch("languageId")))
                      ?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Industry")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    industry?.find((value) => Number(value.id) === Number(watch("industryId")))
                      ?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Survey Status")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    surveyStatusList?.find(
                      (value) => Number(value.id) === Number(watch("surveyStatus"))
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Sample Required")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("sampleRequired")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("CPI($)")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("cpi")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Incident Rate(IR)%")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("ir")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("LOI(mins)")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("loi")}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Start Date")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("surveyStartDate")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("End Date")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("surveyEndDate")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Estimated Revenue")}
                <Typography fontSize="14px" fontWeight="600">
                  {parseFloat(watch("estimatedRevenue")).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("PII")}
                <Typography fontSize="14px" fontWeight="600">
                  {piiType?.find((value) => Number(value.value) === Number(watch("pii")))?.text}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Device")}
                <Typography fontSize="14px" fontWeight="600">
                  {deviceType
                    .filter((val) => watch("device")?.includes(val.value))
                    .map((device, index) => (
                      <span>
                        {index > 0 && ", "}
                        {device.text}
                      </span>
                    ))}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12} style={{ position: "relative" }}>
                {label("Survey Url")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("surveyLink") ? watch("surveyLink") : "NA"}
                </Typography>
                <Tooltip title="Copy Link" placement="top">
                  <Icon
                    style={{
                      position: "absolute",
                      right: 6,
                      bottom: errors?.surveyLink ? 27 : 8,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copy(surveyLink);
                    }}
                  >
                    copy
                  </Icon>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={12} style={{ position: "relative" }}>
                {label("Survey Test Url ", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("surveyTestLink") ? watch("surveyTestLink") : "NA"}
                </Typography>
                <Tooltip title="Copy Link" placement="top">
                  <Icon
                    style={{
                      position: "absolute",
                      right: 6,
                      bottom: errors?.surveyTestLink ? 27 : 8,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copy(surveyTestLink);
                    }}
                  >
                    copy
                  </Icon>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </form>
    </MDBox>
  );
}

export default BasicDetails;

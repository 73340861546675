import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetUserList = () => {
  const [user, setUser] = useState([]);
  const [userMap, setUserMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [roleId, setRoleId] = useState(-1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [currentUser, setCurrentUser] = useState(-1);

  const getUser = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/users?roleId=${roleId}&search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${page+1}&limit=${entriesPerPage}`
      );
      setCurrentUser(data?.data?.currentUser);
      setUser(data?.data?.users);
      setTotalLength(data?.data?.totalLength);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getUser();
  }, [page, search, entriesPerPage, roleId, sortBy, sortOrder]);
  console.log(user);
  return {
    user,
    getUser,
    userMap,
    isLoading,
    setIsLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    roleId,
    setRoleId,
    sortBy, 
    setSortBy,
    sortOrder,
    setSortOrder,
    currentUser,
  };
};

export default GetUserList;

import MDBox from "components/MDBox";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TabsComponent from "components/Tabs/Tabs";
import TLinkApi from "services/tlink.api";
import { Backdrop, CircularProgress } from "@mui/material";
import LogsIcon from "../../../../assets/images/logsIcon.svg";
import ActivityLog from "../../../ActivityLog";
import NotesIcon from "../../../../assets/images/notesIcon.svg";
import ClientTitleCard from "components/ClientTitleCard";

function AddClients() {
  const { client, setClient, isEdit, setIsEdit } = useOutletContext();
  const [step, setStep] = useState([]);
  const [stepPos, setStepPos] = useState(0);
  const location = useLocation();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [subValue, setSubValue] = useState(0);
  const [steps, setSteps] = useState([
    {
      label: "Client Details",
      isDisabled: false,
      route: window.location.pathname === `/clients/new` ? `/clients/new` : `/clients/${id}`,
    },
    {
      label: "Client Encryption",
      isDisabled: window.location.pathname === `/clients/new` ? true : false,
      route: `/clients/${id}/clientEncryption/edit`,
    },
    {
      label: "Client Filters",
      isDisabled: window.location.pathname === `/clients/new` ? true : false,
      route: `/clients/${id}/clientFilters/edit`,
      programmatic: true,
    },
  ]);

  useEffect(() => {
    if (window.location.pathname === `/clients/${id}/clientEncryption/edit`) {
      setStepPos(1);
      setStep([0]);
    } else if (window.location.pathname === `/clients/${id}/clientFilters/edit`) {
      setStepPos(2);
      let tab = [0, 1];
      setStep(tab);
    } else if (window.location.pathname === `/clients/${id}`) {
      setStep([]);
      setStepPos(0);
    } else if (window.location.pathname === `/clients/new`) {
      setStep([]);
      setStepPos(0);
    } else if (window.location.pathname === `/clients/${id}/notes/edit`) {
      setStepPos(-1);
      setSubValue(0);
    }
  }, [location]);

  let getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/clients/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/clients/${id}`);
          setClient(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ClientTitleCard
        newData={window.location.pathname === `/clients/new` ? true : false}
        name={window.location.pathname === `/clients/new` ? "New Client" : client.name}
        nav={"clients"}
      />

      <Grid container spacing={0}>
        <TabsComponent
          setValue={setStepPos}
          value={stepPos}
          labels={steps}
          subValue={subValue}
          sublabels={[
            {
              label: `${NotesIcon}`,
              isDisabled: window.location.pathname === `/clients/new` ? true : false,
              route: `/clients/${id}/notes/edit`,
              name: "Notes",
            },
            {
              label: `${LogsIcon}`,
              isDisabled: window.location.pathname === `/clients/new` ? true : false,
              name: "Activity logs",
              htmlContainer: <ActivityLog objectTypeId={3} />,
            },
          ]}
          tabpanels={
            <Outlet
              context={{
                stepPos,
                setStepPos,
                step,
                setStep,
                steps,
                setSteps,
                subValue,
                setSubValue,
                client,
                setClient,
                setIsEdit,
                isEdit,
                getData,
              }}
            />
          }
        />
      </Grid>
    </MDBox>
  );
}

export default AddClients;

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  Icon,
  IconButton,
  Stack,
  Typography,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const Status = [
  {
    name: "Active",
    value: 1,
  },
  {
    name: "Inactive",
    value: 2,
  },
];

const Programmatic = [
  {
    name: "Programmatic",
    value: 1,
  },
  {
    name: "Manual",
    value: 2,
  },
];

function ClientBasicDetails() {
  const navigate = useNavigate();
  const { client, setClient, isEdit, setIsEdit, getData, steps, setSteps } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      isActive: false,
      isApiClient: false,
      code: "",
      building: "",
      street: "",
      state: "",
      country: "",
      city: "",
      zipCode: "",
    },
  });

  useEffect(() => {
    if (Object.keys(client).length > 0) {
      setValue("email", client.email);
      setValue("name", client.name);
      setValue("phone", client.phone);
      setValue("isActive", client.isActive ? 1 : 2);
      setValue("isApiClient", client.isApiClient ? 1 : 2);
      setValue("code", client.code);
      setValue("building", client.building);
      setValue("street", client.street);
      setValue("country", client.country);
      setValue("city", client.city);
      setValue("state", client.state);
      setValue("zipCode", client.zipCode);
    }
  }, [client]);

  const { id } = useParams();
  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  const save = async (data) => {
    let payload = {
      ...data,
    };

    payload.isActive = data.isActive === 1 ? true : false;
    payload.isApiClient = data.isApiClient === 1 ? true : false;
    setIsLoading(true);

    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/clients/${client.id}`, payload);
       
        setIsLoading(false);

        getData();

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: true,
          edit: false,
        });
        navigate(`/clients/${result.data.id}/clientEncryption/edit`);
      } else {
        payload.accountId = id;
        let result = await TLinkApi.post("/clients", payload);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: true,
        });
        getData(); 
        // let newSteps = steps;
        // newSteps.splice(0, 1);
        // newSteps.splice(0, 0, {
        //   label: "Client Details",
        //   isDisabled: false,
        //   route: `/clients/${result?.data?.id}`,
        // });
        let newSteps = [  {
          label: "Client Details",
          isDisabled: false,
          route: `/clients/${result?.data?.id}`,
        },
        {
          label: "Client Encryption",
          isDisabled: false,
          route: `/clients/${result?.data?.id}/clientEncryption/edit`,
        },
        {
          label: "Client Filters",
          isDisabled: false,
          route: `/clients/${result?.data?.id}/clientFilters/edit`,
          programmatic: true,
        },]
        setSteps(newSteps);
        // setClient({});
        navigate(`/clients/${result.data.id}/clientEncryption/edit`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDBox
            p={2}
            px={20}
            style={{
              width: "100%",
              height: `calc(100vh - 252px)`,
              overflowY: "auto",
            }}
            mt={2}
          >
            <Stack
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%" }}
              mb={1}
              direction={isEdit.edit ? "row" : ""}
            >
              <Stack direction="row" spacing={2}>
                <Typography fontWeight="600">{watch("name")}</Typography>
              </Stack>

              {isEdit.disabled !== false ? (
                <IconButton
                  color="dark"
                  size="small"
                  onClick={() => {
                    navigate(`/clients/${id}/edit`);
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              ) : (
                <MDBox display="flex" alignItems="center" gap="10px">
                  <MDButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      getData();
                      if (window.location.pathname === "/clients/new") {
                        navigate(`/clients`);
                      } else {
                        navigate(`/clients/${id}`);
                        setIsEdit({
                          disabled: true,
                          edit: false,
                        });
                      }
                    }}
                  >
                    &nbsp;cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit" size="small">
                    Save
                  </MDButton>
                </MDBox>
              )}
            </Stack>
            {isEdit.edit || window.location.pathname === "/clients/new" ? (
              <>
                <Grid container spacing={3} pt={1}>
                  <Grid item xs={12} md={12}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ borderBottom: "1px solid #D9D9D9" }}
                    >
                      {"Basic Details"}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Client Name")}
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: "Client Name is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="text"
                          autoComplete="off"
                          {...register("name", {
                            pattern: {
                              value: /^[A-Za-z0-9 ]*$/,
                              message: "Please Enter Valid Character",
                            },
                          })}
                          variant="outlined"
                          placeholder="Client Name"
                          disabled={isEdit.disabled}
                          error={errors?.name ? true : false}
                          helperText={errors?.name ? errors.name.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Tooltip title="Client Code supported alphanumeric value" placement="top-start">
                      {label("Client Code")}
                    </Tooltip>
                    <Controller
                      name="code"
                      control={control}
                      rules={{
                        required: "Client Code is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          autoComplete="off"
                          {...register("code", {
                            pattern: {
                              value: /^[A-Za-z0-9 ]*$/,
                              // window.location.pathname === "/clients/new"
                              //   ? /^[A-Za-z0-9 ]*$/
                              //   : /^[0-9 ]*$/,
                              message: "Please Enter Valid Code",
                            },
                          })}
                          variant="outlined"
                          placeholder="Client Code"
                          disabled={isEdit.disabled}
                          error={errors?.code ? true : false}
                          helperText={errors?.code ? errors.code.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Contact Number")}
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        required: "Client Phone is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="number"
                          autoComplete="off"
                          {...register("phone", {
                            pattern: {
                              value: /^[0-9 ]*$/,
                              message: "Please Enter Valid Number",
                            },
                          })}
                          variant="outlined"
                          placeholder="Client Phone"
                          disabled={isEdit.disabled}
                          error={errors?.phone ? true : false}
                          helperText={errors?.phone ? errors.phone.message : null}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {label("Email Address")}
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          {...register("email", {
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Entered value does not match email format",
                            },
                          })}
                          autoComplete="off"
                          variant="outlined"
                          placeholder="Email Address"
                          disabled={isEdit.disabled}
                          error={errors?.email ? true : false}
                          helperText={errors?.email ? errors.email.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Status")}
                    <Controller
                      name="isActive"
                      control={control}
                      rules={{
                        required: "Status is required!",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <FormControl
                          fullWidth
                          required
                          error={errors?.isActive ? true : false}
                          sx={{
                            label: {
                              color: "rgba(0, 0, 0, 0.25)",
                              fontWeight: "400",
                            },
                          }}
                          size="small"
                        >
                          <Select
                            value={value}
                            onChange={onChange}
                            sx={selectStyle}
                            MenuProps={{
                              style: {
                                width: "150px",
                                height: "250px",
                              },
                            }}
                          >
                            {Status?.map((value, i) => {
                              return (
                                <MenuItem value={value.value} key={i}>
                                  {value.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors?.isActive && (
                            <FormHelperText>
                              {errors?.isActive ? errors.isActive.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Programmatic")}
                    <Controller
                      name="isApiClient"
                      control={control}
                      rules={{
                        required: "Programmatic is required!",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <FormControl
                          fullWidth
                          required
                          error={errors?.isApiClient ? true : false}
                          sx={{
                            label: {
                              color: "rgba(0, 0, 0, 0.25)",
                              fontWeight: "400",
                            },
                          }}
                          size="small"
                        >
                          <Select
                            value={value}
                            onChange={onChange}
                            sx={selectStyle}
                            MenuProps={{
                              style: {
                                width: "150px",
                                height: "250px",
                              },
                            }}
                          >
                            {Programmatic?.map((value, i) => {
                              return (
                                <MenuItem value={value.value} key={i}>
                                  {value.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors?.isApiClient && (
                            <FormHelperText>
                              {errors?.isApiClient ? errors.isApiClient.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container pt={3} spacing={3}>
                  <Grid item xs={12} md={12}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ borderBottom: "1px solid #D9D9D9" }}
                    >
                      {"Address"}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {label("Building/Apartment", true)}
                    <Controller
                      name="building"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          variant="outlined"
                          placeholder="Building"
                          disabled={isEdit.disabled}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {label("Street", true)}
                    <Controller
                      name="street"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          variant="outlined"
                          placeholder="Street"
                          disabled={isEdit.disabled}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {label("City", true)}
                    <Controller
                      name="city"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          type="text"
                          autoComplete="off"
                          variant="outlined"
                          {...register("city", {
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message: "Please Enter Valid Character",
                            },
                          })}
                          placeholder="City"
                          disabled={isEdit.disabled}
                          error={errors?.city ? true : false}
                          helperText={errors?.city ? errors.city.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {label("Province/State", true)}
                    <Controller
                      name="state"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="text"
                          autoComplete="off"
                          {...register("state", {
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message: "Please Enter Valid Character",
                            },
                          })}
                          variant="outlined"
                          placeholder="State"
                          disabled={isEdit.disabled}
                          error={errors?.state ? true : false}
                          helperText={errors?.state ? errors.state.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {label("Country", true)}
                    <Controller
                      name="country"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          type="text"
                          autoComplete="off"
                          {...register("country", {
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message: "Please Enter Valid Character",
                            },
                          })}
                          variant="outlined"
                          placeholder="Country"
                          disabled={isEdit.disabled}
                          error={errors?.country ? true : false}
                          helperText={errors?.country ? errors.country.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {label("Zip Code", true)}
                    <Controller
                      name="zipCode"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => (
                        <MDInput
                          {...field}
                          fullWidth
                          required
                          // type="number"
                          autoComplete="off"
                          {...register("zipCode", {
                            pattern: {
                              value: /^[0-9 ]*$/,
                              message: "Please Enter Valid Zip Code",
                            },
                          })}
                          variant="outlined"
                          placeholder="Zip Code"
                          disabled={isEdit.disabled}
                          error={errors?.zipCode ? true : false}
                          helperText={errors?.zipCode ? errors.zipCode.message : null}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={1.8}>
                  <Grid item xs={12} md={12}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ borderBottom: "1px solid #D9D9D9" }}
                    >
                      {"Basic Details"}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Client Name")}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("name")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Tooltip title="Client Code supported alphanumeric value" placement="top-start">
                      {label("Client Code")}
                    </Tooltip>
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("code")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Contact Number")}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("phone")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Email Address")}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("email")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {label("Status")}
                    <Typography fontSize="14px" fontWeight="600">
                      {
                        Status?.find((value) => Number(value.value) === Number(watch("isActive")))
                          ?.name
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Programmatic")}
                    <Typography fontSize="14px" fontWeight="600">
                      {
                        Programmatic?.find(
                          (value) => Number(value.value) === Number(watch("isApiClient"))
                        )?.name
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ borderBottom: "1px solid #D9D9D9" }}
                    >
                      {"Address"}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Building/Apartment", true)}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("building")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Street", true)}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("street")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("City", true)}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("city")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Province/State", true)}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("state")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Country", true)}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("country")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {label("Zip Code", true)}
                    <Typography fontSize="14px" fontWeight="600">
                      {watch("zipCode")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </MDBox>
        </MDBox>
      </form>
    </>
  );
}

export default ClientBasicDetails;
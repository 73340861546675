import React, { useState } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import TLinkApi from "services/tlink.api";
import PageLayout from "comps/LayoutContainers/PageLayout";
import { Backdrop, CircularProgress } from "@mui/material";
import colors from "assets/theme/base/colors";
import { Grid } from "@mui/material";
import signInLogo from "../../../assets/logo/sign_in_logo.png";

function UserResetPassword() {
  const { background } = colors;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      forgetPasswordToken: "",
    },
  });
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("t");
  const handleFormSubmit = async (requestData) => {
    setIsLoading(true);
    try {
      let payload = { ...requestData };
      payload.forgetPasswordToken = token;

      const { data: responseData, status } = await Axios.patch(
        TLinkApi.baseUrl + "/forgetPasswordUpdate",
        payload
      );
      if (status === 200) {
        enqueueSnackbar(responseData?.message, {
          variant: "success",
        });
        setIsLoading(false);
        navigate("/login");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout>
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{
            background: `linear-gradient(242deg, ${background.default} 0%, #CEDBFF5C 90%)`,
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Grid item xs={12} md={12} lg={5}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100vh" }}
            >
              <MDBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                mx={2}
                p={1}
                mb={1}
                textAlign="center"
                fontWeight="900"
                style={{
                  boxShadow:
                    "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
                }}
              >
                <MDBox
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                  mx={2}
                  p={2}
                  mb={1}
                  textAlign="center"
                  fontWeight="900"
                >
                  <MDBox
                    component="img"
                    mt={2}
                    ml={18}
                    src={signInLogo}
                    width="80px"
                    height="80px"
                    background="#00AEEF"
                    display="block"
                    object-fit="contain"
                  />
                  <MDBox textAlign="center" pt={5}>
                    <span className="welcomeCss">Reset Your </span>
                    <span className="backCss"> Password!</span>
                  </MDBox>
                  <MDBox textAlign="left" pt={1} pb={3} px={3}>
                    <MDBox pt={4} pb={3} px={3} sx={{ width: "350px" }}>
                      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                        <MDBox mb={2}>
                          <Controller
                            name="password"
                            control={control}
                            rules={{
                              required: "Password is required!",
                            }}
                            render={({ field }) => (
                              <MDInput
                                {...field}
                                type="password"
                                label="Password"
                                fullWidth
                                error={errors?.password ? true : false}
                                helperText={errors?.password ? errors.password.message : null}
                              />
                            )}
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{
                              required: "Confirm Password is required!",
                            }}
                            render={({ field }) => (
                              <MDInput
                                {...field}
                                type="password"
                                label="Confirm Password"
                                {...register("confirmPassword", {
                                  validate: (val) => {
                                    if (watch("password") != val) {
                                      return "Your passwords do no match";
                                    }
                                  },
                                })}
                                fullWidth
                                required
                                error={errors?.confirmPassword ? true : false}
                                helperText={
                                  errors?.confirmPassword ? errors.confirmPassword.message : null
                                }
                              />
                            )}
                          />
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton type="submit" variant="gradient" color="info" fullWidth>
                            Reset Password
                          </MDButton>
                        </MDBox>
                      </form>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

export default UserResetPassword;

import MDBox from "components/MDBox";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";
import BackIcon from "../../../../assets/images/Back.svg";

function InviteMember() {
  const { user, setUser, isEdit, setIsEdit, isLoading, setIsLoading } = useOutletContext();
  const location = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  let { textColor } = colors;
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (window.location.pathname === `/users`) {
      setValue(1);
    }
  }, [location]);

  let getUser = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/users/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/users/${id}`);

          setUser(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, [id]);

  return (
    <MDBox>
      <Stack spacing={0} minHeight="85px" pt={1.5}>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          pb={1}
          sx={{ borderBottom: `2px solid ${textColor.focus}` }}
        >
          <IconButton
            sx={{
              padding: 0,
              borderRadius: "4px !important",
            }}
            onClick={() => {
              navigate(`/users`);
            }}
          >
            <img src={BackIcon} />
          </IconButton>
          <Typography variant="h4">
            {id ? user?.firstName + "  " + user?.lastName : "New User"}
          </Typography>
        </Stack>
      </Stack>
      <Outlet context={{ user, setUser, setIsEdit, isEdit, getUser, isLoading, setIsLoading }} />
    </MDBox>
  );
}

export default InviteMember;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetSurveyRespondent = ({ id }) => {
  const [surveyRespondent, setSurveyRespondent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("status");
  const [sortOrder, setSortOrder] = useState("asc");

  const getSurveyRespondent = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(
        `/survey/${id}/respondents/status?sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page + 1}&limit=${entriesPerPage}`
      );
      setSurveyRespondent(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSurveyRespondent();
    // eslint-disable-next-line
  }, [id, sortBy, sortOrder, entriesPerPage, page]);

  return {
    getSurveyRespondent,
    surveyRespondent,
    isLoading,
    page,
    setPage,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    entriesPerPage,
    setEntriesPerPage,
  };
};

export default GetSurveyRespondent;

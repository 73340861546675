import React from "react";
import { useState, useEffect } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BackIcon from "../../assets/images/Back.svg";
import { Select, MenuItem } from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

const TitleCard = ({ name, newData, project, status, getProjectSummary, getData, setProject }) => {
  let navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();

  const [projectStatus, setprojectStatus] = useState(-1);
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);
  const [onLoad, setOnLoad] = useState(false);

  useEffect(() => {
    setprojectStatus(project?.status);
  }, [project]);

  const handleStatusChange = (newStatus) => {
    setprojectStatus(newStatus);
  };

  const changeProjectStatus = async (value) => {
    try {
      await TLinkApi.patch(`/projects/${project?.id}/status`, {
        projectStatusId: value,
      });
      await getProjectSummary();
      await getData();
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (projectStatus && projectStatus !== -1) {
      if (onLoad) {
        changeProjectStatus(projectStatus);
      } else {
        setOnLoad(true);
      }
    }
  }, [projectStatus]);

  const getStatusColor = (statusId) => {
    switch (statusId) {
      case 1:
        return "#FF9999";
      case 2:
        return "#26bd51";
      case 3:
        return "#fff363";
      case 4:
        return "#c29191";
      case 5:
        return "#ADD8E6";
      case 6:
        return "#4682B4";
      case 7:
        return "#D3D3D3";
      case 8:
        return "#A9A9A9";
      case 9:
        return "#696969";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Stack spacing={0} minHeight="85px" marginBottom="6px">
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack spacing={1} direction="row" alignItems="center" marginTop={newData ? "2px" : ""}>
            <IconButton
              sx={{
                padding: 0,
                borderRadius: "4px !important",
              }}
              onClick={() => {
                setProject({});
                navigate("/projects");
              }}
            >
              <img src={BackIcon} />
            </IconButton>
            <Typography variant="h4">{name}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {!newData && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginLeft={"30px"}
          marginTop={"10px"}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <MDBox
              sx={{
                backgroundColor: isDropdownHovered ? "#F0F0F0" : "transparent",
                color: getStatusColor(projectStatus),
                display: "flex",
                alignItems: "center",
                padding: "0px 2px !important",
                borderRadius: "8px",
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color: getStatusColor(projectStatus),
                  height: "10px",
                }}
              />
              <Select
                value={parseInt(projectStatus)}
                onChange={(event) => handleStatusChange(event.target.value)}
                sx={{
                  fontSize: "0.7rem",
                  border: "none",
                  height: "25px",
                  "& fieldset": { border: "none" },
                }}
                onMouseEnter={() => setIsDropdownHovered(true)}
                onMouseLeave={() => setIsDropdownHovered(false)}
              >
                {status.map((value, i) => {
                  return (
                    <MenuItem key={i} value={parseInt(value.id)}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </MDBox>

            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox
                sx={{ fontSize: "0.7rem", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/clients/${project?.clientId}`);
                }}
              >
                {project?.clientName}
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox
                sx={{ fontSize: "0.7rem", cursor: "pointer" }}
                onClick={() => {
                  setProject({});
                  navigate("/projects");
                }}
              >
                Projects
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox sx={{ fontSize: "0.7rem" }}>{project?.id}</MDBox>
            </MDBox>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            <MDBox
              sx={{
                color: "#7A7A7A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiberManualRecordIcon sx={{ color: "#7A7A7A", height: "10px" }} fontSize="small" />
              <MDBox sx={{ fontSize: "0.7rem" }}>{project?.projectManager}</MDBox>
            </MDBox>
            <MDBox sx={{ fontSize: "0.7rem", color: "#7A7A7A" }}>
              {moment(project?.startDate).format("YYYY-MM-DD")} -{" "}
              {moment(project?.endDate).format("YYYY-MM-DD")}
            </MDBox>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default TitleCard;

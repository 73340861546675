import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Survey() {
  const [survey, setSurvey] = useState({});
  const [project, setProject] = useState(-1);
  const [isEdit, setIsEdit] = useState({ disabled: false, edit: false });
  return <Outlet context={{ survey, setSurvey, setIsEdit, isEdit, project, setProject }} />;
}
export default Survey;

import MDBox from "components/MDBox";
import React from "react";

const Label = ({ value, required }) => {
  return (
    <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
      {value} {required && <span>* &nbsp;</span>}
    </MDBox>
  );
};
export default Label;

import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { Backdrop, CircularProgress, Icon, IconButton, Stack, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Label from "components/InputLabel";

const VendorSupplyConfig = () => {
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [newAdd, setNewAdd] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: true,
    edit: false,
  });
  const { id } = useParams();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      id: "",
      minCpi: "",
      maxCpi: "",
      minIr: "",
      maxIr: "",
      minLoi: "",
      maxLoi: "",
      minVcpi: "",
      maxVcpi: "",
      minConversion: "",
      minEpc: "",
      minSampleRequired: "",
    },
  });

  useEffect(() => {
    if (id) getData();
  }, [id]);

  async function getData() {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/vendors/supply/config/${id}`);
        if (Object.keys(result.data).length > 0) {
          setNewAdd(false);
          setValue("id", result.data.id);
          setValue("minCpi", result.data.minCpi);
          setValue("maxCpi", result.data.maxCpi);
          setValue("minIr", result.data.minIr);
          setValue("maxIr", result.data.maxIr);
          setValue("minLoi", result.data.minLoi);
          setValue("maxLoi", result.data.maxLoi);
          setValue("minVcpi", result.data.minVcpi);
          setValue("maxVcpi", result.data.maxVcpi);
          setValue("minConversion", result.data.minConversion);
          setValue("minEpc", result.data.minEpc);
          setValue("minSampleRequired", result.data.minSampleRequired);
        } else {
          setNewAdd(true);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (newAdd !== true) {
        let payloadId = data.id;
        let put_payload = {
          min_cpi: Number(data.minCpi),
          max_cpi: Number(data.maxCpi),
          min_ir: Number(data.minIr),
          max_ir: Number(data.maxIr),
          min_loi: Number(data.minLoi),
          max_loi: Number(data.maxLoi),
          min_vcpi: Number(data.minVcpi),
          max_vcpi: Number(data.maxVcpi),
          min_conversion: Number(data.minConversion),
          min_epc: Number(data.minEpc),
          min_sample_required: Number(data.minSampleRequired),
        };

        let result = await TLinkApi.put(`/vendors/supply/config/${payloadId}`, put_payload);
        await getData();
        setIsLoading(false);
        setNewAdd(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let post_payload = {
          vendor_id: parseInt(id),
          min_cpi: Number(data.minCpi),
          max_cpi: Number(data.maxCpi),
          min_ir: Number(data.minIr),
          max_ir: Number(data.maxIr),
          min_loi: Number(data.minLoi),
          max_loi: Number(data.maxLoi),
          min_vcpi: Number(data.minVcpi),
          max_vcpi: Number(data.maxVcpi),
          min_conversion: Number(data.minConversion),
          min_epc: Number(data.minEpc),
          min_sample_required: Number(data.minSampleRequired),
        };

        let result = await TLinkApi.post("vendors/supply/config/", post_payload);
        await getData();
        setNewAdd(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <MDBox sx={{ height: "calc(100vh - 252px)", overflowY: "auto" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isEdit.edit || newAdd ? (
        <form onSubmit={handleSubmit(save)} noValidate>
          <MDBox p={2} mx={20}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" color="dark">
                    Vendor Supply Config
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <MDButton
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        getData();
                        if (newAdd) {
                          navigate("/vendors");
                        } else {
                          setIsEdit({
                            disabled: true,
                            edit: false,
                          });
                        }
                      }}
                    >
                      &nbsp;cancel
                    </MDButton>
                    <MDButton variant="gradient" color="info" type="submit" size="small">
                      Save
                    </MDButton>
                  </Stack>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Cpi($)" required={true} />
                <MDInput
                  value={watch("minCpi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minCpi", {
                    required: "Min CPI is required!",
                    min: {
                      value: 0.0001,
                      message: "Min Cpi should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxCpi"),
                      message: "Min Cpi should be smaller than Max Cpi",
                    },
                  })}
                  placeholder="Min Cpi"
                  error={errors?.minCpi ? true : false}
                  helperText={
                    errors?.minCpi && errors.minCpi.message !== ""
                      ? errors.minCpi.message
                      : errors?.minCpi?.type === "max"
                      ? "Max Cpi cannot be smaller than Min Cpi"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("minCpi", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max Cpi($)" required={true} />
                <MDInput
                  value={watch("maxCpi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxCpi", {
                    required: "Max Cpi is required!",
                    min: {
                      value: watch("minCpi"),
                      message: "Max Cpi should be greater than Min Cpi",
                    },
                  })}
                  placeholder="Max Cpi"
                  error={errors?.maxCpi ? true : false}
                  helperText={
                    errors?.maxCpi && errors.maxCpi.message !== ""
                      ? errors.maxCpi.message
                      : errors?.maxCpi?.type === "min"
                      ? "Min Cpi cannot be greater than Max Cpi"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("maxCpi", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Ir(%)" required={true} />
                <MDInput
                  value={watch("minIr")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minIr", {
                    required: "Min Ir is required!",
                    min: {
                      value: 0.0001,
                      message: "Min Ir should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxIr"),
                      message: "Min Ir should be smaller than Max Ir",
                    },
                  })}
                  placeholder="Min IR"
                  error={errors?.minIr ? true : false}
                  helperText={
                    errors?.minIr && errors.minIr.message !== ""
                      ? errors.minIr.message
                      : errors?.minIr?.type === "max"
                      ? "Max Ir cannot be smaller than Min Ir"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("minIr", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max Ir(%)" required={true} />
                <MDInput
                  value={watch("maxIr")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxIr", {
                    required: "Max Ir is required!",
                    min: {
                      value: watch("minIr"),
                      message: "Max Ir should be greater than Min Ir",
                    },
                  })}
                  placeholder="Max IR"
                  error={errors?.maxIr ? true : false}
                  helperText={
                    errors?.maxIr && errors.maxIr.message !== ""
                      ? errors.maxIr.message
                      : errors?.maxIr?.type === "min"
                      ? "Min Ir cannot be greater than Max Ir"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("maxIr", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Loi(mins)" required={true} />
                <MDInput
                  value={watch("minLoi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minLoi", {
                    required: "Min Loi is required!",
                    min: {
                      value: 0.0001,
                      message: "Min Loi should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxLoi"),
                      message: "Min Loi should be smaller than Max Loi",
                    },
                    validate: (value) => {
                      if (value % 1 !== 0) {
                        return "Please enter an integer value for Min Loi";
                      }
                      return true;
                    },
                  })}
                  placeholder="Min Loi"
                  error={errors?.minLoi ? true : false}
                  helperText={
                    errors?.minLoi && errors.minLoi.message !== ""
                      ? errors.minLoi.message
                      : errors?.minLoi?.type === "max"
                      ? "Max Loi cannot be smaller than Min Loi"
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max Loi(mins)" required={true} />
                <MDInput
                  value={watch("maxLoi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxLoi", {
                    required: "Max Loi is required!",
                    min: {
                      value: watch("minLoi"),
                      message: "Max Loi should be greater than Min Loi",
                    },
                    validate: (value) => {
                      if (value % 1 !== 0) {
                        return "Please enter an integer value for Max Loi";
                      }
                      return true;
                    },
                  })}
                  placeholder="Max Loi"
                  error={errors?.maxLoi ? true : false}
                  helperText={
                    errors?.maxLoi && errors.maxLoi.message !== ""
                      ? errors.maxLoi.message
                      : errors?.maxLoi?.type === "min"
                      ? "Min Loi cannot be greater than Max Loi"
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min VCpi($)" required={true} />
                <MDInput
                  value={watch("minVcpi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minVcpi", {
                    required: "Min Vcpi is required!",
                    min: {
                      value: 0,
                      message: "Min VCpi should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxVcpi"),
                      message: "Min VCpi should be smaller than Max VCpi",
                    },
                  })}
                  placeholder="Min VCpi"
                  error={errors?.minVcpi ? true : false}
                  helperText={
                    errors?.minVcpi && errors.minVcpi.message !== ""
                      ? errors.minVcpi.message
                      : errors?.minVcpi?.type === "max"
                      ? "Max VCpi cannot be smaller than Min VCpi"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("minVcpi", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max VCpi($)" required={true} />
                <MDInput
                  value={watch("maxVcpi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxVcpi", {
                    required: "Max Vcpi is required!",
                    min: {
                      value: watch("minVcpi"),
                      message: "Max VCpi should be greater than Min VCpi",
                    },
                  })}
                  placeholder="Max VCpi"
                  error={errors?.maxVcpi ? true : false}
                  helperText={
                    errors?.maxVcpi && errors.maxVcpi.message !== ""
                      ? errors.maxVcpi.message
                      : errors?.maxVcpi?.type === "min"
                      ? "Min VCpi cannot be greater than Max VCpi"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("maxVcpi", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min SampleRequired" required={true} />
                <Controller
                  name="minSampleRequired"
                  control={control}
                  rules={{
                    required: "Min SampleRequired is required!",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid integer for Min SampleRequired",
                    },
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      {...register("minSampleRequired", {
                        min: {
                          value: 0.0001,
                          message: "Min Sample Required should be greater than 0",
                        },
                      })}
                      variant="outlined"
                      placeholder="Min SampleRequired"
                      error={errors?.minSampleRequired ? true : false}
                      helperText={
                        errors?.minSampleRequired ? errors.minSampleRequired.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Converson(%)" required={true} />
                <Controller
                  name="minConversion"
                  control={control}
                  rules={{
                    required: "Min Converson is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      {...register("minConversion", {
                        min: {
                          value: 0.0001,
                          message: "Min Converson should be greater than 0 ",
                        },
                      })}
                      placeholder="Min Converson"
                      error={errors?.minConversion ? true : false}
                      helperText={
                        errors?.minConversion && errors.minConversion.message !== ""
                          ? errors.minConversion.message
                          : null
                      }
                      InputProps={{
                        inputProps: {
                          step: 0.01,
                        },
                      }}
                      onBlur={(e) => {
                        const roundedValue = parseFloat(e.target.value).toFixed(2);
                        setValue("minConversion", roundedValue);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Epc" required={true} />
                <Controller
                  name="minEpc"
                  control={control}
                  rules={{
                    required: "Min Epc is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      {...register("minEpc", {
                        min: {
                          value: 0.0001,
                          message: "Min Epc should be greater than 0 ",
                        },
                      })}
                      placeholder="Min Epc"
                      error={errors?.minEpc ? true : false}
                      helperText={
                        errors?.minEpc && errors.minEpc.message !== ""
                          ? errors.minEpc.message
                          : null
                      }
                      InputProps={{
                        inputProps: {
                          step: 0.01,
                        },
                      }}
                      onBlur={(e) => {
                        const roundedValue = Math.round(Number(e.target.value) * 100) / 100;
                        setValue("minEpc", roundedValue);
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
        </form>
      ) : (
        <MDBox p={2} mx={20}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="dark">
                  Vendor Supply Config
                </Typography>
                <MDBox>
                  {isEdit.disabled === true && (
                    <IconButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      onClick={() => {
                        setIsEdit({
                          disabled: false,
                          edit: true,
                        });
                      }}
                    >
                      <Icon>border_color_icon</Icon>
                    </IconButton>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Cpi($)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minCpi") ? watch("minCpi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max Cpi($)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxCpi") ? watch("maxCpi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Ir(%)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minIr") ? watch("minIr") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max Ir(%)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxIr") ? watch("maxIr") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Loi(mins)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minLoi") ? watch("minLoi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max Loi(mins)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxLoi") ? watch("maxLoi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min VCpi($)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minVcpi") ? watch("minVcpi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max VCpi($)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxVcpi") ? watch("maxVcpi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min SampleRequired" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minSampleRequired") ? watch("minSampleRequired") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Converson(%)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minConversion") ? watch("minConversion") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Epc" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minEpc") ? watch("minEpc") : 0}
              </Typography>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

export default VendorSupplyConfig;

import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Stack, Switch, ThemeProvider, Tooltip } from "@mui/material";
import useTableTheme from "constant/TableTheme";
import { useParams } from "react-router-dom";

const QuotaTable = ({
  rows,
  qualification,
  addData,
  isEdit,
  setFinalRows,
  QuotaActiveDeactive,
  QuotaGroupActiveDeactive,
  proceedData,
  setWarning,
  calculationId,
}) => {
  const tableTheme = useTableTheme();
  const { id } = useParams();
  const [groupedColumnMode, setGroupedColumnMode] = useState("reorder");
  const [grouping, setGrouping] = useState([]);

  useEffect(() => {
    const localGrouping = JSON.parse(localStorage.getItem("grouping"));
    if (localGrouping) {
      let index = localGrouping.findIndex((val) => val.id === id);
      if (index !== -1) {
        setGrouping(localGrouping.find((val) => val.id === id)?.grouping);
      } else {
        setGrouping([]);
      }
    }
  }, [rows]);

  useEffect(() => {
    let localGrouping = JSON.parse(localStorage.getItem("grouping"));
    if (localGrouping) {
      let index = localGrouping.findIndex((val) => val.id === id);
      if (index !== -1) {
        localGrouping.find((val) => val.id === id).grouping = grouping;
        localStorage.setItem("grouping", JSON.stringify(localGrouping));
      } else {
        localGrouping.push({ id: id, grouping: grouping });
        localStorage.setItem("grouping", JSON.stringify(localGrouping));
      }
    } else {
      localStorage.setItem("grouping", JSON.stringify([{ id: id, grouping: grouping }]));
    }
  }, [grouping]);

  const initialIformData = useMemo(() => {
    return rows?.map((item) => ({
      Quota: item.quota.sampleNumber ? item.quota.sampleNumber : 0,
      Starts: item.quota.starts,
      Completes: item.quota.completes,
      Conversion:
        item.quota.starts !== 0 ? ((item.quota.completes / item.quota.starts) * 100).toFixed(2) : 0,
      Remaining:
        item.quota.sampleNumber && calculationId == 1
          ? item.quota.sampleNumber - item.quota.completes
          : item.quota.sampleNumber && calculationId == 2
          ? item.quota.sampleNumber - item.quota.starts
          : 0,
      Actions: item.quota.isActive || false,
      conditions: item.quota.conditions,
      id: item.quota.id,
    }));
  }, [rows]);

  const [iformatedData, setIformData] = useState(initialIformData);

  useEffect(() => {
    setIformData(initialIformData);
  }, [initialIformData]);

  useEffect(() => {
    setFinalRows(iformatedData);
    setGroupedColumnMode("reorder");
  }, [iformatedData]);

  const handleToggle = (id, enableActions) => {
    QuotaActiveDeactive(enableActions, id);
  };

  const handleQuotaChange = (id, newRemainingValue, newQuotaValue) => {
    setIformData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, Quota: newQuotaValue, Remaining: newRemainingValue };
        }
        return item;
      });
    });
    setWarning(true);
  };

  const maxQuota = useMemo(
    () =>
      iformatedData.reduce(
        (acc, curr) => Math.max(acc, Number(curr.Quota) ? Number(curr.Quota) : 0),
        0
      ),
    [iformatedData]
  );

  const totalStarts = useMemo(
    () => iformatedData.reduce((acc, curr) => acc + curr.Starts, 0),
    [iformatedData]
  );

  const totalCompletes = useMemo(
    () => iformatedData.reduce((acc, curr) => acc + curr.Completes, 0),
    [iformatedData]
  );

  const totalConversion = useMemo(
    () => (totalStarts !== 0 ? totalCompletes / totalStarts : 0),
    [totalCompletes, totalStarts]
  );

  const totalRemaining = useMemo(
    () =>
      iformatedData.reduce(
        (acc, curr) => acc + (Number(curr.Remaining) ? Number(curr.Remaining) : 0),
        0
      ),
    [iformatedData]
  );

  const dynamicColumns = useMemo(() => {
    const uniqueQualificationIds = new Set();
    iformatedData.forEach((item) => {
      item.conditions.forEach((condition) => {
        uniqueQualificationIds.add(condition.qualificationId);
      });
    });

    const columns = [];
    uniqueQualificationIds.forEach((id) => {
      columns.push({
        accessorKey: `qualificationId_${id}`,
        header: qualification?.find((val) => val.qualificationId === id)?.question?.questionSubText
          ? qualification?.find((val) => val.qualificationId === id)?.question?.questionSubText
          : `${id}`,
        size: 150,
        enableGrouping: true,
        accessorFn: (rowData) => {
          const matchingCondition = rowData.conditions.find(
            (condition) => condition.qualificationId === id
          );
          return matchingCondition?.qualificationId === id ? (
            matchingCondition.answerText.length > 1 ? (
              <Tooltip
                placement="bottom-start"
                title={
                  <ul>
                    {matchingCondition.answerText.map((val) => {
                      return <li>{val}</li>;
                    })}
                  </ul>
                }
              >
                {matchingCondition.answerText[0]}
                {matchingCondition.answerText.length > 1 ? (
                  <b> +{matchingCondition.answerText.length - 1}</b>
                ) : (
                  ""
                )}
              </Tooltip>
            ) : matchingCondition.answerText.join(", ").length > 25 ? (
              <Tooltip title={matchingCondition.answerText.join(", ")}>
                {matchingCondition.answerText.join(", ").substring(0, 25) + "..."}
              </Tooltip>
            ) : (
              matchingCondition.answerText.join(", ")
            )
          ) : (
            ""
          );
        },
      });
    });
    return columns;
  }, [iformatedData, proceedData]);

  const staticColumns = [
    {
      accessorKey: "Quota",
      header: "Quota",
      size: 150,
      enableGrouping: false,
      aggregationFn: "sum",
      Cell: ({ cell, row }) => {
        const handleChange = (e) => {
          const newQuotaValue = parseInt(e.target.value);
          let newRemainingValue = 0;
          if (calculationId == 1) {
            newRemainingValue = newQuotaValue - row.original.Completes;
          } else if (calculationId == 2) {
            newRemainingValue = newQuotaValue - row.original.Starts;
          }
          handleQuotaChange(row.original.id, newRemainingValue, newQuotaValue);
        };
        return (
          <Box>
            {addData || isEdit.edit ? (
              <input
                value={cell.getValue()}
                onChange={handleChange}
                variant="outlined"
                type="number"
                min="0"
                disabled={!row.original.Actions}
              />
            ) : (
              <span>{cell.getValue()}</span>
            )}
          </Box>
        );
      },

      AggregatedCell: ({ cell, row }) => {
        return (
          <>
            <Box>
              <span>{cell.getValue()}</span>
            </Box>
          </>
        );
      },

      Footer: () => (
        <>
          Max Quota:
          <Box color="primary.main">{Math.round(maxQuota)}</Box>
        </>
      ),
    },

    {
      accessorKey: "Starts",
      header: "Starts",
      size: 150,
      enableGrouping: false,

      aggregationFn: "sum",

      AggregatedCell: ({ cell, table }) => (
        <>
          {" "}
          <Box>{cell.getValue()}</Box>
        </>
      ),
      Footer: () => (
        <>
          Total starts:
          <Box color="primary.main">{Math.round(totalStarts)}</Box>
        </>
      ),
    },
    {
      accessorKey: "Completes",
      header: "Completes",
      size: 150,
      enableGrouping: false,
      aggregationFn: "sum",

      AggregatedCell: ({ cell, table }) => (
        <>
          {" "}
          <Box>{cell.getValue()}</Box>
        </>
      ),
      Footer: () => (
        <>
          Total completes:
          <Box color="primary.main">{Math.round(totalCompletes)}</Box>
        </>
      ),
    },

    {
      accessorKey: "Conversion",
      header: "Conversion",
      size: 150,
      enableGrouping: false,
      aggregationFn: "mean",

      AggregatedCell: ({ cell, table }) => (
        <>
          {" "}
          <Box>{cell.getValue()}</Box>
        </>
      ),
      Footer: () => (
        <>
          Total conversion:
          <Box color="primary.main">{totalConversion.toFixed(2)}</Box>
        </>
      ),
    },

    {
      accessorKey: "Remaining",
      header: "Remaining",
      size: 150,
      enableGrouping: false,
      aggregationFn: "sum",

      Cell: ({ cell, row }) => {
        const handleChange = (e) => {
          const newRemainingValue = parseInt(e.target.value);
          let newQuotaValue = 0;
          if (calculationId == 1) {
            newQuotaValue = row.original.Completes + newRemainingValue;
          } else if (calculationId == 2) {
            newQuotaValue = row.original.Starts + newRemainingValue;
          }
          if (addData || proceedData) {
            handleQuotaChange(row.original.id, newRemainingValue, newQuotaValue);
          } else {
            setIformData((prevData) => {
              return prevData.map((item) => {
                if (item.id === row.original.id) {
                  return { ...item, Remaining: newRemainingValue, Quota: newQuotaValue };
                }
                return item;
              });
            });
            setWarning(true);
          }
        };
        return (
          <Box>
            {addData || isEdit.edit ? (
              <input
                value={addData || proceedData ? row.original.Quota : cell.getValue()}
                onChange={handleChange}
                variant="outlined"
                type="number"
                disabled={!row.original.Actions}
                min="0"
              />
            ) : (
              <span>{cell.getValue()}</span>
            )}
          </Box>
        );
      },
      AggregatedCell: ({ cell, table }) => (
        <>
          {" "}
          <Box>{cell.getValue()}</Box>
        </>
      ),
      Footer: () => (
        <>
          Total Remaining:
          <Box color="primary.main">{Math.round(totalRemaining)}</Box>
        </>
      ),
    },

    {
      accessorKey: "Actions",
      header: "Actions",
      size: 150,
      enableGrouping: false,

      Cell: ({ row }) => (
        <Box sx={{ display: addData || isEdit.edit ? "none" : "" }}>
          <Switch
            size="small"
            color="info"
            checked={row?.original?.Actions}
            onChange={() => handleToggle(row.original.id, !row.original.Actions)}
          />
        </Box>
      ),
      accessorFn: (rowData) => rowData.Actions,
      AggregatedCell: ({ row }) => {
        return (
          <Box sx={{ display: addData || isEdit.edit ? "none" : "" }}>
            <Switch
              color="info"
              checked={row?.original?.Actions}
              onChange={(e) => {
                let quotaIds = row.leafRows.map((val) => val.original.id);
                QuotaGroupActiveDeactive(e.target.checked, quotaIds);
              }}
            />
          </Box>
        );
      },
    },
  ];

  const columns = useMemo(() => {
    let mergedColumns = dynamicColumns.concat(staticColumns);
    if (addData || isEdit.edit) {
      mergedColumns = mergedColumns.filter((val) => val.header !== "Actions");
    }
    return mergedColumns;
  }, [dynamicColumns, isEdit]);

  const table = useMaterialReactTable({
    columns: columns,
    data: iformatedData,
    enableGrouping: true,
    onGroupingChange: setGrouping,
    groupedColumnMode,
    initialState: {
      expanded: true,
      pagination: { pageSize: 50 },
    },
    state: {
      grouping,
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
  });

  return (
    <div>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable table={table} />
      </ThemeProvider>
    </div>
  );
};

export default QuotaTable;

import { FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React from "react";
import { Controller } from "react-hook-form";

const OtherSecurity = ({ researchDefender, countryMismatch, control, setValue, isEdit }) => {
  return (
    <MDBox mt={2}>
      <MDTypography fontSize="14px" fontWeight="medium" mb={1}>
        Other Security
      </MDTypography>

      <Grid container spacing={0} px={2}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={countryMismatch}
                onChange={(e, value) => {
                  setValue("countryMismatch", value);
                }}
                disabled={isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                Country mismatch
              </MDTypography>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={researchDefender}
                onChange={(e, value) => {
                  setValue("researchDefender", value);
                }}
                disabled={isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                Research Defender
              </MDTypography>
            }
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} my={1}>
          <MDTypography fontWeight="regular" color="dark" fontSize="12px">
            Research Defender Score
          </MDTypography>

          <Controller
            name="researchDefenderScore"
            control={control}
            render={({ field }) => (
              <MDInput
                {...field}
                sx={{ width: "160px" }}
                disabled={isEdit.disabled}
                autoComplete="off"
                type="number"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default OtherSecurity;

import { Backdrop, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../performance.css";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import colors from "assets/theme/base/colors";
import BigNumber from "bignumber.js";

const PerformanceOverall = () => {
  const [overAll, setOverAll] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { warning, success, error } = colors;
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  function isHTML(str) {
    var a = document.createElement("div");
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }

  const GetOverAllData = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/projects/performance/${id}/all`);
      if (!isHTML(result.data)) {
        setOverAll(result.data);
      }
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
      });
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) GetOverAllData();
  }, [id]);

  const formatCash = (n) => {
    if (isNaN(n) || !isFinite(n)) return '0'; 
    const num = new BigNumber(n).toNumber();
    if (num < 1e3) return num.toString();
    if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(2) + "K";
    if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(2) + "M";
    if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(2) + "B";
    if (num >= 1e12) return +(num / 1e12).toFixed(2) + "T";
    return num.toString();
};

  return (
    <Stack direction="row" justifyContent="space-between" p={2}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Stack spacing={0}>
          <div className="styled-value">
            {overAll?.starts ? formatCash(new BigNumber(overAll.starts)) : "0"}
          </div>
          <div className="styled-key">Starts</div>
        </Stack>
        <Stack spacing={0}>
          <div className="styled-value">
            {overAll?.completes ? formatCash(new BigNumber(overAll?.completes)) : 0}
          </div>
          <div className="styled-key">Completes</div>
        </Stack>
        <Stack spacing={0}>
          <div
            className="value-percentege"
            style={{
              color:
                overAll?.conversion?.status === "good"
                  ? success.main
                  : overAll?.conversion?.status === "low"
                  ? error.main
                  : warning.main,
            }}
          >
            {overAll?.conversion?.value ? Math.round(formatCash(overAll?.conversion?.value)) : 0}%
          </div>
          <div className="styled-key">Conv.</div>
        </Stack>
        <Stack spacing={0}>
          <div
            className="value-percentege"
            style={{
              color:
                overAll?.clientConversion?.status === "good"
                  ? success.main
                  : overAll?.clientConversion?.status === "low"
                  ? error.main
                  : warning.main,
            }}
          >
            {overAll?.clientConversion?.value ? Math.round(formatCash(overAll?.clientConversion?.value)) : 0}%
          </div>
          <div className="styled-key">Client Conv.</div>
        </Stack>
      </Stack>
      <div style={{ display: "block", borderLeft: "1.8px solid #A7A7A7" }}></div>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Stack spacing={0}>
          <div className="styled-value">
            ${overAll?.cpi ? Math.round(formatCash(overAll?.cpi)) : 0}
          </div>
          <div className="styled-key">CPI</div>
        </Stack>
        <Stack spacing={0}>
          <div className="styled-value">
            ${overAll?.cpc ? Math.round(formatCash(overAll?.cpc)) : 0}
          </div>
          <div className="styled-key">CPC</div>
        </Stack>
        <Stack spacing={0}>
          <div
            className="value-percentege"
            style={{
              color:
                overAll?.epc?.status === "good"
                  ? success.main
                  : overAll?.epc?.status === "low"
                  ? error.main
                  : warning.main,
            }}
          >
            ${overAll?.epc?.value ? Math.round(formatCash(overAll?.epc?.value)) : 0}
          </div>
          <div className="styled-key">EPC.</div>
        </Stack>
      </Stack>
      <div style={{ display: "block", borderLeft: "1.8px solid #A7A7A7" }}></div>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Stack spacing={0}>
          <div className="styled-value">
            ${overAll?.revenue ? formatCash(new BigNumber(overAll?.revenue)) : 0}
          </div>
          <div className="styled-key">Revenue</div>
        </Stack>
        <Stack spacing={0}>
          <div className="styled-value">
            ${overAll?.costs ? formatCash(new BigNumber(overAll?.costs)) : 0}
          </div>
          <div className="styled-key">Cost</div>
        </Stack>
        <Stack spacing={0}>
          <div
            className="value-percentege"
            style={{
              color:
                overAll?.profitPercentage?.status === "good"
                  ? success.main
                  : overAll?.profitPercentage?.status === "low"
                  ? error.main
                  : warning.main,
            }}
          >
            {overAll?.profitPercentage?.value
              ? Math.round(formatCash(overAll?.profitPercentage?.value))
              : 0}
            %
          </div>
          <div className="styled-key">Profit.</div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PerformanceOverall;

import { Backdrop, CircularProgress, FormControl, MenuItem, Select, Stack } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import SupplierData from "../SupplierData";
import SurveyData from "../SurveyData";
import LanguageData from "../LanguageData";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import AllVendors from "hooks/listAllVendors/AllVendors";
import Label from "components/InputLabel";
import SurveyAllListWithProjectId from "hooks/SurveyAllListWithProjectId/SurveyAllListWithProjectId";

const PerformanceTab = ({ page, setPage, loader, setLoader, setApiCall }) => {
  const [tabActive, setTabActive] = useState(1);
  const { surveyStatusList } = GetSurveyStatusList();
  const { language } = GetLanguageList();
  const { surveyList } = SurveyAllListWithProjectId();
  const { vendors } = AllVendors();
  const [surveyData, setSurveyData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [supplier, setSupplier] = useState(-1);
  const [selectedLanguage, setSelectedLanguage] = useState(-1);
  const [selectedStatus, setSelectedStatus] = useState("2");
  const [selectedSurvey, setSelectedSurvey] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  function isHTML(str) {
    var a = document.createElement("div");
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }

  const GetSurveyData = async () => {
    if (!loader) setIsLoading(true);
    try {
      let result = await TLinkApi.get(
        `/projects/performance/${id}/surveys?surveyStatusFilter=${selectedStatus}&languageFilter=${selectedLanguage}&surveyFilter=${selectedSurvey}&page=${page}`
      );
      if (!isHTML(result.data)) {
        if (result.data.length === 0) {
          setApiCall(false);
          setLoader(false);
          if (page === 1) {
            setSurveyData([]);
          }
        } else {
          setLoader(false);
          if (page > 1) {
            let resultData = [...surveyData, ...result.data];
            setSurveyData(resultData);
          } else {
            setSurveyData(result.data);
          }
        }
      } else {
        setLoader(false);
      }
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
      console.log(e);
    }
  };

  const GetSupllierData = async () => {
    if (!loader) setIsLoading(true);
    try {
      let result = await TLinkApi.get(
        `/projects/performance/${id}/suppliers?surveyStatusFilter=${selectedStatus}&languageFilter=${selectedLanguage}&surveyFilter=${selectedSurvey}&page=${page}`
      );
      if (!isHTML(result.data)) {
        if (result.data.length === 0) {
          setApiCall(false);
          setLoader(false);
          if (page === 1) {
            setSupplierData([]);
          }
        } else {
          setLoader(false);
          if (page > 1) {
            let resultData = [...supplierData, ...result.data];
            setSupplierData(resultData);
          } else {
            setSupplierData(result.data);
          }
        }
      } else {
        setLoader(false);
      }
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
      console.log(e);
    }
  };

  const GetMarketData = async () => {
    if (!loader) setIsLoading(true);
    try {
      let result = await TLinkApi.get(
        `/projects/performance/${id}/languages?surveyStatusFilter=${selectedStatus}&vendorFilter=${supplier}&surveyFilter=${selectedSurvey}&page=${page}`
      );
      if (!isHTML(result.data)) {
        if (result.data.length === 0) {
          setApiCall(false);
          setLoader(false);
          if (page === 1) {
            setLanguageData([]);
          }
        } else {
          setLoader(false);
          if (page > 1) {
            let resultData = [...languageData, ...result.data];
            setLanguageData(resultData);
          } else {
            setLanguageData(result.data);
          }
        }
      } else {
        setLoader(false);
      }
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      if (tabActive === 1 && (selectedStatus || selectedLanguage || selectedSurvey)) {
        GetSurveyData();
      } else if (tabActive === 2 && (selectedStatus || selectedLanguage || selectedSurvey)) {
        GetSupllierData();
      } else if (tabActive === 3 && (selectedStatus || supplier || selectedSurvey)) {
        GetMarketData();
      }
    }
  }, [id, tabActive, selectedStatus, supplier, selectedLanguage, selectedSurvey, page]);

  let selectStyle = {
    height: "32px",
    width: "150px",
    borderRadius: "0px",
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <MDButton
            variant={tabActive === 1 ? "contained" : "outlined"}
            color="primary"
            size="small"
            onClick={() => {
              setTabActive(1);
              setPage(1);
              setApiCall(true);
              setLoader(false);
            }}
          >
            Surveys
          </MDButton>

          <MDButton
            variant={tabActive === 2 ? "contained" : "outlined"}
            color="primary"
            size="small"
            onClick={() => {
              setTabActive(2);
              setPage(1);
              setApiCall(true);
              setLoader(false);
            }}
          >
            Suppliers
          </MDButton>
          <MDButton
            variant={tabActive === 3 ? "contained" : "outlined"}
            color="primary"
            size="small"
            onClick={() => {
              setTabActive(3);
              setPage(1);
              setApiCall(true);
              setLoader(false);
            }}
          >
            Markets
          </MDButton>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack>
            <Label value="Survey Status" />
            <FormControl
              fullWidth
              sx={{
                label: {
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <Select
                value={selectedStatus.toString()}
                onChange={(e) => {
                  setPage(1);
                  setApiCall(true);
                  setLoader(false);
                  setSelectedStatus(Number(e.target.value));
                }}
                sx={selectStyle}
              >
                <MenuItem value={-1}>All</MenuItem>
                {surveyStatusList.map((value, i) => {
                  return (
                    <MenuItem value={value.id} key={i}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{ display: tabActive === 3 ? "none" : "" }}>
            <Label value="Market" />
            <FormControl
              fullWidth
              sx={{
                label: {
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <Select
                value={selectedLanguage.toString()}
                onChange={(e) => {
                  setPage(1);
                  setApiCall(true);
                  setLoader(false);
                  setSelectedLanguage(Number(e.target.value));
                }}
                sx={selectStyle}
              >
                <MenuItem value={-1}>All</MenuItem>
                {language.map((value, i) => {
                  return (
                    <MenuItem value={value.id} key={i}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{ display: tabActive !== 3 ? "none" : "" }}>
            <Label value="Supplier" />
            <FormControl
              fullWidth
              sx={{
                label: {
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <Select
                value={supplier.toString()}
                onChange={(e) => {
                  setSupplier(Number(e.target.value));
                }}
                sx={selectStyle}
              >
                <MenuItem value={-1}>All</MenuItem>
                {vendors.map((value, i) => {
                  return (
                    <MenuItem value={value.id} key={i}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack>
            <Label value="Survey" />
            <FormControl
              fullWidth
              sx={{
                label: {
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <Select
                value={selectedSurvey.toString()}
                onChange={(e) => {
                  setPage(1);
                  setApiCall(true);
                  setLoader(false);
                  setSelectedSurvey(Number(e.target.value));
                }}
                sx={selectStyle}
              >
                <MenuItem value={-1}>All</MenuItem>
                {surveyList.map((value, i) => {
                  return (
                    <MenuItem value={value.id} key={i}>
                      {value.surveyname}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      {tabActive === 1 ? (
        <SurveyData surveyData={surveyData} />
      ) : tabActive === 2 ? (
        <SupplierData supplierData={supplierData} />
      ) : (
        <LanguageData languageData={languageData} />
      )}
      <Stack direction="row" alignItems="center" justifyContent="center">
        {loader ? <CircularProgress /> : ""}
      </Stack>
    </div>
  );
};

export default PerformanceTab;

import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import ConditionsList from "./ConditionsList";
import ConditionOverview from "./ConditionOverview";

const Conditions = ({
  setAddCondition,
  addCondition,
  qualificationQuestions,
  getQualificationQuestions,
  newConditionPayload,
}) => {
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  let getListData = async () => {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/survey/${id}/qualification/terminate/conditions`);
        let localData = result.terminateConditions.map((val) => {
          let data = val.qualifications.map((value) => {
            let payload = {};
            payload.id = value.qualificationId;
            payload.name = value.question.questionText;
            payload.qualificationTypeId = value.qualificationTypeId;
            payload.qualificationCategoryTypeId = value.qualificationCategoryTypeId;
            payload.isActive = value.isActive;
            if (value.qualificationTypeId === 1) {
              payload.answers = value.question.answers;
              payload.answerId = value.SurveyOptions.answerId;
              payload.selectedId = value.selectedOptions.answerId;
            } else if (value.qualificationTypeId === 2) {
              payload.answers = value.question.answers;
              payload.answerId = value.SurveyOptions.answerId;
              payload.selectedId = value.selectedOptions.answerId;
            } else if (value.qualificationTypeId === 4) {
              let ranges = value.selectedOptions.answerText.map((range) => {
                let rangeValue = range.split("-");
                let rangePayload = {
                  min: parseInt(rangeValue[0]),
                  max: parseInt(rangeValue[1]),
                };
                return rangePayload;
              });
              payload.range = ranges;
            } else {
              payload.text = value.selectedOptions.answerText[0];
            }
            return payload;
          });
          val.qualifications = data;
          return val;
        });
        await setConditions(localData);
        setIsLoading(false);
        return localData;
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
        return [];
      }
    }
  };

  let callApi = async () => {
    let list = await getListData();
    setSelectedCondition(list.length > 0 ? list[0] : null);
  };

  useEffect(() => {
    if (id) {
      callApi();
    }
  }, [id]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (selectedCondition) {
      scrollToElement(selectedCondition?.id);
    }
  }, [selectedCondition]);

  const deleteNote = async () => {
    setIsLoading(true);
    try {
      await TLinkApi.del(
        `/survey/${id}/qualification/terminate/conditions/${selectedCondition?.id}`
      );
      setIsLoading(false);
      await callApi();
      enqueueSnackbar("Condition Successfully deleted", {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (addCondition && newConditionPayload) {
      getQualificationQuestions();
      let conditionsPayload = [...conditions];
      conditionsPayload.push(newConditionPayload);
      setConditions(conditionsPayload);
      setSelectedCondition(newConditionPayload);
    }
  }, [addCondition, newConditionPayload]);

  useEffect(() => {
    if (selectedCondition?.id !== -1) {
      setAddCondition(false);
      let payload = conditions.filter((val) => val.id !== -1);
      setConditions(payload);
    }
  }, [selectedCondition]);

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2.5}>
              <MDBox
                style={{
                  borderRight: "1px solid #E4E4E4",
                  height: "calc(100vh - 300px)",
                  overflowY: "auto",
                }}
              >
                <ConditionsList
                  selectedCondition={selectedCondition}
                  setSelectedCondition={setSelectedCondition}
                  conditions={conditions}
                />
              </MDBox>
            </Grid>
            <Grid item xs={9.5}>
              {selectedCondition ? (
                <ConditionOverview
                  selectedCondition={selectedCondition}
                  setSelectedCondition={setSelectedCondition}
                  conditions={conditions}
                  addCondition={addCondition}
                  setAddCondition={setAddCondition}
                  qualificationQuestions={qualificationQuestions}
                  getListData={getListData}
                  deleteNote={deleteNote}
                />
              ) : (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={4}
                  textAlign="center"
                  fontSize="13px"
                  fontWeight="500"
                >
                  Please select a condition to view its
                  <br /> content here or create a new one
                </MDBox>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Conditions;

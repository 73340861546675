import { Stack, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import React from "react";

const ConditionsList = ({ selectedCondition, setSelectedCondition, conditions }) => {
  const { secondary, primary } = colors;

  return (
    <div style={{ paddingInline: "10px", paddingBottom: "10px" }}>
      <Stack
        sx={{
          borderBottom: `1px solid ${primary.main}`,
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 5,
        }}
        py={1}
        mb={1}
      >
        <Typography variant="h6">Conditions List</Typography>
      </Stack>
      {conditions.length > 0 ? (
        conditions?.map((condition, i) => {
          return (
            <MDBox
              id={`${condition.id}`}
              onClick={() => {
                setSelectedCondition(condition);
              }}
              sx={{
                borderBottom: `1px solid #E4E4E4`,
                background:
                  selectedCondition?.id === condition.id ? `${secondary.light} !important` : "",
                cursor: "pointer",
                "&:hover": {
                  background: "#f5f5f5",
                },
              }}
              p={1}
              key={i}
            >
              <Typography sx={{ fontWeight: "400 !important", fontSize: "14px" }}>
                {condition?.title}
              </Typography>
            </MDBox>
          );
        })
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={3}
          textAlign="center"
          fontSize="13px"
          fontWeight="500"
        >
          There are no Condition yet!
        </MDBox>
      )}
    </div>
  );
};

export default ConditionsList;

import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDBox from "components/MDBox";
import Sidenav from "comps/Sidenav";
import theme from "assets/theme";
import routes from "SideNavLinks";
import { useMaterialUIController } from "context";
import Dashboard from "layouts/dashboard";
import Vendors from "layouts/vendors";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Projects from "layouts/projects";
import Qualification from "layouts/qualification";
import Survey from "layouts/survey";
import AddSurvey from "layouts/survey/components/addSurvey";
import { SnackbarProvider } from "notistack";
import AddVendors from "layouts/vendors/components/addVendors";
import VendorsList from "layouts/vendors/components/vendorsList";
import PrivateOutlet from "components/privateOulet/PrivateOutlet";
import AddProjects from "layouts/projects/components/addProjects";
import ProjectsList from "layouts/projects/components/ProjectList";
import ClientsList from "layouts/clients/components/clientList";
import Clients from "layouts/clients";
import AddClients from "layouts/clients/components/addClient";
import SurveyList from "layouts/survey/components/surveyList";
import BasicDetails from "layouts/survey/components/basicDetails";
import QualificationSurvey from "layouts/survey/components/Qualification";
import SupplierSurvey from "layouts/survey/components/SupplierSurvey";
import VendorDetails from "layouts/vendors/components/vendorDetails";
import ClientBasicDetails from "layouts/clients/components/clientBasicDetails";
import ClientEncryption from "layouts/clients/components/clientEncryption";
import SecurityConfig from "layouts/survey/components/securityConfig";
import RoutingConfig from "layouts/vendors/components/routingConfig";
import ProjectBasicDetails from "layouts/projects/components/BasicDetails";
import ProjectSurveyList from "layouts/projects/components/surveyList";
import SecurityConfiguration from "layouts/clients/components/securityConfiguration";
import Reports from "layouts/Reports";
import GenericReports from "layouts/GenericReports";
import ReportsTabs from "layouts/survey/components/Report";
import RespondentStatus from "layouts/survey/components/respondentStatus";
import Campaigns from "layouts/campaigns";
import CampaignsList from "layouts/campaigns/components/campaignsList";
import AddCampaigns from "layouts/campaigns/components/addCampaigns";
import CampaignBasicDetails from "layouts/campaigns/components/campaignBasicDetails";
import ProjectReconciliation from "layouts/projects/components/reconciliation";
import ProjectReconcilationAdd from "layouts/projects/components/reconciliation/components/reconciliationAdd";
import ProjectReconcilationOverview from "layouts/projects/components/reconciliation/components/reconciliationOverview";
import ClientFilter from "layouts/clients/components/clientFilter";
import SupplierMargin from "layouts/vendors/components/supplierMargin";
import VendorSupplyConfig from "layouts/vendors/components/vendorSupply";
import Users from "layouts/users";
import UsersList from "layouts/users/components/userList";
import InviteMember from "layouts/users/components/inviteUser";
import UserBasicDetails from "layouts/users/components/userBasicDetails";
import UserSetPassword from "layouts/authentication/set-password";
import GlobalReconciliation from "layouts/globalReconciliation";
import Footer from "comps/footer/Footer";
import Performance from "layouts/projects/components/Performance";
import ProjectTeam from "layouts/projects/components/Team";
import SetUp from "layouts/vendors/components/setUp";
import Permissions from "layouts/users/components/permissions";
import MarketsAndClients from "layouts/vendors/components/marketsAndClients";
import Notes from "layouts/Notes";
import PriorityOrder from "layouts/priorityOrder";
import ReconcilationAdd from "layouts/globalReconciliation/components/reconcilationAdd";
import ReconcilationOverview from "layouts/globalReconciliation/components/reconcilationOverview";
import SecurityTemplate from "layouts/securityTemplate";
import TemplateAdd from "layouts/securityTemplate/templateAdd";
import UserForgotPassword from "layouts/authentication/forget-password";
import UserUpdatePassword from "layouts/authentication/update-password";
import UserResetPassword from "layouts/authentication/reset-password";
import PermissionDenied from "layouts/permissionDenied";
import QuotaContainer from "layouts/survey/components/QuotaContainer";
import ProjectSecurityConfig from "layouts/projects/components/projectSecurityConfig";
import ReportHistory from "layouts/ReportHistory";

export default function App() {
  const [controller] = useMaterialUIController();
  const { layout, sidenavColor } = controller;
  const [width, setWidth] = useState(72);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <MDBox>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={2000}
        >
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav color={sidenavColor} routes={routes} setWidth={setWidth} width={width} />
            </>
          )}
          <Routes>
            <Route element={<PrivateOutlet setWidth={setWidth} width={width} />}>
              <Route
                path="/dashboard"
                element={<Dashboard setMenuOpen={setMenuOpen} menuOpen={menuOpen} />}
              />
              <Route path="/permissiondenied" element={<PermissionDenied />} />
              <Route path="/globalreconciliation" element={<GlobalReconciliation />}>
                <Route path="new" element={<ReconcilationAdd />} />
                <Route path=":id" element={<ReconcilationOverview />} />
              </Route>

              <Route path="/securityTemplate" element={<SecurityTemplate />}>
                <Route path="new" element={<TemplateAdd />} />
                <Route path=":id" element={<TemplateAdd />} />
                <Route path=":id/edit" element={<TemplateAdd />} />
              </Route>

              <Route path="/vendors" element={<Vendors />}>
                <Route index element={<VendorsList />} />
                <Route element={<AddVendors />}>
                  <Route path="/vendors/:id" element={<VendorDetails />} />
                  <Route path="/vendors/:id/edit" element={<VendorDetails />} />
                  <Route path="/vendors/new" element={<VendorDetails />} />
                  <Route path="/vendors/:id/routingConfig/edit" element={<RoutingConfig />} />
                  <Route path="/vendors/:id/setUp/edit" element={<SetUp />} />
                  <Route path="/vendors/:id/cpiConfig/edit" element={<SupplierMargin />} />
                  <Route path="/vendors/:id/markets&Clients/edit" element={<MarketsAndClients />} />
                  <Route path="/vendors/:id/notes/edit" element={<Notes objectTypeId="4" />} />
                  <Route
                    path="/vendors/:id/vendorsupplyconfig/edit"
                    element={<VendorSupplyConfig />}
                  />
                </Route>
              </Route>
              <Route path="/projects" element={<Projects />}>
                <Route index element={<ProjectsList />} />
                <Route element={<AddProjects />}>
                  <Route path="/projects/:id" element={<ProjectBasicDetails />} />
                  <Route path="/projects/:id/edit" element={<ProjectBasicDetails />} />
                  <Route path="/projects/:id/securityConfig" element={<ProjectSecurityConfig />} />
                  <Route path="/projects/:id/performance" element={<Performance />} />
                  <Route path="/projects/:id/surveyList" element={<ProjectSurveyList />} />
                  <Route path="/projects/:id/team" element={<ProjectTeam />} />
                  <Route path="/projects/:id/reconciliation" element={<ProjectReconciliation />}>
                    <Route
                      path="/projects/:id/reconciliation/new"
                      element={<ProjectReconcilationAdd />}
                    />
                    <Route
                      path="/projects/:id/reconciliation/:reconcileId"
                      element={<ProjectReconcilationOverview />}
                    />
                  </Route>
                  <Route path="/projects/:id/notes/edit" element={<Notes objectTypeId="1" />} />
                </Route>
              </Route>

              <Route path="/clients" element={<Clients />}>
                <Route index element={<ClientsList />} />
                <Route element={<AddClients />}>
                  <Route path="/clients/:id" element={<ClientBasicDetails />} />
                  <Route path="/clients/:id/edit" element={<ClientBasicDetails />} />
                  <Route path="/clients/new" element={<ClientBasicDetails />} />
                  <Route path="/clients/:id/clientEncryption/edit" element={<ClientEncryption />} />
                  <Route path="/clients/:id/clientFilters/edit" element={<ClientFilter />} />
                  <Route
                    path="/clients/:id/securityConfiguration/edit"
                    element={<SecurityConfiguration />}
                  />
                  <Route path="/clients/:id/notes/edit" element={<Notes objectTypeId="3" />} />
                </Route>
              </Route>

              <Route path="/surveys" element={<Survey />}>
                <Route index element={<SurveyList />} />
                <Route element={<AddSurvey />}>
                  <Route path="/surveys/new" element={<BasicDetails />} />
                  <Route path="/surveys/:id" element={<BasicDetails />} />
                  <Route path="/surveys/:id/edit" element={<BasicDetails />} />
                  <Route path="/surveys/:id/qualification/edit" element={<QualificationSurvey />} />
                  <Route path="/surveys/:id/quota/edit" element={<QuotaContainer />} />
                  <Route path="/surveys/:id/supplier/edit" element={<SupplierSurvey />} />
                  <Route path="/surveys/:id/securityConfig/edit" element={<SecurityConfig />} />
                  <Route path="/surveys/:id/report/edit" element={<ReportsTabs />} />
                  <Route path="/surveys/:id/respondent/edit" element={<RespondentStatus />} />
                  <Route path="/surveys/:id/notes/edit" element={<Notes objectTypeId="2" />} />
                </Route>
              </Route>
              <Route path="/campaigns" element={<Campaigns />}>
                <Route index element={<CampaignsList />} />
                <Route element={<AddCampaigns />}>
                  <Route path="/campaigns/:id" element={<CampaignBasicDetails />} />
                  <Route path="/campaigns/:id/edit" element={<CampaignBasicDetails />} />
                  <Route path="/campaigns/new" element={<CampaignBasicDetails />} />
                </Route>
              </Route>

              <Route path="/priority" element={<PriorityOrder />} />
              <Route path="/qualification" element={<Qualification />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/genericReports" element={<GenericReports />} />
              <Route path="/reportHistory" element={<ReportHistory />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />

              <Route path="/users" element={<Users />}>
                <Route index element={<UsersList />} />
                <Route element={<InviteMember />}>
                  <Route path="/users/:id" element={<UserBasicDetails />} />
                  <Route path="/users/:id/edit" element={<UserBasicDetails />} />
                  <Route path="/users/new" element={<UserBasicDetails />} />
                </Route>
              </Route>
              <Route path="/permissions" element={<Permissions />} />
            </Route>
            <Route path="/login" element={<SignIn />} />
            <Route path="/reset-password" element={<UserResetPassword />} />
            <Route path="/set-password" element={<UserSetPassword />} />
            <Route path="/forget-password" element={<UserForgotPassword />} />
            <Route path="/update-password" element={<UserUpdatePassword />} />
          </Routes>
        </SnackbarProvider>
        {layout === "dashboard" && (
          <footer>
            <Footer />
          </footer>
        )}
      </MDBox>
      {/* <MDBox
        sx={{ display: { xs: "flex", md: "none", lg: "none" }, width: "100%", height: "100vh" }}
        bgColor="rgb(242, 247, 236)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight="600"
      >
        Please Open in Tab or Laptop
      </MDBox> */}
    </ThemeProvider>
  );
}

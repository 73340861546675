import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { DeleteOutline } from "@mui/icons-material";

const QualConfiguretion = ({
  open,
  handleClose,
  editQual,
  setEditQual,
  qualification,
  setQualification,
}) => {
  const [error, setError] = useState(false);
  const onSave = () => {
    let payload = qualification.map((qual) => {
      if (Number(qual.id) === Number(editQual.id)) {
        qual = editQual;
      }
      return qual;
    });
    setQualification(payload);
    handleClose();
  };

  useEffect(() => {
    let error =
      editQual?.range?.some(
        (val) =>
          val.min === "" ||
          val.max === "" ||
          Number(val.min) > Number(val.max) ||
          Number(val.min) < 10 ||
          Number(val.max) > 100
      ) || editQual?.text?.some((val) => val === "");
    setError(error);
  }, [editQual]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <span>Configure Qualification</span>
          <Stack direction="row" alignItems="center" spacing={2}>
            <MDButton size="small" color="secondary" variant="contained" onClick={handleClose}>
              Cancel
            </MDButton>
            <MDButton
              size="small"
              color="info"
              disabled={error}
              variant="contained"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </MDButton>
          </Stack>
        </Stack>
        <hr style={{ marginTop: "0.5rem" }} />
      </DialogTitle>
      <DialogContent sx={{ color: "black !important" }}>
        <Stack spacing={4}>
          <Stack spacing={0.5}>
            <Typography fontSize="15px" fontWeight="700" color="dark">
              Qualification
            </Typography>
            <Typography fontSize="14px" fontWeight="400" color="dark">
              {editQual?.question?.questionText}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography fontSize="15px" fontWeight="700" color="dark">
              Options
            </Typography>
            {editQual?.qualificationTypeId === 4 ? (
              <MDBox width="100%">
                {editQual?.range.map((value, i) => {
                  return (
                    <Grid container spacing={2} mb={1.5} key={i}>
                      {i < editQual?.range?.length - 1 && (
                        <Grid item xs={12} md={5.5}>
                          <MDInput
                            type="number"
                            fullWidth
                            required
                            value={value.min}
                            onChange={(e) => {
                              let payload = { ...editQual };
                              payload.range[i].min = e.target.value;
                              setEditQual(payload);
                            }}
                            autoComplete="off"
                            variant="outlined"
                            label="Min"
                            error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
                            helperText={
                              value.min === ""
                                ? "This field is Required"
                                : Number(value.min) < 10
                                ? "This value can not be less then 10"
                                : ""
                            }
                          />
                        </Grid>
                      )}
                      {i === editQual?.range?.length - 1 && (
                        <Grid item xs={12} md={5.5}>
                          <MDInput
                            type="number"
                            autoFocus={i > 0}
                            fullWidth
                            required
                            value={value.min}
                            onChange={(e) => {
                              let payload = { ...editQual };
                              payload.range[i].min = e.target.value;
                              setEditQual(payload);
                            }}
                            autoComplete="off"
                            variant="outlined"
                            label="Min"
                            error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
                            helperText={
                              value.min === ""
                                ? "This field is Required"
                                : Number(value.min) < 10
                                ? "This value can not be less then 10"
                                : ""
                            }
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} md={5.5}>
                        <MDInput
                          type="number"
                          fullWidth
                          required
                          value={value.max}
                          onChange={(e) => {
                            let payload = { ...editQual };
                            payload.range[i].max = e.target.value;
                            setEditQual(payload);
                          }}
                          autoComplete="off"
                          variant="outlined"
                          label="Max"
                          error={
                            value.max === ""
                              ? true
                              : Number(value.min) > Number(value.max)
                              ? true
                              : Number(value.max) > 100
                              ? true
                              : false
                          }
                          helperText={
                            value.max === ""
                              ? "This field is Required"
                              : Number(value.max) > 100
                              ? "This value can not be greater than 100"
                              : Number(value.min) > Number(value.max)
                              ? "This is not be less then min"
                              : ""
                          }
                        />
                      </Grid>
                      {editQual?.range?.length > 1 && (
                        <Grid item xs={12} md={1}>
                          <IconButton
                            onClick={() => {
                              let payload = { ...editQual };
                              payload.range.splice(i, 1);
                              setEditQual(payload);
                            }}
                          >
                            <DeleteOutline color="error" fontSize="small" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <MDBox display="flex" alignItems="center" justifyContent="flex-end" width="92%">
                  <MDButton
                    variant="text"
                    color="dark"
                    onClick={() => {
                      let payload = { ...editQual };
                      payload.range.push({ min: 10, max: 100 });
                      setEditQual(payload);
                    }}
                  >
                    + add more input
                  </MDButton>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox width="100%">
                {editQual?.text.map((value, i) => {
                  return (
                    <Grid container spacing={2} mb={1.5} key={i}>
                      {i < editQual?.text?.length - 1 && (
                        <Grid item xs={12} md={11}>
                          <MDInput
                            type="text"
                            fullWidth
                            required
                            value={value}
                            onChange={(e) => {
                              let payload = { ...editQual };
                              payload.text[i] = e.target.value;
                              setEditQual(payload);
                            }}
                            autoComplete="off"
                            variant="outlined"
                            label="Text"
                            error={value.text === "" ? true : false}
                            helperText={value.text === "" ? "This field is Required" : ""}
                          />
                        </Grid>
                      )}
                      {i === editQual?.text?.length - 1 && (
                        <Grid item xs={12} md={11}>
                          <MDInput
                            type="text"
                            fullWidth
                            required
                            autoFocus={i > 0}
                            value={value}
                            onChange={(e) => {
                              let payload = { ...editQual };
                              payload.text[i] = e.target.value;
                              setEditQual(payload);
                            }}
                            autoComplete="off"
                            variant="outlined"
                            label="Text"
                            error={value.text === "" ? true : false}
                            helperText={value.text === "" ? "This field is Required" : ""}
                          />
                        </Grid>
                      )}
                      {editQual?.text?.length > 1 && (
                        <Grid item xs={12} md={1}>
                          <IconButton
                            onClick={() => {
                              let payload = { ...editQual };
                              payload.text.splice(i, 1);
                              setEditQual(payload);
                            }}
                          >
                            <DeleteOutline color="error" fontSize="small" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <MDBox display="flex" alignItems="center" justifyContent="flex-end" width="92%">
                  <MDButton
                    variant="text"
                    color="dark"
                    onClick={() => {
                      let payload = { ...editQual };
                      payload.text.push("Text");
                      setEditQual(payload);
                    }}
                  >
                    + add more input
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default QualConfiguretion;

import MDBox from "components/MDBox";
import { useState } from "react";
import MemberTable from "./memberTable";
import AddMember from "./addMember";

function ProjectTeam() {
  const [open, setOpen] = useState(false);

  return (
    <MDBox>
      <AddMember setOpen={setOpen} open={open} />
      <MemberTable open={open} setOpen={setOpen} />
    </MDBox>
  );
}

export default ProjectTeam;

import { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Grid, Tooltip, Switch, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import GetPriorityList from "hooks/PriorityList/GetPriorityList";
import colors from "assets/theme/base/colors";
import { useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SearchInput from "components/searchInput";
import { IconButton, Box } from "@mui/material";
import moment from "moment";

function PriorityTable({ open, setOpen }) {
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [selectPriority, setSelectPriority] = useState([1, 2, 3]);
  const [editedUsers, setEditedUsers] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const { textColor } = colors;
  const [sorting, setSorting] = useState([]);
  const tableTheme = useTableTheme();
  let { enqueueSnackbar } = useSnackbar();
  const [onLoad, setOnLoad] = useState(false);

  const {
    priority,
    getPriority,
    isLoading,
    setIsLoading,
    totalLength,
    setPage,
    setSearch,
    search,
    setEntriesPerPage,
    setSortBy,
    setSortOrder,
  } = GetPriorityList();

  useEffect(() => {
    setData(priority);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [priority, pagination, sorting]);

  useEffect(() => {
    // GetSelectPriorityList();
    setData(priority);
  }, [priority]);

  const GetSelectPriorityList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/prioritylist`);
      setPriorityList(result.data);
      let data = result.data;
      data.map((res) => setSelectPriority((prev) => [...prev, res.id]));
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open === false && onLoad) {
      getPriority();
    } else {
      setOnLoad(true);
    }
  }, [open]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "projectId",
        header: "Project ID",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },
      {
        accessorKey: "surveyId",
        header: "Survey ID",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },

      {
        accessorKey: "priority",
        header: "Priority",
        enableSorting: true,
        editVariant: "select",
        editSelectOptions: selectPriority,
        enableEditing: true,
        size: 50,
        headerAlign: "center",
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          error: !!validationErrors?.priority,
          helperText: validationErrors?.priority,
          onChange: (event) =>
            setEditedUsers({
              ...editedUsers,
              [row.id]: { ...row.original, priority: event.target.value },
            }),
        }),
      },

      {
        accessorKey: "countrycode",
        header: "Country",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },
      {
        accessorKey: "clientName",
        header: "Client",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },
      {
        accessorKey: "cpi",
        header: "CPI($)",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
        Cell: ({ cell }) => {
          return <div>{cell.getValue().toFixed(2)}</div>;
        },
      },
      {
        accessorKey: "surveyEndDate",
        header: "End Date",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },
      {
        accessorKey: "sampleRequired",
        header: "N",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },
      {
        accessorKey: "userName",
        header: "Added by",
        enableSorting: true,
        enableEditing: false,
        size: 50,
        headerAlign: "center",
      },
      {
        accessorKey: "priorityAddedOn",
        header: "Added on",
        enableSorting: true,
        enableEditing: false,
        headerAlign: "center",
        Cell: ({ cell }) => {
          return (
            <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
              <div>{moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}</div>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "priorityRemovedOn",
        header: "Removed on",
        enableSorting: true,
        enableEditing: false,
        headerAlign: "center",
        Cell: ({ cell }) => {
          if (!cell.getValue()) {
            return null;
          }
          return (
            <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
              <div>{moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}</div>
            </Tooltip>
          );
        },
      },
    ],
    [editedUsers, validationErrors]
  );

  const handleSaveUsers = async ({ values, table }) => {
    if (Object.values(validationErrors).some((error) => !!error)) return;
    let payload = {
      priority: values.priority,
      isActive: true,
    };
    setEditedUsers({});
    try {
      let result = await TLinkApi.put(`/project/priority/${values.surveyId}`, payload);
      getPriority();
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
    table.setEditingRow(null);
  };

  const handleSaveisActive = async (row) => {
    setIsLoading(true);
    let payload = {
      priority: row.original.priority,
      isActive: !row.original.isActive,
    };
    try {
      let result = await TLinkApi.put(`/project/priority/${row.original.surveyId}`, payload);
      getPriority();
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    positionActionsColumn: "last",
    getRowId: (row) => row.id,
    onSortingChange: setSorting,
    muiTableHeadCellProps: {
      sx: {
        alignItems: "center",
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 335px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUsers,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Switch
          size="small"
          color="info"
          disabled={false}
          checked={row.original.isActive}
          onChange={(e) => {
            handleSaveisActive(row);
          }}
        />
      </Box>
    ),
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
    renderTopToolbarCustomActions: () => {
      return <SearchInput setSearch={setSearch} search={search} />;
    },
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox width="100%">
        <Grid container spacing={2} pb={1} display="flex" flexDirection="row">
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography fontSize="25px" color="black" ml="1%">
              Prioritization
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12}>
            <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid
            px={0}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography color="black" ml="1%">
              Priority List
            </MDTypography>
          </Grid>
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            gap="15px"
          >
            <Tooltip title="Add New Survey in Priority list" placement="top">
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  setOpen(true);
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + Survey
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
      </MDBox>
    </>
  );
}

export default PriorityTable;

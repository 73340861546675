import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetMemberList = ({id}) => {
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [limit, setLimit] = useState(50); 

  const getMembers = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/project/teams/${id}?search=${search}&page=${page+1}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      );
      setMembers(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getMembers();
    // eslint-disable-next-line
  }, [page, search, sortBy, sortOrder, limit]);

  return {
    members,
    getMembers,
    isLoading,
    setIsLoading,
    setPage,
    page,
    setSearch,
    search,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    limit,
    setLimit
  };
};

export default GetMemberList; 
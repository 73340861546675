import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import GetSurveyReport from "hooks/SurveyReport/GetSurveyReport";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import moment from "moment/moment";
import csvDownload from "json-to-csv-export";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ThemeProvider } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";

function SurveyReport() {
  let { id } = useParams();
  const { surveyReport, isLoading } = GetSurveyReport({ id });
  const tableTheme = useTableTheme();
  const { info } = colors;
  const [status, setStatus] = useState(-1);
  const [data, setData] = useState([]);

  useEffect(() => {
    let newData;
    if (status === -1) {
      newData = surveyReport;
    } else {
      let filterStatus = status === 1 ? false : true;
      newData = surveyReport?.filter((data) => {
        return data?.isTest === filterStatus;
      });
    }
    setData(newData);
  }, [status, surveyReport]);

  let columns = [
    {
      accessorKey: "surveyId",
      header: "Survey Id",
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 150,
    },

    {
      accessorKey: "subStatus",
      header: "Sub Status",
      align: "center",
    },

    {
      accessorKey: "vendorName",
      header: "Supplier Name",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
    },
    {
      accessorKey: "supplierToken",
      header: "Supplier Token",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
    },
    {
      accessorKey: "token",
      header: "Token",
      align: "center",
    },
    {
      accessorKey: "isTest",
      header: "Token Type",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        const isTest = row.original.isTest;
        return isTest ? "Test" : "Live";
      },
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
      Cell: ({ cell }) => {
        return `${moment(cell.getValue()).format("DD-MM-YYYY")}`;
      },
    },
    {
      accessorKey: "endDate",
      header: "End Date",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
      Cell: ({ cell }) => `${moment(cell.getValue()).format("DD-MM-YYYY")}`,
    },
  ];

  let selectStyle = {
    height: "35px",
    fontSize: "13px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    initialState: { density: "compact", pagination: { pageSize: 25 } },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 450px)",
        overflowY: "auto",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderTopToolbarCustomActions: () => {},
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        px={0}
        item
        xs={6}
        md={6}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-end", md: "center" },
        }}
        gap="15px"
      ></Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ width: "400px", marginTop: "15px", marginLeft: "10px" }}
      >
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          marginRight={"16px"}
        >
          <FormControl fullWidth size="small">
            <InputLabel>Token Type</InputLabel>
            <Select
              value={status}
              label="Token Type"
              sx={selectStyle}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <MenuItem value={-1}>All</MenuItem>
              <MenuItem value={1}>Live</MenuItem>
              <MenuItem value={2}>Test</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="flex-start">
          <Tooltip title="Export" placement="right">
            <IconButton
              variant="contained"
              color="white"
              sx={{
                backgroundColor: `${info.main} !important`,
                borderRadius: "4px !important",
              }}
              size="small"
              onClick={() => {
                const dataToConvert = {
                  data: surveyReport,
                  filename: "Survey Report",
                  delimiter: ",",
                };
                csvDownload(dataToConvert);
              }}
            >
              <IosShareIcon sx={{ fontSize: "1.1rem", color: "white" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default SurveyReport;

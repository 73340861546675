import {
  Backdrop,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const S2sUrls = ({ data, GetS2SData }) => {
  let { enqueueSnackbar } = useSnackbar();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: {
      s2sCompleted: "",
      s2sQuotaFull: "",
      s2sTerminate: "",
      s2sSecurity: "",
      s2sEnable: false,
    },
  });

  const label = (value, required) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {required ? <span>* &nbsp;</span> : ""}
      </MDBox>
    );
  };

  let { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data]);

  const getData = async () => {
    if (data !== null) {
      if (data.s2sEnable !== null) {
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
      }
      setValue("s2sCompleted", data.s2sCompleted);
      setValue("s2sQuotaFull", data.s2sQuotaFull);
      setValue("s2sTerminate", data.s2sTerminate);
      setValue("s2sSecurity", data.s2sSecurity);
      setValue("s2sEnable", data.s2sEnable);
    } else {
      setAddData(true);
      setIsEdit({
        disabled: false,
        edit: false,
      });
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };
    payload.vendorId = id;
    if (data.s2sEnable === null) {
      payload.s2sEnable = false;
    }

    try {
      if (addData !== true) {
        let result = await TLinkApi.put(`/vendors/s2s/urls/${id}`, payload);
        await GetS2SData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/vendors/s2s/urls", payload);
        await GetS2SData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox pb={1} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center" gap="15px">
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>S2S URL(s)</Typography>
            <Switch
              size="small"
              disabled={isEdit.disabled === true}
              sx={{ marginTop: "2px" }}
              checked={watch("s2sEnable")}
              onChange={(e) => {
                setValue("s2sEnable", e.target.checked);
              }}
            />
          </MDBox>
          <MDBox>
            {isEdit.disabled === true ? (
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={async () => {
                    if (!addData) {
                      await GetS2SData(true);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    } else {
                      reset({
                        s2sCompleted: "",
                        s2sQuotaFull: "",
                        s2sTerminate: "",
                        s2sSecurity: "",
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  save
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        {isEdit.edit || addData ? (
          <Grid container spacing={3} sx={{ display: !watch("s2sEnable") ? "none" : "" }}>
            <Grid item xs={12} md={12}>
              {label("S2S Completed", true)}
              <Controller
                name="s2sCompleted"
                control={control}
                rules={{
                  required: watch("s2sEnable") ? `S2S Completed is required!` : false,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="S2S Completed"
                      {...register("s2sCompleted", {
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.s2sCompleted ? true : false}
                      helperText={errors?.s2sCompleted ? errors.s2sCompleted.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("s2sCompleted"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {label("S2S Quota Full")}
              <Controller
                name="s2sQuotaFull"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="S2S QuotaFull"
                      {...register("s2sQuotaFull", {
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.s2sQuotaFull ? true : false}
                      helperText={errors?.s2sQuotaFull ? errors.s2sQuotaFull.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("s2sCompleted"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {label("S2S Terminate", true)}
              <Controller
                name="s2sTerminate"
                control={control}
                rules={{
                  required: watch("s2sEnable") ? `S2S Terminate is required!` : false,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="S2S Terminate"
                      {...register("s2sTerminate", {
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.s2sTerminate ? true : false}
                      helperText={errors?.s2sTerminate ? errors.s2sTerminate.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("s2sTerminate"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {label("S2S Security")}
              <Controller
                name="s2sSecurity"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="S2S Security"
                      {...register("s2sSecurity", {
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.s2sSecurity ? true : false}
                      helperText={errors?.s2sSecurity ? errors.s2sSecurity.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("s2sSecurity"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} sx={{ display: !watch("s2sEnable") ? "none" : "" }}>
            <Grid item xs={12} md={12}>
              {label("S2S Completed", true)}
              <Typography fontSize="14px" fontWeight="600">
                {watch("s2sCompleted") ? watch("s2sCompleted") : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {label("S2S Quota Full")}
              <Typography fontSize="14px" fontWeight="600">
                {watch("s2sQuotaFull") ? watch("s2sQuotaFull") : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {label("S2S Terminate", true)}
              <Typography fontSize="14px" fontWeight="600">
                {watch("s2sTerminate") ? watch("s2sTerminate") : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {label("S2S Security")}
              <Typography fontSize="14px" fontWeight="600">
                {watch("s2sSecurity") ? watch("s2sSecurity") : "NA"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default S2sUrls;

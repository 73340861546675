import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Grid, Stack } from "@mui/material";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ThemeProvider } from "@mui/material";
import MDTypography from "components/MDTypography";
import GetSurveyRespondent from "hooks/SurveyRespondent/GetSurveyRespondent";

function RespondentStatus() {
  let { id } = useParams();
  const {
    surveyRespondent,
    isLoading,
    setSortBy,
    setSortOrder,
    setEntriesPerPage,
    setPage,
  } = GetSurveyRespondent({ id });
  const tableTheme = useTableTheme();
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    setData(surveyRespondent);
  }, [surveyRespondent]);

  useEffect(() => {
    setData(surveyRespondent);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("status");
      setSortOrder("asc");
    }
  }, [surveyRespondent, pagination, sorting]);

  let columns = [
    {
      accessorKey: "status",
      header: "Status",
      align: "center",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "subStatus",
      header: "Respondent Sub Status",
      align: "center",
      size: 150,
    },
    {
      accessorKey: "count",
      header: "Count of Records",
      align: "center",
      size: 150,
    },
    {
      accessorKey: "countPercentage",
      header: "Count(%)",
      enableSorting: false,
      align: "center",
      size: 150,
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    manualSorting: true,
    manualPagination: true,
    state: { density: "compact", pagination, sorting },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 365px)",
        overflowY: "auto",
      },
    },
    onPaginationChange: setPagination,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    onSortingChange: setSorting,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        px={0}
        item
        xs={6}
        md={6}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-end", md: "center" },
        }}
        gap="15px"
      >
        <MDTypography color="black" ml="1%">
          Survey Respondent Table
        </MDTypography>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{ width: "400px", marginLeft: "10px" }}
      ></Grid>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default RespondentStatus;

import Grid from "@mui/material/Grid";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import QuotaQuestionBox from "comps/Cards/quotaCheckbox";
import MinMax from "comps/Cards/quotaMInMax";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QuotaList from "./QuotaList";
import QuotaAnswerText from "comps/Cards/quotaAnsText";
import GetCalculationList from "hooks/Calculation/GetCalculationList";

function SimpleQuota({ setNestedQuota, nestedQuota }) {
  let { enqueueSnackbar } = useSnackbar();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      range: "",
      quotaQuestions: [],
      isActive: true,
    },
  });

  let { setIsEdit, isEdit, getData, survey } = useOutletContext();
  let { calculationList } = GetCalculationList();

  const [data, setData] = useState([]);
  const [showDown, setShowDown] = useState(false);
  const [quota, setQuota] = useState([]);
  const [quotaIndex, setQuotaIndex] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [height, setHeight] = useState(true);
  const [quotaError, setQuotaError] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [qualification, setQualifications] = useState(false);
  const [newQuota, setNewQuota] = useState(false);
  const [calculationId, setCalculationId] = useState("");

  let { id } = useParams();

  let quotaQuestions = watch("quotaQuestions");

  let getQuotaQualificationList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`survey/${id}/quota`);
      if (result.length > 0) {
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      let payload = result.map((qu) => {
        qu.quota.quotaTarget =
          qu.quota.sampleNumber && survey.sampleCalculationType == 1
            ? qu.quota.sampleNumber - qu.quota.completes
            : qu.quota.sampleNumber && survey.sampleCalculationType == 2
            ? qu.quota.sampleNumber - qu.quota.starts
            : 0;
        qu.quota.conditions.map((value) => {
          quota.filter((val) => {
            if (value.qualificationId === val.qualificationId) {
              if (val.qualificationTypeId === 4) {
                let ranges = value.answerText.map((value) => {
                  let rangeValue = value.split("-");
                  let payload = {
                    min: parseInt(rangeValue[0]),
                    max: parseInt(rangeValue[1]),
                  };
                  return payload;
                });
                value.range = ranges;
                value.selectedRange = ranges;
                value.question = val.question;
                value.selectedAnswers = [];
                value.qualificationTypeId = val.qualificationTypeId;
              } else if (val.qualificationTypeId === 3) {
                let tempSplitValue = value?.answerText[0];
                value.range = tempSplitValue;
                value.text = tempSplitValue;
                value.question = val.question;
                value.selectedAnswers = [];
                value.qualificationTypeId = val.qualificationTypeId;
              } else {
                value.question = val.question;
                value.selectedAnswers = val.answerId;
                value.qualificationTypeId = val.qualificationTypeId;
              }
            }
          });
        });
        return qu;
      });
      setData(payload);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (quota.length > 0) {
      getQuotaQualificationList();
    }
    // eslint-disable-next-line
  }, [quota]);

  useEffect(() => {
    if (survey) {
      getQuotaList();
      getQuotaQualificationList();
    }
    // eslint-disable-next-line
  }, [survey]);

  let getQuotaList = async () => {
    try {
      let result = await TLinkApi.get(`survey/${id}/qualification/conditions`);

      let tempQualificationQuestions = result?.qualifications.map((qualification) => {
        if (qualification.qualificationTypeId === 4) {
          let ranges = qualification.answerText.map((value) => {
            let rangeValue = value.split("-");
            let payload = {
              min: parseInt(rangeValue[0]),
              max: parseInt(rangeValue[1]),
            };
            return payload;
          });
          qualification.range = ranges;
          qualification.selectedRange = ranges;
        } else if (qualification.qualificationTypeId === 3) {
          let tempSplitValue = qualification?.answerText[0];
          qualification.range = tempSplitValue;
          qualification.text = tempSplitValue;
        } else {
          qualification.selectedAnswers = qualification.answerId;
        }
        return qualification;
      });
      setQuota(tempQualificationQuestions);
    } catch (e) {
      console.log(e);
    }
  };

  const save = async (payload) => {
    if (!quotaError) {
      let conditions = quotaQuestions.map((val) => {
        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
          return {
            qualificationId: val.qualificationId,
            answerId: val.answerId,
          };
        } else if (val.qualificationTypeId === 4) {
          let range = val.selectedRange.map((value) => {
            return `${value.min}-${value.max}`;
          });
          return {
            qualificationId: val.qualificationId,
            answerText: range,
          };
        } else {
          return {
            qualificationId: val.qualificationId,
            answerText: [val.text],
          };
        }
      });
      let surveyQuota = {
        surveyId: id,
        quota: {
          name: payload.name,
          sampleNumber: payload.range,
          conditions: conditions,
          isActive: true,
        },
      };

      try {
        await TLinkApi.post("/survey/quota", surveyQuota);
        enqueueSnackbar("created", {
          variant: "success",
        });
        getQuotaList();
        setValue("quotaQuestions", []);
        setShowDown(!showDown);
        setValue("name", "");
        setValue("range", "");
        setNewQuota(false);
        getQuotaQualificationList();
        setQuotaIndex("");
      } catch (e) {
        enqueueSnackbar("Oopss! somthig went wrong", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (selectedQuestion) {
      let payload = [...watch("quotaQuestions")];
      payload.push(selectedQuestion);
      setValue("quotaQuestions", payload);
      setSelectedQuestion(null);
      setQualifications(false);
    }
  }, [selectedQuestion]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (newQuota) {
      setTimeout(() => {
        scrollToElement("new");
      }, 100);
    }
  }, [newQuota]);

  useEffect(() => {
    if (survey.sampleCalculationType) {
      setCalculationId(survey.sampleCalculationType);
    }
  }, [survey]);

  const changeCalculation = async (value) => {
    setIsLoading(true);
    try {
      await TLinkApi.patch(`/survey/${id}`, {
        sampleCalculationType: value,
      });
      await getData();
      enqueueSnackbar("Calculation type change successfully", {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Oopss! somthig went wrong", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <MDBox>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <MDBox sx={{ height: `calc(100vh - 252px)`, overflowY: "auto" }} pb={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            sx={{ background: "white", zIndex: 5 }}
            top={0}
            px={2.5}
          >
            <Typography variant="h6">Quotas</Typography>
            <MDBox display="flex" alignItems="flex-end" gap="10px">
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={nestedQuota}
                    onChange={(e) => {
                      setNestedQuota(e.target.checked);
                    }}
                  />
                }
                label="Nested Quota"
              />
              <Stack>
                <MDBox
                  color="dark"
                  style={{ marginBottom: "2px" }}
                  sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                >
                  Calculation Type *
                </MDBox>
                <FormControl fullWidth>
                  <Select
                    sx={{ borderRadius: "0rem !important", width: "200px", height: "31px" }}
                    size="small"
                    disabled
                    value={calculationId}
                    onChange={(e) => {
                      setCalculationId(e.target.value);
                      changeCalculation(e.target.value);
                    }}
                  >
                    {calculationList.map((value) => {
                      return <MenuItem value={value.id}>{value.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <MDButton
                variant="gradient"
                color="info"
                // sx={{ display: "none" }}
                size="small"
                disabled={newQuota}
                onClick={() => {
                  setNewQuota(true);
                }}
              >
                + Quota
              </MDButton>
            </MDBox>
          </Stack>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} mx={2}>
              {data.length > 0 || newQuota ? (
                data?.map((value, index) => {
                  return (
                    <QuotaList
                      key={index}
                      index={index}
                      quotaIndex={quotaIndex}
                      setQuotaIndex={setQuotaIndex}
                      data={data}
                      value={value}
                      setData={setData}
                      quota={quota}
                      getQuotaList={getQuotaList}
                      getQuotaQualificationList={getQuotaQualificationList}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  );
                })
              ) : (
                <MDBox
                  fontSize="15px"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  There are no Quotas yet!
                </MDBox>
              )}
            </Grid>
            <Grid id="new" item xs={12} md={12} mx={2} sx={{ display: !newQuota ? "none" : "" }}>
              <form onSubmit={handleSubmit(save)} noValidate>
                <MDBox border="1px solid #D9D9D9" borderRadius="10px" mt={2} p={2.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <MDBox
                        color="dark"
                        style={{ marginBottom: "2px" }}
                        sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                      >
                        Quota Name *
                      </MDBox>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: "Name is required!",
                        }}
                        render={({ field }) => (
                          <MDInput
                            {...field}
                            fullWidth
                            required
                            autoComplete="off"
                            placeholder="Quota Name"
                            variant="outlined"
                            type="text"
                            error={errors?.name ? true : false}
                            helperText={errors?.name ? errors.name.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox
                        color="dark"
                        style={{ marginBottom: "2px" }}
                        sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                      >
                        Target *
                      </MDBox>
                      <Controller
                        name="range"
                        control={control}
                        rules={{
                          required: "Range is required!",
                        }}
                        render={({ field }) => (
                          <MDInput
                            {...field}
                            fullWidth
                            placeholder="No. Of Participants"
                            required
                            autoComplete="off"
                            variant="outlined"
                            type="number"
                            error={errors?.range ? true : false}
                            helperText={errors?.range ? errors.range.message : null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <MDBox
                        style={{
                          color: "black",
                          marginBottom: "10px",
                        }}
                        sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                      >
                        {"Starts"}
                      </MDBox>

                      <Typography fontSize="15px" fontWeight="600">
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox
                        style={{
                          color: "black",
                          marginBottom: "10px",
                        }}
                        sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                      >
                        {"Completes"}
                      </MDBox>

                      <Typography fontSize="15px" fontWeight="600">
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox
                        style={{
                          color: "black",
                          marginBottom: "10px",
                        }}
                        sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
                      >
                        {"Remaining Target"}
                      </MDBox>

                      <Typography fontSize="15px" fontWeight="600">
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <MDBox
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        mt={2}
                        gap="10px"
                      >
                        <IconButton
                          sx={{ transform: height === true ? "rotate(180deg)" : "" }}
                          onClick={() => {
                            if (height) {
                              setHeight(false);
                            } else {
                              setHeight(true);
                            }
                          }}
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                        <MDButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            setValue("name", "");
                            setValue("quotaQuestions", []);
                            setValue("range", "");
                            setHeight(false);
                            setNewQuota(false);
                          }}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          variant="contained"
                          color="info"
                          type="submit"
                          size="small"
                          disabled={quotaQuestions.length <= 0 ? true : false}
                        >
                          Save
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox display={height === true ? "" : "none"} mb={1}>
                    {quotaQuestions.map((value, i) =>
                      value.qualificationTypeId === 4 ? (
                        <Grid item xs={12} md={12} key={i}>
                          <MinMax
                            question={value}
                            updateQuestion={setValue}
                            i={i}
                            quotaQuestions={quotaQuestions}
                            setQuotaError={setQuotaError}
                          />
                        </Grid>
                      ) : value.qualificationTypeId === 3 ? (
                        <QuotaAnswerText
                          question={value}
                          updateQuestion={setValue}
                          i={i}
                          quotaQuestions={quotaQuestions}
                          setQuotaError={setQuotaError}
                        />
                      ) : (
                        <Grid item xs={12} md={12} key={i}>
                          <QuotaQuestionBox
                            question={value}
                            updateQuestion={setValue}
                            index={i}
                            quotaQuestions={quotaQuestions}
                            setQuotaError={setQuotaError}
                          />
                        </Grid>
                      )
                    )}
                    {qualification ? (
                      <>
                        <MDBox fontSize="15px" sx={{ marginTop: "1rem", fontWeight: 400 }} mb={1}>
                          Qualifications *
                        </MDBox>
                        <FormControl fullWidth>
                          <Select
                            sx={{ borderRadius: "0rem !important" }}
                            size="small"
                            onChange={(e) => {
                              setSelectedQuestion(e.target.value);
                            }}
                            value={selectedQuestion}
                          >
                            {quota
                              .filter((option) => {
                                let data = quotaQuestions?.find(
                                  (val) => val.qualificationId === option.qualificationId
                                );
                                if (data || !option.isActive) {
                                  return false;
                                } else {
                                  return true;
                                }
                              })
                              .map((option, i) => {
                                return (
                                  <MenuItem value={option} key={i}>
                                    {option.question.questionText}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </>
                    ) : (
                      <MDButton
                        sx={{ marginTop: "1rem" }}
                        disabled={quotaQuestions?.length === quota?.length}
                        color="dark"
                        variant="text"
                        onClick={() => {
                          setQualifications(true);
                        }}
                      >
                        + Qualifications
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </form>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

export default SimpleQuota;

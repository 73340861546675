import { Backdrop, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import { useParams } from "react-router-dom";
import { useState } from "react";
import PriorityTable from "./PriorityList";
import AddPriority from "./AddPriority";

function PriorityOrder() {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddPriority setOpen={setOpen} open={open} />
      <PriorityTable open={open} setOpen={setOpen} />
    </MDBox>
  );
}

export default PriorityOrder;

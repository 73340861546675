import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import { useNavigate } from "react-router-dom";
import { Stack, Tooltip, Backdrop, CircularProgress } from "@mui/material";
import GetTopSurveyList from "hooks/TopSurveyList/GetTopSurveyList";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ThemeProvider } from "@emotion/react";
import useTableTheme from "constant/TableTheme";

function TopSurvey() {
  const { topSurveys, isLoading } = GetTopSurveyList();
  let navigate = useNavigate();
  const tableTheme = useTableTheme();

  let columns = [
    {
      accessorKey: "surveyId",
      header: "Survey Id",
      headerAlign: "center",
    },

    {
      accessorKey: "clientName",
      header: "Client Name",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName = name.length > 20 ? name.substring(0, 20) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "projectName",
      header: "Project Name",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName = name.length > 20 ? name.substring(0, 20) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "surveyName",
      header: "Survey Name",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName = name.length > 20 ? name.substring(0, 20) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "sampleRequired",
      size: 50,
      header: "Samp. Req.",
      headerAlign: "center",
    },

    {
      accessorKey: "starts",
      size: 50,
      header: "Starts",
      headerAlign: "center",
    },

    {
      accessorKey: "completes",
      size: 50,
      header: "Completes",
      headerAlign: "center",
    },

    {
      accessorKey: "conversion",
      header: "Conv(%)",
      size: 50,
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        if (row) {
          return (
            <div style={{ width: "20px" }}>
              {Math.ceil(
                row?.original?.starts <= 0
                  ? 0
                  : (row?.original?.completes / row?.original?.starts) * 100
              )}
            </div>
          );
        } else {
          return null;
        }
      },
    },

    {
      accessorKey: "countryCode",
      size: 50,
      header: "Country",
      headerAlign: "center",
    },

    {
      accessorKey: "cpi",
      header: "CPI($)",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },

    {
      accessorKey: "epc",
      header: "EPC",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },

    {
      accessorKey: "completion",
      header: "Completion",
      enableSorting: false,
      enableColumnFilter: false, 
      size: 50,
      headerAlign: "center",
      Cell: ({ row }) => {
        const completionValue =
          row.original.starts <= 0
            ? 0
            : Math.ceil((row.original.completes / row.original.sampleRequired) * 100);

        return (
          <MDBox width="8rem" textAlign="left">
            <MDProgress
              value={completionValue >= 100 ? 100 : completionValue}
              color="primary"
              label={true}
            />
          </MDBox>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: topSurveys,
    state: { density: "compact" },
    enableStickyHeader: true,
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enablePagination: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/surveys/${row.original.surveyId}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
      </MDBox>
    </>
  );
}

export default TopSurvey;

import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import QuotaQualificationChart from "comps/Cards/quotaQulificationChart";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";

const QuotaList = ({
  index,
  quotaIndex,
  setQuotaIndex,
  data,
  value,
  setData,
  quota,
  getQuotaQualificationList,
  getQuotaList,
  // isEdit,
  // setIsEdit,
}) => {
  let { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [qualification, setQualifications] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();

  // const activeQualification = async ({ value, val }) => {
  //   try {
  //     let result = await TLinkApi.put(`survey/quota/isActive/${value.quota.id}`, {
  //       isActive: val,
  //     });
  //     enqueueSnackbar(result?.message, {
  //       variant: "success",
  //     });
  //     getQuotaQualificationList();
  //     setQuotaIndex("");
  //   } catch (e) {
  //     enqueueSnackbar(e?.message, {
  //       variant: "error",
  //     });
  //   }
  // };

  const activeDeactiveQualification = async (isActive, quotaIds) => {
    setIsLoading(true);
    try {
      await TLinkApi.patch(`/survey/${id}/quota`, {
        quotaIds: [quotaIds],
        isActive: isActive,
      });
      await getQuotaQualificationList();
      setQuotaIndex("");
      enqueueSnackbar(`Quotas ${isActive ? "active" : "deactive"} successfully`, {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`${e?.response?.data?.message}`, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const save = async (payload) => {
    if (!error) {
      setIsLoading(true);
      let conditions = payload.quota.conditions.map((val) => {
        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
          return {
            qualificationId: val.qualificationId,
            answerId: val.answerId,
          };
        } else if (val.qualificationTypeId === 4) {
          let range = val.selectedRange.map((value) => {
            return `${value.min}-${value.max}`;
          });
          return {
            qualificationId: val.qualificationId,
            answerText: range,
          };
        } else {
          return {
            qualificationId: val.qualificationId,
            answerText: [val.text],
          };
        }
      });
      let surveyQuota = {
        surveyId: payload.id,
        quota: {
          name: payload.quota.name,
          sampleNumber: payload.quota.sampleNumber,
          conditions: conditions,
          isActive: true,
        },
      };

      try {
        let result = await TLinkApi.put(`survey/quota/${payload.quota.id}`, surveyQuota);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        await getQuotaList();
        await getQuotaQualificationList();
        setEdit(false);
        setIsLoading(false);
        setQuotaIndex("");
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (
        data[index]?.quota?.name === "" ||
        data[index]?.quota?.sampleNumber === "" ||
        data[index]?.quota?.conditions.length <= 0
      ) {
        setError(true);
      } else {
        let value = data[index]?.quota?.conditions.some((value) => {
          if (value?.answerId?.length <= 0 && value?.answerText?.length <= 0) {
            return true;
          } else if (value?.text === "") {
            return true;
          } else if (Array.isArray(value?.selectedRange) && value?.selectedRange?.length > 0) {
            let match = value?.selectedRange.some((rangeValue) => {
              if (
                rangeValue.min === "" ||
                rangeValue.max === "" ||
                Number(rangeValue.min) > Number(rangeValue.max) ||
                Number(rangeValue.min) < 10 ||
                Number(rangeValue.max) > 100
              ) {
                return true;
              } else {
                return false;
              }
            });
            return match;
          } else {
            return false;
          }
        });
        setError(value);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (selectedQuestion) {
      let payload = [...data];
      payload[index].quota.conditions.push(selectedQuestion);
      setData(payload);
      setSelectedQuestion(null);
      setQualifications(false);
    }
  }, [selectedQuestion]);

  return (
    <MDBox border="1px solid #D9D9D9" borderRadius="10px" mt={3} key={index} p={2.5}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <MDBox
            style={{
              color: data[index]?.quota?.name === "" ? "red" : "black",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.name === "" ? "Quota Name Required" : "Quota Name *"}
          </MDBox>
          {edit ? (
            <MDInput
              fullWidth
              required
              disabled={!edit}
              value={value.quota.name}
              onChange={(e) => {
                let payload = [...data];
                payload[index].quota.name = e.target.value;
                setData(payload);
              }}
              autoComplete="off"
              variant="outlined"
              type="text"
              placeholder="Quota Name"
              error={data[index]?.quota?.name === "" ? true : false}
            />
          ) : (
            <Typography fontSize="15px" fontWeight="600">
              {data[index]?.quota?.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <MDBox
            style={{
              color: data[index]?.quota?.sampleNumber === "" ? "red" : "black",
              marginBottom: "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {data[index]?.quota?.sampleNumber === "" ? "Target Required" : "Target *"}
          </MDBox>
          {edit ? (
            <MDInput
              value={value.quota.sampleNumber}
              disabled={!edit}
              fullWidth
              placeholder="Target"
              onChange={(e) => {
                let payload = [...data];
                payload[index].quota.sampleNumber = e.target.value;
                setData(payload);
              }}
              required
              autoComplete="off"
              variant="outlined"
              type="number"
              error={data[index]?.quota?.sampleNumber === "" ? true : false}
            />
          ) : (
            <Typography fontSize="15px" fontWeight="600">
              {data[index]?.quota?.sampleNumber}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <MDBox
            style={{
              color: data[index]?.quota?.quotaCpi === "" ? "red" : "black",
              marginBottom: edit ? "10px" : "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {"Starts"}
          </MDBox>

          <Typography fontSize="15px" fontWeight="600">
            {value?.quota?.starts ? value?.quota?.starts : 0}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <MDBox
            style={{
              color: data[index]?.quota?.quotaCpi === "" ? "red" : "black",
              marginBottom: edit ? "10px" : "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {"Completes"}
          </MDBox>

          <Typography fontSize="15px" fontWeight="600">
            {value?.quota?.completes ? value?.quota?.completes : 0}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <MDBox
            style={{
              color: data[index]?.quota?.quotaCpi === "" ? "red" : "black",
              marginBottom: edit ? "10px" : "2px",
            }}
            sx={{ fontSize: { xs: "8px", md: "10px", lg: "12px" } }}
          >
            {"Remaining Target"}
          </MDBox>

          <Typography fontSize="15px" fontWeight="600">
            {value?.quota?.quotaTarget ? value?.quota?.quotaTarget : 0}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {!edit ? (
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              mt={!edit ? 0.5 : 2.5}
            >
              <Switch
                size="small"
                sx={{ marginRight: "0.5rem" }}
                checked={value.quota.isActive}
                // disabled={!edit}
                onChange={(e) => {
                  activeDeactiveQualification(e.target.checked, value?.quota?.id);
                }}
              />
              <IconButton
                color="dark"
                // sx={{ display: "none" }}
                size="small"
                onClick={() => {
                  setEdit(true);
                  setQuotaIndex(index);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
              <IconButton
                sx={{ transform: index === quotaIndex ? "rotate(180deg)" : "" }}
                onClick={() => {
                  if (index === quotaIndex) {
                    setQuotaIndex("");
                  } else {
                    setQuotaIndex(index);
                  }
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </MDBox>
          ) : (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexDirection="row"
              gap="10px"
              mt={2}
            >
              <Switch
                checked={value.quota.isActive}
                disabled={!edit}
                onChange={(e, val) => {
                  // activeQualification({ value, val });
                  setEdit(false);
                  setQuotaIndex("");
                }}
              />
              <MDButton
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  setQualifications(false);
                  setEdit(false);
                  setQuotaIndex("");
                  getQuotaQualificationList();
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                color="info"
                size="small"
                disabled={!edit}
                onClick={() => {
                  save(value);
                }}
              >
                Save
              </MDButton>
            </MDBox>
          )}
        </Grid>
        <Grid item xs={12} display={index === quotaIndex ? "" : "none"}>
          {value?.quota?.conditions?.map((val, i) => (
            <QuotaQualificationChart
              key={i}
              val={val}
              value={value}
              data={data}
              quota={quota}
              index={i}
              dataIndex={index}
              setData={setData}
              setError={setError}
              edit={edit}
            />
          ))}
          {qualification ? (
            <>
              <MDBox fontSize="15px" sx={{ marginTop: "1rem", fontWeight: 400 }} mb={1}>
                Qualifications *
              </MDBox>
              <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                <Select
                  size="small"
                  onChange={(e) => {
                    setSelectedQuestion(e.target.value);
                  }}
                  value={selectedQuestion}
                >
                  {quota
                    .filter((option) => {
                      let data = value?.quota?.conditions?.find(
                        (val) => val.qualificationId === option.qualificationId
                      );
                      if (data || !option.isActive) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map((option, i) => {
                      return (
                        <MenuItem value={option} key={i}>
                          {option.question.questionText}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </>
          ) : (
            <MDButton
              sx={{ marginTop: "1rem", display: !edit ? "none" : "" }}
              disabled={value?.quota?.conditions?.length === quota?.length}
              color="dark"
              variant="text"
              onClick={() => {
                setQualifications(true);
              }}
            >
              + Qualifications
            </MDButton>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default QuotaList;

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Backdrop, CircularProgress, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SurveyReport from "layouts/survey/components/Report/components/surveyReport";
import ScreeningReport from "layouts/survey/components/Report/components/screeningReport";

function ReportsTabs() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const [page, setPage] = useState(1);

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="0.5rem"
        // sx={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <MDButton
            variant={page === 1 ? "contained" : "outlined"}
            size="small"
            color="primary"
            onClick={() => {
              if (page !== 1) {
                setPage(1);
              }
            }}
          >
            Survey Reports
          </MDButton>
          <MDButton
            variant={page === 2 ? "contained" : "outlined"}
            size="small"
            color="primary"
            onClick={() => {
              if (page !== 2) {
                setPage(2);
              }
            }}
          >
            Screening Reports
          </MDButton>
        </Stack>
      </Stack>
      {page === 1 && <SurveyReport />}
      {page === 2 && <ScreeningReport />}
    </MDBox>
  );
}

export default ReportsTabs;

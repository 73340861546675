import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetUserList from "hooks/UsersList/GetUserList";
import SearchInput from "components/searchInput";
import TLinkApi from "services/tlink.api";
import Colors from "../../../../assets/theme/base/colors";
import {
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import moment from "moment";
import MDTypography from "components/MDTypography";

function UsersList() {
  const navigate = useNavigate();
  const {
    user,
    getUser,
    totalLength,
    setPage,
    setSearch,
    search,
    isLoading,
    setIsLoading,
    setEntriesPerPage,
    roleId,
    setRoleId,
    setSortBy,
    setSortOrder,
    currentUser,
  } = GetUserList();
  const { setIsEdit, setUser } = useOutletContext();
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const location = useLocation();
  let { enqueueSnackbar } = useSnackbar();
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const { textColor, info } = Colors;

  const tableTheme = useTableTheme();

  const GetRoles = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/role`);
      setRoles(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetRoles();
    GetStatus();
  }, []);

  const GetStatus = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/userstatus`);
      setStatus(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const deleterow = async (Index) => {
    try {
      setIsLoading(true);
      const data = await TLinkApi.del(`/users/${Index}`);
      getUser();
      setIsLoading(false);
      if (data.data.deleted == true) {
        enqueueSnackbar(data?.data?.message, {
          variant: "success",
        });
        navigate(`${location.pathname}`);
      }
    } catch (err) {
      enqueueSnackbar(err?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    setData(user);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [user, pagination, sorting, roleId]);

  let selectStyle = {
    height: "35px",
    fontSize: "13px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  let columns = [
    {
      accessorKey: "id",
      header: "User Id",
      headerAlign: "center",
    },
    {
      accessorKey: "firstName",
      header: "User Name",
      headerAlign: "center",
      Cell: ({ cell }) => {
        const firstName = cell.getValue();
        const truncatedName =
          firstName?.length > 15 ? firstName.substring(0, 15) + "..." : firstName;
        return (
          <Tooltip title={firstName}>
            <div>{`${truncatedName}`}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "roleId",
      header: "Role",
      headerAlign: "center",
      Cell: ({ cell }) => {
        const userRoleId = cell.getValue();

        const roleObject = roles.find((role) => role.id === userRoleId);
        const userRoleName = roleObject ? roleObject.name : "Unknown";

        return (
          <Tooltip title={userRoleName}>
            <div>{userRoleName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      headerAlign: "center",
    },
    {
      accessorKey: "userStatusId",
      header: "Status",
      headerAlign: "center",
      Cell: ({ cell }) => {
        const userStatusId = cell.getValue();

        const statusObject = status.find((status) => status.id === userStatusId);
        const userStatusName = statusObject ? statusObject.name : "Unknown";

        return (
          <Tooltip title={userStatusName}>
            <div>{userStatusName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: "Added on",
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
            <div>{moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "isActive",
      header: "Action",
      size: 50,
      enableSorting: false,
      headerAlign: "center",
      Cell: ({ row }) => (
        <Stack direction="row" spacing={2}>
          <IconButton
            variant="outlined"
            color="dark"
            size="small"
            disabled={row.original.id === currentUser}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setIsLoading(true);
              deleterow(row.original.id);
            }}
          >
            <Icon>delete_icon</Icon>
          </IconButton>
        </Stack>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (row.original.userStatusId === 1) {
          enqueueSnackbar("E-mail confirmation pending. Cannot change the user status", {
            variant: "error",
          });
        } else {
          navigate(`/users/${row.original.id}`);
        }
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 390px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox width="100%">
        <Stack direction="row" spacing={2} pb={1}>
          <Typography fontSize="25px" fontWeight="500">
            User Management
          </Typography>
        </Stack>
        <hr
          style={{
            border: `1px solid ${textColor.focus}`,
            width: "100%",
          }}
        />
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid
            px={0}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography color="black" ml="1%">
              User List
            </MDTypography>
          </Grid>
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <Tooltip title="	Add New User" placement="top">
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  setUser({});
                  navigate("/users/new");
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + User
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>
        <MDBox display="flex" justifyContent="center">
          <Box py={2} mt={1} borderRadius="5px" bgColor="white" width="98%">
            <Grid container spacing={2}>
              <Grid
                item
                xs={2}
                md={2}
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
                gap="15px"
              >
                <FormControl fullWidth size="small">
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={roleId}
                    label="Role"
                    sx={selectStyle}
                    onChange={(e) => {
                      setRoleId(e.target.value);
                    }}
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    {roles?.map((value, i) => {
                      return (
                        <MenuItem key={i} value={value.id}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={10}
                md={10}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-end", md: "center" },
                }}
                gap="15px"
              >
                <SearchInput setSearch={setSearch} search={search} />
              </Grid>
            </Grid>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                table={table}
                style={{
                  width: "100%",
                }}
              />
            </ThemeProvider>
          </Box>
        </MDBox>
      </MDBox>
    </>
  );
}

export default UsersList;

import {
  Backdrop,
  Grid,
  Icon,
  IconButton,
  CircularProgress,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import DeduplicationClient from "./deduplicationSecurity";
import OtherSecurityClient from "./otherSecurity";

const SecurityConfiguration = ({ data, GetClientSecurityConfiguration }) => {
  let navigate = useNavigate();
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const { client } = useOutletContext();
  const [addData, setAddData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      maxLoi: "",
      minLoi: "",
      minLoiIsActive: false,
      maxLoiIsActive: false,
      researchDefenderScore: 0,
      uniqueIp: false,
      uniqueUser: false,
      countryMismatch: false,
      researchDefender: false,
    },
  });

  let uniqueIp = watch("uniqueIp");
  let uniqueUser = watch("uniqueUser");
  let researchDefender = watch("researchDefender");
  let countryMismatch = watch("countryMismatch");
  let minLoiIsActive = watch("minLoiIsActive");
  let maxLoiIsActive = watch("maxLoiIsActive");
  let maxLoi = watch("maxLoi");
  let minLoi = watch("minLoi");

  let { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data]);

  const getData = async () => {
    if (data !== null) {
      if (
        data.uniqueIp ||
        data.countryMismatch ||
        data.researchDefender ||
        data.uniqueUser ||
        data.researchDefenderScore ||
        data.minLoi ||
        data.maxLoi
      ) {
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
      }
      setValue("uniqueIp", data.uniqueIp);
      setValue("countryMismatch", data.countryMismatch);
      setValue("researchDefender", data.researchDefender);
      setValue("uniqueUser", data.uniqueUser);
      setValue("researchDefenderScore", data.researchDefenderScore);
      setValue("minLoi", data.minLoi);
      setValue("maxLoi", data.maxLoi);
      if (data.minLoi != null || data.maxLoi != null) {
        setValue("maxLoiIsActive", true);
        setValue("minLoiIsActive", true);
      } else {
        setValue("maxLoiIsActive", false);
        setValue("minLoiIsActive", false);
      }
    } else {
      setAddData(true);
      setIsEdit({
        disabled: false,
        edit: false,
      });
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.minLoi = parseInt(data.minLoi);
    payload.maxLoi = parseInt(data.maxLoi);
    payload.researchDefenderScore = parseInt(data.researchDefenderScore);
    if (addData !== true) {
      try {
        let result = await TLinkApi.put(`clients/${id}/securityConfig`, payload);
        await GetClientSecurityConfiguration(true);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        navigate(`/clients/${id}/clientFilters/edit`);
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    } else {
      try {
        payload.clientId = parseInt(id);
        let result = await TLinkApi.post("clients/securityConfig", payload);
        await GetClientSecurityConfiguration(true);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        navigate(`/clients/${id}/clientFilters/edit`);
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <MDBox
        sx={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}
      >
        <form onSubmit={handleSubmit(save)} noValidate>
          <MDBox
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            gap="800px"
            px={2}
            mb={1}
            width="100%"
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Security Configuration
            </Typography>
            {isEdit.disabled === true ? (
              <MDBox
                pt={2}
                px={2}
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                gap="10px"
              >
                <IconButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              </MDBox>
            ) : (
              <MDBox
                pt={2}
                px={2}
                mb={2}
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                gap="10px"
              >
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={async () => {
                    if (!addData) {
                      await GetClientSecurityConfiguration(true);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    } else {
                      reset({
                        maxLoi: "",
                        minLoi: "",
                        minLoiIsActive: false,
                        maxLoiIsActive: false,
                        researchDefenderScore: 0,
                        uniqueIp: false,
                        uniqueUser: false,
                        countryMismatch: false,
                        researchDefender: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </MDBox>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDBox sx={{ minHeight: `457px`, overflowY: "auto" }} mx={2}>
            <DeduplicationClient
              uniqueIp={uniqueIp}
              uniqueUser={uniqueUser}
              setValue={setValue}
              isEdit={isEdit}
            />
            <OtherSecurityClient
              researchDefender={researchDefender}
              countryMismatch={countryMismatch}
              errors={errors}
              control={control}
              setValue={setValue}
              isEdit={isEdit}
            />
            <MDBox>
              <MDTypography fontSize="14px" fontWeight="medium" mb={1}>
                Validation
              </MDTypography>
              <Grid container spacing={1} px={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={minLoiIsActive}
                        onChange={(e, value) => {
                          setValue("minLoiIsActive", value);
                        }}
                        disabled={isEdit.disabled}
                      />
                    }
                    label={
                      <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                        Minimum LOI (mins)
                      </MDTypography>
                    }
                  />
                  <Controller
                    name="minLoi"
                    control={control}
                    rules={{
                      required: minLoiIsActive ? "Min Loi (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        type="number"
                        fullWidth
                        required
                        sx={{ width: "200px", marginLeft: "50px" }}
                        {...register("minLoi", {
                          min: 0,
                          max: maxLoi,
                        })}
                        autoComplete="off"
                        variant="outlined"
                        disabled={isEdit.disabled || !minLoiIsActive}
                        error={errors?.minLoi ? true : false}
                        helperText={
                          errors?.minLoi && errors.minLoi.message !== ""
                            ? errors.minLoi.message
                            : errors?.minLoi?.type === "min" || errors?.minLoi?.type === "max"
                            ? "Check Min Value should be smaller than max"
                            : maxLoiIsActive && !minLoiIsActive
                            ? "Fill the value of min"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={maxLoiIsActive}
                        onChange={(e, value) => {
                          setValue("maxLoiIsActive", value);
                        }}
                        disabled={isEdit.disabled}
                      />
                    }
                    label={
                      <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                        Maximum LOI (mins)
                      </MDTypography>
                    }
                  />
                  <Controller
                    name="maxLoi"
                    control={control}
                    rules={{
                      required: maxLoiIsActive ? "Max Loi (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        sx={{ width: "200px", marginLeft: "50px" }}
                        type="number"
                        disabled={isEdit.disabled || !maxLoiIsActive}
                        required
                        autoComplete="off"
                        variant="outlined"
                        {...register("maxLoi", {
                          min: minLoi,
                        })}
                        error={errors?.maxLoi ? true : false}
                        helperText={
                          errors?.maxLoi && errors.maxLoi.message !== ""
                            ? errors.maxLoi.message
                            : errors?.maxLoi?.type === "min"
                            ? "Check Max Value should be greater than min"
                            : minLoiIsActive && !maxLoiIsActive
                            ? "Fill the value of max"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </>
  );
};

export default SecurityConfiguration;

import React from "react";

const footerStyles = {
  position: "fixed",
  bottom: "0px",
  width: "100%",
  display: "flex",
  padding: "8px 24px",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "8px",
  background: "white",
  borderTop:"1px solid #E4E4E4"
};

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div style={footerStyles}>
      <span style={{ fontSize: 13, color: "gray" }}>
        &copy; {year}- {year + 1} Symmppl | All rights reserved
      </span>
    </div>
  );
}

export default Footer;

import MDBox from "components/MDBox";
import React from "react";
import GetTopSupplierList from "hooks/TopSupplierList/GetTopSupplierList";
import { Stack, Tooltip, Backdrop, CircularProgress } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@emotion/react";

function TopSupplier() {
  const { topSupplier, isLoading } = GetTopSupplierList();
  let navigate = useNavigate();
  const tableTheme = useTableTheme();

  let columns = [
    {
      accessorKey: "supplierId",
      header: "Supplier Id",
      headerAlign: "center",
    },
    {
      accessorKey: "supplierName",
      header: "Supplier Name",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName = name.length > 25 ? name.substring(0, 25) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "starts",
      header: "Starts",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "completes",
      header: "Completes",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "conversion",
      header: "Conv(%)",
      size: 50,
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        if (row) {
          return (
            <div style={{ width: "20px" }}>
              {Math.ceil(
                row?.original?.starts <= 0
                  ? 0
                  : (row?.original?.completes / row?.original?.starts) * 100
              )}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      accessorKey: "avgCpi",
      header: "CPI($)",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },
    {
      accessorKey: "epc",
      header: "EPC",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },
    {
      accessorKey: "revenue",
      header: "Cost($)",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },

    {
      accessorKey: "profit",
      header: "Profit",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: topSupplier,
    state: { density: "compact" },
    enableStickyHeader: true,
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enablePagination: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/vendors/${row.original.supplierId}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3}>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
      </MDBox>
    </>
  );
}

export default TopSupplier;

import { Grid, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React from "react";

function MinMax({ question, updateQuestion }) {
  return (
    <MDBox p={2} fontSize="14px" width="100%">
      {question?.selectedRange.map((value, i) => {
        return (
          <Grid container spacing={4} ml={-1} mb={1.5} key={i}>
            <Grid item xs={12} md={5}>
              <MDInput
                type="number"
                fullWidth
                required
                value={value.min}
                onChange={(e) => {
                  question.selectedRange[i].min = e.target.value;
                  updateQuestion("searchStatus", question);
                }}
                autoComplete="off"
                variant="outlined"
                label="min"
                error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
                helperText={
                  value.min === ""
                    ? "This field is Required"
                    : Number(value.min) < 10
                    ? "This value can not be less then 10"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <MDInput
                type="number"
                fullWidth
                required
                value={value.max}
                onChange={(e) => {
                  question.selectedRange[i].max = e.target.value;
                  updateQuestion("searchStatus", question);
                }}
                autoComplete="off"
                variant="outlined"
                label="max"
                error={
                  value.max === ""
                    ? true
                    : Number(value.min) > Number(value.max)
                    ? true
                    : Number(value.max) > 100
                    ? true
                    : false
                }
                helperText={
                  value.max === ""
                    ? "This field is Required"
                    : Number(value.max) > 100
                    ? "This value can not be greater than 100"
                    : Number(value.min) > Number(value.max)
                    ? "This is not be less then min"
                    : ""
                }
              />
            </Grid>
            {i != 0 && (
              <Grid item xs={12} md={2}>
                <IconButton
                  onClick={() => {
                    question.selectedRange.splice(i, 1);
                    updateQuestion("searchStatus", question);
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            )}
          </Grid>
        );
      })}
      <MDBox display="flex" alignItems="center" justifyContent="flex-end" width="85%">
        <MDButton
          variant="text"
          color="dark"
          onClick={() => {
            let payload = { ...question };
            payload.selectedRange.push({ min: 10, max: 100 });
            updateQuestion("searchStatus", payload);
          }}
        >
          + add more input
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default MinMax;

import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: "#F0F0F0",
      height: `calc(100vh - ${pxToRem(95)})`,
      borderRadius: borderRadius.xl,
      top: `${pxToRem(95)}`,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;

import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Label from "components/InputLabel";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import MatchedIcon from "assets/images/matched.svg";
import UnmatchedIcon from "assets/images/unmatched.svg";
import TokenIcon from "assets/images/token.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReconcilationTable from "../reconcilationTable";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import { writeFile, utils } from "xlsx";
import GetGlobalReconciliationTypeList from "hooks/GlobalReconciliationTypeList/GetGlobalReconciliationTypeList";

const ReconcilationOverview = () => {
  const [reconcileData, setReconcileData] = useState(null);
  const [reconcileCardData, setReconcileCardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  let { reconciliationType } = GetGlobalReconciliationTypeList();

  const GetReconcilationCardData = async () => {
    try {
      let data = await TLinkApi.get(`/global/reconciliation/summary/${id}`);
      if (data.data) setReconcileCardData(data.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const GetReconcilationData = async () => {
    try {
      let data = await TLinkApi.get(`/global/reconciliation/details/${id}`);
      if (data.data) setReconcileData(data.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const PostReconcilation = async () => {
    setLoading(true);
    try {
      let result = await TLinkApi.post(`/global/reconciliation/reconciliation-click`, {
        reconcileId: Number(id),
      });
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      callApi();
      setLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const DownloadFile = async () => {
    setLoading(true);
    try {
      let result = await TLinkApi.get(`/global/reconciliation/${id}`);
      enqueueSnackbar("Successfully Download", {
        variant: "success",
      });
      const tokens = result.data.map((item) => [item.token]);
      const wb = utils.book_new();
      const ws = utils.aoa_to_sheet(tokens);
      utils.book_append_sheet(wb, ws, "Tokens");
      writeFile(wb, `${reconcileData?.fileName}`);
      callApi();
      setLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const callApi = async () => {
    setLoading(true);
    await GetReconcilationCardData();
    await GetReconcilationData();
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      callApi();
    }
  }, [id]);

  return (
    <div style={{ paddingRight: "10px", position: "relative" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 2,
            paddingTop: "0px !important",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            py={1}
            mb={2}
            borderBottom={"1px solid #E4E4E4"}
          >
            <Stack spacing={1} sx={{ width: "70%", overflowWrap: "break-word" }}>
              <Typography fontSize="20px" fontWeight="700">
                {reconcileCardData?.title}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <MDBox
                  sx={{
                    backgroundColor: reconcileCardData?.status === 3 ? "#A9FFBC" : "#BED0FF",
                    color: reconcileCardData?.status === 3 ? "#1DA522" : "#3669EC",
                    display: "flex",
                    alignItems: "center",
                    padding: "4px 16px !important",
                    borderRadius: "8px",
                    fontSize: "11px",
                  }}
                >
                  {reconcileCardData?.status === 3 ? "Completed" : "Draft"}
                </MDBox>
                <FiberManualRecordIcon sx={{ height: "10px" }} />
                <Tooltip title="Reconciliation Id" placement="top">
                  <Typography fontSize="14px" fontWeight="400" color="#7A7A7A">
                    {id}
                  </Typography>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack alignItems="flex-end" spacing={1}>
                <Typography fontSize="16px" fontWeight="700" color="dark">
                  {reconcileCardData?.total}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img src={TokenIcon} height="20px" />
                  <Typography fontSize="14px" fontWeight="400" color="dark">
                    Total Tokens
                  </Typography>
                </Stack>
              </Stack>
              <Stack alignItems="flex-end" spacing={1}>
                <Typography fontSize="16px" fontWeight="700" color="dark">
                  {reconcileCardData?.totalMatched}
                </Typography>
                <Tooltip title="Matching Tokens" placement="top">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img src={MatchedIcon} height="20px" />
                    <Typography fontSize="14px" fontWeight="400" color="dark">
                      Matched
                    </Typography>
                  </Stack>
                </Tooltip>
              </Stack>

              <Stack alignItems="flex-end" spacing={1}>
                <Typography fontSize="16px" fontWeight="700" color="dark">
                  {reconcileCardData?.totalUnMatched}
                </Typography>
                <Tooltip title="Tokens not found" placement="top">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img src={UnmatchedIcon} height="20px" />
                    <Typography fontSize="14px" fontWeight="400" color="dark">
                      Unmatched
                    </Typography>
                  </Stack>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Label value="Reconciliation Title" required={true} />
          <Typography
            fontSize="14px"
            fontWeight="600"
            sx={{ width: "100%", overflowWrap: "break-word" }}
          >
            {reconcileData?.title ? reconcileData?.title : "NA"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Label value="Reconciliation Type" required={true} />
          <Typography fontSize="14px" fontWeight="600">
            {reconciliationType?.find((data) => data.id == reconcileData?.reconcileTypeId)?.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Label value="Reconciliation File" required={true} />
          <Typography
            fontSize="14px"
            fontWeight="600"
            sx={{ width: "100%", overflowWrap: "break-word" }}
          >
            {reconcileData?.fileName ? reconcileData?.fileName : "NA"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            display={!reconcileData ? "none" : ""}
          >
            <MDButton
              size="small"
              variant="contained"
              color="secondary"
              sx={{ display: reconcileCardData?.status === 3 ? "none" : "" }}
              onClick={() => {
                navigate("/globalReconciliation");
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              sx={{ display: reconcileCardData?.status === 3 ? "none" : "" }}
              size="small"
              variant="contained"
              color="info"
              onClick={() => {
                PostReconcilation();
              }}
            >
              Reconcile
            </MDButton>
            <IconButton
              onClick={handleClick}
              sx={{ display: reconcileCardData?.status === 3 ? "" : "none" }}
            >
              <MoreHorizIcon />
            </IconButton>
          </Stack>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={async () => {
                await DownloadFile();
                handleClose();
              }}
            >
              Download File
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12}>
          <Label value="Notes" required={false} />
          <Typography
            fontSize="14px"
            fontWeight="600"
            sx={{ width: "100%", overflowWrap: "break-word" }}
          >
            {reconcileData?.description ? reconcileData?.description : "NA"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize="20px" fontWeight="700" mb={3}>
            Details
          </Typography>
          {reconcileData?.details.length > 0 ? (
            reconcileData?.details.map((val) => {
              return (
                <Stack spacing={0} mb={3}>
                  <Typography fontSize="16px" fontWeight="700">
                    {val.clientName}
                  </Typography>
                  <ReconcilationTable rows={val.projects} />
                </Stack>
              );
            })
          ) : (
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={4}
              textAlign="center"
              fontSize="15px"
              fontWeight="500"
            >
              No matched token
            </MDBox>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReconcilationOverview;

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./stepper.css";
import colors from "assets/theme/base/colors";

const Stepper = ({ steps, step, stepPos, isEdit }) => {
  let navigate = useNavigate();
  let { info, textColor } = colors;

  return (
    <Grid
      item
      xs={12}
      md={3}
      className="tab-card"
      lg={2}
      sx={{
        minHeight: "calc(100vh - 130px)",
      }}
    >
      <MDBox
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        sx={{
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        {steps.map((value, i) => {
          let Logo = value.logo;
          return (
            <div
              style={{
                cursor: isEdit?.edit === true || isEdit?.disabled ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                if (isEdit?.edit === true || isEdit?.disabled) {
                  navigate(value.navigate);
                }
              }}
              key={i}
            >
              <div className="step">
                <Logo
                  fill={
                    i === 0
                      ? info.main
                      : stepPos === i
                      ? textColor.main
                      : step.includes(i)
                      ? info.main
                      : "#aaa"
                  }
                />
                <MDBox
                  className="tab-name"
                  color={
                    stepPos === i ? "textColor" : step.includes(i) ? "info" : " rgba(0, 0, 0, 0.45)"
                  }
                >
                  {value.name}
                </MDBox>
              </div>
              {steps.length - 1 !== i && (
                <>
                  <MDBox
                    sx={{
                      borderLeft:
                        stepPos === i
                          ? `1px solid ${textColor.main}`
                          : step.includes(i)
                          ? `1px solid ${info.main}`
                          : "1px solid #aaa",
                      height: "100px",
                      marginLeft: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  ></MDBox>
                </>
              )}
            </div>
          );
        })}
      </MDBox>
    </Grid>
  );
};

export default Stepper;

import { EditOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const AllowMarkets = ({ langList, setIsLoading, selectedClient, setSelectedClient }) => {
  const { grey } = colors;
  const { id } = useParams();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [markets, setMarkets] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

  const GetAllowMarkets = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`vendors/${id}/clients/${selectedClient}/languages`);
      if (data?.data?.languages && data?.data?.languages.length > 0) {
        setMarkets(data.data.languages.map((val) => Number(val.languageId)));
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
        setIsEdit({
          disabled: false,
          edit: false,
        });
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      GetAllowMarkets();
    }
  }, [selectedClient]);

  const save = async () => {
    try {
      if (addData) {
        let payload = {
          vendorId: id,
          clientId: selectedClient,
          languageIds: markets,
        };
        let result = await TLinkApi.post(`vendors/clients/languages`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let payload = {
          languageIds: markets,
        };
        let result = await TLinkApi.put(
          `vendors/${id}/clients/${selectedClient}/languages`,
          payload
        );
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
      await GetAllowMarkets();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setMarkets([]);
  }, [selectedClient]);

  return (
    <MDBox
      sx={{
        height: "calc(100vh - 300px)",
        borderRight: `1px solid ${grey[400]}`,
        overflowY: "auto",
        paddingInline: "1rem",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        pb="0.5rem"
        sx={{ borderBottom: `1px solid ${grey[400]}`, background: "white", zIndex: "2" }}
      >
        <Typography variant="v6" fontWeight="600">
          Client Markets Allowed
        </Typography>

        {isEdit.disabled ? (
          <MDButton
            size="small"
            variant="contained"
            color="secondary"
            sx={{ display: !selectedClient ? "none" : "" }}
            startIcon={<EditOutlined />}
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
            }}
          >
            Edit
          </MDButton>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ display: !selectedClient ? "none" : "" }}
          >
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                if (addData) {
                  setMarkets([]);
                  setSelectedClient(null);
                } else {
                  GetAllowMarkets();
                }
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              disabled={markets.length <= 0}
              size="small"
              onClick={() => {
                save();
              }}
            >
              Save
            </MDButton>
          </Stack>
        )}
      </Stack>
      {selectedClient ? (
        <FormGroup>
          {isEdit.edit || addData ? (
            <MDBox
              sx={{
                padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                borderBottom: `1px solid ${grey[400]}`,
                display: langList?.length === 0 ? "none" : "",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={markets.length !== 0 && langList?.length === markets.length}
                    color="secondary"
                    onChange={(e) => {
                      let payload = [];
                      if (e.target.checked) {
                        payload = langList?.map((lang) => Number(lang.id));
                      } else {
                        payload = [];
                      }
                      setMarkets(payload);
                    }}
                  />
                }
                label={"Select All"}
              />
            </MDBox>
          ) : (
            ""
          )}
          {langList?.length > 0 ? (
            langList
              ?.filter(
                (lang) =>
                  (isEdit.disabled && markets.includes(Number(lang.id))) || isEdit.edit || addData
              )
              .map((lang, i) => (
                <MDBox
                  sx={{
                    padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                    borderBottom: `1px solid ${grey[400]}`,
                  }}
                  key={i}
                >
                  {isEdit.edit || addData ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={markets.includes(Number(lang.id))}
                          color="secondary"
                          onChange={(e) => {
                            let payload = [...markets];
                            if (e.target.checked) {
                              payload.push(Number(lang.id));
                            } else {
                              let data = payload.filter((val) => val !== Number(lang.id));
                              payload = data;
                            }
                            setMarkets(payload);
                          }}
                        />
                      }
                      label={lang.name}
                    />
                  ) : (
                    <Typography fontSize="0.875rem" fontWeight="700">
                      {lang.name}
                    </Typography>
                  )}
                </MDBox>
              ))
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
              <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
                No markets here !
              </Typography>
            </Stack>
          )}
        </FormGroup>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
          <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
            Please select client to see its markets
          </Typography>
        </Stack>
      )}
    </MDBox>
  );
};

export default AllowMarkets;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const SurveyAllListWithProjectId = () => {
  let { id } = useParams();
  const [surveyList, setSurveyList] = useState([]);
  const getAllSurveysWithProjectId = async () => {
    try {
      let data = await TLinkApi.get(`/projects/${id}/surveys/list`);
      if (data.data) {
        setSurveyList(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAllSurveysWithProjectId();
    // eslint-disable-next-line
  }, [id]);
  return { surveyList, getAllSurveysWithProjectId };
};

export default SurveyAllListWithProjectId;

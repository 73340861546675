import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const Type = [
  { name: "HMAC-SHA256 Hash", value: "1" },
  { name: "HMAC-SHA-256 Hash (BASE64)", value: "2" },
  { name: "HMAC-SHA-1 Hash (BASE64)", value: "3" },
  { name: "HMAC-SHA-1 Hash", value: "4" },
  { name: "DYNATA", value: "5" },
  { name: "PRODEGE", value: "8" },
];

function Encryption({ data, GetEncryptionData }) {
  const [addData, setAddData] = useState(true);
  let { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      inComingEncryptionParam: "",
      inComingEncryptionEnable: false,
      inComingEncryptionKey: "",
      inComingEncryptionTypeId: -1,
      outGoingEncryptionParam: "",
      outGoingEncryptionEnable: false,
      outGoingEncryptionKey: "",
      outGoingEncryptionTypeId: -1,
    },
  });

  let inComingEncryptionEnable = watch("inComingEncryptionEnable");
  let outGoingEncryptionEnable = watch("outGoingEncryptionEnable");

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };
  const { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data]);

  async function getData() {
    if (data !== null) {
      if (data.outGoingEncryptionEnable || data.inComingEncryptionEnable) {
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
      }
      setValue("inComingEncryptionParam", data.inComingEncryptionParam);
      setValue("inComingEncryptionEnable", data.inComingEncryptionEnable);
      setValue("inComingEncryptionKey", data.inComingEncryptionKey);
      setValue("inComingEncryptionTypeId", data.inComingEncryptionTypeId);

      setValue("outGoingEncryptionParam", data.outGoingEncryptionParam);
      setValue("outGoingEncryptionEnable", data.outGoingEncryptionEnable);
      setValue("outGoingEncryptionKey", data.outGoingEncryptionKey);
      setValue("outGoingEncryptionTypeId", data.outGoingEncryptionTypeId);
    } else {
      setAddData(true);
      setIsEdit({
        disabled: false,
        edit: false,
      });
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.clientId = id;

    try {
      if (addData !== true) {
        let result = await TLinkApi.put(`/clients/${id}/encryption`, payload);
        await GetEncryptionData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post(`/clients/encryption`, payload);
        await GetEncryptionData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox pb={1} display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Encryption</Typography>
          <MDBox>
            {isEdit.disabled === true ? (
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={async () => {
                    if (!addData) {
                      await GetEncryptionData(true);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    } else {
                      reset({
                        inComingEncryptionParam: "",
                        inComingEncryptionEnable: false,
                        inComingEncryptionKey: "",
                        inComingEncryptionTypeId: -1,
                        outGoingEncryptionParam: "",
                        outGoingEncryptionEnable: false,
                        outGoingEncryptionKey: "",
                        outGoingEncryptionTypeId: -1,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        {addData || isEdit.edit ? (
          <MDBox style={{ paddingLeft: "10px" }}>
            <FormControlLabel
              sx={{ marginBottom: "10px" }}
              control={
                <Switch
                  size="small"
                  disabled={isEdit.disabled}
                  checked={inComingEncryptionEnable}
                  onChange={(e, value) => {
                    setValue("inComingEncryptionEnable", value);
                    if (!value) {
                      setValue("inComingEncryptionParam", "");
                      setValue("inComingEncryptionKey", "");
                      setValue("inComingEncryptionTypeId", -1);
                    }
                  }}
                />
              }
              label={
                <MDTypography variant="h6" fontWeight="medium">
                  {"Incoming"}
                </MDTypography>
              }
            />
            {inComingEncryptionEnable && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {label("Param")}
                  <Controller
                    name="inComingEncryptionParam"
                    control={control}
                    rules={{
                      required: "Param Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Param"
                        error={errors?.inComingEncryptionParam ? true : false}
                        helperText={
                          errors?.inComingEncryptionParam
                            ? errors.inComingEncryptionParam.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Key")}
                  <Controller
                    name="inComingEncryptionKey"
                    control={control}
                    rules={{
                      required: "Key Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Key"
                        error={errors?.inComingEncryptionKey ? true : false}
                        helperText={
                          errors?.inComingEncryptionKey
                            ? errors.inComingEncryptionKey.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Encryption Type", true)}
                  <Controller
                    name="inComingEncryptionTypeId"
                    control={control}
                    rules={{
                      required: "Enycription Type Required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        required
                        size="small"
                        sx={{
                          label: {
                            color: "rgba(0, 0, 0, 0.25)",
                            fontWeight: "400",
                          },
                        }}
                        error={errors?.inComingEncryptionTypeId ? true : false}
                      >
                        <Select
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          onChange={onChange}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Enycription Type"
                        >
                          <MenuItem value="1">HMAC-SHA256 Hash</MenuItem>
                          <MenuItem value="2">HMAC-SHA-256 Hash (BASE64)</MenuItem>
                          <MenuItem value="3">HMAC-SHA-1 Hash (BASE64)</MenuItem>
                          <MenuItem value="4">HMAC-SHA-1 Hash</MenuItem>
                          <MenuItem value="5">DYNATA</MenuItem>
                          <MenuItem value="8">PRODEGE</MenuItem>
                          {/* <MenuItem value="1">SHA 256</MenuItem>
                        <MenuItem value="2">SHA 1</MenuItem> */}
                        </Select>
                        {errors?.inComingEncryptionTypeId && (
                          <FormHelperText>
                            {errors?.inComingEncryptionTypeId
                              ? errors.inComingEncryptionTypeId.message
                              : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <FormControlLabel
              sx={{ marginBottom: "10px", marginTop: "30px" }}
              control={
                <Switch
                  size="small"
                  disabled={isEdit.disabled}
                  checked={outGoingEncryptionEnable}
                  onChange={(e, value) => {
                    setValue("outGoingEncryptionEnable", value);
                    if (!value) {
                      setValue("outGoingEncryptionParam", "");
                      setValue("outGoingEncryptionKey", "");
                      setValue("outGoingEncryptionTypeId", -1);
                    }
                  }}
                />
              }
              label={
                <MDTypography variant="h6" fontWeight="medium">
                  {"Outgoing"}
                </MDTypography>
              }
            />

            {outGoingEncryptionEnable && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {label("Param")}
                  <Controller
                    name="outGoingEncryptionParam"
                    control={control}
                    rules={{
                      required: "Param Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        required
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Param"
                        error={errors?.outGoingEncryptionParam ? true : false}
                        helperText={
                          errors?.outGoingEncryptionParam
                            ? errors.outGoingEncryptionParam.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("key")}
                  <Controller
                    name="outGoingEncryptionKey"
                    control={control}
                    rules={{
                      required: "Key Type Required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Key"
                        error={errors?.outGoingEncryptionKey ? true : false}
                        helperText={
                          errors?.outGoingEncryptionKey
                            ? errors.outGoingEncryptionKey.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Encryption Type", true)}
                  <Controller
                    name="outGoingEncryptionTypeId"
                    control={control}
                    rules={{
                      required: "Enycription Type Required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: "rgba(0, 0, 0, 0.25)",
                            fontWeight: "400",
                          },
                        }}
                        required
                        error={errors?.inComingEncryptionTypeId ? true : false}
                      >
                        <Select
                          value={value}
                          sx={selectStyle}
                          disabled={isEdit.disabled}
                          onChange={onChange}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Enycription Type"
                        >
                          <MenuItem value="1">HMAC-SHA256 Hash</MenuItem>
                          <MenuItem value="2">HMAC-SHA-256 Hash (BASE64)</MenuItem>
                          <MenuItem value="3">HMAC-SHA-1 Hash (BASE64)</MenuItem>
                          <MenuItem value="4">HMAC-SHA-1 Hash</MenuItem>
                          <MenuItem value="5">DYNATA</MenuItem>
                          <MenuItem value="8">PRODEGE</MenuItem>
                          {/* <MenuItem value="1">SHA 256</MenuItem>
                        <MenuItem value="2">SHA 1</MenuItem> */}
                        </Select>
                        {errors?.outGoingEncryptionTypeId && (
                          <FormHelperText>
                            {errors?.outGoingEncryptionTypeId
                              ? errors.outGoingEncryptionTypeId.message
                              : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
        ) : (
          <MDBox style={{ paddingLeft: "10px" }}>
            <FormControlLabel
              sx={{ marginBottom: "10px" }}
              control={
                <Switch
                  size="small"
                  disabled
                  checked={inComingEncryptionEnable}
                  onChange={(e, value) => {
                    setValue("inComingEncryptionEnable", value);
                  }}
                />
              }
              label={
                <MDTypography variant="h6" fontWeight="medium">
                  {"Incoming"}
                </MDTypography>
              }
            />
            {inComingEncryptionEnable && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {label("Param")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("inComingEncryptionParam") ? watch("inComingEncryptionParam") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Key")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("inComingEncryptionKey") ? watch("inComingEncryptionKey") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Encryption Type", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("inComingEncryptionTypeId")
                      ? Type?.find(
                          (value) =>
                            Number(value.value) === Number(watch("inComingEncryptionTypeId"))
                        )?.name
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <FormControlLabel
              sx={{ marginBottom: "10px", marginTop: "30px" }}
              control={
                <Switch
                  size="small"
                  disabled
                  checked={outGoingEncryptionEnable}
                  onChange={(e, value) => {
                    setValue("outGoingEncryptionEnable", value);
                  }}
                />
              }
              label={
                <MDTypography variant="h6" fontWeight="medium">
                  {"Outgoing"}
                </MDTypography>
              }
            />

            {outGoingEncryptionEnable && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {label("Param")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("outGoingEncryptionParam") ? watch("outGoingEncryptionParam") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("key")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("outGoingEncryptionKey") ? watch("outGoingEncryptionKey") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Encryption Type", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("outGoingEncryptionTypeId")
                      ? Type?.find(
                          (value) =>
                            Number(value.value) === Number(watch("outGoingEncryptionTypeId"))
                        )?.name
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </MDBox>
        )}
      </form>
    </>
  );
}

export default Encryption;

import { Checkbox, FormControlLabel, IconButton, Stack, Typography, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditIcon from "assets/images/Pencile.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import colors from "assets/theme/base/colors";
import QualConfiguretion from "../QualConfiguretion";
import MDButton from "components/MDButton";
import QualificationIcon from "assets/images/qualificationIcon.svg";

const QuestionList = ({
  setRows,
  setColumns,
  setQualOpen,
  qualification,
  setQualification,
  addData,
  setProceedData,
}) => {
  const [qualId, setQualId] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editQual, setEditQual] = useState(null);
  const [valid, setValid] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setEditQual(null);
  };

  let { id } = useParams();
  let { primary } = colors;

  useEffect(() => {
    let check = false;
    qualification.filter((qual) => {
      if (qual.qualificationTypeId === 1 || qual.qualificationTypeId === 2) {
        if (qual.checked === true) {
          if (qual.selectedAnsCheck.length > 0) {
            check = true;
          } else {
            check = false;
          }
        }
      } else if (qual.qualificationTypeId === 3) {
        if (qual.checked === true) {
          if (qual.selectedTextCheck.length > 0) {
            check = true;
          } else {
            check = false;
          }
        }
      } else {
        if (qual.checked === true) {
          if (qual.selectedRangeCheck.length > 0) {
            check = true;
          } else {
            check = false;
          }
        }
      }
    });

    setValid(check);
  }, [qualification]);

  function crossJoin(surveyId, questions) {
    // Initialize result array
    let result = [];
    // Recursive function to generate combinations
    function generateCombinations(surveyId, index, currentCombination) {
      // If index is equal to the number of questions, we've reached the end
      if (index === questions.length) {
        let quota = {
          name: "",
          quotaCpi: 0,
          completes: 0,
          starts: 0,
          sampleNumber: null,
          sampleNumber: 0,
          remaining: 0,
          isActive: true,
          conditions: currentCombination.slice(),
        };
        result.push({ surveyId: surveyId, quota: quota });
        return;
      }

      // Iterate over answers for the current question
      for (let answerObj of questions[index].answers) {
        // Add current answer to the combination
        if (
          questions[index].qualificationTypeId == 4 ||
          questions[index].qualificationTypeId == 3
        ) {
          let answerText = [answerObj.qualificationAnswerDesc];
          currentCombination.push({
            qualificationId: questions[index].qualificationId,
            answerText,
            isActive: true,
          });
        } else {
          let answerText = [answerObj.qualificationAnswerDesc];
          let answerId = [answerObj.id];
          currentCombination.push({
            qualificationId: questions[index].qualificationId,
            answerId,
            answerText,
            isActive: true,
          });
        }

        // Recur to next question
        generateCombinations(surveyId, index + 1, currentCombination);
        // Backtrack - remove the last added answer
        currentCombination.pop();
      }
    }
    // Start generating combinationscd
    generateCombinations(surveyId, 0, []);
    return result;
  }

  const onPrceed = () => {
    let data = JSON.parse(JSON.stringify(qualification));
    console.log(data, "datadatadatadata");
    let proceedData = JSON.parse(
      JSON.stringify(
        data
          .filter((val) => {
            if (val.checked && val.isActive) {
              return true;
            } else {
              return false;
            }
          })
          .map((val) => {
            if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
              val.answers = val.question.answers
                .filter((ans) => val.selectedAnsCheck.includes(Number(ans.id)))
                .map((ans) => {
                  delete ans.createdAt;
                  delete ans.createdBy;
                  delete ans.answerRefId;
                  delete ans.isActive;
                  delete ans.precode;
                  delete ans.questionId;
                  delete ans.updatedAt;
                  delete ans.updatedBy;
                  return ans;
                });
              delete val.selectedAnsCheck;
              delete val.selectedAnswers;
            } else if (val.qualificationTypeId === 3) {
              val.answers = val.text
                .filter((ans, textIndex) => val.selectedTextCheck.includes(Number(textIndex)))
                .map((ans) => {
                  return {
                    id: null,
                    qualificationAnswerDesc: ans,
                  };
                });
              delete val.selectedText;
              delete val.selectedTextCheck;
              delete val.text;
            } else {
              val.answers = val.range
                .filter((ans, rangeIndex) => val.selectedRangeCheck.includes(Number(rangeIndex)))
                .map((ans) => {
                  return {
                    id: null,
                    qualificationAnswerDesc: `${ans.min}-${ans.max}`,
                  };
                });
              delete val.selectedRange;
              delete val.selectedRangeCheck;
              delete val.range;
            }
            delete val.question.answers;
            return val;
          })
      )
    );
    let createColumns = proceedData
      .filter((val) => val.isActive)
      .map((val, index) => {
        return {
          id: Number(val.qualificationId),
          numeric: false,
          disablePadding: index === 0 ? true : false,
          label: val.question.questionSubText
            ? val.question.questionSubText
            : val.question.questionText,
        };
      });
    let uniqueColumn = [
      {
        id: "sampleNumber",
        numeric: true,
        disablePadding: false,
        label: "Quota",
      },
      {
        id: "starts",
        numeric: true,
        disablePadding: false,
        label: "Starts",
      },
      {
        id: "completes",
        numeric: true,
        disablePadding: false,
        label: "Completes",
      },
      {
        id: "conversion",
        numeric: true,
        disablePadding: false,
        label: "Conversion",
      },
      {
        id: "remaining",
        numeric: true,
        disablePadding: false,
        label: "Remaining",
      },
    ];

    const newColumn = createColumns.concat(uniqueColumn);
    if (!addData) {
      newColumn.push({
        id: "isActive",
        numeric: true,
        disablePadding: false,
        label: "Actions",
      });
    }
    setColumns(newColumn);
    const result = crossJoin(id, proceedData);
    setRows(
      JSON.parse(
        JSON.stringify(
          result.map((val, index) => {
            val.quota.id = -(Number(index) + 1);
            return val;
          })
        )
      )
    );
    setQualOpen(false);
    setProceedData(true);
  };

  return (
    <MDBox
      sx={{
        height: `calc(100vh - 285px)`,
        overflowY: "auto",
        borderRight: "1px solid var(--Grey-3, #E4E4E4)",
        paddingRight: "1rem",
      }}
    >
      <QualConfiguretion
        open={open}
        handleClose={handleClose}
        editQual={editQual}
        setEditQual={setEditQual}
        qualification={qualification}
        setQualification={setQualification}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        mt={1}
        sx={{
          paddingTop: "0.5rem",
          paddingInline: "0.5rem",
          top: 0,
          position: "sticky",
          background: "white",
          zIndex: 2,
        }}
      >
        <img src={QualificationIcon} />
        <Typography fontWeight="600" fontSize="18px" color="primary">
          Qualifications
        </Typography>
      </Stack>
      {qualification.length > 0 ? (
        qualification?.map((val, qualIndex) => {
          return (
            <MDBox sx={{ background: !val.isActive ? "#f5f5f5" : "" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                py={0.5}
                sx={{
                  borderBottom: qualId.includes(val.id)
                    ? `1px solid ${primary.main}`
                    : "1px solid #E4E4E4",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0}
                  sx={{
                    width:
                      val.qualificationTypeId === 4 || val.qualificationTypeId === 3
                        ? "75%"
                        : "80%",
                  }}
                >
                  <Checkbox
                    disabled={!val.isActive}
                    color="secondary"
                    checked={val.isActive && val.checked ? true : false}
                    onChange={(e) => {
                      let payload = [...qualification];
                      payload[qualIndex].checked = e.target.checked;
                      if (e.target.checked === false) {
                        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
                          payload[qualIndex].selectedAnsCheck = [];
                        } else if (val.qualificationTypeId === 3) {
                          payload[qualIndex].selectedTextCheck = [];
                        } else {
                          payload[qualIndex].selectedRangeCheck = [];
                        }
                      } else {
                        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
                          payload[qualIndex].selectedAnsCheck =
                            qualification[qualIndex].selectedAnswers;
                        } else if (val.qualificationTypeId === 3) {
                          payload[qualIndex].selectedTextCheck = qualification[qualIndex].text.map(
                            (_val, index) => Number(index)
                          );
                        } else {
                          payload[qualIndex].selectedRangeCheck = qualification[
                            qualIndex
                          ].range.map((_val, index) => Number(index));
                        }
                      }
                      setQualification(payload);

                      let payloadCheck = [...qualId];
                      if (!qualId.includes(val.id)) {
                        payloadCheck.push(Number(val.id));
                      }
                      setQualId(payloadCheck);
                    }}
                  />
                  <Tooltip
                    title={
                      val?.question?.questionSubText
                        ? val?.question?.questionSubText
                        : val?.question?.questionText
                    }
                  >
                    <Typography
                      fontWeight="400"
                      fontSize="14px"
                      sx={{ width: "90%", overflowWrap: "break-word" }}
                    >
                      {val?.question?.questionSubText
                        ? val?.question?.questionSubText
                        : val?.question?.questionText}{" "}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <IconButton
                    disabled={!val.isActive}
                    sx={{
                      display:
                        val.qualificationTypeId === 4 || val.qualificationTypeId === 3
                          ? ""
                          : "none",
                    }}
                    onClick={() => {
                      setOpen(true);
                      setEditQual(JSON.parse(JSON.stringify(val)));
                      let payload = [...qualId];
                      if (!qualId.includes(val.id)) {
                        payload.push(Number(val.id));
                      }
                      setQualId(payload);
                    }}
                  >
                    <img src={EditIcon} height="15px" />
                  </IconButton>
                  <IconButton
                    sx={{ padding: "6px !important" }}
                    onClick={() => {
                      let payload = [...qualId];
                      if (qualId.includes(val.id)) {
                        payload = qualId.filter((valId) => valId !== Number(val.id));
                      } else {
                        payload.push(Number(val.id));
                      }
                      setQualId(payload);
                    }}
                  >
                    <KeyboardArrowDownIcon
                      sx={{
                        fontSize: "5rem",
                        transform: qualId.includes(val.id) ? "rotate(180deg)" : "",
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <MDBox sx={{ display: !qualId.includes(Number(val.id)) ? "none" : "" }}>
                {val.qualificationTypeId === 1 || val.qualificationTypeId === 2
                  ? val.question.answers
                      .filter((ans) => val.selectedAnswers.includes(Number(ans.id)))
                      .map((ans, ansI) => {
                        return (
                          <Stack sx={{ marginLeft: "1rem", borderBottom: "1px solid #E4E4E4" }}>
                            <FormControlLabel
                              sx={{ paddingInline: "0.2rem" }}
                              control={
                                <Checkbox
                                  disabled={!val.isActive}
                                  checked={
                                    val.isActive && val.selectedAnsCheck.includes(Number(ans.id))
                                  }
                                  color="secondary"
                                  onChange={(e) => {
                                    let payload = [...qualification];
                                    if (e.target.checked) {
                                      payload[qualIndex].selectedAnsCheck.push(Number(ans.id));
                                    } else {
                                      payload[qualIndex].selectedAnsCheck = qualification[
                                        qualIndex
                                      ].selectedAnsCheck.filter(
                                        (value) => value !== Number(ans.id)
                                      );
                                    }
                                    if (payload[qualIndex].selectedAnsCheck.length > 0) {
                                      payload[qualIndex].checked = true;
                                    } else {
                                      payload[qualIndex].checked = false;
                                    }
                                    setQualification(payload);
                                  }}
                                />
                              }
                              label={
                                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                  <Tooltip title={ans.qualificationAnswerDesc}>
                                    {ans.qualificationAnswerDesc.length > 40
                                      ? ans.qualificationAnswerDesc.substring(0, 40) + "..."
                                      : ans.qualificationAnswerDesc}
                                  </Tooltip>
                                </span>
                              }
                              key={ansI}
                            />
                          </Stack>
                        );
                      })
                  : val.qualificationTypeId === 3
                  ? val?.text?.map((textValue, textI) => {
                      return (
                        <Stack sx={{ marginLeft: "1rem", borderBottom: "1px solid #E4E4E4" }}>
                          <FormControlLabel
                            sx={{ paddingInline: "0.2rem" }}
                            control={
                              <Checkbox
                                disabled={!val.isActive}
                                color="secondary"
                                checked={
                                  val.isActive && val.selectedTextCheck.includes(Number(textI))
                                }
                                onChange={(e) => {
                                  let payload = [...qualification];
                                  if (e.target.checked) {
                                    payload[qualIndex].selectedTextCheck.push(Number(textI));
                                  } else {
                                    payload[qualIndex].selectedTextCheck = qualification[
                                      qualIndex
                                    ].selectedTextCheck.filter((value) => value !== Number(textI));
                                  }
                                  if (payload[qualIndex].selectedTextCheck.length > 0) {
                                    payload[qualIndex].checked = true;
                                  } else {
                                    payload[qualIndex].checked = false;
                                  }
                                  setQualification(payload);
                                }}
                              />
                            }
                            label={
                              <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                <Tooltip title={textValue}>
                                  {textValue.length > 40
                                    ? textValue.substring(0, 40) + "..."
                                    : textValue}
                                </Tooltip>
                              </span>
                            }
                            key={textI}
                          />
                        </Stack>
                      );
                    })
                  : val.qualificationTypeId === 4
                  ? val?.range?.map((rangeValue, rangeI) => {
                      return (
                        <Stack sx={{ marginLeft: "1rem", borderBottom: "1px solid #E4E4E4" }}>
                          <FormControlLabel
                            sx={{ paddingInline: "0.2rem" }}
                            control={
                              <Checkbox
                                disabled={!val.isActive}
                                checked={
                                  val.isActive && val.selectedRangeCheck.includes(Number(rangeI))
                                }
                                color="secondary"
                                onChange={(e) => {
                                  let payload = [...qualification];
                                  if (e.target.checked) {
                                    payload[qualIndex].selectedRangeCheck.push(Number(rangeI));
                                  } else {
                                    payload[qualIndex].selectedRangeCheck = qualification[
                                      qualIndex
                                    ].selectedRangeCheck.filter(
                                      (value) => value !== Number(rangeI)
                                    );
                                  }
                                  if (payload[qualIndex].selectedRangeCheck.length > 0) {
                                    payload[qualIndex].checked = true;
                                  } else {
                                    payload[qualIndex].checked = false;
                                  }
                                  setQualification(payload);
                                }}
                              />
                            }
                            label={
                              <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                <Tooltip title={`${rangeValue?.min}-${rangeValue?.max}`}>
                                  {`${rangeValue?.min}-${rangeValue?.max}`.length > 40
                                    ? `${rangeValue?.min}-${rangeValue?.max}`.substring(0, 40) +
                                      "..."
                                    : `${rangeValue?.min}-${rangeValue?.max}`}
                                </Tooltip>
                              </span>
                            }
                            key={rangeI}
                          />
                        </Stack>
                      );
                    })
                  : ""}
              </MDBox>
            </MDBox>
          );
        })
      ) : (
        <MDBox
          fontSize="15px"
          fontWeight="500"
          display="flex"
          mt={5}
          alignItems="center"
          justifyContent="center"
        >
          There are no Qualification here!
        </MDBox>
      )}

      <Stack
        sx={{
          paddingBottom: "0.5rem",
          paddingInline: "0.2rem",
          bottom: 0,
          position: "sticky",
          background: "white",
          zIndex: 2,
          marginTop: "1rem",
          display: qualification.length <= 0 ? "none" : "",
        }}
      >
        <MDButton
          color="info"
          fullWidth
          disabled={valid ? false : true}
          onClick={() => {
            onPrceed();
          }}
        >
          Proceed
        </MDButton>
      </Stack>
    </MDBox>
  );
};

export default QuestionList;

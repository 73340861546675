import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Box,
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowDown from "assets/images/ArrowDown.svg";
import ArrowUp from "assets/images/ArrowUp.svg";
import TLinkApi from "services/tlink.api";
import MDBox from "components/MDBox";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material";
import MDButton from "components/MDButton";
import Colors from "../../../../assets/theme/base/colors";
import { EditOutlined } from "@mui/icons-material";
import MDTypography from "components/MDTypography";

const StyledHeadTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 14,
  lineHeight: "140%",
  color: "grey",
  borderRight: `1px solid #ded9db`,
  width: "25%",
}));

const StyledRolesHeadTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 14,
  lineHeight: "140%",
  color: "grey",
}));

const ScrollableTableContainer = styled(Box)`
  max-height: calc(100vh - 300px);
  max-width: 100%;
  overflow: auto;
  // scrollbar-width: none;
  // -ms-overflow-style: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
`;

export default function Permissions() {
  const [roles, setRoles] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);
  const [permissionTypes, setPermissionTypes] = useState([]);
  const [data, setData] = useState([]);

  const [openRows, setOpenRows] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [edit, setEdit] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const { textColor } = Colors;

  const handleRoleCheckboxChange = (objectID, roleID, value) => {
    setData((prevData) => {
      const newData = [...prevData];
      const objectIndex = newData.findIndex((obj) => obj.objectId === objectID);
      const roleIndex = newData[objectIndex].roles.findIndex((r) => r.roleId === roleID);
      newData[objectIndex].roles[roleIndex].permissions.forEach((p) => (p.isActive = value));

      return newData;
    });
  };

  const toggleOpen = (id) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const postData = objectTypes.map((object) => {
      const currentObject = data.find((d) => d.objectId === object.id);
      if (!currentObject) return;

      const rolesArray = roles.map((role) => {
        const currentRole = currentObject.roles.find((r) => r.roleId === role.id);
        if (!currentRole) return;

        const permissionsArray = permissionTypes.map((permission) => {
          const currentPermission = currentRole.permissions.find(
            (p) => p.permissionTypeId === permission.id
          );
          if (
            !currentPermission &&
            !((permission.id == 2 && object.id == 7) || (permission.id == 4 && object.id == 7))
          )
            return;

          return (
            !((permission.id == 2 && object.id == 7) || (permission.id == 4 && object.id == 7)) && {
              permissionTypeId: permission.id,
              isActive: currentPermission.isActive,
              id: currentPermission.id,
            }
          );
        });

        return {
          roleId: role.id,
          permissions: permissionsArray,
        };
      });

      return {
        objectId: object.id,
        roles: rolesArray,
      };
    });

    try {
      if (isDataFetched) {
        let result = await TLinkApi.put(`/roles/permissions/`, postData);
        setIsLoading(false);

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
    setEdit(false);
    setIsLoading(false);
  };

  useEffect(() => {
    const initializedData = objectTypes.map((object) => ({
      objectId: object.id,
      roles: roles.map((role) => ({
        roleId: role.id,
        permissions: permissionTypes.map((permission) => ({
          permissionTypeId: permission.id,
          isActive: false,
        })),
      })),
    }));
    if (!isDataFetched) setData(initializedData);
  }, [roles, objectTypes, permissionTypes]);

  const handleCheckboxChange = (objectID, roleID, permissionID, value) => {
    setData((prevData) => {
      const newData = [...prevData];
      const objectIndex = newData.findIndex((obj) => obj.objectId === objectID);
      const roleIndex = newData[objectIndex].roles.findIndex((r) => r.roleId === roleID);
      const permissionIndex = newData[objectIndex].roles[roleIndex].permissions.findIndex(
        (p) => p.permissionTypeId === permissionID
      );

      newData[objectIndex].roles[roleIndex].permissions[permissionIndex].isActive = value;

      return newData;
    });
  };

  const GetRolesPermissions = async () => {
    try {
      const rolePermissionData = await TLinkApi.get(`/roles/permissions`);
      if (rolePermissionData && rolePermissionData.data.length > 0) {
        setData(rolePermissionData.data);
        setIsDataFetched(true);
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const GetRoles = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/role`);
      setRoles(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const GetObjectTypeList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/object`);
      setObjectTypes(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const GetPermissionTypeList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/permission`);
      setPermissionTypes(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetRoles();
    GetObjectTypeList();
    GetPermissionTypeList();
    GetRolesPermissions();
  }, []);

  return (
    <>
      <MDBox>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2} pb={1} display="flex" flexDirection="row">
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography fontSize="25px" color="black" ml="1%">
              Permissions
            </MDTypography>
          </Grid>
        </Grid>
        <hr
          style={{
            border: `1px solid ${textColor.main}`,
            width: "100%",
          }}
        />

        <MDBox
          display="flex"
          justifyContent="flex-end"
          pb={2}
          pt={2}
          sx={{ width: "80%", boxSizing: "border-box", marginLeft: "140px" }}
        >
          {edit === false && (
            <MDButton
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<EditOutlined />}
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </MDButton>
          )}

          {edit === true && (
            <MDBox display="flex" justifyContent="flex-end" gap="10px">
              <MDButton
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  GetRoles();
                  GetObjectTypeList();
                  GetPermissionTypeList();
                  GetRolesPermissions();
                  setEdit(false);
                }}
              >
                &nbsp;cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </MDButton>
            </MDBox>
          )}
        </MDBox>
        <MDBox sx={{ width: "80%", border: "1px solid #ded9db", marginLeft: "140px" }}>
          <ScrollableTableContainer>
            <Box sx={{ width: "100%" }}>
              <Paper
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  boxShadow: "none",
                }}
              >
                <Table>
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow sx={{ borderBottom: "1px solid #ded9db" }}>
                      <StyledHeadTableCell
                        style={{ paddingLeft: "3.7rem", borderBottom: "1px solid #ded9db" }}
                      >
                        Scope
                      </StyledHeadTableCell>
                      {roles.map((role) => (
                        <StyledRolesHeadTableCell
                          style={{ borderBottom: "1px solid #ded9db" }}
                          key={role.id}
                        >
                          {role.name}
                        </StyledRolesHeadTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objectTypes.map((object) => (
                      <React.Fragment key={object.id}>
                        <TableRow>
                          <StyledHeadTableCell
                            onClick={() => toggleOpen(object.id)}
                            style={{ cursor: "pointer", borderBottom: "1px solid #ded9db" }}
                          >
                            <IconButton size="small" style={{ padding: "1rem" }}>
                              {openRows[object.id] ? (
                                <img src={ArrowDown} />
                              ) : (
                                <img src={ArrowUp} />
                              )}
                            </IconButton>
                            {object.name}
                          </StyledHeadTableCell>
                          {roles.map((role) => (
                            <TableCell key={role.id} style={{ borderBottom: "1px solid #ded9db" }}>
                              <Checkbox
                                style={{
                                  color: edit ? "#B1A1EE !important" : "#B1A1EE !important",
                                }}
                                disabled={!edit}
                                checked={
                                  data
                                    ?.find((o) => o.objectId == object.id)
                                    ?.roles.find((r) => r.roleId == role.id)
                                    ?.permissions.every((p) => p.isActive) || false
                                }
                                onChange={(e) =>
                                  handleRoleCheckboxChange(object.id, role.id, e.target.checked)
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                        {openRows[object.id] &&
                          permissionTypes.map(
                            (permission) =>
                              !(
                                (permission.id == 2 && object.id == 7) ||
                                (permission.id == 4 && object.id == 7)
                              ) && (
                                <TableRow key={permission.id}>
                                  <StyledHeadTableCell
                                    style={{ borderBottom: "1px solid #ded9db" }}
                                  >
                                    <Typography sx={{ marginLeft: "2.8rem", fontSize: "15px" }}>
                                      {" "}
                                      {permission.name}
                                    </Typography>
                                  </StyledHeadTableCell>
                                  {roles.map((role) => (
                                    <TableCell
                                      key={role.id}
                                      style={{ borderBottom: "1px solid #ded9db" }}
                                    >
                                      <Checkbox
                                        style={{
                                          color: edit ? "#B1A1EE !important" : "#B1A1EE !important",
                                        }}
                                        disabled={!edit}
                                        checked={
                                          data
                                            ?.find((o) => o.objectId == object.id)
                                            ?.roles.find((r) => r.roleId == role.id)
                                            ?.permissions.find(
                                              (p) => p.permissionTypeId == permission.id
                                            )?.isActive || false
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            object.id,
                                            role.id,
                                            permission.id,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                          )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Box>
          </ScrollableTableContainer>
        </MDBox>
      </MDBox>
    </>
  );
}

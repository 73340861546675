import { Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import React from "react";

const NotesList = ({ selectedNote, setSelectedNote, notes }) => {
  const { secondary } = colors;

  return (
    <div style={{ paddingInline: "10px", paddingBottom: "10px" }}>
      {notes && notes?.length > 0 ? (
        notes?.map((note, i) => (
          <MDBox
            id={`${note.id}`}
            onClick={() => {
              setSelectedNote(note);
            }}
            sx={{
              background: selectedNote?.id === note.id ? `${secondary.light} !important` : "",
              cursor: "pointer",
              "&:hover": {
                background: "#f5f5f5",
              },
            }}
            p={1.5}
            key={i}
          >
            <Typography
              variant="caption"
              color="dark"
              fontWeight="500"
              dangerouslySetInnerHTML={{
                __html:
                  note?.htmlData.length > 60
                    ? note?.htmlData.substring(0, 60) + "..."
                    : note?.htmlData,
              }}
            ></Typography>
          </MDBox>
        ))
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={3}
          textAlign="center"
          fontSize="13px"
          fontWeight="500"
        >
          There are no Notes yet!
        </MDBox>
      )}
    </div>
  );
};

export default NotesList; 
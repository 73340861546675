import React from "react";
import permissionDenied from "assets/images/permissionDenied.png.jpg";

const PermissionDenied = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  };

  const messageStyle = {
    textAlign: "center",
    fontSize: "8px",
    color: "grey",
  };

  return (
    <div style={containerStyle}>
      <div style={messageStyle}>
        <img src={permissionDenied} width="30%" />
        <h1>You don't have permission for this!</h1>
      </div>
    </div>
  );
};

export default PermissionDenied;

import React from "react";
import QuotaTable from "../QuotaTable";

const QuotaOverview = ({
  columns,
  rows,
  setRows,
  addData,
  isEdit,
  QuotaActiveDeactive,
  qualification,
  setFinalRows,
  QuotaGroupActiveDeactive,
  proceedData,
  setWarning,
  calculationId
}) => {
  return (
    <QuotaTable
      rows={rows}
      columns={columns}
      setRows={setRows}
      addData={addData}
      isEdit={isEdit}
      QuotaActiveDeactive={QuotaActiveDeactive}
      qualification={qualification}
      setFinalRows={setFinalRows}
      QuotaGroupActiveDeactive={QuotaGroupActiveDeactive}
      proceedData={proceedData}
      setWarning={setWarning}
      calculationId={calculationId}
    />
  );
};

export default QuotaOverview;

import { FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

const Deduplication = (props) => {
  return (
    <MDBox>
      <MDTypography fontSize="14px" fontWeight="medium" mb={1} mt={2}>
        Deduplication
      </MDTypography>
      <Grid container spacing={0} px={2}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={props.uniqueIp}
                onChange={(e, value) => {
                  props.setValue("uniqueIp", value);
                }}
                disabled={props.isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                IP duplication
              </MDTypography>
            }
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={props.uniqueUser}
                onChange={(e, value) => {
                  props.setValue("uniqueUser", value);
                }}
                disabled={props.isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                User duplication
              </MDTypography>
            }
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Deduplication;

import MDBox from "components/MDBox";
import React, { useState } from "react";
import BackImageCard from "assets/images/icons/Image_Back.png";
import "./index.css";
function DashboardCard(props) {
  const [hover, setHover] = useState(false);
  const boxColor = () => {
    if (hover) {
      return props.hoverBackground;
    } else {
      return props.Colour;
    }
  };

  return (
    <MDBox mb={1.5}>
      <div
        className="cardDetails"
        style={{
          backgroundColor: boxColor(),
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div
          style={{
            position: "absolute",
            background: props.elipsBackground,
            right: "10px",
            top: "-15px",
            width: "208px",
            height: "208px",
            zIndex: "1",
          }}
        ></div>
        <img
          className="backImage"
          src={BackImageCard}
          width="120px"
          height="140px"
          alt="backImage"
        />

        <div className="row">
          <div className="nameDesign" style={{ color: `${props.nameColour}` }}>
            {props.Name}
          </div>
          <div>
            <img src={props.Icon} width="40px" height="40px" alt="icon" />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div className="statusDesign" style={{ color: `${props.statusColour}` }}>
              {props.Status}
            </div>
            <div className="valueDesign" style={{ color: `${props.nameColour}` }}>
              {props.Value}
            </div>
          </div>
          <div className="column">
            <div className="statusDesign" style={{ color: `${props.statusColour}` }}>
              {props.Maths}
            </div>
            <div className="valueDesign" style={{ color: `${props.nameColour}` }}>
              {props.MathsValue}
            </div>
          </div>
        </div>
      </div>
    </MDBox>
  );
}

export default DashboardCard;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetProjectManagerList = () => {
  const [projectManager, setProjectManager] = useState([]);
  const [managerSearch, setManagerSearch] = useState("");

  const getProjectManager = async () => {
    try {
      let data = await TLinkApi.get(`/users/managers?search=${managerSearch}`);
      setProjectManager(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProjectManager();
    // eslint-disable-next-line
  }, [managerSearch]);
  return {
    projectManager,
    getProjectManager,
    setManagerSearch,
    managerSearch,
  };
};

export default GetProjectManagerList;

const colors = {
  background: {
    default: "rgba(255, 255, 255, 1e-05);",
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#3669EC",
    focus: "#3669EC",
  },

  secondary: {
    main: "#B6E5FF",
    focus: "#B6E5FF",
    light: "#F0FAFB",
  },
  secondaryText: {
    main: "#3669EC",
    focus: "#3669EC",
  },

  info: {
    main: "rgb(129, 176, 60)",
    focus: "rgb(129, 176, 60)",
  },

  textColor: {
    main: "rgb(0, 173, 239)",
    focus: "rgb(0, 173, 239)",
  },

  headingColor: {
    main: "rgb(129, 176, 60)",
    focus: "rgb(129, 176, 60)",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fcae3a",
    focus: "#fcae3a",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "rgba(255, 255, 255, 1e-05);",
    focus: "rgba(255, 255, 255, 1e-05);",
  },

  dark: {
    main: "#344767",
    focus: "#2c3c58",
  },

  grey: {
    100: "#f8f9fa",
    200: "rgba(255, 255, 255, 1e-05)",
    300: "#F0F0F0",
    400: "#E4E4E4",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#3669EC",
      state: "#3669EC",
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    info: {
      main: "rgb(129, 176, 60)",
      state: "rgb(129, 176, 60)",
    },
    textColor: {
      main: "rgb(0, 173, 239)",
      focus: "rgb(0, 173, 239)",
    },
    headingColor: {
      main: "rgb(129, 176, 60)",
      focus: "rgb(129, 176, 60)",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#2EBCF2",
      focus: "#2EBCF2",
    },

    error: {
      main: "#49a3f1",
      state: "#1A73E8",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#3669EC",
      text: "#3669EC",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "rgb(129, 176, 60)",
      text: "rgb(129, 176, 60)",
    },
    textColor: {
      main: "rgb(0, 173, 239)",
      focus: "rgb(0, 173, 239)",
    },
    headingColor: {
      main: "rgb(129, 176, 60)",
      focus: "rgb(129, 176, 60)",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#2EBCF2",
      text: "#2EBCF2",
    },

    error: {
      background: "#aecef7",
      text: "#095bc6",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#3669EC",
    secondary: "#110e0e",
    info: "rgb(129, 176, 60)",
    textColor: "rgb(0, 173, 239)",
    headingColor: "rgb(129, 176, 60)",
    success: "#4caf4f",
    warning: "#2EBCF2",
    error: "#00bbd4",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;

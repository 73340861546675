import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetPriorityList = () => {
  const [priority, setPriority] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getPriority = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/project/priority/?search=${search}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${entriesPerPage}&page=${page+1}`
      );
      setPriority(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getPriority();
  }, [page, search, entriesPerPage, sortBy, sortOrder]);
  
  return {
    priority,
    setPriority,
    getPriority,
    isLoading,
    setIsLoading,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    sortBy, 
    setSortBy,
    sortOrder,
    setSortOrder,
  };
};

export default GetPriorityList; 
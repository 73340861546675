import {
  Backdrop,
  Grid,
  Icon,
  IconButton,
  CircularProgress,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import DeduplicationClient from "./deduplicationSecurity";

import OtherSecurityClient from "./otherSecurity";

const SecurityConfiguration = () => {
  let navigate = useNavigate();
  const { setClient, setIsEdit, isEdit } = useOutletContext();
  const [confiqIsEdit, setConfigIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      maxLoi: "",
      minLoi: "",
      minLoiIsActive: false,
      maxLoiIsActive: false,
      researchDefenderScore: 0,
      uniqueIp: false,
      uniqueUser: false,
      countryMismatch: false,
      researchDefender: false,
    },
  });
  let { id } = useParams();

  let uniqueIp = watch("uniqueIp");
  let uniqueUser = watch("uniqueUser");
  let researchDefender = watch("researchDefender");
  let countryMismatch = watch("countryMismatch");
  let minLoiIsActive = watch("minLoiIsActive");
  let maxLoiIsActive = watch("maxLoiIsActive");
  let maxLoi = watch("maxLoi");
  let minLoi = watch("minLoi");

  let { enqueueSnackbar } = useSnackbar();

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.minLoi = parseInt(data.minLoi);
    payload.maxLoi = parseInt(data.maxLoi);
    payload.researchDefenderScore = parseInt(data.researchDefenderScore);
    if (confiqIsEdit === true) {
      try {
        let result = await TLinkApi.put(`clients/${id}/securityConfig`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsLoading(false);
        navigate(`/clients/${id}/clientFilters/edit`);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setConfigIsEdit(false);
        setClient({});
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    } else {
      try {
        payload.clientId = parseInt(id);
        let result = await TLinkApi.post("clients/securityConfig", payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/clients/${id}/clientFilters/edit`);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setConfigIsEdit(false);
        setClient({});
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    }
  };

  const getClientSecurityConfiguration = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`clients/${id}/securityConfig`);
      if (result?.status !== 404 && Object.keys(result).length > 0) {
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setConfigIsEdit(true);
        setValue("uniqueIp", result.uniqueIp);
        setValue("countryMismatch", result.countryMismatch);
        setValue("researchDefender", result.researchDefender);
        setValue("uniqueUser", result.uniqueUser);
        setValue("researchDefenderScore", result.researchDefenderScore);
        setValue("minLoi", result.minLoi);
        setValue("maxLoi", result.maxLoi);
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsEdit({
        disabled: false,
        edit: true,
      });
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getClientSecurityConfiguration();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <MDBox sx={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
        <form onSubmit={handleSubmit(save)} noValidate>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            mb={1}
            width="100%"
          >
            <Typography variant="h6">Security Configuration</Typography>
            {isEdit.disabled === true ? (
              <MDBox
                pt={2}
                px={2}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="10px"
              >
                <IconButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              </MDBox>
            ) : (
              <MDBox
                pt={2}
                px={2}
                mb={2}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="10px"
              >
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: true,
                      edit: false,
                    });
                    setClient({});
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </MDBox>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDBox sx={{ minHeight: `457px`, overflowY: "auto" }} mx={2}>
            <DeduplicationClient
              uniqueIp={uniqueIp}
              uniqueUser={uniqueUser}
              setValue={setValue}
              isEdit={isEdit}
            />
            <OtherSecurityClient
              researchDefender={researchDefender}
              countryMismatch={countryMismatch}
              errors={errors}
              control={control}
              setValue={setValue}
              isEdit={isEdit}
            />
            <MDBox>
              <MDTypography fontSize="14px" fontWeight="medium" mb={1}>
                Validation
              </MDTypography>
              <Grid container spacing={1} px={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={minLoiIsActive}
                        onChange={(e, value) => {
                          setValue("minLoiIsActive", value);
                        }}
                        disabled={isEdit.disabled}
                      />
                    }
                    label={
                      <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                        Minimum LOI (mins)
                      </MDTypography>
                    }
                  />
                  <Controller
                    name="minLoi"
                    control={control}
                    rules={{
                      required: minLoiIsActive ? "Min LoI (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        type="number"
                        fullWidth
                        required
                        sx={{ width: "200px", marginLeft: "50px" }}
                        {...register("minLoi", {
                          min: 0,
                          max: maxLoi,
                        })}
                        autoComplete="off"
                        variant="outlined"
                        disabled={isEdit.disabled || !minLoiIsActive}
                        error={errors?.minLoi ? true : false}
                        helperText={
                          errors?.minLoi && errors.minLoi.message !== ""
                            ? errors.minLoi.message
                            : errors?.minLoi?.type === "min" || errors?.minLoi?.type === "max"
                            ? "Check Min Value should be smaller than max"
                            : maxLoiIsActive && !minLoiIsActive
                            ? "Fill the value of min"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={maxLoiIsActive}
                        onChange={(e, value) => {
                          setValue("maxLoiIsActive", value);
                        }}
                        disabled={isEdit.disabled}
                      />
                    }
                    label={
                      <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                        Maximum LOI (mins)
                      </MDTypography>
                    }
                  />
                  <Controller
                    name="maxLoi"
                    control={control}
                    rules={{
                      required: maxLoiIsActive ? "Max LoI (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        sx={{ width: "200px", marginLeft: "50px" }}
                        type="number"
                        disabled={isEdit.disabled || !maxLoiIsActive}
                        required
                        autoComplete="off"
                        variant="outlined"
                        {...register("maxLoi", {
                          min: minLoi,
                        })}
                        error={errors?.maxLoi ? true : false}
                        helperText={
                          errors?.maxLoi && errors.maxLoi.message !== ""
                            ? errors.maxLoi.message
                            : errors?.maxLoi?.type === "min"
                            ? "Check Max Value should be greater than min"
                            : minLoiIsActive && !maxLoiIsActive
                            ? "Fill the value of max"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </>
  );
};

export default SecurityConfiguration;

import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Chip,
  Stack,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { useForm, Controller } from "react-hook-form";
import MDButton from "components/MDButton";
import Union2 from "assets/images/Union2.png";
import "../../global.css";
import csvDownload from "json-to-csv-export";
import TLinkApi from "services/tlink.api";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetReconcileStatusList from "hooks/ReconcileStatus/GetReconcileStatusList";
import GetReportStatusConfigList from "hooks/ReportStatusConfig/GetReportStatusConfigList";
import GetReportSubStatusList from "hooks/ReportSubStatus/GetReportSubStatusList";
import GetListOfProjects from "hooks/ListOfProjects/GetListOfProjects";
import AllVendors from "hooks/listAllVendors/AllVendors";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MDInput from "components/MDInput";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const Reports = () => {
  let navigate = useNavigate();
  let { textColor } = colors;
  let { clients } = GetListOfClients();
  const [isLoading, setIsLoading] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [search, setSearch] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");
  const [searchSupplierList, setSearchSupplierList] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [searchClientList, setSearchClientList] = useState([]);
  const [searchProject, setSearchProject] = useState("");
  const [searchProjectList, setSearchProjectList] = useState([]);
  const [searchSelectStatus, setSearchSelectStatus] = useState("");
  const [searchSelectStatusList, setSearchSelectStatusList] = useState([]);
  const [searchSelectSubStatus, setSearchSelectSubStatus] = useState("");
  const [searchSelectSubStatusList, setSearchSelectSubStatusList] = useState([]);
  let { reconcileStatus } = GetReconcileStatusList();
  let { reportStatusConfig } = GetReportStatusConfigList();
  let { reportSubStatus } = GetReportSubStatusList();
  const [reportType, setReportType] = useState("");
  const { vendors } = AllVendors();
  const { projects } = GetListOfProjects();
  const [showAllSelectedStatus, setShowAllSelectedStatus] = useState(false);
  const [showAllSelectedSubStatus, setShowAllSelectedSubStatus] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      surveyId: "",
      report: "",
      date: [],
      clientId: "",
      projectId: "",
      supplierId: "",
      isReconcilied: 1,
      statusConfig: [],
      subStatusConfig: [],
      reconciliationType: 1,
    },
  });

  let clientId = watch("clientId");
  let projectId = watch("projectId");
  let statusConfig = watch("statusConfig");
  let subStatusConfig = watch("subStatusConfig");

  useEffect(() => {
    setSearchSupplierList(vendors);
  }, [vendors]);

  useEffect(() => {
    let searchData = vendors?.filter((data) => {
      return data?.name.toLowerCase().includes(searchSupplier?.toLowerCase());
    });
    setSearchSupplierList(searchData);
  }, [searchSupplier]);

  useEffect(() => {
    setSearchClientList(clients);
  }, [clients]);

  useEffect(() => {
    let searchData = clients?.filter((data) => {
      return data?.name.toLowerCase().includes(searchClient?.toLowerCase());
    });
    setSearchClientList(searchData);
  }, [searchClient]);

  useEffect(() => {
    setSearchProjectList(projects);
  }, [projects]);

  useEffect(() => {
    let searchData = projects?.filter((data) => {
      return data?.name.toLowerCase().includes(searchProject?.toLowerCase());
    });
    setSearchProjectList(searchData);
  }, [searchProject]);

  useEffect(() => {
    setSearchSelectStatusList(reportStatusConfig);
  }, [reportStatusConfig]);

  useEffect(() => {
    let searchData = reportStatusConfig?.filter((data) => {
      return data?.name.toLowerCase().includes(searchSelectStatus?.toLowerCase());
    });
    setSearchSelectStatusList(searchData);
  }, [searchSelectStatus]);

  useEffect(() => {
    setSearchSelectSubStatusList(reportSubStatus);
  }, [reportSubStatus]);

  useEffect(() => {
    let searchData = reportSubStatus?.filter((data) => {
      return data?.name.toLowerCase().includes(searchSelectSubStatus?.toLowerCase());
    });
    setSearchSelectSubStatusList(searchData);
  }, [searchSelectSubStatus]);

  const getSurveyList = async () => {
    try {
      let data = await TLinkApi.get(`/reports/survey-list/${clientId}?search=${search}`);
      setSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (clientId && !projectId) {
      getSurveyList();
    }
    // eslint-disable-next-line
  }, [clientId]);

  const getSurveyListWithProjectId = async () => {
    try {
      let data = await TLinkApi.get(`/projects/${projectId}/survey-list?search=${search}`);
      setSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (projectId) {
      getSurveyListWithProjectId();
    }
    // eslint-disable-next-line
  }, [projectId]);

  const getAllSurveyList = async () => {
    try {
      let data = await TLinkApi.get(`/reports/survey-list-search?search=${search}`);
      setSurveys(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!projectId || !clientId) {
      getAllSurveyList();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search.length > 2) {
      if (projectId) {
        getSurveyListWithProjectId();
      } else if (clientId && !projectId) {
        getSurveyList();
      } else {
        getAllSurveyList();
      }
    }
    // eslint-disable-next-line
  }, [search]);

  const label = (value, required) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={1}>
        {value} {required && <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const save = async (data) => {
    let reportId = data.report;
    let payload = {};
    if (reportId == 1) {
      payload.surveyId = data.surveyId;
      payload.report = data.report;
      payload.date = data.date;
      payload.clientId = data.clientId;
      payload.projectId = data.projectId;
      payload.supplierId = data.supplierId;
      payload.isReconcilied = data.isReconcilied;
      payload.statusConfig = data.statusConfig;
      payload.subStatusConfig = data.subStatusConfig;
      setIsLoading(true);
      try {
        let res = await TLinkApi.post("reports/survey-report", payload);
        setIsLoading(false);
        const dataToConvert = {
          data: res?.data,
          filename: "Hit Report",
          delimiter: ",",
        };
        csvDownload(dataToConvert);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    } else if (reportId == 2) {
      payload.qualificationCategoryTypeId = 2;
      payload.startDate = data.date[0];
      payload.endDate = data.date[1];
      setIsLoading(true);
      try {
        let res = await TLinkApi.post("/reports/qualification", payload);
        setIsLoading(false);
        const dataToConvert = {
          data: res?.data,
          filename: "Custom Qualification Report",
          delimiter: ",",
        };
        csvDownload(dataToConvert);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    } else if (reportId == 3) {
      payload.startDate = data.date[0].toString();
      payload.endDate = data.date[1].toString();
      payload.surveyId = data.surveyId.toString();
      payload.clientId = data.clientId.toString();
      payload.projectId = data.projectId.toString();
      payload.supplierId = data.supplierId.toString();
      payload.reconciliationType = data.reconciliationType.toString();
      setIsLoading(true);
      try {
        let res = await TLinkApi.post("/reports/reconciliation", payload);
        setIsLoading(false);
        const dataToConvert = {
          data: res?.data,
          filename: "Reconciliation Report",
          delimiter: ",",
        };
        csvDownload(dataToConvert);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
    else if (reportId == 4) {
      payload.startDate = data.date[0].toString();
      payload.endDate = data.date[1].toString();
      payload.surveyId = data.surveyId.toString();
      setIsLoading(true);
      try {
        let res = await TLinkApi.post("/reports/screening/status", payload);
        setIsLoading(false);
        const dataToConvert = {
          data: res?.data,
          filename: "Screening Report",
          delimiter: ",",
        };
        csvDownload(dataToConvert);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
        <MDTypography fontSize="25px" color="black" ml="1%">
          Reports
        </MDTypography>
      </Stack>
      <Grid container>
        <Grid item xs={12} md={12}>
          <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          px={3}
          mt={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              navigate(`/dashboard`);
            }}
          >
            &nbsp;Exit
          </MDButton>
          <MDButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              window.location.reload();
            }}
          >
            &nbsp;Reset Form
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit" size="small">
            <img
              src={Union2}
              width="15px"
              height="15px"
              style={{ paddingRight: "5px" }}
              alt="export"
            />
            Export
          </MDButton>
        </MDBox>

        <Grid container spacing={2} paddingX={3}>
          <Grid item xs={12} md={4}>
            {label("Select Report", true)}
            <Controller
              name="report"
              control={control}
              sx={{
                label: {
                  color: "rgba(0, 0, 0, 0.25)",
                  fontWeight: "400",
                },
              }}
              rules={{
                required: "Report is required !",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  error={errors?.report ? true : false}
                  required
                  size="small"
                  sx={{
                    label: {
                      color: "rgba(0, 0, 0, 0.25)",
                      fontWeight: "400",
                    },
                  }}
                >
                  <Select
                    value={value}
                    onChange={(event) => {
                      setValue("report", event.target.value);
                      setReportType(event.target.value);
                    }}
                    sx={selectStyle}
                  >
                    <MenuItem value="1">Hit Report</MenuItem>
                    <MenuItem value="2">Custom Qualification Report</MenuItem>
                    <MenuItem value="3">Reconciliation Report</MenuItem>
                    <MenuItem value="4">Screening Report</MenuItem>
                  </Select>
                  {errors?.report && (
                    <FormHelperText>{errors?.report ? errors.report.message : null}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Project")}
            <Controller
              name="projectId"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      color: "rgba(0, 0, 0, 0.25)",
                      fontWeight: "400",
                    },
                  }}
                >
                  <Select
                    value={value}
                    onChange={onChange}
                    sx={selectStyle}
                    disabled={reportType == 2 || reportType == 4}
                    MenuProps={{
                      style: {
                        width: "150px",
                        height: "250px",
                      },
                    }}
                    onClose={() => setSearchProject("")}
                  >
                    <ListSubheader sx={{marginBottom: "1rem"}}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem", 
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        value={searchProject}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setSearchProject(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {searchProjectList.map((value, i) => {
                      return (
                        <MenuItem value={value.id} key={i}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Supplier")}
            <Controller
              name="supplierId"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    label: {
                      color: "rgba(0, 0, 0, 0.25)",
                      fontWeight: "400",
                    },
                  }}
                  size="small"
                >
                  <Select
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    sx={selectStyle}
                    disabled={reportType == 2 || reportType == 4}
                    onClose={() => setSearchSupplier("")}
                  >
                    <ListSubheader sx={{marginBottom: "1rem"}}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        value={searchSupplier}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setSearchSupplier(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {searchSupplierList?.map((value, i) => {
                      return (
                        <MenuItem value={value.id} key={i}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Client")}
            <Controller
              name="clientId"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      color: "rgba(0, 0, 0, 0.25)",
                      fontWeight: "400",
                    },
                  }}
                >
                  <Select
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    sx={selectStyle}
                    disabled={reportType == 2 || reportType == 4}
                    onClose={() => setSearchClient("")}
                  >
                    <ListSubheader sx={{marginBottom: "1rem"}}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        value={searchClient}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setSearchClient(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {searchClientList?.map((value) => (
                      <MenuItem value={value.id} key={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Survey")}
            <Controller
              name="surveyId"
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      color: "rgba(0, 0, 0, 0.25)",
                      fontWeight: "400",
                    },
                  }}
                >
                  <Select
                    value={value}
                    sx={selectStyle}
                    disabled={reportType == 2}
                    onChange={onChange}
                    MenuProps={{
                      autoFocus: false,
                    }}
                    onClose={() => setSearch("")}
                  >
                    <ListSubheader sx={{marginBottom: "1rem"}}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        value={search}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    <MenuItem value={""}>
                      <em>None</em>
                    </MenuItem>
                    {surveys?.map((value) => (
                      <MenuItem value={value.id} key={value.id}>
                        {value.surveyName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="date"
              control={control}
              sx={{
                label: {
                  color: "rgba(0, 0, 0, 0.25)",
                  fontWeight: "400",
                },
              }}
              rules={{
                required: "Date is required !",
              }}
              render={({ field: { onChange } }) => (
                <>
                  {label("Date", true)}
                  <RangePicker
                    style={{ borderColor: errors?.date && "red" }}
                    format={dateFormat}
                    className={"createDateRangePicker"}
                    onChange={(e, value) => {
                      if (value[0] === "" && value[1] === "") {
                        onChange([]);
                      } else {
                        onChange(value);
                      }
                    }}
                  />
                  {errors?.date && (
                    <FormHelperText style={{ color: errors?.date && "red" }}>
                      {errors?.date ? errors.date.message : null}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          {reportType != 3 && (
            <Grid item xs={12} md={4}>
              {label("Select Reconcile")}
              <Controller
                name="isReconcilied"
                control={control}
                sx={{
                  label: {
                    color: "rgba(0, 0, 0, 0.25)",
                    fontWeight: "400",
                  },
                }}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                  >
                    <Select
                      value={value}
                      onChange={onChange}
                      sx={selectStyle}
                      disabled={reportType == 2 || reportType == 4}
                    >
                      {reconcileStatus?.map((value, index) => (
                        <MenuItem value={value.id} key={index}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          {reportType == 3 && (
            <Grid item xs={12} md={4}>
              {label("Reconcile Type")}
              <Controller
                name="reconciliationType"
                control={control}
                sx={{
                  label: {
                    color: "rgba(0, 0, 0, 0.25)",
                    fontWeight: "400",
                  },
                }}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                  >
                    <Select
                      value={value}
                      onChange={onChange}
                      sx={selectStyle}
                      disabled={reportType == 2}
                    >
                      <MenuItem value="1">Reconciled to Complete</MenuItem>
                      <MenuItem value="2">Reconciled to Terminate</MenuItem>
                      <MenuItem value="3">Not Reconciled</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            {label("Select Status")}
            <Controller
              name="statusConfig"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple={true}
                  disableCloseOnSelect
                  disabled={reportType == 4}
                  value={value}
                  onChange={(event, newValue) => {
                    let payload = newValue.map((val) => (val.id ? val.id : val));
                    onChange(payload);
                  }}
                  style={{
                    "& .MuiInputBase-input": {
                      minHeight: "20px",
                    },
                  }}
                  renderTags={(value, getTagProps) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          maxWidth: "90%",
                          maxHeight: "250px",
                          WebkitOverflowScrolling: "touch",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        }}
                      >
                        <style>
                          {`
                            /* Hide horizontal scrollbar */
                            div::-webkit-scrollbar {
                              width: 0;
                              height: 0;
                            }
                            `}
                        </style>
                        {value.slice(0, 1).map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={reportStatusConfig?.find((data) => data.id === option)?.name}
                            style={{
                              height: "20px",
                            }}
                            {...getTagProps({ index })}
                          />
                        ))}
                        {value?.length > 1 && !showAllSelectedStatus && (
                          <Chip
                            variant="outlined"
                            label={`+${value?.length - 1} more`}
                            style={{
                              height: "20px",
                              marginTop: "3px",
                            }}
                            onClick={() => setShowAllSelectedStatus(true)}
                          />
                        )}

                        {showAllSelectedStatus &&
                          value.slice(1).map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={reportStatusConfig?.find((data) => data.id === option)?.name}
                              style={{
                                height: "20px",
                              }}
                              {...getTagProps({ index })}
                            />
                          ))}
                      </div>
                    </>
                  )}
                  isOptionEqualToValue={(option, value) => option?.id == value}
                  options={reportStatusConfig}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => {
                    if (props["data-option-index"] === 0) {
                      return (
                        <li style={{ paddingLeft: "16px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={value.length === reportStatusConfig.length}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    onChange(reportStatusConfig.map((option) => option.id));
                                  } else {
                                    onChange([]);
                                  }
                                }}
                              />
                            }
                            label="Select All"
                          />
                        </li>
                      );
                    }
                    return (
                      <li {...props}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                          }
                          label={option.name}
                        />
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="outlined"
                      size="small"
                      error={errors?.statusConfig && statusConfig?.length <= 0 ? true : false}
                      helperText={
                        errors?.statusConfig && statusConfig?.length <= 0
                          ? errors?.statusConfig.message
                          : null
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Select Sub Status")}
            <Controller
              name="subStatusConfig"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple={true}
                  disableCloseOnSelect
                  disabled={reportType == 4}
                  value={value}
                  onChange={(event, newValue) => {
                    let payload = newValue.map((val) => (val.id ? val.id : val));
                    onChange(payload);
                  }}
                  style={{
                    "& .MuiInputBase-input": {
                      minHeight: "20px",
                    },
                  }}
                  renderTags={(value, getTagProps) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          maxWidth: "90%",
                          maxHeight: "250px",
                          WebkitOverflowScrolling: "touch",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        }}
                      >
                        <style>
                          {`
                              /* Hide horizontal scrollbar */
                              div::-webkit-scrollbar {
                                width: 0;
                                height: 0;
                              }
                              `}
                        </style>
                        {value.slice(0, 1).map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={reportSubStatus?.find((data) => data.id === option)?.name}
                            style={{
                              height: "20px",
                            }}
                            {...getTagProps({ index })}
                          />
                        ))}
                        {value?.length > 1 && !showAllSelectedSubStatus && (
                          <Chip
                            variant="outlined"
                            label={`+${value?.length - 1} more`}
                            style={{
                              height: "20px",
                              marginTop: "3px",
                            }}
                            onClick={() => setShowAllSelectedSubStatus(true)}
                          />
                        )}

                        {showAllSelectedSubStatus &&
                          value.slice(1).map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={reportSubStatus?.find((data) => data.id === option)?.name}
                              style={{
                                height: "20px",
                              }}
                              {...getTagProps({ index })}
                            />
                          ))}
                      </div>
                    </>
                  )}
                  isOptionEqualToValue={(option, value) => option?.id == value}
                  options={reportSubStatus}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => {
                    if (props["data-option-index"] === 0) {
                      return (
                        <li style={{ paddingLeft: "16px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={value.length === reportSubStatus.length}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    onChange(reportSubStatus.map((option) => option.id));
                                  } else {
                                    onChange([]);
                                  }
                                }}
                              />
                            }
                            label="Select All"
                          />
                        </li>
                      );
                    }
                    return (
                      <li {...props}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                          }
                          label={option.name}
                        />
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      variant="outlined"
                      size="small"
                      error={errors?.subStatusConfig && subStatusConfig?.length <= 0 ? true : false}
                      helperText={
                        errors?.subStatusConfig && subStatusConfig?.length <= 0
                          ? errors?.subStatusConfig.message
                          : null
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </MDBox>
  );
};

export default Reports;

import React, { useState } from "react";
import {
  Dialog,
  Backdrop,
  CircularProgress,
  DialogTitle,
  Stack,
  Typography,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import MDButton from "components/MDButton";
import Label from "components/InputLabel";
import MDInput from "components/MDInput";

export const Modal = ({ closeModal, onSubmit, defaultValue, rows, isLoading }) => {
  const [isvalidTocpi, setIsvalidTocpi] = useState(false);
  const [isvalidFromcpi, setIsvalidFromcpi] = useState(false);
  const [isvalidSuppliermargin, setIsvalidSuppliermargin] = useState(false);
  const [isduplicate, setIsDuplicate] = useState(false);

  const [formState, setFormState] = useState(
    defaultValue || {
      id: Math.floor(Math.random() * 10000 + 10000),
      from_cpi: "",
      to_cpi: "",
      supplier_margin: "",
    }
  );

  const [previoustate] = useState(defaultValue ? defaultValue : {});

  const validateForm = () => {
    if (
      formState.from_cpi &&
      formState.to_cpi &&
      formState.supplier_margin &&
      !isNaN(formState.from_cpi) &&
      !isNaN(formState.to_cpi) &&
      !isNaN(formState.supplier_margin) &&
      formState.from_cpi > 0 &&
      formState.to_cpi > 0 &&
      formState.supplier_margin > 0 &&
      Number(formState.to_cpi) > Number(formState.from_cpi) &&
      Number(formState.supplier_margin) <= 100
    ) {
      let response = true;
      setIsvalidTocpi(false);
      setIsvalidFromcpi(false);
      setIsvalidSuppliermargin(false);
      setIsDuplicate(false);
      rows.map((row) => {
        if (
          Number(row.from_cpi) === Number(formState.from_cpi) &&
          Number(row.to_cpi) === Number(formState.to_cpi)
        ) {
          setIsDuplicate(true);
          response = false;
        }
      });
      if (
        defaultValue &&
        Number(formState.from_cpi) === Number(previoustate.from_cpi) &&
        Number(formState.to_cpi) === Number(previoustate.to_cpi)
      ) {
        setIsDuplicate(false);
        response = true;
      }
      return response;
    } else {
      if (formState.from_cpi === "" || formState.from_cpi <= 0 || isNaN(formState.from_cpi)) {
        setIsvalidFromcpi(true);
      } else {
        setIsvalidFromcpi(false);
      }
      if (
        formState.to_cpi === "" ||
        isNaN(formState.to_cpi) ||
        formState.to_cpi <= 0 ||
        Number(formState.from_cpi) >= Number(formState.to_cpi)
      ) {
        setIsvalidTocpi(true);
      } else {
        setIsvalidTocpi(false);
      }
      if (
        formState.supplier_margin === "" ||
        formState.supplier_margin <= 0 ||
        isNaN(formState.supplier_margin) ||
        formState.supplier_margin > 100
      ) {
        setIsvalidSuppliermargin(true);
      } else {
        setIsvalidSuppliermargin(false);
      }
      return false;
    }
  };

  const handleChange = (e) => {
    let input = e.target.value;
    setFormState({ ...formState, [e.target.name]: input });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    formState.to_cpi = Number(formState.to_cpi).toFixed(2);
    formState.from_cpi = Number(formState.from_cpi).toFixed(2);
    formState.supplier_margin = Number(formState.supplier_margin).toFixed(2);
    onSubmit(formState);
    closeModal();
  };
  const handleClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h6" color="dark">
            {defaultValue ? "Edit Row" : "Add Row"}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <MDButton size="small" color="secondary" variant="contained" onClick={handleClose}>
              Cancel
            </MDButton>
            <MDButton size="small" color="info" variant="contained" onClick={handleSubmit}>
              Add
            </MDButton>
          </Stack>
        </Stack>
        <hr style={{ backgroundColor: "#03aeff", height: "2px" }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Label value="From Cpi" required={true} />
          <MDInput
            name="from_cpi"
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            fullWidth
            type="number"
            onChange={handleChange}
            value={formState.from_cpi}
            error={
              (isvalidFromcpi || isduplicate) &&
              (formState.from_cpi === "" ||
                isNaN(formState.from_cpi) ||
                formState.from_cpi <= 0 ||
                isduplicate)
            }
            helperText={
              (isvalidFromcpi || isduplicate) &&
              (formState.from_cpi === ""
                ? "Value cannot be empty"
                : isNaN(formState.from_cpi)
                ? "Value should be numeric"
                : formState.from_cpi <= 0
                ? "Value cannot be less than or equal to zero"
                : isduplicate
                ? "From Cpi and To Cpi already exist"
                : "")
            }
          />
          <Label value="To Cpi" required={true} />
          <MDInput
            name="to_cpi"
            variant="outlined"
            fullWidth
            type="number"
            sx={{ marginBottom: "1rem" }}
            onChange={handleChange}
            value={formState.to_cpi}
            error={
              (isvalidTocpi || isduplicate) &&
              (formState.to_cpi === "" ||
                isNaN(formState.to_cpi) ||
                formState.to_cpi <= 0 ||
                Number(formState.from_cpi) > Number(formState.to_cpi) ||
                Number(formState.from_cpi) === Number(formState.to_cpi) ||
                isduplicate)
            }
            helperText={
              (isvalidTocpi || isduplicate) &&
              (formState.to_cpi === ""
                ? "Value cannot be empty"
                : isNaN(formState.to_cpi)
                ? "Value should be numeric"
                : formState.to_cpi <= 0
                ? "Value cannot be less than or equal to zero"
                : Number(formState.from_cpi) > Number(formState.to_cpi)
                ? "To Cpi should always be greater than From Cpi"
                : Number(formState.from_cpi) === Number(formState.to_cpi)
                ? "To Cpi should not be equal to From Cpi"
                : isduplicate
                ? "To Cpi and From Cpi already exist"
                : "")
            }
          />
          <Label value="Supplier Margin %" required={true} />
          <MDInput
            name="supplier_margin"
            variant="outlined"
            fullWidth
            type="number"
            onChange={handleChange}
            value={formState.supplier_margin}
            error={
              isvalidSuppliermargin &&
              (formState.supplier_margin === "" ||
                isNaN(formState.supplier_margin) ||
                formState.supplier_margin <= 0 ||
                formState.supplier_margin > 100)
            }
            helperText={
              isvalidSuppliermargin &&
              (formState.supplier_margin === ""
                ? "Value cannot be empty"
                : isNaN(formState.supplier_margin)
                ? "Value should be numeric"
                : formState.supplier_margin <= 0
                ? "Value cannot be less than or equal to zero"
                : formState.supplier_margin > 100
                ? "Value cannot be greater than 100"
                : "")
            }
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

import { EditOutlined } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const AllowBuyers = ({
  buyerList,
  setIsLoading,
  selectedClient,
  setSelectedClient,
  page,
  setPage,
  totalLength,
  setTotalLength,
  entriesPerPage,
  getBuyerList,
}) => {
  const { grey } = colors;
  const { id } = useParams();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: true,
    edit: false,
  });
  const [blockList, setBlockList] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

  const totalPages = Math.ceil(totalLength / entriesPerPage);
  const [totalLengthBlock, setTotalLengthBlock] = useState(0);
  const [pageBlock, setPageBlock] = useState(1);

  const [addBuyers, setAddBuyers] = useState([]);
  const [deleteBuyers, setDeleteBuyers] = useState([]);
  const [activeBuyers, setActiveBuyers] = useState([]);
  const [displayActiveBuyers, setDisplayActiveBuyers] = useState([]);
  const [checked, setChecked] = useState(false);

  const GetBlockBuyers = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(
        `vendors/${id}/clients/${selectedClient}/blocked/buyers?page=${pageBlock}&limit=${entriesPerPage}`
      );
      if (data?.data?.blockedClientBuyerData && data?.data.blockedClientBuyerData.length > 0) {
        setBlockList(
          data?.data?.blockedClientBuyerData.map((val) => {
            return {
              id: Number(val.buyerId),
              name: val.buyer.name,
            };
          })
        );
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
        setIsEdit({
          disabled: false,
          edit: false,
        });
      }
      setTotalLengthBlock(data?.data?.totalLength);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      GetBlockBuyers();
    }
  }, [selectedClient, pageBlock, entriesPerPage]);

  useEffect(() => {
    let newActiveBuyers = buyerList.filter((buyer) => buyer.blocked).map((val) => val.id);
    setActiveBuyers(newActiveBuyers);
    setDisplayActiveBuyers(newActiveBuyers);
  }, [buyerList]);

  useEffect(() => {
    if (buyerList.length > 0 && displayActiveBuyers.length === buyerList.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [displayActiveBuyers, addBuyers, buyerList]);

  const save = async () => {
    try {
      let payload = {
        vendorId: id,
        clientId: selectedClient,
        addOrBlockBuyerIds: addBuyers.length > 0 ? addBuyers : [],
        deleteOrUnBlockBuyerIds: deleteBuyers.length > 0 ? deleteBuyers : [],
      };
      let result = await TLinkApi.put(`/vendors/client/buyers/block`, payload);
      console.log(payload);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });

      setIsEdit({
        disabled: true,
        edit: false,
      });
      setAddBuyers([]);
      setDeleteBuyers([]);
      await GetBlockBuyers();
      await getBuyerList();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setBlockList([]);
  }, [selectedClient]);

  useEffect(() => {
    console.log(blockList);
    console.log(buyerList);
    console.log(blockList.length);
  }, [blockList, buyerList]);

  return (
    <MDBox
      sx={{
        height: "calc(100vh - 300px)",
        overflowY: "auto",
        paddingInline: "1rem",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        pb="0.5rem"
        sx={{ borderBottom: `1px solid ${grey[400]}`, background: "white", zIndex: "2" }}
      >
        <Typography variant="v6" fontWeight="600">
          Client Buyers Blocked
        </Typography>

        {isEdit.disabled ? (
          <MDButton
            size="small"
            variant="contained"
            color="secondary"
            sx={{ display: !selectedClient ? "none" : "" }}
            startIcon={<EditOutlined />}
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
            }}
          >
            Edit
          </MDButton>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ display: !selectedClient ? "none" : "" }}
          >
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                if (addData) {
                  setBlockList([]);
                  setSelectedClient(null);
                } else {
                  GetBlockBuyers();
                }
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              // disabled={blockList.length <= 0}
              size="small"
              onClick={() => {
                save();
              }}
            >
              Save
            </MDButton>
          </Stack>
        )}
      </Stack>
      {selectedClient ? (
        <FormGroup>
          {isEdit.edit || addData ? (
            <MDBox
              sx={{
                padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                borderBottom: `1px solid ${grey[400]}`,
                display: buyerList?.length === 0 ? "none" : "",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    color="secondary"
                    onClick={(e) => {
                      if (e.target.checked) {
                        let newDisplayBuyersList = displayActiveBuyers;
                        let newAddBuyersList = addBuyers;
                        buyerList.map((buyer) => {
                          if (
                            activeBuyers.findIndex((val) => val == buyer?.id) > -1 &&
                            !displayActiveBuyers.includes(buyer?.id)
                          ) {
                            if (!newDisplayBuyersList.includes(buyer?.id)) {
                              newDisplayBuyersList.push(buyer?.id);
                            }
                          } else if (activeBuyers.findIndex((val) => val == buyer?.id) <= -1) {
                            if (!newAddBuyersList.includes(buyer?.id)) {
                              newAddBuyersList.push(buyer?.id);
                            }
                          }
                          setDisplayActiveBuyers(newDisplayBuyersList);
                          setAddBuyers(newAddBuyersList);
                          setChecked(true);
                        });
                      } else {
                        let newDeleteBuyersList = deleteBuyers;
                        let newDisplayBuyersList = displayActiveBuyers;
                        let newAddBuyersList = addBuyers;
                        buyerList.map((buyer) => {
                          if (newDisplayBuyersList.includes(buyer?.id)) {
                            newDeleteBuyersList.push(buyer?.id);
                            let index = newDisplayBuyersList.findIndex((val) => val == buyer?.id);
                            if (index > -1) {
                              newDisplayBuyersList.splice(index, 1);
                            }
                          }
                          if (newAddBuyersList.includes(buyer?.id)) {
                            let index = newAddBuyersList.findIndex((val) => val == buyer?.id);
                            if (index > -1) {
                              newAddBuyersList.splice(index, 1);
                            }
                          }
                        });

                        setDisplayActiveBuyers(newDisplayBuyersList);
                        setAddBuyers(newAddBuyersList);
                        setDeleteBuyers(newDeleteBuyersList);
                        setChecked(false);
                      }
                    }}
                  />
                }
                label={"Select All"}
              />
            </MDBox>
          ) : (
            ""
          )}
          {isEdit.edit || addData ? (
            buyerList?.length > 0 ? (
              buyerList.map((buyer, i) => (
                <MDBox
                  sx={{
                    padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                    borderBottom: `1px solid ${grey[400]}`,
                  }}
                  key={i}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          displayActiveBuyers.includes(buyer.id) || addBuyers.includes(buyer.id)
                        }
                        color="secondary"
                        onChange={(e) => {
                          if (displayActiveBuyers.includes(buyer?.id)) {
                            let newDeleteBuyersList = deleteBuyers;
                            newDeleteBuyersList.push(buyer?.id);
                            setDeleteBuyers(newDeleteBuyersList);
                            let newDisplayBuyersList = displayActiveBuyers.filter(
                              (val) => val !== buyer?.id
                            );
                            setDisplayActiveBuyers(newDisplayBuyersList);
                          } else if (activeBuyers.findIndex((val) => val == buyer?.id) > -1) {
                            let newDeleteBuyersList = deleteBuyers;
                            let index = newDeleteBuyersList.findIndex((val) => val == buyer?.id);
                            if (index > -1) {
                              newDeleteBuyersList.splice(index, 1);
                            }
                            setDeleteBuyers(newDeleteBuyersList);

                            setDisplayActiveBuyers([...displayActiveBuyers, buyer?.id]);
                          } else if (addBuyers.includes(buyer?.id)) {
                            let newAddBuyersList = addBuyers.filter((val) => val !== buyer?.id);
                            setAddBuyers(newAddBuyersList);
                          } else if (!addBuyers.includes(buyer?.id)) {
                            setAddBuyers([...addBuyers, buyer?.id]);
                          }
                        }}
                      />
                    }
                    label={buyer.name}
                  />
                </MDBox>
              ))
            ) : (
              <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
                <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
                  No buyers here !
                </Typography>
              </Stack>
            )
          ) : blockList?.length > 0 ? (
            blockList.map((block, i) => (
              <MDBox
                sx={{
                  padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                  borderBottom: `1px solid ${grey[400]}`,
                }}
                key={i}
              >
                <Typography fontSize="0.875rem" fontWeight="700">
                  {block?.name}
                </Typography>
              </MDBox>
            ))
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
              <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
                No buyers here !
              </Typography>
            </Stack>
          )}
          {isEdit.edit || addData ? (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={2}>
              <Pagination
                count={Math.ceil(totalLength / entriesPerPage)}
                page={page}
                onChange={(e, newPage) => setPage(newPage)}
                color="primary"
                size="medium"
                showFirstButton
                showLastButton
                siblingCount={0}
                boundaryCount={1}
              />
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} mt={2}>
              <Pagination
                count={Math.ceil(totalLengthBlock / entriesPerPage)}
                page={pageBlock}
                onChange={(e, newPage) => setPageBlock(newPage)}
                color="primary"
                size="medium"
                showFirstButton
                showLastButton
                siblingCount={0}
                boundaryCount={1}
              />
            </Stack>
          )}
        </FormGroup>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
          <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
            Please select client to see its buyers
          </Typography>
        </Stack>
      )}
    </MDBox>
  );
};

export default AllowBuyers;

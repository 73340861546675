import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Label from "components/InputLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { Controller, useForm } from "react-hook-form";
import { Tooltip } from "@mui/material";
const ConditionOverview = ({
  selectedCondition,
  setSelectedCondition,
  addCondition,
  setAddCondition,
  qualificationQuestions,
  getListData,
  deleteNote,
}) => {
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qualificationOpen, setQualificationOpen] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [error, setError] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: { title: "" },
  });

  useEffect(() => {
    if (selectedCondition) {
      setQualificationOpen(false);
      setQualifications([]);
      setValue("title", selectedCondition?.title ? selectedCondition.title : "");
      setQualifications(selectedCondition?.qualifications);
      setEdit(false);
    }
  }, [selectedCondition]);

  const save = async (obj) => {
    let payload = {
      conditionTitle: obj.title,
      surveyId: id,
      qualifications: qualifications.map((value) => {
        return {
          qualificationId: value.id,
          qualificationTypeId: value.qualificationTypeId,
          answerId: value.qualificationTypeId === 2 ? value.selectedId : value.selectedId,
          isActive:
            addCondition && selectedCondition.id === -1 ? true : value.isActive ? true : false,
          answerText:
            value.qualificationTypeId === 4
              ? value.range.map((val) => {
                  return `${val.min} - ${val.max}`;
                })
              : value.text
              ? [value.text]
              : [],
        };
      }),
    };
    setIsLoading(true);
    if (addCondition && selectedCondition.id === -1) {
      try {
        let result = await TLinkApi.post(`/survey/qualification/terminate/conditions`, payload);
        setAddCondition(false);
        setEdit(false);
        let list = await getListData();
        let localnode = list.find((value) => result.qualificationTermination.id === value.id);
        setSelectedCondition(localnode);
        setIsLoading(false);
        setQualificationOpen(false);
        enqueueSnackbar("Condition created successfully", {
          variant: "success",
        });
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    } else {
      try {
        let result = await TLinkApi.put(
          `/survey/${id}/qualification/terminate/conditions/${selectedCondition?.id}`,
          payload
        );
        enqueueSnackbar("Condition saved successfully", {
          variant: "success",
        });
        let list = await getListData();
        let localCondition = list.find(
          (value) => result.data.qualificationTermination.id === value.id
        );
        setSelectedCondition(localCondition);
        setIsLoading(false);
        setAddCondition(false);
        setQualificationOpen(false);
        setEdit(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    }
  };
  useEffect(() => {
    let qualificationQuestionsList = qualificationQuestions.map((qualification) => {
      let payload = {};
      payload.id = qualification?.qualificationId;
      payload.name = qualification?.question?.questionText;
      payload.qualificationTypeId = qualification?.qualificationTypeId;
      payload.qualificationCategoryTypeId = qualification?.qualificationCategoryTypeId;
      payload.isActive = qualification.isActive;
      if (qualification?.qualificationTypeId === 1) {
        payload.answers = qualification?.question.answers;
        payload.answerId = qualification?.answerId;
        payload.selectedId = [];
      } else if (qualification?.qualificationTypeId === 2) {
        payload.answers = qualification?.question.answers;
        payload.answerId = qualification?.answerId;
        payload.selectedId = [];
      } else if (qualification?.qualificationTypeId === 4) {
        payload.range = qualification?.range;
      } else {
        payload.text = qualification?.range[0];
      }
      return payload;
    });
    setQualificationList(qualificationQuestionsList);
  }, [qualificationQuestions]);

  useEffect(() => {
    let check = qualifications.some((val) => {
      if (val.qualificationTypeId === 1 && val.selectedId.length <= 0) {
        return true;
      } else if (val.qualificationTypeId === 2 && val.selectedId.length <= 0) {
        return true;
      } else if (val.qualificationTypeId === 3 && val.text === "") {
        return true;
      } else if (val.qualificationTypeId === 4) {
        let error = val?.range?.some(
          (val) =>
            val.min === "" ||
            val.max === "" ||
            Number(val.min) > Number(val.max) ||
            Number(val.min) < 10 ||
            Number(val.max) > 100
        );
        if (error) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    if (qualifications.length <= 0) {
      check = true;
    }
    setError(check);
  }, [qualifications]);

  return (
    <div style={{ height: "calc(100vh - 300px)", overflowY: "auto" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {edit || addCondition ? (
        <form onSubmit={handleSubmit(save)} noValidate style={{ padding: "10px" }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" marginBottom="1rem">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title="Add Qualification" placement="top">
                <MDButton
                  size="small"
                  variant="text"
                  color="dark"
                  disabled={qualifications.length === qualificationList.length}
                  onClick={() => {
                    setQualificationOpen(true);
                  }}
                >
                  + Qualification
                </MDButton>
              </Tooltip>
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setEdit(false);
                  setQualificationOpen(false);
                  let list = await getListData();
                  if (addCondition) {
                    setAddCondition(false);
                    setSelectedCondition(list[0]);
                  } else {
                    let selectedId = selectedCondition.id;
                    let localSelected = list.find((val) => val.id === selectedId);
                    if (localSelected) setSelectedCondition(localSelected);
                  }
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                size="small"
                variant="contained"
                color="info"
                type="submit"
                disabled={error ? true : false}
              >
                Save
              </MDButton>
            </Stack>
          </Stack>
          <Label value="Title" required={true} />
          <Controller
            name="title"
            control={control}
            rules={{
              required: "Title is required!",
            }}
            render={({ field }) => (
              <Stack mb={1}>
                <MDInput {...field} fullWidth variant="outlined" value={watch("title")} />
                <MDBox color="error" fontSize="12px">
                  {errors?.title && watch("title") === "" ? errors.title.message : null}
                </MDBox>
              </Stack>
            )}
          />
        </form>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="1rem"
          sx={{ padding: "10px !important" }}
        >
          <Typography variant="h6">{watch("title")}</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title="Edit Terminate Condition" placement="top">
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<EditOutlined />}
                onClick={() => {
                  setEdit(true);
                }}
              >
                Edit
              </MDButton>
            </Tooltip>

            <Tooltip title="Delete Terminate Condition" placement="top">
              <IconButton
                sx={{ padding: "5px !important" }}
                onClick={() => {
                  deleteNote();
                }}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}
      {qualificationOpen ? (
        <Stack p={1}>
          <Label value="Qualifications" required={true} />
          <FormControl fullWidth required size="small">
            <Select
              multiple
              value={qualifications}
              onChange={(e) => {
                setQualifications(e.target.value);
              }}
              sx={{ borderRadius: "0px !important" }}
              renderValue={(selected) => selected.map((x) => x.name).join(", ")}
            >
              {qualificationList
                .filter((value) => value.isActive)
                .map((value, i) => {
                  return (
                    <MenuItem value={value} key={i}>
                      <Stack direction="row" alignItems="flex-start">
                        <Checkbox
                          checked={qualifications.find((val) => val.id === value.id) ? true : false}
                        />
                        <span style={{ marginTop: "0.5rem" }}>{value.name}</span>
                      </Stack>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>
      ) : (
        ""
      )}
      {qualifications.map((value, index) => {
        return (
          <Stack
            style={{
              padding: "10px",
              borderBottom: `1px solid #E4E4E4`,
              opacity: !value.isActive ? "0.5" : "",
            }}
            key={index}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      overflowWrap: "break-word",
                      width: "95%",
                    }}
                  >
                    {value.name}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{ display: (!edit && !addCondition) || !value.isActive ? "none" : "" }}
                    onClick={() => {
                      let payload = [...qualifications];
                      payload.splice(index, 1);
                      setQualifications(payload);
                    }}
                  >
                    <DeleteOutline color="error" fontSize="small" />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1.8}
                  mt={1}
                  mb={0.5}
                  sx={{
                    display:
                      value.qualificationTypeId === 2 || value.qualificationTypeId === 1
                        ? ""
                        : "none",
                  }}
                >
                  <MDBox
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: "5px",
                      width: "18px",
                      height: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: (!edit && !addCondition) || !value.isActive ? "" : "pointer",
                    }}
                    onClick={() => {
                      if ((edit || addCondition) && value.isActive) {
                        let payload = [...qualifications];
                        if (value.selectedId.length !== value.answerId.length) {
                          payload[index].selectedId = payload[index].answerId.map((ans) =>
                            Number(ans)
                          );
                        } else {
                          payload[index].selectedId = [];
                        }
                        setQualifications(payload);
                      }
                    }}
                  >
                    {value?.selectedId?.length === value?.answerId?.length && (
                      <MDBox
                        bgColor="primary"
                        style={{
                          borderRadius: "3px",
                          width: "10px",
                          height: "10px",
                        }}
                      ></MDBox>
                    )}
                  </MDBox>

                  <span
                    style={{
                      marginTop: "0.2rem",
                      coloe: "#344767",
                      fontSize: "0.875rem",
                      fontWeight: "700",
                    }}
                  >
                    Select All
                  </span>
                </Stack>
              </Grid>
              {value.qualificationTypeId === 2 || value.qualificationTypeId === 1 ? (
                value.answers
                  .filter((ans) => value.answerId.includes(Number(ans.id)))
                  .map((ans, i) => (
                    <Grid item xs={6} key={i}>
                      <FormControlLabel
                        sx={{ paddingLeft: "0.15rem" }}
                        control={
                          <Checkbox
                            disabled={(!edit && !addCondition) || !value.isActive}
                            size="small"
                            color="secondary"
                            checked={value.selectedId.includes(Number(ans.id))}
                            onChange={(e) => {
                              if (e.target.checked) {
                                let payload = [...qualifications];
                                payload[index].selectedId.push(Number(ans.id));
                                setQualifications(payload);
                              } else {
                                let payload = [...qualifications];
                                let localIndex = payload[index].selectedId.findIndex(
                                  (val) => Number(val) === Number(ans.id)
                                );
                                payload[index].selectedId.splice(localIndex, 1);
                                setQualifications(payload);
                              }
                            }}
                          />
                        }
                        label={
                          ans.qualificationAnswerDesc
                            ? ans.qualificationAnswerDesc
                            : ans.qualificationAns
                            ? ans.qualificationAns
                            : ""
                        }
                      />
                    </Grid>
                  ))
              ) : value.qualificationTypeId === 4 ? (
                <Grid item xs={6} mt={1}>
                  {value.range.map((val, i) => {
                    // return edit || addCondition ? (
                    return (
                      <Stack direction="row" alignItems="center" key={i} spacing={2} mt={1}>
                        <MDInput
                          variant="outlined"
                          fullWidth
                          disabled={(!edit && !addCondition) || !value.isActive}
                          placeholder="Min"
                          size="small"
                          type="number"
                          onChange={(e) => {
                            let payload = [...qualifications];
                            payload[index].range[i].min = Number(e.target.value);
                            setQualifications(payload);
                          }}
                          helperText={
                            <MDBox fontSize="12px" color="error">
                              {val.min === ""
                                ? "This field is Required"
                                : Number(val.min) < 10
                                ? "This value can not be less then 10"
                                : ""}
                            </MDBox>
                          }
                          value={val.min}
                        />
                        <MDInput
                          variant="outlined"
                          fullWidth
                          disabled={(!edit && !addCondition) || !value.isActive}
                          placeholder="Max"
                          type="number"
                          size="small"
                          onChange={(e) => {
                            let payload = [...qualifications];
                            payload[index].range[i].max = Number(e.target.value);
                            setQualifications(payload);
                          }}
                          helperText={
                            <MDBox fontSize="12px" color="error">
                              {val.max === ""
                                ? "This field is Required"
                                : Number(val.max) > 100
                                ? "This val can not be greater than 100"
                                : Number(val.min) > Number(val.max)
                                ? "This is not be less then min"
                                : ""}
                            </MDBox>
                          }
                          value={val.max}
                        />
                        <IconButton
                          size="small"
                          disabled={
                            value.range.length === 1 || (!edit && !addCondition) || !value.isActive
                          }
                          onClick={() => {
                            let payload = [...qualifications];
                            payload[index].range.splice(i, 1);
                            setQualifications(payload);
                          }}
                        >
                          <DeleteOutline color="error" fontSize="small" />
                        </IconButton>
                      </Stack>
                    );
                    // : (
                    //   <Typography
                    //     fontSize="14px"
                    //     fontWeight="500"
                    //   >{`${val.min} - ${val.max}`}</Typography>
                    // );
                  })}
                  <Stack direction="row" justifyContent="flex-end" px={5.5} pt={1}>
                    <MDButton
                      variant="text"
                      color="dark"
                      size="small"
                      sx={{ display: (!edit && !addCondition) || !value.isActive ? "none" : "" }}
                      onClick={() => {
                        let payload = [...qualifications];
                        payload[index].range.push({
                          min: 10,
                          max: 100,
                        });
                        setQualifications(payload);
                      }}
                    >
                      + Add Input
                    </MDButton>
                  </Stack>
                </Grid>
              ) : (
                <Grid item xs={6} mt={1}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {(edit || addCondition) && value.isActive ? (
                      <MDInput
                        variant="outlined"
                        fullWidth
                        placeholder="Text"
                        size="small"
                        value={value.text}
                        onChange={(e) => {
                          let payload = [...qualifications];
                          payload[index].text = e.target.value;
                          setQualifications(payload);
                        }}
                      />
                    ) : (
                      <Typography fontSize="14px" fontWeight="500">{`${value.text}`}</Typography>
                    )}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Stack>
        );
      })}
    </div>
  );
};
export default ConditionOverview;

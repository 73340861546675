import * as React from "react";
import { useState } from "react";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Backdrop, CircularProgress, Dialog, DialogTitle, TextField, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Stack, Chip } from "@mui/material";
import Typography from "@mui/material/Typography";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useSnackbar } from "notistack";
import { ThemeProvider } from "@mui/material";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable, MRT_Table } from "material-react-table";

const AddMember = ({ setOpen, open }) => {
  let { textColor } = colors;
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setselected] = useState([]);
  const [search, setSearch] = useState("");
  const [memberList, setMemberList] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

  const tableTheme = useTableTheme();

  const [roleList, setRoleList] = useState([]);
  const [rolesData, setRolesData] = useState([]);

  React.useEffect(() => {
    setselected([]);
    setRoleList([]);
  }, [open]);

  const handleClose = (e) => {
    setSearch("");
    setOpen(false);
  };

  const save = async () => {
    let memberData = selected.map((data) => {
      return {
        userId: data,
        projectRoleId: memberList.find((key) => key.id === data).roleId,
      };
    });
    let payload = {
      projectId: id,
      members: memberData,
    };
    setIsLoading(true);
    try {
      let result = await TLinkApi.post("/project/teams", payload);
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  async function GetMembers() {
    try {
      let data = await TLinkApi.get(`/project/teams/${id}/users`);
      setMemberList(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  async function GetRoleList() {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`/masters/role`);
      setRolesData(data.data);
      let result = data.data;
      result.map((res) => setRoleList((roles) => [...roles, res.name]));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  React.useEffect(() => {
    setData(memberList);
  }, [memberList]);

  React.useEffect(() => {
    if (open === true) {
      GetRoleList();
      GetMembers();
    }
  }, [open]);

  React.useEffect(() => {
    let searchData = memberList.filter((value) => {
      if (
        selected?.findIndex(
          (val) =>
            memberList.find((element) => element.id === val).userName?.toLowerCase() ==
            value.userName?.toLowerCase()
        ) <= -1
      ) {
        return value.userName?.toLowerCase().includes(search.toLowerCase());
      }
    });
    setData(searchData);
  }, [search]);

  React.useEffect(() => {
    if (selected.length > 0) {
      let newMemberList = memberList.filter((val) => !selected.includes(val.id));
      setData(newMemberList);
    } else if (selected.length === 0) {
      setData(memberList);
    }
  }, [selected]);

  function getInitials(str) {
    let names = str.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }

  let columns = [
    {
      accessorKey: "userName",
      header: "",
      enableEditing: false,
      size: 50,
      muiTableBodyCellProps: {
        align: "left",
        sx: { pl: "45px" },
      },
      Cell: ({ cell }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            height: "15px",
          }}
        >
          <div
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              width: "2rem",
              height: "2rem",
              borderRadius: "50%",
              background: stringToHslColor(getInitials(cell.getValue()), 30, 50),
              fontSize: "1rem",
              color: "#fff",
              textAlign: "center",
              lineHeight: "2rem",
            }}
          >
            {getInitials(cell.getValue())}
          </div>
          <span>{cell.getValue()}</span>
        </Box>
      ),
    },
    {
      accessorKey: "roleName",
      header: "",
      size: 50,
      editVariant: "select",
      editSelectOptions: roleList,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        onChange: (event) => {
          let roleid = rolesData.find((data) => data.name === event.target.value).id;
          let rolename = rolesData.find((data) => data.name === event.target.value).name;
          memberList[memberList.findIndex((val) => val.id === row.original.id)].roleId = roleid;
          memberList[
            memberList.findIndex((val) => val.roleName === row.original.roleName)
          ].roleName = rolename;
        },
      }),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    editDisplayMode: "table",
    enableEditing: true,
    initialState: { density: "compact" },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        setselected([...selected, row.original.id]);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        display: "none",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <span> {"Add Team Member"}</span>
          <Stack direction="row" alignItems="center" spacing={2}>
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              &nbsp;cancel
            </MDButton>

            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => {
                save();
              }}
            >
              Save
            </MDButton>
          </Stack>
        </Stack>
        <hr style={{ border: `1px solid #CCC`, width: "100%" }} />
      </DialogTitle>
      <Paper fullWidth>
        <TextField
          sx={{ width: "555px", marginX: "10px" }}
          placeholder="search and add your team"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          direction="row"
          spacing={1}
          sx={{
            marginLeft: "10px",
            marginTop: "5px",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
          }}
        >
          {selected.map((chipid) => {
            return (
              <Chip
                sx={{ backgroundColor: "#A9FFBC" }}
                label={memberList.find((element) => element.id === chipid).userName}
                onDelete={() => {
                  setselected(selected.filter((id) => id !== chipid));
                }}
              />
            );
          })}
        </Stack>
        <MDBox borderRadius="5px" bgColor="white" width="98%">
          <ThemeProvider theme={tableTheme}>
            <MRT_Table
              table={table}
              style={{
                width: "100%",
              }}
            />
          </ThemeProvider>
        </MDBox>
      </Paper>
    </Dialog>
  );
};

export default AddMember;

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import React, { useEffect, useState } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import MDBox from "components/MDBox";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <IconButton
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      <CalendarMonthIcon fontSize="small" />
    </IconButton>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      withDate
      maxDate={dayjs().add(-1, "day")}
    />
  );
}

const ActivityLog = ({ handleClose, objectTypeId }) => {
  const { id } = useParams();
  const [activityList, setActivityList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(5, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [active, setActive] = useState("5d");
  const [value, setValue] = useState(null);

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  useEffect(() => {
    if (active === "5d") {
      setStartDate(moment().subtract(5, "days").format("YYYY-MM-DD"));
    } else if (active === "7d") {
      setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    } else if (active === "15d") {
      setStartDate(moment().subtract(15, "days").format("YYYY-MM-DD"));
    } else if (active === "1mos") {
      setStartDate(moment().subtract(30, "days").format("YYYY-MM-DD"));
    }
  }, [active]);

  useEffect(() => {
    if (value) {
      setStartDate(dayjs(value).format("YYYY-MM-DD"));
    }
  }, [value]);

  const getActivityLogData = async () => {
    let payload = {
      startDate: startDate,
      endDate: endDate,
    };
    try {
      let data = await TLinkApi.post(`/activity/logs/${objectTypeId}/${id}`, payload);
      setActivityList(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getActivityLogData();
  }, [id, startDate]);

  return (
    <MDBox height="500px">
      <Stack
        px={1}
        sx={{
          position: "sticky",
          top: "0px",
          zIndex: 1,
          background: "white",
        }}
        pt={2}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h6" color="dark">
            Activity Log
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          my={1}
          sx={{
            boxShadow:
              "0px 8px 24px 4px rgba(228, 211, 255, 0.25), 0px 8px 12px 0px rgba(138, 138, 138, 0.25)",
            borderRadius: "12px",
          }}
        >
          <IconButton
            id={"5d"}
            px={1}
            variant="text"
            sx={{
              height: "20px",
              width: "30px",
              fontSize: "12px",
              borderRadius: "10px",
              backgroundColor: active === "5d" ? "#FDB447" : "",
            }}
            onClick={handleClick}
          >
            5d
          </IconButton>
          <IconButton
            id={"7d"}
            variant="text"
            sx={{
              height: "20px",
              width: "30px",
              fontSize: "12px",
              borderRadius: "10px",
              backgroundColor: active === "7d" ? "#FDB447" : "",
            }}
            onClick={handleClick}
          >
            7d
          </IconButton>
          <IconButton
            id={"15d"}
            variant="text"
            sx={{
              height: "20px",
              width: "30px",
              fontSize: "12px",
              borderRadius: "10px",
              backgroundColor: active === "15d" ? "#FDB447" : "",
            }}
            onClick={handleClick}
          >
            15d
          </IconButton>
          <IconButton
            id={"1mos"}
            variant="text"
            sx={{
              height: "25px",
              width: "45px",
              fontSize: "12px",
              borderRadius: "10px",
              backgroundColor: active === "1mos" ? "#FDB447" : "",
            }}
            onClick={handleClick}
          >
            1mos
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonDatePicker
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
                setActive("cal");
              }}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      <Timeline
        sx={{
          paddingTop: "0px",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {activityList.length <= 0 && (
          <span style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
            No Records
          </span>
        )}
        {activityList.length > 0 &&
          activityList.map((activity, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="info" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ paddingTop: "0px !important" }}>
                  <Stack marginTop="8px">
                    <Tooltip title={activity?.title.length > 28 ? activity?.title : ""}>
                      <span style={{ fontSize: "14px", color: "black" }}>
                        {activity?.title && activity?.title.length > 28
                          ? activity?.title.substring(0, 28) + "..."
                          : activity?.title}
                      </span>
                    </Tooltip>
                    <Tooltip title={activity?.userName.length > 15 ? activity?.userName : ""}>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PersonOutlineIcon />
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                            fontSize: "12px",
                          }}
                        >
                          <b>
                            {activity?.userName && activity?.userName.length > 15
                              ? activity?.userName.substring(0, 15) + "..."
                              : activity?.userName}
                          </b>
                          /{activity?.roleName}
                        </span>
                      </span>
                    </Tooltip>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AccessTimeIcon />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#aaa",
                          fontSize: "12px",
                          marginLeft: "3px",
                        }}
                      >
                        {activity?.updatedAt
                          ? moment(moment(activity.updatedAt).local().format()).fromNow()
                          : "NA"}
                      </span>
                    </span>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </MDBox>
  );
};

export default ActivityLog;

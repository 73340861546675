import { Grid, Stack, Divider, Typography } from "@mui/material";
import React from "react";
import "../../performance.css";
import colors from "assets/theme/base/colors";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import BigNumber from "bignumber.js";

const SurveyData = ({ surveyData }) => {
  const { primary, warning, success, error } = colors;
  let navigate = useNavigate();

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };

  return (
    <Grid container spacing={5} my={1.5}>
      {surveyData?.length > 0 ? (
        surveyData.map((val, i) => (
          <Grid item xs={6} key={i}>
            <Stack
              sx={{
                border: "1px solid var(--Grey-3, #E4E4E4)",
                padding: "10px",
                borderRadius: "8px",
                "&:hover": {
                  border: `1px solid ${primary.main}`,
                  background: "var(--Grey-1, #F3F3F3)",
                },
              }}
            >
              <Stack
                sx={{ borderBottom: "1px solid var(--Grey-5, #A7A7A7)", width: "100%" }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <div>
                    <MDButton
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        background: "var(--Grey-1, #F3F3F3)",
                        "&:hover": {
                          background: "var(--Grey-1, #F3F3F3)",
                        },
                      }}
                      onClick={() => {
                        navigate(`/surveys/${val?.surveyId}`);
                      }}
                    >
                      {val.surveyName.length > 30
                        ? val.surveyName.substring(0, 30) + "..."
                        : val.surveyName}
                    </MDButton>
                  </div>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <div style={{ fontSize: "16px", fontWeight: "400" }}>{val.surveyId}</div>
                </Stack>
              </Stack>
              <Grid container spacing={4} p={1}>
                <Grid item xs={4}>
                  <div className="styled-value">{val?.starts}</div>
                  <div className="styled-key">Starts</div>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems="center">
                    <Stack alignItems="center">
                      <div
                        className="styled-value"
                        style={{
                          color:
                            val?.cpi.status === "good"
                              ? success.main
                              : val?.cpi.status === "low"
                              ? error.main
                              : warning.main,
                        }}
                      >
                        $
                        {val?.cpi?.value
                          ? new BigNumber(Math.round(val?.cpi?.value)).toFormat()
                          : 0}
                      </div>
                      <div className="styled-key">Avg. CPI</div>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems="flex-end">
                    <div
                      className="styled-value"
                      style={{
                        color:
                          val?.revenue.status === "good"
                            ? success.main
                            : val?.revenue.status === "low"
                            ? error.main
                            : warning.main,
                      }}
                    >
                      $
                      {val?.revenue?.value
                        ? new BigNumber(Math.round(val?.revenue?.value)).toFormat()
                        : 0}
                    </div>
                    <div className="styled-key">Revenue</div>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <div className="styled-value">{Math.round(val.completes)}</div>
                  <div className="styled-key">Completes</div>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems="center">
                    <Stack alignItems="center">
                      <div
                        className="value-percentege"
                        style={{
                          color:
                            val?.epc.status === "good"
                              ? success.main
                              : val?.epc.status === "low"
                              ? error.main
                              : warning.main,
                        }}
                      >
                        $
                        {val?.epc?.value
                          ? new BigNumber(Math.round(val?.epc?.value)).toFormat()
                          : 0}
                      </div>
                      <div className="styled-key">EPC</div>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems="flex-end">
                    <div className="styled-value">${Math.round(val.costs)}</div>
                    <div className="styled-key">Cost</div>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <div
                    className="value-percentege"
                    style={{
                      color:
                        val?.conversion.status === "good"
                          ? success.main
                          : val?.conversion.status === "low"
                          ? error.main
                          : warning.main,
                    }}
                  >
                    {val?.conversion?.value ? Math.round(formatCash(val?.conversion?.value)) : 0}%
                  </div>
                  <div className="styled-key">Overall Conv.</div>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems="center">
                    <Stack alignItems="center">
                      <div
                        className="value-percentege"
                        style={{
                          color:
                            val?.clientConversion?.status === "good"
                              ? success.main
                              : val?.clientConversion?.status === "low"
                              ? error.main
                              : warning.main,
                        }}
                      >
                        {val?.clientConversion?.value
                          ? Math.round(formatCash(val?.clientConversion?.value))
                          : 0}
                        %
                      </div>
                      <div className="styled-key">Client Conv.</div>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack alignItems="flex-end">
                    <div
                      className="value-percentege"
                      style={{
                        color:
                          val?.profitPercentage.status === "good"
                            ? success.main
                            : val?.profitPercentage.status === "low"
                            ? error.main
                            : warning.main,
                      }}
                    >
                      {val?.profitPercentage?.value
                        ? Math.round(formatCash(val.profitPercentage.value))
                        : 0}
                      %
                    </div>
                    <div className="styled-key">Profit.</div>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Stack alignItems="center" justifyContent="center" mt={5}>
            <Typography variant="h6">No Data Available !</Typography>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default SurveyData;

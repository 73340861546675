import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "comps/Sidenav/styles/sidenavCollapse";
import { useMaterialUIController } from "context";
import { Tooltip } from "@mui/material";

function SidenavCollapse({
  icon,
  name,
  width,
  child,
  sideNavMin,
  dropDownIcon,
  root,
  active,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  return (
    <ListItem component="li">
      <Tooltip title={width === 72 ? name : ""} placement="right">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
              dropDownIcon,
              child,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active,
                name,
                dropDownIcon,
                width,
                child,
                sideNavMin,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            style={{ display: sideNavMin === false && width === 72 ? "none" : "" }}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          <div
            style={{
              alignItems: "flex-end",
              transform: dropDownIcon === name ? "rotate(180deg)" : "",
              display: sideNavMin === false && width === 72 ? "none" : "flex",
            }}
          >
            {root === true && <Icon>arrow_drop_down</Icon>}
          </div>
        </MDBox>
      </Tooltip>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;

import React, { useEffect, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/images/Back.svg";

const ClientTitleCard = ({ name }) => {
  let navigate = useNavigate();
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    setClientName(name);
  }, [name]);

  return (
    <Stack spacing={0} minHeight="85px" pt={1.5}>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton
          sx={{
            padding: 0,
            borderRadius: "4px !important",
          }}
          onClick={() => {
            navigate(`/clients`);
          }}
        >
          <img src={BackIcon} />
        </IconButton>
        <Typography variant="h4">{clientName}</Typography>
      </Stack>
    </Stack>
  );
};

export default ClientTitleCard;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetTopClientList = () => {
  const [topClients, setTopClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTopClients = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("dashboards/list/top-client");
      setTopClients(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getTopClients();
    // eslint-disable-next-line
  }, []);
  return { topClients, getTopClients, isLoading };
};

export default GetTopClientList;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetLanguageMapList = () => {
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const [languageMap, setLanguageMap] = useState([]);
  const getLanguage = async () => {
    setLoading(true);
    try {
      let data = await TLinkApi.get("/masters/language");
      if (data.data) {
        setLanguage(data.data);
        let languageMap = [];
        data.data.map((value) => {
          languageMap[value.id] = value.name;
          return true;
        });
        setLanguageMap(languageMap);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    getLanguage();
    // eslint-disable-next-line
  }, []);
  return { language, getLanguage, languageMap, loading };
};

export default GetLanguageMapList;

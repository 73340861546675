import { Checkbox, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

function QuotaQualificationChart(props) {
  const [qualificationObj, setQualificationObj] = useState({});
  useEffect(() => {
    let data = props.quota.find((value) => value.qualificationId === props.val.qualificationId);
    if (data) {
      let payload = {};
      payload.qualificationTypeId = data.qualificationTypeId;
      payload.questionText = data.question.questionText;
      payload.answers = data.question.answers;
      if (data.qualificationTypeId === 1 || data.qualificationTypeId === 2) {
        payload.selectedAnswers = props.val.selectedAnswers;
      }
      setQualificationObj(payload);
    }
    console.log(props, "props");
  }, [props]);

  return (
    <MDBox mb={1} fontSize="15px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            flexWrap="wrap"
            mt={1}
            sx={{ opacity: !props.val.isActive ? "0.5" : "" }}
          >
            <MDTypography variant="button" color="dark" sx={{ mb: 1, fontWeight: 600 }}>
              {qualificationObj?.questionText}
            </MDTypography>
            <IconButton
              sx={{
                marginTop: "0.2rem",
                display: !props.edit || !props.val.isActive ? "none" : "",
              }}
              onClick={() => {
                let payload = [...props.data];
                payload[props.dataIndex].quota.conditions.splice(props.index, 1);
                props.setData(payload);
              }}
            >
              <DeleteOutlineIcon fontSize="small" color="error" />
            </IconButton>
          </MDBox>
          {qualificationObj?.qualificationTypeId === 1 ||
          qualificationObj?.qualificationTypeId === 2 ? (
            <Grid item xs={12} md={12}>
              <Grid container spacing={0} sx={{ opacity: !props.val.isActive ? "0.5" : "" }}>
                {qualificationObj.answers
                  .filter((option) =>
                    qualificationObj?.selectedAnswers?.includes(Number(option.id))
                  )
                  .map((option, i) => (
                    <Grid item xs={12} md={12} lg={4} key={i}>
                      <Checkbox
                        disabled={!props.edit || !props.val.isActive}
                        onChange={(e) => {
                          let payload = [];
                          if (!e.target.checked) {
                            payload = [...props.data];
                            payload[props.dataIndex].quota.conditions[props.index].answerId =
                              payload[props.dataIndex].quota.conditions[
                                props.index
                              ].answerId.filter((answer) => answer !== parseInt(option.id));
                          } else {
                            payload = [...props.data];
                            payload[props.dataIndex].quota.conditions[props.index].answerId.push(
                              parseInt(option.id)
                            );
                          }
                          props.setData(payload);
                        }}
                        checked={props.val.answerId.includes(parseInt(option.id))}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;{option.qualificationAnswerDesc}&nbsp;
                      </MDTypography>
                    </Grid>
                  ))}
              </Grid>
              {props.data[props.dataIndex].quota?.conditions[props.index].answerId.length <= 0 ? (
                <MDBox color="error">Please select at least one field</MDBox>
              ) : (
                ""
              )}
            </Grid>
          ) : qualificationObj.qualificationTypeId === 4 ? (
            <>
              {props?.val?.selectedRange?.map((value, i) => {
                return props.edit && props.val.isActive ? (
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginBottom: "1rem", opacity: !props.val.isActive ? "0.5" : "" }}
                  >
                    <Grid item xs={12} md={5}>
                      <MDInput
                        type="number"
                        autoComplete="off"
                        variant="outlined"
                        label="min"
                        fullWidth
                        required
                        disabled={!props.edit}
                        value={value.min}
                        onChange={(e) => {
                          let payload = [...props.data];
                          payload[props.dataIndex].quota.conditions[props.index].selectedRange[
                            i
                          ].min = e.target.value;
                          props.setData(payload);
                        }}
                        error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
                        helperText={
                          value.min === ""
                            ? "This field is Required"
                            : Number(value.min) < 10
                            ? "This value can not be less then 10"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <MDInput
                        type="number"
                        fullWidth
                        required
                        disabled={!props.edit}
                        value={value.max}
                        onChange={(e) => {
                          let payload = [...props.data];
                          payload[props.dataIndex].quota.conditions[props.index].selectedRange[
                            i
                          ].max = e.target.value;
                          props.setData(payload);
                        }}
                        autoComplete="off"
                        variant="outlined"
                        label="max"
                        error={
                          value.max === ""
                            ? true
                            : Number(value.min) > Number(value.max)
                            ? true
                            : Number(value.max) > 100
                            ? true
                            : false
                        }
                        helperText={
                          value.max === ""
                            ? "This field is Required"
                            : Number(value.max) > 100
                            ? "This value can not be greater than 100"
                            : Number(value.min) > Number(value.max)
                            ? "This is not be less then min"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        disabled={props?.val?.selectedRange.length === 1}
                        onClick={() => {
                          let payload = [...props.data];
                          payload[props.dataIndex].quota.conditions[
                            props.index
                          ].selectedRange.splice(i, 1);
                          props.setData(payload);
                        }}
                      >
                        <DeleteOutlineIcon color="error" fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} sx={{ opacity: !props.val.isActive ? "0.5" : "" }}>
                    <Grid item xs={12} sx={{ marginLeft: "0.6rem" }}>
                      {value.min} - {value.max}
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid container spacing={2} sx={{ opacity: !props.val.isActive ? "0.5" : "" }}>
              <Grid item xs={12} md={5}>
                {props.edit && props.val.isActive ? (
                  <MDInput
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    label="Answer Text"
                    fullWidth
                    required
                    disabled={!props.edit}
                    value={props?.val?.text}
                    onChange={(e) => {
                      let payload = [...props.data];
                      payload[props.dataIndex].quota.conditions[props.index].text = e.target.value;
                      props.setData(payload);
                    }}
                    error={
                      props.data[props.dataIndex]?.quota?.conditions[props.index].text === ""
                        ? true
                        : false
                    }
                    helperText={
                      props.data[props.dataIndex]?.quota?.conditions[props.index].text === ""
                        ? "This field is required"
                        : ""
                    }
                  />
                ) : (
                  <span style={{ marginLeft: "0.6rem" }}>{props?.val?.text}</span>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QuotaQualificationChart;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";
import Axios from "axios";
import Auth from "services/auth";
import { useSnackbar } from "notistack";
import BackImage from "assets/images/logos/backgroundLogin.png";
import { Grid, IconButton, Backdrop, CircularProgress } from "@mui/material";
import PageLayout from "comps/LayoutContainers/PageLayout";
import "./index.css";
import Signin from "assets/images/icons/Signin.png";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Controller, useForm } from "react-hook-form";
import signInLogo from "assets/logo/sign_in_logo.png";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

function Basic() {
  const { enqueueSnackbar } = useSnackbar();
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const save = async (value) => {
    try {
      setIsLoading(true);
      const { data, status } = await Axios.post(TLinkApi.baseUrl + "/login", value);
      if (status === 200) {
        Auth.saveAuthorizationToken(data.token);
        enqueueSnackbar("Logged in", {
          variant: "success",
        });
        setIsLoading(false);
        navigate("/dashboard");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err?.response.data.message);
      enqueueSnackbar(err?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout>
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          style={{ background: "#F2F7EC", height: "100%", width: "100%", overflow: "auto" }}
        >
          <Grid
            item
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none;",
                lg: "block",
                xl: "block",
              },
            }}
            xs={12}
            md={12}
            lg={7}
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundImage: `url(${BackImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              overflow: "hidden",
              backgroundPosition: "center",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                height: "100vh",
                minHeight: "700px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1 className="leftText">Scientifically Balanced Samples WorldWide</h1>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100vh" }}
            >
              <MDBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                mx={2}
                p={2}
                mb={1}
                textAlign="center"
                fontWeight="900"
                style={{
                  background: "#F2F7EC",
                  boxShadow:
                    "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
                }}
              >
                <MDBox
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                  mx={2}
                  p={2}
                  mb={1}
                  textAlign="center"
                  fontWeight="900"
                  style={{ background: "#F2F7EC" }}
                >
                  <MDBox
                    component="img"
                    mt={3}
                    src={signInLogo}
                    alt="ELink"
                    width="64px"
                    height="64px"
                  />
                  <MDBox color="rgba(131, 177, 64, 1)" style={{ fontSize: "50px" }}>
                    Symmppl&trade;
                  </MDBox>
                  <MDBox fontSize="16px" color="rgba(140, 140, 140, 1)">
                    Scientifically balanced samples worldwide
                  </MDBox>
                  <MDBox textAlign="center" pt={5} pb={4}>
                    <span className="welcomeCss">Welcome</span>
                    <span className="backCss"> back !</span>
                  </MDBox>
                  <MDBox textAlign="left" pt={1} pb={3} px={3}>
                    <form onSubmit={handleSubmit(save)} noValidate>
                      <MDBox mb={2} maxWidth="300px">
                        <label className="labelCss">
                          <span style={{ color: "red" }}>*</span>User Email Address
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: "Email is required!",
                          }}
                          render={({ field }) => (
                            <MDInput
                              {...field}
                              fullWidth
                              required
                              type="email"
                              {...register("email", {
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: "Entered value does not match email format",
                                },
                              })}
                              variant="outlined"
                              placeholder="Input Email"
                              error={errors?.email ? true : false}
                              helperText={errors?.email ? errors.email.message : null}
                            />
                          )}
                        />
                      </MDBox>
                      <MDBox mb={2} className="passwordInput">
                        <label className="labelCss">
                          <span style={{ color: "red" }}>*</span>Password
                        </label>
                        <Controller
                          name="password"
                          control={control}
                          rules={{
                            required: "Password is required!",
                          }}
                          render={({ field }) => (
                            <MDInput
                              {...field}
                              fullWidth
                              required
                              type={passwordShown ? "text" : "password"}
                              placeholder="Input Password"
                              variant="outlined"
                              error={errors?.password ? true : false}
                              helperText={errors?.password ? errors.password.message : null}
                            />
                          )}
                        />
                        <div className="eyeIcon">
                          {passwordShown === true ? (
                            <IconButton
                              onClick={() => {
                                setPasswordShown(false);
                              }}
                            >
                              <VisibilityOutlinedIcon color="dark" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setPasswordShown(true);
                              }}
                            >
                              <VisibilityOffOutlinedIcon color="dark" />
                            </IconButton>
                          )}
                        </div>
                      </MDBox>
                      <MDBox mt={-2} mb={-1} textAlign="right">
                        <MDTypography variant="button" color="text">
                          <MDTypography
                            component={Link}
                            to="/forget-password"
                            variant="button"
                            color="info"
                            fontWeight="small"
                            textGradient
                          >
                            Forgot password?
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        mt={4}
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          fullWidth
                          style={{
                            width: "65%",
                          }}
                        >
                          <MDBox
                            component="img"
                            src={Signin}
                            width=" 23.52px"
                            height="23.04px"
                            className="imageSymmetric"
                            mr={2}
                          />
                          Sign in
                        </MDButton>
                      </MDBox>
                    </form>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

export default Basic;

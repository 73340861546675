import * as React from "react";
import { useState } from "react";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import TLinkApi from "services/tlink.api";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";

const AddPriority = ({ setOpen, open }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [priorityList, setPriorityList] = useState([]);
  let { enqueueSnackbar } = useSnackbar();
  const { formDetailsColor } = colors;

  const [descNote, setDescNote] = useState([]);
  const [descObject, setDescObject] = useState([]);
  const [descAction, setDescAction] = useState([]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      surveyId: "",
      priority: "",
    },
  });

  const GetPriorityList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/prioritylist`);
      setPriorityList(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    GetPriorityList();
    GetDescNotes();
    GetDescNotesObject();
    GetDescNotesAction();
  }, []);

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const handleClose = (e) => {
    setValue("surveyId", "");
    setValue("priority", "");
    setOpen(false);
  };

  const GetDescNotes = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/descriptivenotes`);
      setDescNote(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const GetDescNotesObject = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/descriptivenotesobject`);
      setDescObject(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const GetDescNotesAction = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/descriptivenotesaction`);
      setDescAction(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const save = async (data) => {
    let actionTypeId = descAction.find((val) => val.name === "Create").id;
    let objectTypeId = descObject.find((val) => val.name === "Project Priority").id;
    let descMessage = descNote.find(
      (val) =>
        val.desc_note_object_type_id === objectTypeId &&
        val.desc_note_action_type_id === actionTypeId
    ).description;
    try {
      let result = await TLinkApi.post("/project/priority", data);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      setValue("surveyId", "");
      setValue("priority", "");
      setOpen(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <form onSubmit={handleSubmit(save)} noValidate>
          <DialogTitle sx={{ position: "sticky", top: 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Survey Prioritization</Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  &nbsp;cancel
                </MDButton>

                <MDButton variant="gradient" color="info" size="small" type="submit">
                  Save
                </MDButton>
              </Stack>
            </Stack>
            <hr style={{ backgroundColor: "#CCC", height: "3px", marginTop: "0.5rem" }} />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                {label("Survey ID")}
                <Controller
                  name="surveyId"
                  control={control}
                  rules={{
                    required: "Survey ID is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      disabled={false}
                      {...field}
                      fullWidth
                      required
                      {...register("surveyId")}
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.surveyId ? true : false}
                      helperText={errors?.surveyId ? errors.surveyId.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                {label("Priority Number")}
                <Controller
                  name="priority"
                  control={control}
                  rules={{
                    required: "Priority Number is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.priority ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor?.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        disabled={false}
                        onChange={onChange}
                        sx={selectStyle}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        {priorityList.map((data) => (
                          <MenuItem value={data.id}>{data.name}</MenuItem>
                        ))}
                      </Select>
                      {errors?.priority && (
                        <FormHelperText>
                          {errors?.priority ? errors.priority.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default AddPriority;

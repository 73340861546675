import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { StyledTabs } from "./tabs.style";
import colors from "assets/theme/base/colors";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ marginTop: "2rem", display: value === index ? "block" : "none" }}
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const TabsComponent = (props) => {
  const [value, setValue] = React.useState(0);
  const { info } = colors;

  const { labels, tabpanels } = props;

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: `${info.main}`,
            },
          }}
        >
          {labels.map(({ label, isDisabled, icon }, index) => (
            <Tab
              key={index}
              label={label}
              icon={icon || ""}
              disabled={isDisabled}
              iconPosition="start"
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      </Box>

      {tabpanels.map((tabpanel, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tabpanel}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsComponent;

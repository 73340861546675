import MDBox from "components/MDBox";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TabsComponent from "components/Tabs/Tabs";
import TLinkApi from "services/tlink.api";
import { Backdrop, CircularProgress } from "@mui/material";
import VendorTitleCard from "components/VendorTitleCard";
import NotesIcon from "../../../../assets/images/notesIcon.svg";
import LogsIcon from "../../../../assets/images/logsIcon.svg";
import ActivityLog from "../../../ActivityLog";
import { useSnackbar } from "notistack";

function AddVendors() {
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();
  const [value, setValue] = useState(0);
  const [subValue, setSubValue] = useState(0);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (window.location.pathname === `/vendors/${id}/setUp/edit`) {
      setValue(1);
    } else if (window.location.pathname === `/vendors/${id}/routingConfig/edit`) {
      setValue(2);
    } else if (window.location.pathname === `/vendors/${id}/cpiConfig/edit`) {
      setValue(3);
    } else if (window.location.pathname === `/vendors/${id}/markets&Clients/edit`) {
      setValue(4);
    } else if (window.location.pathname === `/vendors/${id}/vendorsupplyconfig/edit`) {
      setValue(5);
    } else if (window.location.pathname === `/vendors/${id}/edit`) {
      setValue(0);
      setIsEdit({
        disabled: false,
        edit: true,
      });
    } else if (window.location.pathname === `/vendors/${id}`) {
      setValue(0);
      setIsEdit({
        disabled: true,
        edit: false,
      });
    } else if (window.location.pathname === `/vendors/new`) {
      setValue(0);
      setIsEdit({
        disabled: false,
        edit: false,
      });
    } else if (window.location.pathname === `/vendors/${id}/notes/edit`) {
      setValue(-1);
      setSubValue(0);
    }
  }, [location]);

  let getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        try {
          let result = await TLinkApi.get(`/vendors/${id}`);
          setVendor(result.data);
          if (result.data.vendorOnboardingStatus === false) {
            enqueueSnackbar("Vendor Setup is incomplete", {
              variant: "error",
            });
          }
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      setVendor({});
      getData();
    }
  }, [id]);

  const steps = [
    {
      label: "Vendor Details",
      isDisabled: false,
      route: window.location.pathname === `/vendors/new` ? `/vendors/new` : `/vendors/${id}`,
      programmatic: true,
    },
    {
      label: "Setup",
      isDisabled: window.location.pathname === `/vendors/new` ? true : false,
      route: `/vendors/${id}/setUp/edit`,
      programmatic: true,
    },
    {
      label: "Routing",
      isDisabled: window.location.pathname === `/vendors/new` ? true : false,
      route: `/vendors/${id}/routingConfig/edit`,
      programmatic: false,
    },
    {
      label: "CPI Config",
      isDisabled: window.location.pathname === `/vendors/new` ? true : false,
      route: `/vendors/${id}/cpiConfig/edit`,
      programmatic: false,
    },
    {
      label: "Markets & Clients",
      isDisabled: window.location.pathname === `/vendors/new` ? true : false,
      route: `/vendors/${id}/markets&Clients/edit`,
      programmatic: false,
    },
    {
      label: "Vendor Supply Config",
      isDisabled: window.location.pathname === `/vendors/new` ? true : false,
      route: `/vendors/${id}/vendorsupplyconfig/edit`,
      programmatic: false,
    },
  ];

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <VendorTitleCard
        name={window.location.pathname === `/vendors/new` ? "New Vendor" : vendor.name}
      />
      <Grid container spacing={0}>
        <TabsComponent
          setValue={setValue}
          value={value}
          labels={steps}
          subValue={subValue}
          isApi={vendor.isApi ? true : false}
          sublabels={[
            {
              label: `${NotesIcon}`,
              isDisabled: window.location.pathname === `/vendors/new` ? true : false,
              route: `/vendors/${id}/notes/edit`,
              name: "Notes",
            },
            {
              label: `${LogsIcon}`,
              isDisabled: window.location.pathname === `/vendors/new` ? true : false,
              name: "Activity logs",
              htmlContainer: <ActivityLog objectTypeId={4} />,
            },
          ]}
          tabpanels={
            <Outlet context={{ value, setValue, vendor, setVendor, isEdit, setIsEdit, getData }} />
          }
        />
      </Grid>
    </MDBox>
  );
}

export default AddVendors;

import { EditOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const ClientConfig = ({ allClients, setIsLoading, setSelectedClient, selectedClient }) => {
  const { grey, secondary } = colors;
  const { id } = useParams();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [clientConfig, setClientConfig] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

  const GetClientConfig = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`vendors/${id}/clients`);
      if (data.data && data.data.length > 0) {
        setClientConfig(data.data.map((val) => Number(val.clientId)));
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
        setIsEdit({
          disabled: false,
          edit: false,
        });
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetClientConfig();
    }
  }, [id]);

  const save = async () => {
    setIsLoading(true);
    try {
      if (addData) {
        let payload = {
          vendorId: id,
          clients: clientConfig.map((client) => {
            return {
              clientId: client,
              minVcpi: 1,
            };
          }),
        };
        await TLinkApi.post(`vendors/clients`, payload);
      } else {
        let payload = {
          clients: clientConfig.map((client) => {
            return {
              clientId: client,
              minVcpi: 1,
            };
          }),
        };
        await TLinkApi.put(`vendors/${id}/clients`, payload);
      }
      await GetClientConfig();
      enqueueSnackbar("Successfully saved", {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit.edit || addData) {
      setSelectedClient(null);
    }
  }, [isEdit, addData]);

  // useEffect(() => {
  //   let payload = allClients.map((client) => Number(client.id));
  //   setClientConfig(payload);
  // }, [allClients]);

  return (
    <MDBox
      sx={{
        height: "calc(100vh - 300px)",
        borderRight: `1px solid ${grey[400]}`,
        paddingInline: "1rem",
        overflowY: "auto",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        pb="0.5rem"
        sx={{ borderBottom: `1px solid ${grey[400]}`, background: "white", zIndex: "2" }}
      >
        <Typography variant="v6" fontWeight="600">
          Clients
        </Typography>
        {isEdit.disabled ? (
          <MDButton
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<EditOutlined />}
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
            }}
          >
            Edit
          </MDButton>
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                if (addData) {
                  setClientConfig([]);
                } else {
                  GetClientConfig();
                }
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              disabled={clientConfig.length <= 0}
              size="small"
              onClick={() => {
                save();
              }}
            >
              Save
            </MDButton>
          </Stack>
        )}
      </Stack>
      <FormGroup>
        {isEdit.edit || addData ? (
          <MDBox
            sx={{
              padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
              borderBottom: `1px solid ${grey[400]}`,
              display: allClients.length === 0 ? "none" : "",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={clientConfig.length !== 0 && allClients.length === clientConfig.length}
                  color="secondary"
                  onChange={(e) => {
                    let payload = [];
                    if (e.target.checked) {
                      payload = allClients.map((client) => Number(client.id));
                    } else {
                      payload = [];
                    }
                    setClientConfig(payload);
                  }}
                />
              }
              label={"Select All"}
            />
          </MDBox>
        ) : (
          ""
        )}
        {allClients?.length > 0 ? (
          allClients
            .filter(
              (client) =>
                (isEdit.disabled && clientConfig.includes(Number(client.id))) ||
                isEdit.edit ||
                addData
            )
            .map((client, i) => (
              <MDBox
                sx={{
                  padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                  borderBottom: `1px solid ${grey[400]}`,
                  cursor: "pointer",
                  background: selectedClient === client.id ? secondary.light : "",
                }}
                key={i}
                onClick={() => {
                  if (isEdit.disabled && selectedClient !== Number(client.id)) {
                    setSelectedClient(client.id);
                  } else {
                    setSelectedClient(null);
                  }
                }}
              >
                {isEdit.edit || addData ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={clientConfig.includes(Number(client.id))}
                        color="secondary"
                        onChange={(e) => {
                          let payload = [...clientConfig];
                          if (e.target.checked) {
                            payload.push(Number(client.id));
                          } else {
                            let data = payload.filter((val) => val !== Number(client.id));
                            payload = data;
                          }
                          setClientConfig(payload);
                        }}
                      />
                    }
                    label={client.name}
                  />
                ) : (
                  <Typography
                    fontSize="0.875rem"
                    fontWeight="700"
                    sx={{ width: "100%", overflowWrap: "break-word" }}
                  >
                    {client.name}
                  </Typography>
                )}
              </MDBox>
            ))
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
            <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
              No clients here !
            </Typography>
          </Stack>
        )}
      </FormGroup>
    </MDBox>
  );
};

export default ClientConfig;

import { Stack, Typography, TextField, IconButton, Tooltip } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

const ProjectReconcilationList = ({ reconcilations, sortOrder, setSortOrder, setSearch }) => {
  const { secondary, primary } = colors;
  let { id, reconcileId } = useParams();
  let navigate = useNavigate();

  return (
    <div style={{ paddingInline: "10px", paddingBottom: "10px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 5,
        }}
        py={1}
        mb={1}
        direction="row"
        alignItems="center"
      >
        <MDTypography color="black" ml="1%">
          Reconcilation List
        </MDTypography>
        <Tooltip title={`Sort ${sortOrder === 'asc' ? 'Descending' : 'Ascending'}`}>
        <IconButton
          aria-label="sort"
          sx={{ marginLeft: "auto" }}
          onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
        >
          {sortOrder === "asc" ? <NorthIcon fontSize="small"/> : <SouthIcon fontSize="small"/>}
        </IconButton>
        </Tooltip>
      </Stack>
      <TextField
        fullWidth
        autoComplete="off"
        placeholder="Type to search..."
        sx={{
          width: "600px",
          border: "none",
          "& fieldset": { border: "none" },
        }}
        onChange={(e) => setSearch(e.target.value)}
      />
      {reconcilations.length > 0 ? (
        reconcilations?.map((reconcilation, i) => {
          return (
            <>
              <MDBox
                id={`${reconcilation.id}`}
                onClick={() => {
                  navigate(`/projects/${id}/reconciliation/${reconcilation.id}`);
                }}
                sx={{
                  borderBottom:
                    Number(id) === reconcilation.id
                      ? `1px solid ${primary.main}`
                      : `1px solid #E4E4E4`,
                  background:
                    Number(reconcileId) === reconcilation.id ? `${secondary.light} !important` : "",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#f5f5f5",
                  },
                }}
                p={1}
                key={i}
              >
                <Typography sx={{ fontWeight: "500 !important", fontSize: "14px" }}>
                  {reconcilation?.title}
                </Typography>
              </MDBox>
            </>
          );
        })
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          textAlign="center"
          fontSize="13px"
          fontWeight="500"
        >
          There are no Reconcilation yet!
        </MDBox>
      )}
    </div>
  );
};

export default ProjectReconcilationList;

import {
  Backdrop,
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Label from "components/InputLabel";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  IconButton,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  ListSubheader,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetStudyTypeList from "hooks/StudyTypeList/GetStudyTypeList";
import { EditOutlined, DeleteOutline } from "@mui/icons-material";

const TemplateAdd = () => {
  const { templatesData, setTemplatesData, GetTemplateList, isEdit, setIsEdit, templates } =
    useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const { clients } = GetListOfClients();
  const { studyType } = GetStudyTypeList();
  const [studyTypeSearch, setStudyTypeSearch] = useState("");
  const [searchStudyList, setSearchStudyList] = useState([]);
  const [clientsSearch, setClientsSearch] = useState("");
  const [searchClientsList, setSearchClientsList] = useState([]);
  const [researchDefenderList, setResearchDefenderList] = useState([]);

  let navigate = useNavigate();
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();

  const GetResearchDefenderList = async () => {
    try {
      let result = await TLinkApi.get(`/masters/researchdefender`);
      setResearchDefenderList(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    GetResearchDefenderList();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: {
      templateName: "",
      clientId: "",
      studyTypeId: "",
      description: "",
      countryMismatch: false,
      uniqueIp: false,
      uniqueUser: false,
      researchDefender: false,
      researchDefenderScore: 0,
      researchDefenderOpenEnded: false,
      researchDefenderOpenEndedId: 0,
      compositeScore: 0,
      maxLoi: 0.0,
      minLoi: 0.0,
      minLoiIsActive: false,
      maxLoiIsActive: false,
    },
  });

  let countryMismatch = watch("countryMismatch");
  let uniqueIp = watch("uniqueIp");
  let uniqueUser = watch("uniqueUser");
  let researchDefender = watch("researchDefender");
  let researchDefenderOpenEnded = watch("researchDefenderOpenEnded");
  let minLoiIsActive = watch("minLoiIsActive");
  let maxLoiIsActive = watch("maxLoiIsActive");
  let maxLoi = watch("maxLoi");
  let minLoi = watch("minLoi");

  const getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/securityTemplate/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/security/template/${id}`);
          if (result) {
            setTemplatesData(result.data);
            setIsLoading(false);
          }
        } catch (e) {
          setIsLoading(false);
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {
    if (Object.keys(templatesData).length > 0) {
      setValue("templateName", templatesData.templateName);
      setValue("clientId", templatesData.clientId);
      setValue("studyTypeId", templatesData.studyTypeId);
      setValue("description", templatesData.description);
      setValue("countryMismatch", templatesData.countryMismatch);
      setValue("uniqueIp", templatesData.uniqueIp);
      setValue("uniqueUser", templatesData.uniqueUser);
      setValue("researchDefender", templatesData.researchDefender);
      setValue("researchDefenderScore", templatesData.researchDefenderScore);
      setValue("researchDefenderOpenEnded", templatesData.researchDefenderOpenEnded);
      setValue("researchDefenderOpenEndedId", templatesData.researchDefenderOpenEndedId);
      setValue("compositeScore", templatesData.compositeScore);
      setValue("minLoi", templatesData.minLoi);
      setValue("maxLoi", templatesData.maxLoi);
      setValue("minLoiIsActive", templatesData.minLoi > 0);
      setValue("maxLoiIsActive", templatesData.maxLoi > 0);
    } else if (Object.keys(templatesData).length <= 0) {
      setValue("templateName", "");
      setValue("clientId", "");
      setValue("studyTypeId", "");
      setValue("description", "");
      setValue("countryMismatch", false);
      setValue("uniqueIp", false);
      setValue("uniqueUser", false);
      setValue("researchDefender", false);
      setValue("researchDefenderScore", 0);
      setValue("researchDefenderOpenEnded", false);
      setValue("researchDefenderOpenEndedId", 0);
      setValue("compositeScore", 0);
      setValue("minLoi", 0.0);
      setValue("maxLoi", 0.0);
      setValue("minLoiIsActive", false);
      setValue("maxLoiIsActive", false);
    }
  }, [templatesData]);

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    let searchData = studyType.filter((data) => {
      return data.name.toLowerCase().includes(studyTypeSearch.toLowerCase());
    });
    setSearchStudyList(searchData);
  }, [studyTypeSearch, studyType]);

  useEffect(() => {
    let searchClientsData = clients.filter((data) => {
      return data.name.toLowerCase().includes(clientsSearch.toLowerCase());
    });
    setSearchClientsList(searchClientsData);
  }, [clientsSearch, clients]);

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const save = async (data) => {
    let payload = { ...data };
    payload.minLoi = payload.minLoiIsActive ? parseFloat(data.minLoi) : 0.0;
    payload.maxLoi = payload.maxLoiIsActive ? parseFloat(data.maxLoi) : 0.0;
    payload.researchDefenderScore = payload.researchDefender
      ? parseInt(data.researchDefenderScore)
      : 0;
    payload.compositeScore = payload.compositeScore ? parseInt(data.compositeScore) : 0;
    setIsLoading(true);
    try {
      if (window.location.pathname === `/securityTemplate/${id}/edit`) {
        let result = await TLinkApi.put(`/security/template/${id}`, payload);
        GetTemplateList();
        navigate(`/securityTemplate/${id}`);
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/security/template", payload);
        GetTemplateList();
        navigate(`/securityTemplate/${result.data.id}`);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: true,
          edit: false,
        });
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const deleteData = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.del(`/security/template/${id}`);
      GetTemplateList();
      if (templates.length > 0) navigate(`/securityTemplate/${templates[0].id}`);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(save)} noValidate style={{ padding: "10px" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {window.location.pathname === "/securityTemplate/new" ? (
            <Typography variant="h5">{"New Security Template"}</Typography>
          ) : (
            <Typography variant="h5">{watch("templateName")}</Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" pb={1}>
            <Tooltip title="Delete Security Template" placement="top">
              <IconButton
                sx={{
                  display:
                    window.location.pathname === "/securityTemplate/new" ||
                    window.location.pathname === `/securityTemplate/${id}/edit`
                      ? "none"
                      : "flex",
                  direction: "row",
                  justifyContent: "flex-end",
                }}
                onClick={() => {
                  deleteData();
                }}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </Tooltip>
            {isEdit.disabled !== false ? (
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<EditOutlined />}
                onClick={() => {
                  navigate(`/securityTemplate/${id}/edit`);
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                Edit
              </MDButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    getData();
                    if (window.location.pathname === "/securityTemplate/new") {
                      navigate(`/securityTemplate`);
                    } else {
                      navigate(`/securityTemplate/${id}`);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </Stack>
        </Grid>
        {isEdit.edit || window.location.pathname === "/securityTemplate/new" ? (
          <>
            <Grid container sx={{ marginLeft: "5px" }} spacing={2}>
              <Grid item xs={4}>
                <Label value="Template Name" required={true} />
                <Controller
                  name="templateName"
                  control={control}
                  rules={{
                    required: "Template Name is required!",
                  }}
                  render={({ field }) => (
                    <Stack mb={1}>
                      <MDInput
                        required
                        {...field}
                        fullWidth
                        variant="outlined"
                        error={errors?.templateName ? true : false}
                        helperText={errors?.templateName ? errors.templateName.message : null}
                      />
                    </Stack>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Label value="Client" required={true} />
                <Controller
                  name="clientId"
                  control={control}
                  rules={{
                    required: "Client is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.clientId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={selectStyle}
                        onClose={() => setClientsSearch("")}
                      >
                        <ListSubheader sx={{marginBottom: "1rem"}}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={clientsSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setClientsSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {searchClientsList?.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.clientId && (
                        <FormHelperText>
                          {errors?.clientId ? errors.clientId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Label value="StudyType" required={true} />
                <Controller
                  name="studyTypeId"
                  control={control}
                  rules={{
                    required: "Study Type is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.studyTypeId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={selectStyle}
                        onClose={() => setStudyTypeSearch("")}
                      >
                        <ListSubheader sx={{marginBottom: "1rem"}}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={studyTypeSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setStudyTypeSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {searchStudyList?.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.studyTypeId && (
                        <FormHelperText>
                          {errors?.studyTypeId ? errors.studyTypeId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Label value="Description" required={false} />
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <MDInput
                      multiline
                      placeholder="Type in here…"
                      rows={4}
                      disabled={isEdit.disabled}
                      {...field}
                      fullWidth
                      required
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography fontWeight="600">Security Configuration</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      disabled={isEdit.disabled}
                      checked={uniqueUser}
                      onChange={(e, value) => {
                        setValue("uniqueUser", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;User Duplication&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      disabled={isEdit.disabled}
                      checked={uniqueIp}
                      onChange={(e, value) => {
                        setValue("uniqueIp", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;IP Duplication&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      disabled={isEdit.disabled}
                      checked={countryMismatch}
                      onChange={(e, value) => {
                        setValue("countryMismatch", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Country Mismatch&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      disabled={isEdit.disabled}
                      checked={researchDefender}
                      onChange={(e, value) => {
                        setValue("researchDefender", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Research Defender&nbsp;
                    </MDTypography>
                  }
                />
                <Grid item xs={3} ml={4}>
                  {researchDefender && <Label value="Research Defender Score" required={true} />}
                  <Controller
                    name="researchDefenderScore"
                    control={control}
                    rules={{
                      required: researchDefender ? "Research Defender Score is required!" : false,
                    }}
                    render={({ field }) => (
                      <Stack mb={1}>
                        <MDInput
                          type="number"
                          required
                          {...field}
                          disabled={!researchDefender}
                          sx={{ display: researchDefender ? "" : "none" }}
                          fullWidth
                          variant="outlined"
                          error={errors?.researchDefenderScore ? true : false}
                          helperText={
                            errors?.researchDefenderScore
                              ? errors.researchDefenderScore.message
                              : null
                          }
                        />
                      </Stack>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={researchDefenderOpenEnded}
                      onChange={(e, value) => {
                        setValue("researchDefenderOpenEnded", value);
                      }}
                      disabled={isEdit.disabled}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Research Defender Open Ended&nbsp;
                    </MDTypography>
                  }
                />

                <Grid container display={"flex"}>
                  <Grid item xs={3} ml={4} display={researchDefenderOpenEnded ? "" : "none"}>
                    {researchDefenderOpenEnded && (
                      <Label value="Research Defender Open Ended" required={true} />
                    )}
                    <Controller
                      name="researchDefenderOpenEndedId"
                      control={control}
                      rules={{
                        required: "Research Defender Open Ended is required!",
                      }}
                      render={({ field: { value, onChange } }) => (
                        <FormControl
                          fullWidth
                          required
                          error={errors?.researchDefenderOpenEndedId ? true : false}
                          sx={{
                            label: {
                              color: "rgba(0, 0, 0, 0.25)",
                              fontWeight: "400",
                            },
                          }}
                          size="small"
                        >
                          <Select
                            value={value}
                            onChange={onChange}
                            disabled={isEdit.disabled || !researchDefenderOpenEnded}
                            sx={selectStyle}
                            autoComplete="off"
                          >
                            {researchDefenderList.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors?.researchDefenderOpenEndedId && (
                            <FormHelperText>
                              {errors?.researchDefenderOpenEndedId
                                ? errors.researchDefenderOpenEndedId.message
                                : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={3} ml={1}>
                    {researchDefenderOpenEnded && <Label value="Composite Score" required={true} />}
                    <Controller
                      name="compositeScore"
                      control={control}
                      rules={{
                        required: researchDefenderOpenEnded
                          ? "Composite Score is required!"
                          : false,
                      }}
                      render={({ field }) => (
                        <Stack mb={1}>
                          <MDInput
                            type="number"
                            required
                            {...field}
                            disabled={!researchDefenderOpenEnded}
                            sx={{ display: researchDefenderOpenEnded ? "" : "none" }}
                            fullWidth
                            variant="outlined"
                            error={
                              researchDefenderOpenEnded && errors?.compositeScore ? true : false
                            }
                            helperText={
                              errors?.compositeScore ? errors.compositeScore.message : null
                            }
                          />
                        </Stack>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      disabled={isEdit.disabled}
                      checked={minLoiIsActive}
                      onChange={(e, value) => {
                        setValue("minLoiIsActive", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Minimum LOI(mins)&nbsp;
                    </MDTypography>
                  }
                />
                <Grid item xs={3} ml={4}>
                  <Controller
                    name="minLoi"
                    control={control}
                    rules={{
                      required: watch("minLoiIsActive") ? "Min LoI (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <Stack mb={1}>
                        <MDInput
                          type="number"
                          required
                          {...field}
                          {...register("minLoi", {
                            min: minLoiIsActive && 1,
                            max: maxLoi,
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only integer value is allowed!",
                            },
                          })}
                          disabled={!minLoiIsActive}
                          sx={{ display: minLoiIsActive ? "" : "none" }}
                          fullWidth
                          variant="outlined"
                          error={watch("minLoiIsActive") && errors?.minLoi ? true : false}
                          helperText={
                            errors?.minLoi && errors.minLoi.message !== ""
                              ? errors.minLoi.message
                              : errors?.minLoi?.type === "min"
                              ? "Minimum value should be 1!"
                              : errors?.minLoi?.type === "max"
                              ? "Check Min Value should be smaller than max!"
                              : // : null
                              maxLoiIsActive && !minLoiIsActive
                              ? "Fill the value of min!"
                              : null
                          }
                        />
                      </Stack>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      disabled={isEdit.disabled}
                      checked={maxLoiIsActive}
                      onChange={(e, value) => {
                        setValue("maxLoiIsActive", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Maximum LOI(mins)&nbsp;
                    </MDTypography>
                  }
                />
                <Grid item xs={3} ml={4}>
                  <Controller
                    name="maxLoi"
                    control={control}
                    rules={{
                      required: watch("maxLoiIsActive") ? "Max LoI (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <Stack mb={1}>
                        <MDInput
                          type="number"
                          required
                          {...field}
                          {...register("maxLoi", {
                            min: maxLoiIsActive && minLoi,
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only integer value is allowed!",
                            },
                            validate: (value) => {
                              if (maxLoiIsActive && watch("minLoi") >= value) {
                                return "Max LOI should be greater than Min LOI!";
                              }
                              return true;
                            },
                          })}
                          disabled={!maxLoiIsActive}
                          sx={{ display: maxLoiIsActive ? "" : "none" }}
                          fullWidth
                          variant="outlined"
                          error={watch("maxLoiIsActive") && errors?.maxLoi ? true : false}
                          helperText={
                            errors?.maxLoi && errors.maxLoi.message !== ""
                              ? errors.maxLoi.message
                              : errors?.maxLoi?.type === "min"
                              ? maxLoiIsActive
                                ? "Check Max Value should be greater than min!"
                                : null
                              : null
                          }
                        />
                      </Stack>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={1.8} sx={{ marginLeft: "5px" }}>
              <Grid item xs={12} md={4}>
                {label("Template Name")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("templateName")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Client Name")}
                <Typography fontSize="14px" fontWeight="600">
                  {clients?.find((data) => data.id == watch("clientId"))?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("StudyType")}
                <Typography fontSize="14px" fontWeight="600">
                  {studyType?.find((data) => data.id == watch("studyTypeId"))?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                {label("Description", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("description")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} mt={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography fontWeight="600">Security Configuration</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Switch size="small" disabled={true} checked={uniqueUser} />}
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;User Duplication&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Switch size="small" disabled={true} checked={uniqueIp} />}
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;IP Duplication&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Switch size="small" disabled={true} checked={countryMismatch} />}
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Country Mismatch&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Switch size="small" disabled={true} checked={researchDefender} />}
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Research Defender&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              {watch("researchDefender") && (
                <Grid item xs={12} md={4} ml={4}>
                  {label("Research Defender Score")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("researchDefenderScore")}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Switch size="small" disabled={true} checked={researchDefenderOpenEnded} />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Research Defender Open Ended&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              {watch("researchDefenderOpenEnded") && (
                <Grid item xs={3} ml={4}>
                  {label("Research Defender Open Ended")}
                  <Typography fontSize="14px" fontWeight="600">
                    {
                      researchDefenderList?.find(
                        (value) => Number(value.id) === watch("researchDefenderOpenEndedId")
                      )?.name
                    }
                  </Typography>
                </Grid>
              )}
              {watch("researchDefenderOpenEnded") && (
                <Grid item xs={3}>
                  {label("Composite Score")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("compositeScore")}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Switch size="small" disabled={true} checked={minLoiIsActive} />}
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Minimum LOI(mins)&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              {watch("minLoiIsActive") && (
                <Grid item xs={12} md={4} ml={4}>
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("minLoi")}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<Switch size="small" disabled={true} checked={maxLoiIsActive} />}
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Maximum LOI(mins)&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
              {watch("maxLoiIsActive") && (
                <Grid item xs={12} md={4} ml={4}>
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("maxLoi")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default TemplateAdd;

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "projectName",
    numeric: false,
    disablePadding: true,
    label: "Project",
  },
  {
    id: "surveyName",
    numeric: true,
    disablePadding: false,
    label: "Survey",
  },
  {
    id: "totalCount",
    numeric: true,
    disablePadding: false,
    label: "Total Tokens",
  },
  {
    id: "matchedCount",
    numeric: true,
    disablePadding: false,
    label: "Matched",
  },
  {
    id: "unMatchedCount",
    numeric: true,
    disablePadding: false,
    label: "Unmatched",
  },
  {
    id: "expectedRevenue",
    numeric: true,
    disablePadding: false,
    label: "Expct. Revenue",
  },
  {
    id: "newRevenue",
    numeric: true,
    disablePadding: false,
    label: "New Revenue",
  },
  {
    id: "percentageRevenue",
    numeric: true,
    disablePadding: false,
    label: "% New/Expct.",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      background: "white",
      color: "#637381",
      fontSize: 14,
      fontWeight: "700",
      borderBottom: "2px solid #e8e8e8",
    },
  }));

  return (
    <TableHead sx={{ display: "table-header-group" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ReconcilationTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "1px solid #e8e8e8",
      fontSize: 13,
      fontWeight: "600",
    },
  }));
  return (
    <Box sx={{ width: "100%" }}>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {visibleRows.map((row, index) => {
            return (
              <TableRow hover key={index}>
                <StyledTableCell component="th" scope="row" padding="none">
                  {row.projectName}
                </StyledTableCell>
                <StyledTableCell align="left">{row.surveyName}</StyledTableCell>
                <StyledTableCell align="left">{row.totalCount}</StyledTableCell>
                <StyledTableCell align="left">{row.matchedCount}</StyledTableCell>
                <StyledTableCell align="left">{row.unMatchedCount}</StyledTableCell>
                <StyledTableCell align="left">${row.expectedRevenue}</StyledTableCell>
                <StyledTableCell align="left">${row.newRevenue}</StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ color: row.percentageRevenue > 20 ? "green" : "red" }}
                >
                  {row.percentageRevenue}%
                </StyledTableCell>
              </TableRow>
            );
          })}
          {visibleRows.length <= 0 && (
            <TableRow
              style={{
                height: 53,
              }}
            >
              <StyledTableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
}

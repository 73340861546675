import MDBox from "components/MDBox";
import DashboardLayout from "comps/LayoutContainers/DashboardLayout";
import DashboardNavbar from "comps/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Auth from "services/auth";
import PrincipalState from "services/principal.state";

const PrivateOutlet = ({ setWidth, width }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const authorize = async () => {
    try {
      await Auth.authorize();
      let data = PrincipalState.getIdentity();
      if (data) setCurrentUser(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    authorize();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout setWidth={setWidth} width={width}>
      <DashboardNavbar />
      <MDBox sx={{ pt: 2, pl: 2, pr: 2 }}>
        <Outlet context={{ currentUser, setCurrentUser }} />
      </MDBox>
    </DashboardLayout>
  );
};

export default PrivateOutlet;

import {
  FormControlLabel,
  Grid,
  Switch,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

const Deduplication = (props) => {
  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <MDBox>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          {label("Template List", true)}
          <Controller
            name="surveySecurityTemplateId"
            control={props.control}
            rules={{
              required: "Template name is required!",
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl
                fullWidth
                required
                error={props.errors?.surveySecurityTemplateId ? true : false}
                sx={{
                  label: {
                    color: "rgba(0, 0, 0, 0.25)",
                    fontWeight: "400",
                  },
                }}
                size="small"
              >
                <Select
                  value={value}
                  onChange={(e) => {
                    props.setValue("surveySecurityTemplateId", e.target.value);
                    props.setSelectedTemplateId(e.target.value);
                  }}
                  placeholder="Template list"
                  disabled={props.isEdit.disabled}
                  sx={selectStyle}
                >
                  <MenuItem disabled value={-1}>
                    <em>Custom</em>
                  </MenuItem>
                  {props.templates.map((value, i) => {
                    return (
                      <MenuItem value={value.id} key={i}>
                        {value.templateName}
                      </MenuItem>
                    );
                  })}
                </Select>
                {props.errors?.surveySecurityTemplateId && (
                  <FormHelperText>
                    {props.errors?.surveySecurityTemplateId
                      ? props.errors.surveySecurityTemplateId.message
                      : null}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <MDTypography fontSize="14px" fontWeight="medium" mb={1} mt={2}>
        Deduplication
      </MDTypography>
      <Grid container spacing={0} px={2}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={props.uniqueIp}
                onChange={(e, value) => {
                  props.setValue("uniqueIp", value);
                }}
                disabled={props.isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                IP duplication
              </MDTypography>
            }
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={props.uniqueUser}
                onChange={(e, value) => {
                  props.setValue("uniqueUser", value);
                }}
                disabled={props.isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                User duplication
              </MDTypography>
            }
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Deduplication;

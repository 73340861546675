import SurveyList from "layouts/survey/components/surveyList";
import { useOutletContext, useParams } from "react-router-dom";
import React from "react";

function SurveyProjectList() {
  let { id } = useParams();
  let { surveyStatusId } = useOutletContext();

  return (
    <>{surveyStatusId && id ? <SurveyList projectId={id} surveyStatusId={surveyStatusId} /> : ""}</>
  );
}

export default SurveyProjectList;

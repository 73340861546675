import { Backdrop, CircularProgress, Grid, Stack, Tooltip, Typography } from "@mui/material";
import ReconcilationList from "./components/reconcilationList";
import { useEffect, useState } from "react";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import BlueDownloadIcon from "assets/images/blueDownload.svg";
import ExcelJS from "exceljs";
import MDTypography from "components/MDTypography";

function GlobalReconciliation() {
  const [reconcilations, setReconciliations] = useState([]);
  const [loading, setLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  let { textColor } = colors;
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [search, setSearch] = useState("");

  const GetReconcilationList = async () => {
    setLoading(true);
    try {
      let data = await TLinkApi.get(
        `/global/reconciliation/title/list?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      );
      if (Array.isArray(data.data)) setReconciliations(data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    GetReconcilationList();
  }, [search, sortBy, sortOrder]);

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={1}
        sx={{ borderBottom: `2px solid ${textColor.main}`, width: "100%" }}
      >
        <MDTypography fontSize="25px" color="black" ml="1%">
          Global Reconciliation
        </MDTypography>
        <Stack direction="row" spacing={2}>
          <Tooltip title="Sample Global Reconciliation File" placement="top">
            <MDButton
              size="small"
              variant="contained"
              color="secondary"
              sx={{ alignItems: "center" }}
              startIcon={<img src={BlueDownloadIcon} height="20px" />}
              onClick={async () => {
                const tokens = [
                  "22e8728645234140abff54fbbd7632f9",
                  "609405b5e4b548109bc7fb004c8be675",
                ];
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet("Sheet1");
                tokens.forEach((token) => {
                  worksheet.addRow([token]);
                });
                const blob = await workbook.xlsx.writeBuffer();
                const link = document.createElement("a");
                link.href = URL.createObjectURL(new Blob([blob]));
                link.download = "SampleFile.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              <span> Sample.</span>
              <Typography sx={{ textTransform: "lowercase !important", fontSize: "13px" }}>
                xlsx
              </Typography>
            </MDButton>
          </Tooltip>
          {location.pathname !== "/globalReconciliation/new" ? (
            <Tooltip title="Add New Global Reconcilaition" placement="top">
              <MDButton
                variant="contained"
                size="small"
                color="info"
                onClick={() => {
                  navigate("new");
                }}
              >
                + New
              </MDButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MDBox
            style={{
              borderRight: "1px solid #E4E4E4",
              height: "calc(100vh - 170px)",
              overflowY: "auto",
            }}
          >
            <ReconcilationList
              reconcilations={reconcilations}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSearch={setSearch}
            />
          </MDBox>
        </Grid>
        <Grid item xs={9}>
          <MDBox
            style={{
              height: "calc(100vh - 170px)",
              overflowY: "auto",
            }}
          >
            {location.pathname === "/globalReconciliation" ? (
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={4}
                textAlign="center"
                fontSize="13px"
                fontWeight="500"
              >
                Please select a reconcilation to view its
                <br /> content here or create a new one
              </MDBox>
            ) : (
              <Outlet
                context={{
                  GetReconcilationList,
                }}
              />
            )}
          </MDBox>
        </Grid>
      </Grid>
    </div>
  );
}

export default GlobalReconciliation;

import { Backdrop, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import CollapsibleTable from "./SupplierTable";
import AddSupplier from "./AddSupplier";
import AllVendors from "hooks/listAllVendors/AllVendors";
import EditSupplier from "./EditSupplier";
import moment from "moment/moment";

function SupplierSurvey() {
  const { setIsEdit, isEdit, survey } = useOutletContext();
  const { vendors, vendorsMap, getVendors} = AllVendors();
  const [vendorsList, setVendorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [supplierData, setSupplierData] = useState([-1]);
  const [suppliers, setSuppliers] = useState(-1);
  const [supplierMode, setSupplierMode] = useState(-1);
  // const [timeline, setTimeline] = useState({ endDate: "", startDate: "" });

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsEdit({
        disabled: true,
        edit: false,
      });
    }
    // eslint-disable-next-line
  }, [id]);

  let getTableData = async () => {
    try {
      let data = await TLinkApi.post(
        `/survey/${id}/supplier/?supplierId=${suppliers}&supplierModeId=${supplierMode}`,
      );
      setSupplierData(data);
      console.log(data); 
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id !== undefined) getTableData();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    var ids = supplierData.map((el) => {
      return el.supplierId;
    });
    var data = vendors.filter((el) => !ids.includes(el.id)).map((val) => val);
    setVendorList(data);
    // eslint-disable-next-line
  }, [supplierData, vendors]);

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddSupplier
        vendorsList={vendorsList}
        setOpen={setOpen}
        open={open}
        getTableData={getTableData}
        getVendors={getVendors}
        survey={survey}
      />
      <EditSupplier
        vendorsList={vendorsList}
        setOpen={setEditOpen}
        open={editOpen}
        getTableData={getTableData}
        getVendors={getVendors}
        survey={survey}
        supplierTable={supplierData}
      />
      <CollapsibleTable
        supplierTable={supplierData}
        vendorsMap={vendorsMap}
        getTableData={getTableData}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        open={open}
        editOpen={editOpen}
        setOpen={setOpen}
        setEditOpen={setEditOpen}
        survey={survey}
      />
    </MDBox>
  );
}

export default SupplierSurvey;

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React from "react";

function AnswerText({ question, zipCodes, setZipCodeText }) {
  return (
    <MDBox p={2} fontSize="14px" width="100%">
      <Grid container spacing={4} ml={-1}>
        <Grid item xs={12} md={5}>
          <MDInput
            type="text"
            fullWidth
            value={zipCodes}
            onChange={(e) => {
              setZipCodeText(e.target.value);
            }}
            autoComplete="off"
            variant="outlined"
            label="Answer Text"
            error={question.text === "" ? true : false}
            helperText={question.text === "" ? "This field is Required" : ""}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AnswerText;

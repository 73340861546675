import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Stack, ThemeProvider, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import GetVendorsList from "hooks/vendorsList/GetVendorsList";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";

function VendorsList() {
  const navigate = useNavigate();
  const {
    vendors,
    totalLength,
    setPage,
    setSearch,
    search,
    isLoading,
    setEntriesPerPage,
    setSortBy,
    setSortOrder,
  } = GetVendorsList();
  const { setIsEdit, setVendor } = useOutletContext();
  const [sorting, setSorting] = useState([]);
  let { textColor } = colors;
  const tableTheme = useTableTheme();

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  useEffect(() => {
    setData(vendors);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [vendors, pagination, sorting]);

  let columns = [
    {
      accessorKey: "id",
      header: "Vendor Id",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 50,
    },
    {
      accessorKey: "name",
      header: "Vendor Name",
      size: 50,
    },
    {
      accessorKey: "phone",
      header: "Contact Number",
      size: 50,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 50,
    },
    {
      accessorKey: "isApi",
      header: "Type",
      Cell: ({ row }) => {
        const isApi = row.original.isApi;
        return isApi ? "Programmatic" : "Manual";
      },
    },
    {
      accessorKey: "vendorOnboardingStatus",
      header: "Onboarding Status",
      Cell: ({ row }) => {
        const vendorOnboardingStatus = row.original.vendorOnboardingStatus;
        const status = vendorOnboardingStatus ? "Complete" : "Pending";
        return (
          <Tooltip
            title={!vendorOnboardingStatus ? "Vendor Setup is incomplete" : ""}
            placement="bottom-start"
          >
            <div>{status}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: "Added on",
      size: 50,
      Cell: ({ cell }) => {
        return (
          <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
            <div>{moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}</div>
          </Tooltip>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/vendors/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 335px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
    renderTopToolbarCustomActions: () => {
      return <SearchInput setSearch={setSearch} search={search} />;
    },
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={0} width="100%">
        <Grid container spacing={2} pb={1} display="flex" flexDirection="row">
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography fontSize="25px" color="black" ml="1%">
              Vendors
            </MDTypography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
        </Grid>
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid
            px={0}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography color="black" ml="1%">
              Vendor List
            </MDTypography>
          </Grid>
          <Grid
            px={2}
            item
            xs={6}
            md={6}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <Tooltip title="Add New Vendor" placement="top">
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  navigate("/vendors/new");
                  setVendor({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + Vendor
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              table={table}
              style={{
                width: "100%",
              }}
            />
          </ThemeProvider>
        </Grid>
      </MDBox>
    </>
  );
}

export default VendorsList;

import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Tooltip, Grid, IconButton } from "@mui/material";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ThemeProvider } from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import moment from "moment";
import csvDownload from "json-to-csv-export";
import IosShareIcon from "@mui/icons-material/IosShare";
import colors from "assets/theme/base/colors";

function SreeningReport() {
  const tableTheme = useTableTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const { info } = colors;

  const postScreeningReport = async () => {
    setIsLoading(true);
    let payload = {};
    payload.startDate = "";
    payload.endDate = "";
    payload.surveyId = String(id);
    try {
      let res = await TLinkApi.post("/reports/screening/status", payload);
      setRows(res.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    postScreeningReport();
  }, []);

  useEffect(() => {
    if (rows.length > 0) {
      let newData = rows.map((row) => {
        return {
          SurveyId: row["Survey Id"],
          Token: row["Token"],
          StartDate: row["Start Date"],
          EndDate: row["End Date"],
          SupplierName: row["Supplier Name"],
          Status: row["Status"],
          SubStatus: row["Sub Status"],
          ClientName: row["Client Name"],
          CPI: row["CPI"],
          Cost: row["Cost"],
          IsRouted: row["Is Routed"],
          SurveyQualificationId: row["Survey Qualification Id"],
          SurveyAnswerIds: row["Survey Answer Ids"],
          SurveyAnswerTexts: row["Survey Answer Texts"],
          UserResponseQualificationId: row["User Response Qualification Id"],
          UserResponseAnswerIds: row["User Response Answer Ids"],
          UserResponseAnswerTexts: row["User Response Answer Texts"],
        };
      });
      setData(newData);
    }
  }, [rows]);

  const columns = [
    { accessorKey: "SurveyId", header: "Survey Id", size: 150, align: "center" },
    { accessorKey: "SupplierName", header: "Supplier Name", size: 150, align: "center" },
    { accessorKey: "Token", header: "Token", size: 150 },
    { accessorKey: "Status", header: "Status", size: 150, align: "center" },
    {
      accessorKey: "SubStatus",
      header: "Sub Status",
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        const SubStatus = cell.getValue();
        const truncatedName =
          SubStatus?.length > 10 ? SubStatus.substring(0, 10) + "..." : SubStatus;
        return (
          <Tooltip title={SubStatus} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    { accessorKey: "CPI", header: "CPI", size: 150, align: "center" },
    { accessorKey: "Cost", header: "VCpi", size: 150, align: "center" },
    {
      accessorKey: "UserResponseQualificationId",
      header: (
        <Tooltip title="User Response Qualification ID">
          <span>User Response Qualification Id</span>
        </Tooltip>
      ),
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        let id = cell.getValue();
        if (id[0]) {
          return <div>{id[0]}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      accessorKey: "UserResponseAnswerIds",
      header: (
        <Tooltip title="User Response Answer Ids">
          <span>User Response Answer Ids</span>
        </Tooltip>
      ),
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        let id = cell.getValue();
        if (id[0] !== null) {
          return <div>{id.toString()}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      accessorKey: "UserResponseAnswerTexts",
      header: (
        <Tooltip title="User Response Answer Texts">
          <span>User Response Answer Texts</span>
        </Tooltip>
      ),
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        let text = cell.getValue();
        if (text[0] !== null) {
          return <div>{text.toString()}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      accessorKey: "SurveyQualificationId",
      header: (
        <Tooltip title="Survey Qualification Id">
          <span>Survey Qualification Id</span>
        </Tooltip>
      ),
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        let id = cell.getValue();
        if (id[0]) {
          return <div>{id[0]}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      accessorKey: "SurveyAnswerIds",
      header: (
        <Tooltip title="Survey Answer Ids">
          <span>Survey Answer Ids</span>
        </Tooltip>
      ),
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        let id = cell.getValue();
        if (id[0] !== null) {
          return <div>{id.toString()}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      accessorKey: "SurveyAnswerTexts",
      header: (
        <Tooltip title="Survey Answer Texts">
          <span>Survey Answer Texts</span>
        </Tooltip>
      ),
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        let text = cell.getValue();
        if (text[0] !== null) {
          return <div>{text.toString()}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      accessorKey: "StartDate",
      header: "Start Date",
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return <div>NA</div>;
        } else {
          const createdAtTime = moment(cell.getValue());
          const formattedDateTime = createdAtTime.format("Do MMM YYYY, h:mm:ss a");
          const truncatedName =
            formattedDateTime?.length > 8
              ? formattedDateTime?.substring(0, 8) + "..."
              : formattedDateTime;
          return data ? (
            <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
              <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
            </Tooltip>
          ) : null;
        }
      },
    },
    {
      accessorKey: "EndDate",
      header: "End Date",
      size: 150,
      align: "center",
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return <div>NA</div>;
        } else {
          const lastCompletedTime = moment(cell.getValue());
          const formattedDateTime = lastCompletedTime.format("Do MMM YYYY, h:mm:ss a");
          const truncatedName =
            formattedDateTime?.length > 8
              ? formattedDateTime?.substring(0, 8) + "..."
              : formattedDateTime;
          return data ? (
            <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
              <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
            </Tooltip>
          ) : null;
        }
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 400px)",
        overflowY: "auto",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderTopToolbarCustomActions: () => {},
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={2} display="flex" alignItems="center" justifyContent="flex-end" mr={2}>
        <Tooltip title="Export" placement="top">
          <IconButton
            variant="contained"
            color="white"
            sx={{
              backgroundColor: `${info.main} !important`,
              borderRadius: "4px !important",
            }}
            size="small"
            onClick={() => {
              const dataToConvert = {
                data: rows,
                filename: "Screening Report",
                delimiter: ",",
              };
              csvDownload(dataToConvert);
            }}
          >
            <IosShareIcon sx={{ fontSize: "1.1rem", color: "white" }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </>
  );
}
export default SreeningReport;

import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const Variables = ({ data, GetVariableData }) => {
  let { enqueueSnackbar } = useSnackbar();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: {
      variable_1: "",
      variable_2: "",
      variable_3: "",
      variable_4: "",
      incomingAutoPunch: false,
      supplierQualificationParam: false,
    },
  });

  const label = (value, required) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {required ? <span>* &nbsp;</span> : ""}
      </MDBox>
    );
  };

  let { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data]);

  const getData = async () => {
    if (data !== null) {
      if (data.variable_1) {
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
      }
      setValue("variable_1", data.variable_1);
      setValue("variable_2", data.variable_2);
      setValue("variable_3", data.variable_3);
      setValue("variable_4", data.variable_4);
      setValue("incomingAutoPunch", data.incomingAutoPunch);
      setValue("supplierQualificationParam", data.supplierQualificationParam);
    } else {
      setAddData(true);
      setIsEdit({
        disabled: false,
        edit: false,
      });
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };
    payload.vendorId = id;
    try {
      if (addData !== true) {
        let result = await TLinkApi.put(`/vendors/details/variables/${id}`, payload);
        await GetVariableData(true);
        setIsLoading(false);

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/vendors/variables", payload);
        await GetVariableData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <MDBox pb={1} display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Variables</Typography>
          <MDBox>
            {isEdit.disabled === true ? (
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={async () => {
                    if (!addData) {
                      reset({
                        variable_1: "",
                        variable_2: "",
                        variable_3: "",
                        variable_4: "",
                        incomingAutoPunch: false,
                        supplierQualificationParam: false,
                      });
                      await GetVariableData(true);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    } else {
                      reset({
                        variable_1: "",
                        variable_2: "",
                        variable_3: "",
                        variable_4: "",
                        incomingAutoPunch: false,
                        supplierQualificationParam: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  save
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        {isEdit.edit || addData ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {label("Variable 1", true)}
              <Controller
                name="variable_1"
                control={control}
                rules={{
                  required: "Variable 1 is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Variable 1"
                    {...register("variable_1", {
                      validate: (value) => {
                        let regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g ;
                        if(value.match(regex)){
                          return("URL is not supported!")  
                      }
                      return true;
                    }})}
                    error={errors?.variable_1 ? true : false}
                    helperText={errors?.variable_1 ? errors.variable_1.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {label("Variable 2")}
              <Controller
                name="variable_2"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    disabled={isEdit.disabled}
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Variable 2"
                    {...register("variable_2", {
                      validate: (value) => {
                        let regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g ;
                        if(value.match(regex)){
                          return("URL is not supported!")  
                      }
                      return true;
                    }})}
                    error={errors?.variable_2 ? true : false}
                    helperText={errors?.variable_2 ? errors.variable_2.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {label("Variable 3")}
              <Controller
                name="variable_3"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    type="text"
                    disabled={isEdit.disabled}
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Variable 3"
                    {...register("variable_3", {
                      validate: (value) => {
                        let regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g ;
                        if(value.match(regex)){
                          return("URL is not supported!")  
                      }
                      return true;
                    }})}
                    error={errors?.variable_3 ? true : false}
                    helperText={errors?.variable_3 ? errors.variable_3.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {label("Variable 4")}
              <Controller
                name="variable_4"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    type="text"
                    disabled={isEdit.disabled}
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Variable 4"
                    {...register("variable_4", {
                      validate: (value) => {
                        let regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g ;
                        if(value.match(regex)){
                          return("URL is not supported!")  
                      }
                      return true;
                    }})}
                    error={errors?.variable_4 ? true : false}
                    helperText={errors?.variable_4 ? errors.variable_4.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={<Checkbox checked={watch("incomingAutoPunch")} />}
                  label="Incoming Autopunch"
                  onChange={(e) => {
                    setValue("incomingAutoPunch", e.target.checked);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={watch("supplierQualificationParam")} />}
                  label="Supplier Qualification Parameter"
                  onChange={(e) => {
                    setValue("supplierQualificationParam", e.target.checked);
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Tooltip title="Vendor Primary Variable is allowed in {} format" placement="top-start">
                {label("Variable 1", true)}
              </Tooltip>
              <Typography fontSize="14px" fontWeight="600">
                {watch("variable_1") ? watch("variable_1") : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Tooltip title="Vendor User Id variable" placement="top-start">
                {label("Variable 2")}
              </Tooltip>
              <Typography fontSize="14px" fontWeight="600">
                {watch("variable_2") ? watch("variable_2") : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Tooltip title="Additional Vendor Variable" placement="top-start">
                {label("Variable 3")}
              </Tooltip>
              <Typography fontSize="14px" fontWeight="600">
                {watch("variable_3") ? watch("variable_3") : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Tooltip title="Additional Vendor Variable" placement="top-start">
                {label("Variable 4")}
              </Tooltip>
              <Typography fontSize="14px" fontWeight="600">
                {watch("variable_4") ? watch("variable_4") : "NA"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={<Checkbox disabled checked={watch("incomingAutoPunch")} />}
                  label="Incoming Autopunch"
                />
                <FormControlLabel
                  control={<Checkbox disabled checked={watch("supplierQualificationParam")} />}
                  label="Supplier Qualification Parameter"
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default Variables;

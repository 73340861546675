import { Checkbox, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const QuotaQuestionBox = ({ question, updateQuestion, index, quotaQuestions, setQuotaError }) => {
  useEffect(() => {
    if (quotaQuestions[index].answerId.length <= 0) {
      setQuotaError(true);
    } else {
      setQuotaError(false);
    }
    // eslint-disable-next-line
  }, [quotaQuestions]);
  return (
    <MDBox fontSize="15px" mt={2}>
      <Grid container spacing={1} style={{ paddingLeft: "5px" }}>
        <Grid item xs={10} md={12}>
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            flexWrap="wrap"
          >
            <MDTypography variant="button" color="dark" sx={{ mb: 1, fontWeight: 600 }}>
              {question?.question?.questionText}&nbsp;
            </MDTypography>
            <IconButton
              sx={{ marginTop: "0.2rem" }}
              onClick={() => {
                let data = [...quotaQuestions];
                data.splice(index, 1);
                updateQuestion("quotaQuestions", data);
              }}
            >
              <DeleteOutlineIcon fontSize="small" color="error" />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {question?.question?.answers
          .filter((option, i) => {
            if (question?.selectedAnswers?.includes(parseInt(option.id))) {
              return true;
            } else {
              return false;
            }
          })
          .map((option, i) => (
            <Grid item xs={10} md={3} lg={3} ml={2} key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={question.answerId.includes(parseInt(option.id))}
                    onChange={(e, value) => {
                      let data;
                      if (!e.target.checked) {
                        data = [...quotaQuestions];
                        data[index].answerId = data[index].answerId.filter(
                          (answer) => answer !== parseInt(option.id)
                        );
                      } else {
                        data = [...quotaQuestions];
                        data[index].answerId.push(parseInt(option.id));
                      }
                      updateQuestion("quotaQuestions", data);
                    }}
                  />
                }
                label={
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;{option?.qualificationAnswerDesc}&nbsp;
                  </MDTypography>
                }
              />
            </Grid>
          ))}
      </Grid>
      {quotaQuestions[index].answerId.length <= 0 ? (
        <MDBox color="error" px={2}>
          Please select at least one field
        </MDBox>
      ) : (
        ""
      )}
    </MDBox>
  );
};

export default QuotaQuestionBox;

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  Backdrop,
  CircularProgress,
  Icon,
  IconButton,
  Typography,
  Stack,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import { Controller, useForm } from "react-hook-form";

const Status = [
  {
    name: "Active",
    value: 1,
  },
  {
    name: "Inactive",
    value: 2,
  },
];

const Programmatic = [
  {
    name: "Programmatic",
    value: 1,
  },
  {
    name: "Manual",
    value: 2,
  },
];
function VendorDetails() {
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  let { vendor, isEdit, setIsEdit, getData } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      isActive: null,
      isApi: null,
      building: "",
      street: "",
      state: "",
      country: "",
      city: "",
      zipCode: "",
    },
  });

  useEffect(() => {
    if (Object.keys(vendor).length > 0) {
      setValue("email", vendor.email);
      setValue("phone", vendor.phone);
      setValue("name", vendor.name);
      setValue("isActive", vendor.isActive ? 1 : 2);
      setValue("isApi", vendor.isApi ? 1 : 2);
      setValue("building", vendor.building);
      setValue("street", vendor.street);
      setValue("country", vendor.country);
      setValue("city", vendor.city);
      setValue("zipCode", vendor.zipCode);
      setValue("state", vendor.state);
    }
  }, [vendor]);

  let { id } = useParams();

  const label = (value, required) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {required ? <span>* &nbsp;</span> : ""}
      </MDBox>
    );
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };

    payload.vendorId = id;
    payload.isActive = data.isActive === 1 ? true : false;
    payload.isApi = data.isApi === 1 ? true : false;
    setIsLoading(true);

    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/vendors/${id}`, payload);
        await getData();
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/vendors/${id}/setUp/edit`);
      } else {
        data.isActive = data.isActive === 1 ? true : false;
        data.isApi = data.isApi === 1 ? true : false;
        let result = await TLinkApi.post("/vendors", data);
        await getData();
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: true,
          edit: false,
        });
        navigate(`/vendors/${result.data.id}/setUp/edit`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <MDBox sx={{ height: "calc(100vh - 252px)", overflowY: "auto" }}>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <MDBox p={2} px={20} style={{ width: "100%" }} mt={2}>
          <Stack
            justifyContent={"space-between"}
            alignItems="flex-end"
            sx={{ width: "100%" }}
            mb={1}
            direction={isEdit.edit ? "row" : ""}
          >
            <Stack direction="row" spacing={2}>
              <Typography fontWeight="600">{watch("name")}</Typography>
            </Stack>

            {isEdit.disabled !== false ? (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  navigate(`/vendors/${id}/edit`);
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={async () => {
                    if (window.location.pathname === "/vendors/new") {
                      navigate(`/vendors`);
                    } else {
                      await getData();
                      navigate(`/vendors/${id}`);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </Stack>

          {isEdit.edit || window.location.pathname === "/vendors/new" ? (
            <>
              <Grid container pt={3} spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    color="dark"
                    style={{ borderBottom: "1px solid #D9D9D9" }}
                  >
                    {"Basic Details"}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Vendor Name", true)}
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: "Name is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        {...register("name", {})}
                        variant="outlined"
                        placeholder="Name"
                        disabled={isEdit.disabled}
                        error={errors?.name ? true : false}
                        helperText={errors?.name ? errors.name.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Email Address", true)}
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="email"
                        {...register("email", {
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Entered value does not match email format",
                          },
                        })}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="email"
                        disabled={isEdit.disabled}
                        error={errors?.email ? true : false}
                        helperText={errors?.email ? errors.email.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Contact Number", true)}
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: "Phone is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="number"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Phone"
                        disabled={isEdit.disabled}
                        error={errors?.phone ? true : false}
                        helperText={errors?.phone ? errors.phone.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Status", true)}
                  <Controller
                    name="isActive"
                    control={control}
                    rules={{
                      required: "Status is required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        required
                        error={errors?.isActive ? true : false}
                        sx={{
                          label: {
                            color: "rgba(0, 0, 0, 0.25)",
                            fontWeight: "400",
                          },
                        }}
                        size="small"
                      >
                        <Select
                          value={value}
                          onChange={onChange}
                          sx={selectStyle}
                          MenuProps={{
                            style: {
                              width: "150px",
                              height: "250px",
                            },
                          }}
                        >
                          {Status?.map((value, i) => {
                            return (
                              <MenuItem value={value.value} key={i}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {errors?.isActive && (
                          <FormHelperText>
                            {errors?.isActive ? errors.isActive.message : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Programmatic", true)}
                  <Controller
                    name="isApi"
                    control={control}
                    rules={{
                      required: "Programmatic is required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        required
                        error={errors?.isApi ? true : false}
                        sx={{
                          label: {
                            color: "rgba(0, 0, 0, 0.25)",
                            fontWeight: "400",
                          },
                        }}
                        size="small"
                      >
                        <Select
                          value={value}
                          onChange={onChange}
                          sx={selectStyle}
                          MenuProps={{
                            style: {
                              width: "150px",
                              height: "250px",
                            },
                          }}
                        >
                          {Programmatic?.map((value, i) => {
                            return (
                              <MenuItem value={value.value} key={i}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {errors?.isApi && (
                          <FormHelperText>
                            {errors?.isApi ? errors.isApi.message : null}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container pt={3} spacing={3}>
                <Grid item xs={12} md={12}>
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    color="dark"
                    style={{ borderBottom: "1px solid #D9D9D9" }}
                  >
                    {"Address"}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Building/Apartment")}
                  <Controller
                    name="building"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Building"
                        disabled={isEdit.disabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Street")}
                  <Controller
                    name="street"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Street"
                        disabled={isEdit.disabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("City")}
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      // required: "City is required!",
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        {...register("city", {
                          pattern: {
                            value: /^[a-zA-Z ]*$/,
                            message: "Please Enter Valid Character",
                          },
                        })}
                        placeholder="City"
                        disabled={isEdit.disabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Province/State")}
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        {...register("state", {
                          pattern: {
                            value: /^[a-zA-Z ]*$/,
                            message: "Please Enter Valid Character",
                          },
                        })}
                        variant="outlined"
                        placeholder="State"
                        disabled={isEdit.disabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Country")}
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        {...register("country", {
                          pattern: {
                            value: /^[a-zA-Z ]*$/,
                            message: "Please Enter Valid Character",
                          },
                        })}
                        variant="outlined"
                        placeholder="Country"
                        disabled={isEdit.disabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {label("Zip Code")}
                  <Controller
                    name="zipCode"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        type="number"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Zip Code"
                        disabled={isEdit.disabled}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={1.8}>
                <Grid item xs={12} md={12}>
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    color="dark"
                    style={{ borderBottom: "1px solid #D9D9D9" }}
                  >
                    {"Basic Details"}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Vendor Name", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("name")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Email Address", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("email")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Contact Number", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("phone")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Status", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {
                      Status?.find((value) => Number(value.value) === Number(watch("isActive")))
                        ?.name
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Programmatic", true)}
                  <Typography fontSize="14px" fontWeight="600">
                    {
                      Programmatic?.find((value) => Number(value.value) === Number(watch("isApi")))
                        ?.name
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    color="dark"
                    style={{ borderBottom: "1px solid #D9D9D9" }}
                  >
                    {"Address"}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Building/Apartment")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("building") ? watch("building") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Street")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("street") ? watch("street") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("City")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("city") ? watch("city") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Province/State")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("state") ? watch("state") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Country")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("country") ? watch("country") : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {label("Zip Code")}
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("zipCode") ? watch("zipCode") : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </MDBox>
      </form>
    </MDBox>
  );
}

export default VendorDetails;

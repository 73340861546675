import { Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

function QuotaAnswerText({ question, updateQuestion, i, quotaQuestions, setQuotaError }) {
  useEffect(() => {
    if (quotaQuestions[i]?.text === "") {
      setQuotaError(true);
    } else {
      setQuotaError(false);
    }
    // eslint-disable-next-line
  }, [quotaQuestions]);

  return (
    <MDBox fontSize="15px" mt={2}>
      <Grid container spacing={1} style={{ paddingLeft: "5px" }}>
        <Grid item xs={10} md={12}>
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            flexWrap="wrap"
          >
            <MDTypography variant="button" color="dark" sx={{ mb: 1, fontWeight: 600 }}>
              {question?.question?.questionText}&nbsp;
            </MDTypography>
            <IconButton
              sx={{ marginTop: "0.2rem" }}
              onClick={() => {
                let data = [...quotaQuestions];
                data.splice(i, 1);
                updateQuestion("quotaQuestions", data);
              }}
            >
              <DeleteOutlineIcon fontSize="small" color="error" />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={5} ml={1}>
          <MDInput
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="Answer Text"
            fullWidth
            required
            value={question?.text}
            onChange={(e) => {
              let data = [...quotaQuestions];
              data[i].text = e.target.value;
              updateQuestion("quotaQuestions", data);
            }}
            error={quotaQuestions[i]?.text === "" ? true : false}
            helperText={quotaQuestions[i]?.text === "" ? "This field is Required" : ""}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QuotaAnswerText;

import { EditOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const LanguageConfig = ({ language, setIsLoading }) => {
  const { grey } = colors;
  const { id } = useParams();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [languageConfig, setLanguageConfig] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [search, setSearch] = useState("");
  let { enqueueSnackbar } = useSnackbar();

  const GetLanguageConfig = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`vendors/${id}/languages`);
      if (data.data && data.data.length > 0) {
        setLanguageConfig(data.data.map((val) => Number(val.languageId)));
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
        setIsEdit({
          disabled: false,
          edit: false,
        });
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetLanguageConfig();
    }
  }, [id]);

  const save = async () => {
    try {
      if (addData) {
        let payload = {
          vendorId: id,
          languageIds: languageConfig,
        };
        await TLinkApi.post(`vendors/languages`, payload);
      } else {
        let payload = {
          languageIds: languageConfig,
        };
        await TLinkApi.put(`vendors/${id}/languages`, payload);
      }
      await GetLanguageConfig();
      enqueueSnackbar("Successfully saved", {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search.length > 2) {
      let serachData = language.filter((val) =>
        val.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );
      setLanguages(serachData);
    } else {
      setLanguages(language);
    }
  }, [search, language]);

  useEffect(() => {
    let payload = language.map((lang) => Number(lang.id));
    setLanguageConfig(payload);
  }, [language]);

  return (
    <MDBox
      sx={{
        height: "calc(100vh - 300px)",
        overflowY: "auto",
        borderRight: `1px solid ${grey[400]}`,
        paddingInline: "1rem",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        pb="0.5rem"
        sx={{ borderBottom: `1px solid ${grey[400]}`, background: "white", zIndex: "2" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="v6" fontWeight="600">
            Markets
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search Markets"
            fullWidth
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            size="small"
            sx={{
              border: "none",
              "& fieldset": { border: "none" },
              width: "200px",
            }}
            InputProps={{
              style: {
                height: "1.8rem",
              },
            }}
          />
        </Stack>

        {isEdit.disabled ? (
          <MDButton
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<EditOutlined />}
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
            }}
          >
            Edit
          </MDButton>
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                if (addData) {
                  setLanguageConfig([]);
                } else {
                  GetLanguageConfig();
                }
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              disabled={languageConfig.length <= 0}
              size="small"
              onClick={() => {
                save();
              }}
            >
              Save
            </MDButton>
          </Stack>
        )}
      </Stack>
      <FormGroup>
        {isEdit.edit || addData ? (
          <MDBox
            sx={{
              padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
              borderBottom: `1px solid ${grey[400]}`,
              display: language.length === 0 || search !== "" ? "none" : "",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={languageConfig.length !== 0 && language.length === languageConfig.length}
                  color="secondary"
                  onChange={(e) => {
                    let payload = [];
                    if (e.target.checked) {
                      payload = language.map((lang) => Number(lang.id));
                    } else {
                      payload = [];
                    }
                    setLanguageConfig(payload);
                  }}
                />
              }
              label={"Select All"}
            />
          </MDBox>
        ) : (
          ""
        )}
        {languages?.length > 0 ? (
          languages
            .filter(
              (lang) =>
                (isEdit.disabled && languageConfig.includes(Number(lang.id))) ||
                isEdit.edit ||
                addData
            )
            .map((lang, i) => (
              <MDBox
                sx={{
                  padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                  borderBottom: `1px solid ${grey[400]}`,
                }}
                key={i}
              >
                {isEdit.edit || addData ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={languageConfig.includes(Number(lang.id))}
                        color="secondary"
                        onChange={(e) => {
                          let payload = [...languageConfig];
                          if (e.target.checked) {
                            payload.push(Number(lang.id));
                          } else {
                            let data = payload.filter((val) => val !== Number(lang.id));
                            payload = data;
                          }
                          setLanguageConfig(payload);
                        }}
                      />
                    }
                    label={lang.name}
                  />
                ) : (
                  <Typography fontSize="0.875rem" fontWeight="700">
                    {lang.name}
                  </Typography>
                )}
              </MDBox>
            ))
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
            <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
              No language here !
            </Typography>
          </Stack>
        )}
      </FormGroup>
    </MDBox>
  );
};

export default LanguageConfig;

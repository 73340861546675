import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Backdrop, Button, CircularProgress, Typography, Stack } from "@mui/material";
import "../../global.css";
import { useEffect, useState } from "react";
import DashboardCard from "./components/CardCompotents";
import BlueIcon from "assets/images/icons/Icon_Blue.png";
import Dollar from "assets/images/icons/Icon_Dollar.png";
import Graph from "assets/images/icons/Icon_Graph.png";
import Green from "assets/images/icons/Icon_Green.png";
import TopClients from "./components/TopClients";
import TopSupplier from "./components/TopSupplier";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import TLinkApi from "services/tlink.api";
import TopSurvey from "./components/TopSurvey";
import MDInput from "components/MDInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDTypography from "components/MDTypography";
import Colors from "../../assets/theme/base/colors";

function Dashboard() {
  const [tab, setTab] = useState("survey");
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { textColor } = Colors;

  const ranges = {
    Today: [moment().subtract(1, "days").startOf("day"), moment().subtract(0, "days").endOf("day")],
    "Last Week": [
      moment().subtract(7, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Month": [
      moment().subtract(30, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Year": [
      moment().subtract(365, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
  };

  const [timeline, setTimeLine] = useState({
    endDate: moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD"),
    startDate: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (Object.keys(timeline).length > 0) {
      getCardData();
    }
    // eslint-disable-next-line
  }, [timeline]);

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };

  let getCardData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("/dashboards/by-range", timeline);

      setCardData(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <MDBox pb={3} pt={0}>
      <Grid container spacing={1} pb={3}>
        <Grid
          item
          xs={12}
          md={9}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
        ></Grid>

        <Grid
          item
          xs={12}
          md={9}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          position="relative"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-end", md: "center" },
          }}
        >
          <Stack direction="row" spacing={2} pb={1}>
            <Typography fontSize="25px" fontWeight="500">
              Dashboard
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} pl={3} style={{ color: "#6E9633" }}>
          <DateRangePicker
            initialSettings={{
              endDate: moment().subtract(0, "days").endOf("day"),
              startDate: moment().subtract(1, "days").startOf("day"),
              ranges: ranges,
            }}
            alwaysShowCalendars={false}
            onCallback={(start, end, label) => {
              setTimeLine({
                startDate: moment(start._d).format("YYYY-MM-DD"),
                endDate: moment(end._d).format("YYYY-MM-DD"),
              });
            }}
          >
            <MDBox position="relative">
              <MDInput
                ml={2}
                color="text"
                inputProps={{
                  style: {
                    height: "20px",
                    border: "2px solid #D9D9D9",
                  },
                }}
                size="large"
                fullWidth
                value={
                  moment(timeline.startDate).format("LL") +
                  "  -  " +
                  moment(timeline.endDate).format("LL")
                }
              />
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{
                  fontSize: 20,
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "12px",
                }}
              />
            </MDBox>
          </DateRangePicker>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <DashboardCard
            Colour={"#C2EBFB"}
            Name={"Entrants"}
            nameColour={"#004E6C"}
            Icon={BlueIcon}
            Status={"Starts"}
            statusColour={"#595959"}
            Value={
              cardData?.entrants?.starts === undefined ? 0 : formatCash(cardData?.entrants?.starts)
            }
            Maths={"EPC"}
            MathsValue={
              cardData?.entrants?.epc === undefined
                ? 0
                : parseFloat(cardData?.entrants?.epc).toFixed(2)
            }
            hoverBackground="#91DCF8"
            elipsBackground="radial-gradient(50% 50% at 50% 50%, #AEE1F4 0%, rgba(220, 243, 252, 0) 100%)"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              Colour={"#C9DDAB"}
              Name={"Completes"}
              nameColour={"#3A4F1B"}
              Icon={Green}
              Status={"Complete"}
              statusColour={"#595959"}
              Value={
                cardData?.completes?.complete === undefined
                  ? 0
                  : formatCash(cardData?.completes?.complete)
              }
              Maths={"Conversion (%)"}
              MathsValue={
                cardData?.completes?.conversion === undefined
                  ? 0
                  : parseFloat(cardData?.completes?.conversion).toFixed(2)
              }
              hoverBackground="#B2D97A"
              elipsBackground="radial-gradient(50% 50% at 50% 50%, #CCEF9A 0%, rgba(212, 238, 175, 0) 100%)"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              Colour={"#FFCCC7"}
              Name={"Revenue"}
              nameColour={"#5C0011"}
              Icon={Graph}
              Status={"Revenue ($)"}
              statusColour={"#595959"}
              Value={
                cardData?.revenue?.revenue === undefined
                  ? 0
                  : formatCash(parseFloat(cardData?.revenue?.revenue).toFixed(2))
              }
              Maths={"CPI (%)"}
              MathsValue={
                cardData?.revenue?.cpi === undefined
                  ? 0
                  : parseFloat(cardData?.revenue?.cpi).toFixed(2)
              }
              hoverBackground="#FFA39E"
              elipsBackground="radial-gradient(50% 50% at 50% 50%, #FFC3BF 0%, rgba(255, 215, 211, 0) 100%)"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              Colour={"#ADC6FF"}
              Name={"Profit"}
              nameColour={"#030852"}
              Icon={Dollar}
              Status={"Profit ($)"}
              statusColour={"#595959"}
              Value={
                cardData?.profit?.profit === undefined
                  ? 0
                  : formatCash(parseFloat(cardData?.profit?.profit).toFixed(2))
              }
              Maths={"Margin (%)"}
              MathsValue={
                cardData?.profit?.margin === undefined
                  ? 0
                  : parseFloat(cardData?.profit?.margin).toFixed(2)
              }
              hoverBackground="#85A5FF"
              elipsBackground="radial-gradient(50% 50% at 50% 50%, #CFDEFF 0%, rgba(230, 237, 255, 0) 100%)"
            />
          </MDBox>
        </Grid>
      </Grid>
      <hr
        style={{
          marginTop: "10px",
          border: `1px solid ${textColor.focus}`,
          width: "100%",
        }}
      />

      <MDBox position="relative">
        <MDBox display="flex" alignItems="center" flexDirection="row" mt={2}>
          <Button style={{ padding: "2px" }}>
            <MDBox
              className="tab"
              onClick={() => setTab("survey")}
              bgColor={tab === "survey" ? "info" : ""}
              style={{
                color: tab === "survey" && "white",
                boxShadow:
                  "inset -1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0, inset 1px 0px 0px #f0f0f0",
                cursor: "pointer",
              }}
            >
              Top Survey
            </MDBox>
          </Button>
          <Button style={{ padding: "2px" }}>
            <MDBox
              className="tab"
              onClick={() => setTab("client")}
              bgColor={tab === "client" ? "info" : ""}
              style={{
                color: tab === "client" && "white",
                boxShadow:
                  "inset -1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0, inset 1px 0px 0px #f0f0f0",
                cursor: "pointer",
              }}
            >
              Top Client
            </MDBox>
          </Button>
          <Button style={{ padding: "2px" }}>
            <MDBox
              className="tab"
              onClick={() => setTab("supplier")}
              bgColor={tab === "supplier" ? "info" : ""}
              style={{
                color: tab === "supplier" && "white",
                boxShadow:
                  "inset -1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0, inset 1px 0px 0px #f0f0f0",
                cursor: "pointer",
              }}
            >
              Top Supplier
            </MDBox>
          </Button>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            position="absolute"
            right="25px"
            top="10px"
          >
            <MDTypography style={{ fontSize: "14px", color: "#8c8c8c" }}>
              Updated 2 days ago
            </MDTypography>
          </MDBox>
        </MDBox>

        <hr style={{ border: "1px solid #F0F0F0" }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {tab === "survey" && <TopSurvey />}
            {tab === "client" && <TopClients />}
            {tab === "supplier" && <TopSupplier />}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
export default Dashboard;

import React, { useId } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { Backdrop, CircularProgress, Grid, Stack, Box, Tooltip } from "@mui/material";
import GetMemberList from "hooks/MemberList/GetMemberList";
import { MaterialReactTable, useMaterialReactTable, MRT_Table } from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { ThemeProvider } from "@mui/material";
import { Icon, IconButton } from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

function MemberTable({ open, setOpen }) {
  let { id } = useParams();
  const [roleList, setRoleList] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const [basicData, setBasicData] = useState({});
  const tableTheme = useTableTheme();
  let { enqueueSnackbar } = useSnackbar();
  const [onload, setOnload] = useState(false);

  const {
    members,
    getMembers,
    isLoading,
    setIsLoading,
    setPage,
    setSortOrder,
    setSortBy,
    setLimit,
  } = GetMemberList({
    id,
  });

  async function GetRoleList() {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`/masters/role`);
      setRolesData(data.data);
      let result = data.data;
      result.map((res) => setRoleList((roles) => [...roles, res.name]));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  useEffect(() => {
    if (id) {
      GetRoleList();
    }
  }, [id]);

  const UpdateRole = async (data, userId) => {
    console.log(data);
    let payload = {
      projectRoleId: data,
    };
    setIsLoading(true);
    try {
      let result = await TLinkApi.put(`/project/teams/${id}/${userId}`, payload);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const DeleteMember = async (userId) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.del(`/project/teams/${id}/${userId}`);
      getMembers();
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setData(members);
  }, [members]);

  useEffect(() => {
    if (open === false && onload) {
      getMembers();
    } else {
      setOnload(true);
    }
  }, [open]);

  function getInitials(str) {
    let names = str.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }

  const getBasicDetailsData = async () => {
    try {
      let result = await TLinkApi.get(`/projects/${id}`);
      setBasicData(result.data);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getBasicDetailsData();
  }, [id]);

  let columns = [
    {
      accessorKey: "userName",
      header: "Team Member",
      enableSorting: true,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
        sx: { pl: "120px" },
      },
      muiTableBodyCellProps: {
        align: "left",
        sx: { pl: "75px" },
      },
      Cell: ({ cell }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            height: "15px",
          }}
        >
          <div
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              width: "2rem",
              height: "2rem",
              borderRadius: "50%",
              background: stringToHslColor(getInitials(cell.getValue()), 30, 50),
              fontSize: "1rem",
              color: "#fff",
              textAlign: "center",
              lineHeight: "2rem",
            }}
          >
            {getInitials(cell.getValue())}
          </div>
          <span>{cell.getValue()}</span>
        </Box>
      ),
    },
    {
      accessorKey: "email",
      header: "Email ID",
      enableSorting: true,
      enableEditing: false,
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "roleName",
      header: "Role",
      enableSorting: false,
      size: 50,
      headerAlign: "center",
      editVariant: "select",
      editSelectOptions: roleList,
      muiEditTextFieldProps: ({ row }) => ({
        disabled:
          row.original.userId === parseInt(basicData?.projectManager) ||
          row.original.userId === parseInt(basicData?.updatedBy) ||
          row.original.userId === parseInt(basicData?.currentUser),
        select: !(
          row.original.userId === parseInt(basicData?.projectManager) ||
          row.original.userId === parseInt(basicData?.updatedBy) ||
          row.original.userId === parseInt(basicData?.currentUser)
        ),
        onChange: (event) => {
          let data = rolesData?.find((data) => data.name === event.target.value)?.id;
          UpdateRole(data, row.original.userId);
        },
      }),
    },
    {
      accessorKey: "isActive",
      header: "Action",
      enableSorting: false,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      headerAlign: "center",
      Cell: ({ row }) => (
        <Stack display={"flex"} justifyContent={"center"} alignItems={"center"} spacing={2}>
          <Tooltip title="Delete Member" placement="top">
            <IconButton
              variant="outlined"
              color="dark"
              size="small"
              disabled={
                row.original.userId === parseInt(basicData?.projectManager) ||
                row.original.userId === parseInt(basicData?.updatedBy) ||
                row.original.userId === parseInt(basicData?.currentUser)
              }
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (
                  !(
                    row.original.userId === parseInt(basicData?.projectManager) ||
                    row.original.userId === parseInt(basicData?.updatedBy) ||
                    row.original.userId === parseInt(basicData?.currentUser)
                  )
                ) {
                  setIsLoading(true);
                  DeleteMember(row.original.userId);
                } else {
                  enqueueSnackbar("You don't have permission to do this", {
                    variant: "error",
                  });
                }
              }}
            >
              <Icon>delete_icon</Icon>
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    setData(members);
    setPage(pagination.pageIndex);
    setLimit(pagination.pageSize);
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [members, pagination, sorting]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    editDisplayMode: "table",
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableEditing: true,
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 150,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 400px)",
        overflowY: "auto",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    onSortingChange: setSorting,
    muiTableBodyCellProps: ({ column }) => ({
      sx: {
        height: "15px",
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  return (
    <React.Fragment>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox width="100%">
        <Grid container spacing={2} pl={9} pr={6}>
          <Grid item xs={6} md={6} display="flex" flexDirection="row">
            <MDTypography color="black" ml="1%">
              Team Members
            </MDTypography>
          </Grid>
          <Grid item xs={6} md={6} display="flex" justifyContent="flex-end" flexDirection="row">
            <Tooltip title="Add New Team Member" placement="top">
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                + Members
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>
        <MDBox borderRadius="5px" bgColor="white" width="98%">
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              table={table}
              style={{
                width: "100%",
              }}
            />
          </ThemeProvider>
        </MDBox>
      </MDBox>
    </React.Fragment>
  );
}

export default MemberTable;

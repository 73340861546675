import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Backdrop,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Checkbox,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import GetBuyerList from "hooks/ClientBuyerList/GetBuyerList";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Label from "components/InputLabel";

export const piiType = [
  {
    value: 1,
    text: "Enabled",
  },
  {
    value: 2,
    text: "Disabled",
  },
];

export const groupExistsType = [
  {
    value: 1,
    text: "Enabled",
  },
  {
    value: 2,
    text: "Disabled",
  },
];

const ClientFilter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { client, setClient } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { language, languageMap } = GetLanguageList();
  const { buyerList, buyerListMap } = GetBuyerList(id);
  const [newAdd, setNewAdd] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: true,
    edit: false,
  });

  const [showAllSelectedLanguages, setShowAllSelectedLanguages] = useState(false);

  const [showAllSelectedAllowedBuyers, setShowAllSelectedAllowedBuyers] = useState(false);
  const [showAllSelectedExcludedBuyers, setShowAllSelectedExcludedBuyers] = useState(false);

  const [isAllowedBuyerIdDisabled, setIsAllowedBuyerIdDisabled] = useState(false);
  const [isExcludedBuyerIdDisabled, setIsExcludedBuyerIdDisabled] = useState(false);

  const handleAllowedBuyerIdChange = (newValue) => {
    if (newValue?.length > 0) {
      setIsExcludedBuyerIdDisabled(true);
    } else {
      setIsExcludedBuyerIdDisabled(false);
    }
  };

  const handleExcludedBuyerIdChange = (newValue) => {
    if (newValue?.length > 0) {
      setIsAllowedBuyerIdDisabled(true);
    } else {
      setIsAllowedBuyerIdDisabled(false);
    }
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      minCpi: "",
      maxCpi: "",
      minIr: "",
      maxIr: "",
      minLoi: "",
      maxLoi: "",
      minSampleRequired: "",
      collectPii: "2",
      groupExists: "2",
      quotaCalcTypeId: "1",
      languageIds: [],
      revenueShare: "",
      allowedbuyerIds: [],
      excludedbuyerIds: [],
    },
  });

  let languageIds = watch("languageIds");
  let allowedbuyerIds = watch("allowedbuyerIds");
  let excludedbuyerIds = watch("excludedbuyerIds");

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  useEffect(() => {
    if (Object.keys(client).length > 0) {
      getData();
    }
  }, [client]);

  async function getData() {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/clients/${id}/filters`);
        if (Object.keys(result.data).length > 0) {
          setNewAdd(false);
          setValue("minCpi", result.data.minCpi);
          setValue("maxCpi", result.data.maxCpi);
          setValue("minIr", result.data.minIr);
          setValue("maxIr", result.data.maxIr);
          setValue("minLoi", result.data.minLoi);
          setValue("minSampleRequired", result.data.minSampleRequired);
          setValue("maxLoi", result.data.maxLoi);
          setValue("collectPii", result.data.collectPii ? 1 : 2);
          setValue("groupExists", result.data.groupExists ? 1 : 2);
          setValue("quotaCalcTypeId", result.data.quotaCalcTypeId);
          setValue("languageIds", result.data.languageIds);
          setValue("revenueShare", result.data.revenueShare);
          setValue("allowedbuyerIds", result.data.allowedbuyerIds);
          setValue("excludedbuyerIds", result.data.excludedbuyerIds);
        } else {
          setNewAdd(true);
        }
        setIsLoading(false);
      } catch (e) {
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        console.log(e);
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.clientId = parseInt(id);
    payload.maxIr = parseFloat(data.maxIr);
    payload.minIr = parseFloat(data.minIr);
    payload.collectPii = data.collectPii === 1 ? true : false;
    payload.groupExists = data.groupExists === 1 ? true : false;
    try {
      if (newAdd !== true) {
        let result = await TLinkApi.put(`/clients/${id}/filters`, payload);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });

        setIsEdit({
          disabled: false,
          edit: false,
        });
        setClient({});
        navigate("/clients");
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/clients/filters", payload);

        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setClient({});
        navigate("/clients");
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isEdit.edit || newAdd ? (
        <form onSubmit={handleSubmit(save)} noValidate>
          <MDBox
            p={2}
            mx={20}
            style={{
              height: `calc(100vh - 252px)`,
              overflowY: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" color="dark">
                    Client Filter
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <MDButton
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (newAdd) {
                          navigate("/clients");
                        } else {
                          setIsEdit({
                            disabled: true,
                            edit: false,
                          });
                        }
                      }}
                    >
                      &nbsp;cancel
                    </MDButton>
                    <MDButton variant="gradient" color="info" type="submit" size="small">
                      Save
                    </MDButton>
                  </Stack>
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6}>
                <Label value="Min Cpi ($)" required={true} />
                <MDInput
                  value={watch("minCpi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minCpi", {
                    required: "Min CPI is required!",
                    min: {
                      value: 0.0001,
                      message: "Min Cpi should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxCpi"),
                      message: "Min Cpi should be smaller than Max Cpi",
                    },
                  })}
                  placeholder="Min Cpi"
                  error={errors?.minCpi ? true : false}
                  helperText={
                    errors?.minCpi && errors.minCpi.message !== ""
                      ? errors.minCpi.message
                      : errors?.minCpi?.type === "max"
                      ? "Max Cpi cannot be smaller than Min Cpi"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("minCpi", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max Cpi ($)" required={true} />
                <MDInput
                  value={watch("maxCpi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxCpi", {
                    required: "Max Cpi is required!",
                    min: {
                      value: watch("minCpi"),
                      message: "Max Cpi should be greater than Min Cpi",
                    },
                  })}
                  placeholder="Max Cpi"
                  error={errors?.maxCpi ? true : false}
                  helperText={
                    errors?.maxCpi && errors.maxCpi.message !== ""
                      ? errors.maxCpi.message
                      : errors?.maxCpi?.type === "min"
                      ? "Min Cpi cannot be greater than Max Cpi"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("maxCpi", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Ir (%)" required={true} />
                <MDInput
                  value={watch("minIr")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minIr", {
                    required: "Min Ir is required!",
                    min: {
                      value: 0.0001,
                      message: "Min Ir should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxIr"),
                      message: "Min Ir should be smaller than Max Ir",
                    },
                  })}
                  placeholder="Min IR"
                  error={errors?.minIr ? true : false}
                  helperText={
                    errors?.minIr && errors.minIr.message !== ""
                      ? errors.minIr.message
                      : errors?.minIr?.type === "max"
                      ? "Max Ir cannot be smaller than Min Ir"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("minIr", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max Ir (%)" required={true} />
                <MDInput
                  value={watch("maxIr")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxIr", {
                    required: "Max Ir is required!",
                    // min: {
                    //   value: watch("minIr"),
                    //   message: "Max Ir should be greater than Min Ir",
                    // },
                    validate: (value) => {
                      if (value <= watch("minIr")) {
                        return "Max Ir should be greater than Min Ir";
                      }
                      return true;
                    },
                  })}
                  placeholder="Max IR"
                  error={errors?.maxIr ? true : false}
                  helperText={
                    errors?.maxIr && errors.maxIr.message !== ""
                      ? errors.maxIr.message
                      : errors?.maxIr?.type === "min"
                      ? "Min Ir cannot be greater than Max Ir"
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("maxIr", roundedValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min Loi (mins)" required={true} />
                <MDInput
                  value={watch("minLoi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minLoi", {
                    required: "Min Loi is required!",
                    min: {
                      value: 0.0001,
                      message: "Min Loi should be greater than 0 ",
                    },
                    max: {
                      value: watch("maxLoi"),
                      message: "Min Loi should be smaller than Max Loi",
                    },
                    validate: (value) => {
                      if (value % 1 !== 0) {
                        return "Please enter an integer value for Min Loi";
                      }
                      return true;
                    },
                  })}
                  placeholder="Min Loi"
                  error={errors?.minLoi ? true : false}
                  helperText={
                    errors?.minLoi && errors.minLoi.message !== ""
                      ? errors.minLoi.message
                      : errors?.minLoi?.type === "max"
                      ? "Max Loi cannot be smaller than Min Loi"
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Max Loi (mins)" required={true} />
                <MDInput
                  value={watch("maxLoi")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("maxLoi", {
                    required: "Max Loi is required!",
                    min: {
                      value: watch("minLoi"),
                      message: "Max Loi should be greater than Min Loi",
                    },
                    validate: (value) => {
                      if (value % 1 !== 0) {
                        return "Please enter an integer value for Max Loi";
                      }
                      return true;
                    },
                  })}
                  placeholder="Max Loi"
                  error={errors?.maxLoi ? true : false}
                  helperText={
                    errors?.maxLoi && errors.maxLoi.message !== ""
                      ? errors.maxLoi.message
                      : errors?.maxLoi?.type === "min"
                      ? "Min Loi cannot be greater than Max Loi"
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Min SampleRequired" required={true} />
                <Controller
                  name="minSampleRequired"
                  control={control}
                  rules={{
                    required: "Min SampleRequired is required!",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid integer for Min SampleRequired",
                    },
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      {...register("minSampleRequired", {
                        min: {
                          value: 0.0001,
                          message: "Min Sample Required should be greater than 0",
                        },
                      })}
                      variant="outlined"
                      placeholder="Min SampleRequired"
                      error={errors?.minSampleRequired ? true : false}
                      helperText={
                        errors?.minSampleRequired ? errors.minSampleRequired.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Language Id" required={false} />
                <Controller
                  name="languageIds"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple={true}
                      disableCloseOnSelect
                      // disabled={isEdit.disabled}
                      value={value}
                      onChange={(event, newValue) => {
                        let payload = newValue.map((val) => (val.id ? val.id : val));
                        onChange(payload);
                      }}
                      style={{
                        "& .MuiInputBase-input": {
                          minHeight: "20px",
                        },
                      }}
                      renderTags={(value, getTagProps) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              overflowX: "auto",
                              maxWidth: "90%",
                              maxHeight: "250px",
                              WebkitOverflowScrolling: "touch",
                              scrollbarWidth: "none",
                              msOverflowStyle: "none",
                            }}
                          >
                            <style>
                              {`
                              /* Hide horizontal scrollbar */
                              div::-webkit-scrollbar {
                                width: 0;
                                height: 0;
                              }
                              `}
                            </style>
                            {value.slice(0, 2).map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={languageMap[option]}
                                style={{
                                  height: "20px",
                                }}
                                {...getTagProps({ index })}
                              />
                            ))}
                            {value?.length > 2 && !showAllSelectedLanguages && (
                              <Chip
                                variant="outlined"
                                label={`+${value?.length - 2} more`}
                                style={{
                                  height: "20px",
                                  marginTop: "3px",
                                }}
                                onClick={() => setShowAllSelectedLanguages(true)}
                              />
                            )}

                            {showAllSelectedLanguages &&
                              value.slice(2).map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={languageMap[option]}
                                  style={{
                                    height: "20px",
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))}
                          </div>
                        </>
                      )}
                      isOptionEqualToValue={(option, value) => option?.id == value}
                      options={language}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => {
                        if (props["data-option-index"] === 0) {
                          return (
                            <li style={{ paddingLeft: "16px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={value.length === language.length}
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        onChange(language.map((option) => option.id));
                                      } else {
                                        onChange([]);
                                      }
                                    }}
                                  />
                                }
                                label="Select All"
                              />
                            </li>
                          );
                        }
                        return (
                          <li {...props}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  checked={selected}
                                />
                              }
                              label={option.name}
                            />
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="outlined"
                          size="small"
                          error={errors?.languageIds && languageIds?.length <= 0 ? true : false}
                          helperText={
                            errors?.languageIds && languageIds?.length <= 0
                              ? errors?.languageIds.message
                              : null
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Search buyers name inside the field" placement="top-start">
                  <Label value="Allowed Buyer Id" required={false} />
                  <Controller
                    name="allowedbuyerIds"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        multiple={true}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          handleAllowedBuyerIdChange(newValue);
                          let payload = newValue.map((val) => (val.buyerId ? val.buyerId : val));
                          onChange(payload);
                        }}
                        disabled={
                          isAllowedBuyerIdDisabled ||
                          isEdit.disabled ||
                          excludedbuyerIds?.length > 0
                        }
                        value={value}
                        sx={{
                          "& .MuiInputBase-input": {
                            minHeight: "20px",
                          },
                        }}
                        renderTags={(value, getTagProps) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                overflowX: "auto",
                                maxWidth: "90%",
                                maxHeight: "250px",
                                WebkitOverflowScrolling: "touch",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                              }}
                            >
                              <style>
                                {`
                        /* Hide horizontal scrollbar */
                        div::-webkit-scrollbar {
                          width: 0;
                          height: 0;
                        }
                        `}
                              </style>
                              {value.slice(0, 2).map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={buyerListMap[option]}
                                  style={{
                                    height: "20px",
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))}
                              {value?.length > 2 && !showAllSelectedAllowedBuyers && (
                                <Chip
                                  variant="outlined"
                                  label={`+${value?.length - 2} more`}
                                  style={{
                                    height: "20px",
                                    marginTop: "3px",
                                  }}
                                  onClick={() => setShowAllSelectedAllowedBuyers(true)}
                                />
                              )}

                              {showAllSelectedAllowedBuyers &&
                                value.slice(2).map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={buyerListMap[option]}
                                    style={{
                                      height: "20px",
                                    }}
                                    {...getTagProps({ index })}
                                  />
                                ))}
                            </div>
                          </>
                        )}
                        isOptionEqualToValue={(option, value) => option?.buyerId == value}
                        options={buyerList}
                        getOptionLabel={(option) => option.buyerName}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  checked={selected}
                                />
                              }
                              label={option.buyerName}
                            />
                          </li>
                        )}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="outlined"
                            size="small"
                            error={
                              errors?.allowedbuyerIds && allowedbuyerIds?.length <= 0 ? true : false
                            }
                            helperText={
                              errors?.allowedbuyerIds && allowedbuyerIds?.length <= 0
                                ? errors.allowedbuyerIds.message
                                : null
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Search buyers name inside the field" placement="top-start">
                  <Label value="Excluded Buyer Id" required={false} />
                  <Controller
                    name="excludedbuyerIds"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        multiple={true}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          handleExcludedBuyerIdChange(newValue);
                          let payload = newValue.map((val) => (val.buyerId ? val.buyerId : val));
                          onChange(payload);
                        }}
                        disabled={
                          isExcludedBuyerIdDisabled ||
                          isEdit.disabled ||
                          allowedbuyerIds?.length > 0
                        }
                        value={value}
                        sx={{
                          "& .MuiInputBase-input": {
                            minHeight: "20px",
                          },
                        }}
                        renderTags={(value, getTagProps) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                overflowX: "auto",
                                maxWidth: "90%",
                                maxHeight: "250px",
                                WebkitOverflowScrolling: "touch",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                              }}
                            >
                              <style>
                                {`
                            /* Hide horizontal scrollbar */
                            div::-webkit-scrollbar {
                              width: 0;
                              height: 0;
                            }
                           `}
                              </style>
                              {value.slice(0, 2).map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={buyerListMap[option]}
                                  style={{
                                    height: "20px",
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))}
                              {value?.length > 2 && !showAllSelectedExcludedBuyers && (
                                <Chip
                                  variant="outlined"
                                  label={`+${value?.length - 2} more`}
                                  style={{
                                    height: "20px",
                                    marginTop: "3px",
                                  }}
                                  onClick={() => setShowAllSelectedExcludedBuyers(true)}
                                />
                              )}

                              {showAllSelectedExcludedBuyers &&
                                value.slice(2).map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={buyerListMap[option]}
                                    style={{
                                      height: "20px",
                                    }}
                                    {...getTagProps({ index })}
                                  />
                                ))}
                            </div>
                          </>
                        )}
                        isOptionEqualToValue={(option, value) => option?.buyerId == value}
                        options={buyerList}
                        getOptionLabel={(option) => option.buyerName}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                                  checked={selected}
                                />
                              }
                              label={option.buyerName}
                            />
                          </li>
                        )}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="outlined"
                            size="small"
                            error={
                              errors?.excludedbuyerIds && excludedbuyerIds?.length <= 0
                                ? true
                                : false
                            }
                            helperText={
                              errors?.excludedbuyerIds && excludedbuyerIds?.length <= 0
                                ? errors.excludedbuyerIds.message
                                : null
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Label value="Revenue Share (%)" required={true} />
                <Controller
                  name="revenueShare"
                  control={control}
                  rules={{
                    required: "Revenue Share is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      // disabled={isEdit.disabled}
                      type="number"
                      autoComplete="off"
                      {...register("revenueShare", {
                        pattern: {
                          value: /^[0-9 ]*$/,
                          message: "Please Enter Valid Number",
                        },
                      })}
                      variant="outlined"
                      placeholder="Revenue Share"
                      error={errors?.revenueShare ? true : false}
                      helperText={errors?.revenueShare ? errors.revenueShare.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="PII" required={true} />
                <Controller
                  name="collectPii"
                  control={control}
                  rules={{
                    required: "Pii is required!",
                  }}
                  render={({ field: { onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.collectPii ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={watch("collectPii")}
                        onChange={onChange}
                        placeholder="Pii"
                        sx={selectStyle}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                      >
                        {piiType?.map((value, i) => {
                          return (
                            <MenuItem value={value.value} key={i}>
                              {value.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.collectPii && (
                        <FormHelperText>
                          {errors?.collectPii ? errors.collectPii.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label value="Group Exists" required={true} />
                <Controller
                  name="groupExists"
                  control={control}
                  rules={{
                    required: "Group Exists is required!",
                  }}
                  render={({ field: { onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.groupExists ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={watch("groupExists")}
                        onChange={onChange}
                        placeholder="Group Exists"
                        sx={selectStyle}
                        MenuProps={{
                          style: {
                            width: "150px",
                            height: "250px",
                          },
                        }}
                      >
                        {groupExistsType?.map((value, i) => {
                          return (
                            <MenuItem value={value.value} key={i}>
                              {value.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.groupExists && (
                        <FormHelperText>
                          {errors?.groupExists ? errors.groupExists.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
        </form>
      ) : (
        <MDBox p={2} mx={20}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="dark">
                  Client Filter
                </Typography>
                <MDBox>
                  {isEdit.disabled === true && (
                    <IconButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      onClick={() => {
                        setIsEdit({
                          disabled: false,
                          edit: true,
                        });
                      }}
                    >
                      <Icon>border_color_icon</Icon>
                    </IconButton>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Cpi ($)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minCpi") ? watch("minCpi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max Cpi ($)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxCpi") ? watch("maxCpi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Ir (%)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minIr") ? watch("minIr") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max Ir (%)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxIr") ? watch("maxIr") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min Loi (mins)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minLoi") ? watch("minLoi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Max Loi (mins)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("maxLoi") ? watch("maxLoi") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Min SampleRequired" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("minSampleRequired") ? watch("minSampleRequired") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Language Id" required={false} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("languageIds")
                  ? watch("languageIds").length > 2
                    ? watch("languageIds")
                        .slice(0, 2)
                        .map((lang) => ` ${languageMap[lang]} `) +
                      ` +${watch("languageIds").length - 2} more`
                    : watch("languageIds").map((lang) => `${languageMap[lang]} `)
                  : 0}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Label value="Allowed Buyer ID" required={false} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("allowedbuyerIds")
                  ? watch("allowedbuyerIds").length > 2
                    ? watch("allowedbuyerIds")
                        .slice(0, 2)
                        .map((option) => ` ${buyerListMap[option]} `) +
                      ` +${watch("allowedbuyerIds").length - 2} more`
                    : watch("allowedbuyerIds").map((option) => `${buyerListMap[option]} `)
                  : 0}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Label value="Excluded Buyer ID" required={false} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("excludedbuyerIds")
                  ? watch("excludedbuyerIds").length > 2
                    ? watch("excludedbuyerIds")
                        .slice(0, 2)
                        .map((option) => ` ${buyerListMap[option]} `) +
                      ` +${watch("excludedbuyerIds").length - 2} more`
                    : watch("excludedbuyerIds").map((option) => `${buyerListMap[option]} `)
                  : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Revenue Share (%)" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {watch("revenueShare") ? watch("revenueShare") : 0}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="PII" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {
                  piiType?.find((value) => Number(value.value) === Number(watch("collectPii")))
                    ?.text
                }
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label value="Group Exists" required={true} />
              <Typography fontSize="14px" fontWeight="600">
                {
                  groupExistsType?.find(
                    (value) => Number(value.value) === Number(watch("groupExists"))
                  )?.text
                }
              </Typography>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

export default ClientFilter;

import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import "../../global.css";

const SearchInput = ({ dashbord, setSearch, search, setMenuOpen }) => {
  return (
    <MDBox
      id="search"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      border={dashbord === true ? "2px solid #D9D9D9" : "1px solid #D9D9D9"}
      height={dashbord === true ? "45px" : "37px"}
      borderRadius={dashbord === true ? "2px" : "0"}
      width={dashbord === true ? "100%" : "200px"}
      sx={{ minWidth: "200px" }}
      onFocus={(e) => {
        if (dashbord) {
          e.stopPropagation();
          setMenuOpen(true);
        }
      }}
      onBlur={(e) => {
        if (dashbord) {
          e.stopPropagation();
          setMenuOpen(false);
        }
      }}
    >
      <input
        value={search}
        size="large"
        style={{
          border: "none",
          outline: "none",
          paddingLeft: dashbord === true ? "10px" : "5px",
          width: "100%",
        }}
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        borderLeft={dashbord === true ? "2px solid #D9D9D9" : "1px solid #D9D9D9"}
        padding="7px"
      >
        <Icon>search</Icon>
      </MDBox>
    </MDBox>
  );
};

export default SearchInput;

import { Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

function MinMax({ question, updateQuestion, i, quotaQuestions, setQuotaError }) {
  useEffect(() => {
    let error = quotaQuestions[i]?.selectedRange?.some(
      (val) =>
        val.min === "" ||
        val.max === "" ||
        Number(val.min) > Number(val.max) ||
        Number(val.min) < 10 ||
        Number(val.max) > 100
    );
    setQuotaError(error);
    // eslint-disable-next-line
  }, [quotaQuestions]);

  return (
    <MDBox fontSize="15px" mt={2}>
      <Grid container spacing={1} style={{ paddingLeft: "5px" }}>
        <Grid item xs={10} md={12}>
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            flexWrap="wrap"
          >
            <MDTypography variant="button" color="dark" sx={{ mb: 1, fontWeight: 600 }}>
              {question?.question?.questionText}&nbsp;
            </MDTypography>
            <IconButton
              sx={{ marginTop: "0.2rem" }}
              onClick={() => {
                let data = [...quotaQuestions];
                data.splice(i, 1);
                updateQuestion("quotaQuestions", data);
              }}
            >
              <DeleteOutlineIcon fontSize="small" color="error" />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>

      {question.selectedRange.map((value, index) => (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={5} ml={1}>
            <MDInput
              type="number"
              autoComplete="off"
              variant="outlined"
              label="min"
              fullWidth
              required
              value={value.min}
              onChange={(e) => {
                let data = [...quotaQuestions];
                data[i].selectedRange[index].min = e.target.value;
                updateQuestion("quotaQuestions", data);
              }}
              error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
              helperText={
                value.min === ""
                  ? "This field is Required"
                  : Number(value.min) < 10
                  ? "This value can not be less then 10"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <MDInput
              type="number"
              fullWidth
              required
              value={value.max}
              onChange={(e) => {
                let data = [...quotaQuestions];
                data[i].selectedRange[index].max = e.target.value;
                updateQuestion("quotaQuestions", data);
              }}
              autoComplete="off"
              variant="outlined"
              label="max"
              error={
                value.max === ""
                  ? true
                  : Number(value.min) > Number(value.max)
                  ? true
                  : Number(value.max) > 100
                  ? true
                  : false
              }
              helperText={
                value.max === ""
                  ? "This field is Required"
                  : Number(value.max) > 100
                  ? "This value can not be greater than 100"
                  : Number(value.min) > Number(value.max)
                  ? "This is not be less then min"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              disabled={question.selectedRange.length === 1}
              onClick={() => {
                let data = [...quotaQuestions];
                data[i].selectedRange.splice(index, 1);
                updateQuestion("quotaQuestions", data);
              }}
            >
              <DeleteOutlineIcon color="error" fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </MDBox>
  );
}

export default MinMax;

import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/images/Back.svg";

const VendorTitleCard = ({ name }) => {
  let navigate = useNavigate();
  return (
    <Stack spacing={0} minHeight="85px" pt={1.5}>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton
          sx={{
            padding: 0,
            borderRadius: "4px !important",
          }}
          onClick={() => {
            navigate(`/vendors`);
          }}
        >
          <img src={BackIcon} />
        </IconButton>
        <Typography variant="h4">{name}</Typography>
      </Stack>
    </Stack>
  );
};

export default VendorTitleCard;

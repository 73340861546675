import {
  FormControlLabel,
  Grid,
  Switch,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const OtherSecurity = ({
  researchDefender,
  researchDefenderOpenEnded,
  countryMismatch,
  errors,
  control,
  setValue,
  isEdit,
}) => {
  let { id } = useParams();
  const [researchDefenderList, setResearchDefenderList] = useState([]);

  const GetResearchDefenderList = async () => {
    try {
      let result = await TLinkApi.get(`/masters/researchdefender`);
      setResearchDefenderList(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      GetResearchDefenderList();
    }
  }, [id]);

  let selectStyle = {
    height: "36.5px",
    width: "160px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <MDBox mt={2}>
      <MDTypography fontSize="14px" fontWeight="medium" mb={1}>
        Other Security
      </MDTypography>

      <Grid container spacing={0} px={2}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={countryMismatch}
                onChange={(e, value) => {
                  setValue("countryMismatch", value);
                }}
                disabled={isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                Country mismatch
              </MDTypography>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={researchDefender}
                onChange={(e, value) => {
                  setValue("researchDefender", value);
                }}
                disabled={isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                Research Defender
              </MDTypography>
            }
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} my={1}>
          <MDTypography fontWeight="regular" color="dark" fontSize="12px">
            Research Defender Score
          </MDTypography>

          <Controller
            name="researchDefenderScore"
            control={control}
            render={({ field }) => (
              <MDInput
                {...field}
                sx={{ width: "160px" }}
                disabled={isEdit.disabled || !researchDefender}
                autoComplete="off"
                type="number"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={researchDefenderOpenEnded}
                onChange={(e, value) => {
                  setValue("researchDefenderOpenEnded", value);
                }}
                disabled={isEdit.disabled}
              />
            }
            label={
              <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                Research Defender Open Ended
              </MDTypography>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} my={1}>
          <MDTypography fontWeight="regular" color="dark" fontSize="12px">
            Research Defender Open Ended Score
          </MDTypography>

          <Controller
            name="researchDefenderOpenEndedId"
            control={control}
            rules={{
              required: "Research Defender Open Ended Score is required!",
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl
                fullWidth
                required
                error={errors?.researchDefenderOpenEndedId ? true : false}
                sx={{
                  label: {
                    color: "rgba(0, 0, 0, 0.25)",
                    fontWeight: "400",
                  },
                }}
                size="small"
              >
                <Select
                  value={value}
                  onChange={onChange}
                  sx={selectStyle}
                  disabled={isEdit.disabled || !researchDefenderOpenEnded}
                  autoComplete="off"
                >
                  {researchDefenderList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.researchDefenderOpenEndedId && (
                  <FormHelperText>
                    {errors?.researchDefenderOpenEndedId
                      ? errors.researchDefenderOpenEndedId.message
                      : null}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} my={1}>
          <MDTypography fontWeight="regular" color="dark" fontSize="12px">
            Composite Score
          </MDTypography>

          <Controller
            name="compositeScore"
            control={control}
            render={({ field }) => (
              <MDInput
                {...field}
                sx={{ width: "160px" }}
                disabled={isEdit.disabled || !researchDefenderOpenEnded}
                autoComplete="off"
                type="number"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default OtherSecurity;

import { Backdrop, CircularProgress, Grid, Icon, IconButton, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Redirection = ({ data, GetRedirectionData }) => {
  let { enqueueSnackbar } = useSnackbar();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: {
      vendorCompleted: "",
      vendorQuotaFull: "",
      vendorTerminate: "",
      vendorSecurity: "",
    },
  });

  let vendorCompleted = watch("vendorCompleted");

  const label = (value, required) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {required ? <span>* &nbsp;</span> : ""}
      </MDBox>
    );
  };

  let { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data]);

  const getData = async () => {
    if (data !== null) {
      if (data.vendorCompleted !== null) {
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setAddData(true);
      }
      setValue("vendorCompleted", data.vendorCompleted);
      setValue("vendorQuotaFull", data.vendorQuotaFull);
      setValue("vendorTerminate", data.vendorTerminate);
      setValue("vendorSecurity", data.vendorSecurity);
    } else {
      reset({
        vendorCompleted: "",
        vendorQuotaFull: "",
        vendorTerminate: "",
        vendorSecurity: "",
      });
      setAddData(true);
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };
    payload.vendorId = id;
    try {
      if (addData !== true) {
        let result = await TLinkApi.put(`/vendors/redirection/urls/${id}`, payload);
        await GetRedirectionData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/vendors/redirection/urls", payload);
        await GetRedirectionData(true);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox pb={1} display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>Redirection URL(s)</Typography>
          <MDBox>
            {isEdit.disabled === true ? (
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={async () => {
                    if (!addData) {
                      await GetRedirectionData(true);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    } else {
                      reset({
                        vendorCompleted: "",
                        vendorQuotaFull: "",
                        vendorTerminate: "",
                        vendorSecurity: "",
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  save
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        {isEdit.edit || addData ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {label("Vendor Completed", true)}
              <Controller
                name="vendorCompleted"
                control={control}
                rules={{
                  required: `Vendor Completed is required!`,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      placeholder="Vendor Completed"
                      {...register("vendorCompleted", {
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorCompleted ? true : false}
                      helperText={errors?.vendorCompleted ? errors.vendorCompleted.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("vendorCompleted"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {label("Vendor Quota Full")}
              <Controller
                name="vendorQuotaFull"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="Vendor QuotaFull"
                      {...register("vendorQuotaFull", {
                        validate: (value) => {
                          if (vendorCompleted === value) {
                            return "Vendor Quota Full and Vendor Completed can't be same!";
                          }
                          return true;
                        },
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorQuotaFull ? true : false}
                      helperText={errors?.vendorQuotaFull ? errors.vendorQuotaFull.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("vendorQuotaFull"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {label("Vendor Terminate", true)}
              <Controller
                name="vendorTerminate"
                control={control}
                rules={{
                  required: `Vendor Terminate is required!`,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      placeholder="Vendor Terminate"
                      {...register("vendorTerminate", {
                        validate: (value) => {
                          if (vendorCompleted === value) {
                            return "Vendor Terminate and Vendor Completed can't be same!";
                          }
                          return true;
                        },
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorTerminate ? true : false}
                      helperText={errors?.vendorTerminate ? errors.vendorTerminate.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("vendorTerminate"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {label("Vendor Security")}
              <Controller
                name="vendorSecurity"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MDBox sx={{ position: "relative" }}>
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="Vendor Security"
                      {...register("vendorSecurity", {
                        validate: (value) => {
                          if (vendorCompleted === value) {
                            return "Vendor Security and Vendor Completed can't be same!";
                          }
                          return true;
                        },
                        pattern: {
                          value:
                            //eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorSecurity ? true : false}
                      helperText={errors?.vendorSecurity ? errors.vendorSecurity.message : null}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 4, right: 5 }}
                      size="small"
                      onClick={() => {
                        copy(watch("vendorSecurity"));
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </MDBox>
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {label("Vendor Completed", true)}
              <Typography fontSize="14px" fontWeight="600">
                {watch("vendorCompleted") ? watch("vendorCompleted") : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {label("Vendor Quota Full")}
              <Typography fontSize="14px" fontWeight="600">
                {watch("vendorQuotaFull") ? watch("vendorQuotaFull") : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {label("Vendor Terminate", true)}
              <Typography fontSize="14px" fontWeight="600">
                {watch("vendorTerminate") ? watch("vendorTerminate") : "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {label("Vendor Security")}
              <Typography fontSize="14px" fontWeight="600">
                {watch("vendorSecurity") ? watch("vendorSecurity") : "NA"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default Redirection;

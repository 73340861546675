import {
  Backdrop,
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Label from "components/InputLabel";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import UploadIcon from "@mui/icons-material/Upload";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import GetReconciliationTypeList from "hooks/ReconciliationTypeList/GetReconciliationTypeList";

const ProjectReconcilationAdd = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { GetReconcilationList } = useOutletContext();
  let { reconciliationType } = GetReconciliationTypeList();
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { title: "", file: "", notes: "", reconcileTypeId: "" },
  });
  let file = watch("file");

  const save = async (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("title", data.title);
    formData.append("description", data.notes);
    formData.append("reconcileTypeId", data.reconcileTypeId);
    formData.append("projectId", id);
    try {
      let result = await TLinkApi.upload(`/reconciliation/${id}`, formData);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      if (result.data.reconcileData.id) {
        navigate(`/projects/${id}/reconciliation/${result.data.reconcileData.id}`);
      }
      GetReconcilationList();
      setLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <form onSubmit={handleSubmit(save)} noValidate style={{ padding: "10px" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">New Reconciliation</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(`/projects/${id}/reconciliation`);
                }}
              >
                Cancel
              </MDButton>
              <MDButton size="small" variant="contained" color="info" type="submit">
                Continue
              </MDButton>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Label value="Reconciliation Title" required={true} />
          <Controller
            name="title"
            control={control}
            rules={{
              required: "Reconciliation Title is required!",
            }}
            render={({ field }) => (
              <Stack mb={1}>
                <MDInput {...field} fullWidth variant="outlined" value={watch("title")} />
                <MDBox color="error" fontSize="12px">
                  {errors?.title && watch("title") === "" ? errors.title.message : null}
                </MDBox>
              </Stack>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Label value="Reconciliation Type" required={true} />
          <Controller
            name="reconcileTypeId"
            control={control}
            rules={{
              required: "Reconcile Type is required!",
            }}
            render={({ field: { onChange, value } }) => (
              <FormControl
                fullWidth
                required
                error={errors?.reconcileTypeId ? true : false}
                size="small"
                sx={{
                  label: {
                    fontWeight: "400",
                  },
                }}
              >
                <Select
                  value={value}
                  onChange={onChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={selectStyle}
                >
                  {reconciliationType.map((value, i) => {
                    return (
                      <MenuItem value={value.id} key={i}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors?.reconcileTypeId && (
                  <FormHelperText>
                    {errors?.reconcileTypeId ? errors.reconcileTypeId.message : null}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="xlsx file is allowed to upload" placement="top-start">
            <Label value="Reconciliation File" required={true} />
            <Controller
              name="file"
              control={control}
              rules={{
                required: "File is required!",
              }}
              render={({ field: { onChange } }) => (
                <>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    size="small"
                    sx={{
                      borderColor: "#d2d6da",
                      height: "36px",
                      borderRadius: "0rem",
                      boxShadow: "none !important",
                      "&:focus:not(:hover)": {
                        boxShadow: "none !important",
                      },
                    }}
                    htmlFor="files"
                    onClick={() => {
                      document.getElementById("files").click();
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between !important"
                      width="100%"
                    >
                      <span>{file !== "" ? file?.name : "Upload file"}</span>
                      <UploadIcon sx={{ fontSize: "1rem" }} />
                    </Stack>
                  </MDButton>
                  <input
                    id="files"
                    onChange={(e) => {
                      onChange(e.target.files[0]);
                    }}
                    required
                    type="file"
                    style={{ display: "none" }}
                    label="File Upload"
                    autoComplete="off"
                    accept=".xlsx"
                    variant="outlined"
                  />
                  {errors?.file && (
                    <FormHelperText style={{ color: errors?.file && "red" }}>
                      {errors?.file ? errors.file.message : null}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Label value="Notes" required={false} />
          <Controller
            name="notes"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <Stack mb={1}>
                <MDInput
                  {...field}
                  fullWidth
                  variant="outlined"
                  value={watch("notes")}
                  multiline
                  rows={4}
                />
              </Stack>
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ProjectReconcilationAdd;

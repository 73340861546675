import MDBox from "components/MDBox";
import { Link, Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ReactComponent as BasicDetailsLogo } from "assets/images/stepperSvg/basicDetails.svg";
import { Grid } from "@mui/material";
import Stepper from "components/Stepper";
import MDTypography from "components/MDTypography";

function AddCampaigns() {
  const { campaign, setCampaign, setIsEdit, isEdit } = useOutletContext();
  const [step, setStep] = useState([]);
  const [stepPos, setStepPos] = useState(0);
  const location = useLocation();
  let { id } = useParams();

  useEffect(() => {
    if (window.location.pathname === `/campaigns/${id}/edit`) {
      setStep([]);
      setStepPos(0);
    } else if (window.location.pathname === `/campaigns/new`) {
      setStep([]);
      setStepPos(0);
    }
    // eslint-disable-next-line
  }, [location]);

  const steps = [
    {
      name: "Campaigns Details",
      logo: BasicDetailsLogo,
      navigate: `/campaigns/${id}/edit`,
    },
  ];

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <Link to="/dashboard">Home</Link> &nbsp;/&nbsp;<Link to="/campaigns">Campaigns</Link>
        &nbsp;/&nbsp;{id ? id : "New Campaign"}
      </MDTypography>
      <Grid container spacing={0}>
        <Stepper
          steps={steps}
          setStep={setStep}
          step={step}
          setStepPos={setStepPos}
          stepPos={stepPos}
          isEdit={isEdit}
        />

        <Grid item xs={12} md={9} lg={10}>
          <Outlet
            context={{
              stepPos,
              setStepPos,
              step,
              setStep,
              campaign,
              setCampaign,
              setIsEdit,
              isEdit,
            }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AddCampaigns;

import { Icon, IconButton, Switch, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QualificationChart from "comps/Cards/QualificationChart";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const QualificationList = ({
  val,
  index,
  getQualificationQuestions,
  qualificationQuestions,
  setQualificationQuestions,
  provided,
}) => {
  const [i, setI] = useState(null);
  let { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [singleQualification, setsingleQualification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  let { id } = useParams();

  const activeQualification = async ({ val, value }) => {
    try {
      let result = await TLinkApi.put(`survey/${id}/qualification/${val.surveyQualificationId}`, {
        isActive: value,
      });
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      getQualificationQuestions();
      handleClose();
      setI(null);
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const deleteQualification = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.del(`survey/${id}/qualification/${val.surveyQualificationId}`);
      getQualificationQuestions();
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  return (
    <MDBox
      key={index}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <MDBox
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
          background: !val.isActive ? "#f5f5f5" : "",
        }}
        mt={3}
      >
        <Dialog
          open={open || editOpen}
          onClose={() => {
            if (editOpen) {
              handleEditClose();
            } else {
              handleClose();
            }
          }}
        >
          <DialogTitle>
            <span>{"Are you sure?"}</span>
            <hr />
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {editOpen
                ? "Updating qualification will remove the associated quotas and terminate conditions. Do you want to continue?"
                : "Disabling the qualification will remove the associated quotas and terminate conditions. Do you want to continue?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                if (editOpen) {
                  handleEditClose();
                } else {
                  handleClose();
                }
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => {
                if (editOpen) {
                  setEdit(true);
                  setI(val.qualificationId);
                  handleEditClose();
                } else {
                  activeQualification({ val, value: false });
                }
              }}
            >
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteOpen} onClose={handleDeleteClose}>
          <DialogTitle>
            <span>{"Are you sure?"}</span>
            <hr />
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              Deleting the qualification will remove the associated quotas and terminate conditions.
              Do you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleDeleteClose}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => {
                deleteQualification();
              }}
            >
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
        <MDBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap="5px"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "black",
          }}
        >
          <MDBox
            width="15%"
            px={1}
            sx={{
              width: {
                xs: "10%",
                md: "20%",
                lg: "15%",
              },
            }}
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.06)",
              borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <DragIndicatorIcon sx={{ fontSize: "1.5rem !important" }} />
            <MDBox
              sx={{
                fontSize: { xs: "10px", md: "12px", lg: "15px" },
              }}
            >
              {val.qualificationCategoryTypeId === 1
                ? "Standard"
                : val.qualificationCategoryTypeId === 2
                ? "Custom"
                : val.qualificationCategoryTypeId === 3
                ? "Trap"
                : "Open Ended Screener"}
            </MDBox>
          </MDBox>
          <MDBox
            px={1}
            width="72%"
            height="auto"
            flexWrap="wrap"
            // display="flex"
            sx={{
              width: { xs: "40%", md: "50%", lg: "72%" },
              fontSize: { xs: "10px", md: "12px", lg: "15px" },
              overflowWrap: "break-word",
            }}
          >
            {val?.question?.questionText}
          </MDBox>
          <MDBox
            width="10%"
            sx={{
              width: { xs: "20%", md: "20%", lg: "10%" },
              fontSize: { xs: "10px", md: "12px", lg: "15px" },
            }}
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.06)",
              borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
            }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              sx={{ marginTop: "0.2rem" }}
              onClick={() => {
                handleDeleteClickOpen();
              }}
            >
              <DeleteOutlineIcon fontSize="small" color="error" />
            </IconButton>
            <Switch
              size="small"
              color="secondary"
              checked={val.isActive}
              onChange={(e, value) => {
                if (value === false) {
                  handleClickOpen();
                } else {
                  activeQualification({ val, value });
                }
                setEdit(false);
                setI(null);
              }}
            />
            <Tooltip title="Edit Qualification" placement="top">
              <IconButton
                color="dark"
                size="small"
                sx={{ display: !val.isActive ? "none" : "" }}
                onClick={() => {
                  setEditOpen(true);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
          <MDBox
            display={"flex"}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: { xs: "10%", md: "10%", lg: "3%" } }}
            style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <IconButton
              sx={{
                transform: Number(val.qualificationId) === Number(i) ? "rotate(180deg)" : "",
              }}
              onClick={() => {
                if (i === null) {
                  setI(val.qualificationId);
                } else {
                  setI(null);
                }
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </MDBox>
        </MDBox>
        <MDBox style={{ display: Number(val.qualificationId) === Number(i) ? "" : "none" }}>
          <QualificationChart
            val={val}
            index={index}
            qualificationQuestions={qualificationQuestions}
            setQualificationQuestions={setQualificationQuestions}
            getQualificationQuestions={getQualificationQuestions}
            setI={setI}
            edit={edit}
            setEdit={setEdit}
            singleQualification={singleQualification}
            setsingleQualification={setsingleQualification}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default QualificationList;

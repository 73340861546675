import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { StyledTabs } from "./tabs.style";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, Menu, Stack, Tooltip } from "@mui/material";
import colors from "assets/theme/base/colors";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        marginTop: "1rem",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const TabsComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buttonIndex, setButtonIndex] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const { info, textColor } = colors;

  const { labels, tabpanels, setValue, value, subValue, sublabels, setSubValue, isApi } = props;

  const handleChange = (_, newValue) => {
    // setValue(newValue);
  };

  const handleSubValueChange = (_, newValue) => {
    // setSubValue(newValue);
  };

  let location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setButtonIndex(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: `2px solid ${textColor.main}` }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{
            minHeight: "0px",
            width: "100%",
          }}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          {labels.map(({ label, route, isDisabled, matchRoutes, programmatic }, index) => {
            return (
              <Tab
                sx={{
                  display: isApi === false && programmatic === false ? "none" : "",
                  minHeight: "30px",
                  height: "30px",
                  paddingInline: "10px !important",
                  borderTopLeftRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem",
                  background: value === index ? info.main : "",
                  "&:hover": {
                    background: value === index ? info.main : "",
                  },
                  fontWeight: 400,
                  color: value === index ? "white !important" : "black",
                }}
                key={index}
                label={label}
                disabled={isDisabled}
                onClick={() => {
                  if (route) {
                    navigate(route);
                  }
                }}
                {...a11yProps(index)}
              />
            );
          })}
        </StyledTabs>
        <StyledTabs
          value={subValue}
          onChange={handleSubValueChange}
          sx={{
            minHeight: "0px",
          }}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          {sublabels &&
            sublabels.map(({ label, route, isDisabled, name, htmlContainer }, index) => {
              return (
                <Tooltip title={open && index === buttonIndex ? "" : name}>
                  <IconButton
                    key={index}
                    disabled={isDisabled}
                    onClick={(e) => {
                      if (route) {
                        navigate(route);
                      } else {
                        handleClick(e);
                        setButtonIndex(index);
                      }
                    }}
                    sx={{
                      borderRadius: "4px",
                      padding: "4px !important",
                      marginRight: "15px !important",
                      background:
                        (route && route === location.pathname) || (open && index === buttonIndex)
                          ? "#3669EC"
                          : "",
                      "&:hover": {
                        background:
                          (route && route === location.pathname) || (open && index === buttonIndex)
                            ? "#3669EC"
                            : "",
                      },
                    }}
                  >
                    <img
                      src={label}
                      height="18px"
                      style={{
                        filter:
                          (route && route === location.pathname) || (open && index === buttonIndex)
                            ? "invert(99%) sepia(32%) saturate(0%) hue-rotate(0deg) brightness(22212%) contrast(100%)"
                            : "invert(0%) sepia(1%) saturate(0%) hue-rotate(53deg) brightness(0%) contrast(114%)",
                      }}
                    />
                  </IconButton>
                  {open && index === buttonIndex ? (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      open={open}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        sx: {
                          maxHeight: "550px !important",
                          minWidth: "300px !important",
                          paddingTop: "0rem !important",
                        },
                      }}
                    >
                      {htmlContainer &&
                        React.cloneElement(htmlContainer, {
                          handleClose,
                        })}
                    </Menu>
                  ) : (
                    ""
                  )}
                </Tooltip>
              );
            })}
        </StyledTabs>
      </Stack>

      <TabPanel
        value={value === -1 && subValue ? subValue : value}
        index={value === -1 && subValue ? subValue : value}
      >
        {tabpanels}
      </TabPanel>
    </Box>
  );
};

export default TabsComponent;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import BlueTick from "assets/images/BlueTick.svg";
import GreenTick from "assets/images/GreenTick.svg";
import Tick from "assets/images/Tick.svg";
import Variables from "./variables";
import Encryption from "./encryption";
import MDBox from "components/MDBox";
import TLinkApi from "services/tlink.api";
import SecurityConfiguration from "./securityConfiguration";

const Steps = [
  {
    name: "Variable",
    value: 1,
  },
  {
    name: "Encryption",
    value: 2,
  },
  {
    name: "Security Configuration",
    value: 3,
  },
];

const SetUp = () => {
  const [val, setVal] = useState(1);
  const [completeStepsId, setCompleteStepsId] = useState([]);
  const [encryptionData, setEncryptionData] = useState(null);
  const [variableData, setVariableData] = useState(null);
  const [securityConfigData, setSecurityConfigData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  let payload = [...completeStepsId];

  const GetEncryptionData = async (loading) => {
    if (loading) setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/clients/encryption/${id}`);
      if (result.status !== 404 && result.data !== null) {
        if (result.data.outGoingEncryptionEnable || result.data.inComingEncryptionEnable) {
          payload.push(2);
        } else {
          let indexToDelete = payload.indexOf(2);
          if (indexToDelete !== -1) {
            payload.splice(indexToDelete, 1);
          }
        }
        setCompleteStepsId(payload);
        setEncryptionData(result.data);
      } else {
        setEncryptionData(null);
      }
      if (loading) setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const GetClientSecurityConfiguration = async (loading) => {
    if (loading) setIsLoading(true);
    try {
      let result = await TLinkApi.get(`clients/${id}/securityConfig`);
      if (result.status !== 404 && result.data !== null) {
        if (
          result.uniqueIp ||
          result.countryMismatch ||
          result.researchDefender ||
          result.uniqueUser ||
          result.researchDefenderScore ||
          result.minLoi ||
          result.maxLoi
        ) {
          payload.push(3);
        } else {
          let indexToDelete = payload.indexOf(3);
          if (indexToDelete !== -1) {
            payload.splice(indexToDelete, 1);
          }
        }
        setCompleteStepsId(payload);
        setSecurityConfigData(result);
      } else {
        setSecurityConfigData(null);
      }
      if (loading) setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const GetVariableData = async (loading) => {
    if (loading) setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/clients/variable/${id}`);
      if (result.data !== null) {
        if (result.data.variable1) {
          payload.push(1);
        } else {
          let indexToDelete = payload.indexOf(1);
          if (indexToDelete !== -1) {
            payload.splice(indexToDelete, 1);
          }
        }
        setCompleteStepsId(payload);
        setVariableData(result.data);
      } else {
        setVariableData(null);
      }
      if (loading) setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const callApi = async () => {
    setIsLoading(true);
    await GetVariableData(false);
    await GetEncryptionData(false);
    await GetClientSecurityConfiguration(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      callApi();
    }
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={2.5} sx={{ paddingTop: "10px !important" }}>
        <List
          sx={{
            borderRight: "1px solid #E4E4E4",
            height: "calc(100vh - 240px)",
            overflowY: "auto",
            paddingBottom: "15px",
          }}
        >
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", paddingLeft: "8px" }}
            color="dark"
            mb={1}
          >
            Setup
          </Typography>
          {Steps.map((step) => {
            return (
              <ListItem
                key={step.value}
                sx={{
                  borderBottom: completeStepsId.includes(step.value)
                    ? "1px solid #1DA522"
                    : val === step.value
                    ? "1px solid #3669EC"
                    : "1px solid #E4E4E4",
                  height: "56px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                  background: val === step.value ? "#F0FAFB !important" : "",
                  "&:hover": {
                    background: "#f5f5f5",
                  },
                }}
                onClick={() => {
                  setVal(step.value);
                }}
              >
                <ListItemText>
                  <Typography sx={{ fontSize: "18px", fontWeight: "600" }} color="dark">
                    {step.name}
                  </Typography>
                </ListItemText>
                <ListItemIcon>
                  <img
                    src={
                      completeStepsId.includes(step.value)
                        ? GreenTick
                        : val === step.value
                        ? BlueTick
                        : Tick
                    }
                    alt="tick"
                  />
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </Grid>

      <Grid item xs={9.5} sx={{ paddingTop: "10px !important" }}>
        <MDBox sx={{ height: "calc(100vh - 240px)", overflowY: "auto" }}>
          {val === 1 ? (
            <Variables data={variableData} GetVariableData={GetVariableData} />
          ) : val === 2 ? (
            <Encryption data={encryptionData} GetEncryptionData={GetEncryptionData} />
          ) : (
            <SecurityConfiguration
              data={securityConfigData}
              GetClientSecurityConfiguration={GetClientSecurityConfiguration}
            />
          )}
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default SetUp;

import {
  Backdrop,
  Grid,
  Icon,
  IconButton,
  CircularProgress,
  Switch,
  FormControlLabel,
  Typography,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import Deduplication from "./deduplicationSecurity";
import OtherSecurity from "./otherSecurity";

const SecurityConfig = () => {
  const [confiqIsAdd, setConfigIsAdd] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: true,
    edit: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [surveyTemplate, setSurveyTemplate] = useState("");
  const [templateData, setTemplateData] = useState({});
  let { survey } = useOutletContext();

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      surveySecurityTemplateId: -1,
      maxLoi: "",
      minLoi: "",
      minLoiIsActive: false,
      maxLoiIsActive: false,
      researchDefenderScore: 0,
      compositeScore: 0,
      uniqueIp: false,
      uniqueUser: false,
      countryMismatch: false,
      researchDefender: false,
      researchDefenderOpenEnded: false,
      researchDefenderOpenEndedId: 0,
    },
  });
  let { id } = useParams();

  let uniqueIp = watch("uniqueIp");
  let uniqueUser = watch("uniqueUser");
  let researchDefender = watch("researchDefender");
  let researchDefenderOpenEnded = watch("researchDefenderOpenEnded");
  let researchDefenderOpenEndedId = watch("researchDefenderOpenEndedId");
  let compositeScore = watch("compositeScore");
  let countryMismatch = watch("countryMismatch");
  let minLoiIsActive = watch("minLoiIsActive");
  let maxLoiIsActive = watch("maxLoiIsActive");
  let maxLoi = watch("maxLoi");
  let minLoi = watch("minLoi");

  let { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (survey && JSON.stringify(survey) !== "{}") {
      GetTemplateList(survey);
      GetSurveySecurityConfig();
    }
  }, [survey]);

  useEffect(() => {
    if (selectedTemplateId !== -1) {
      let selectedTemplateIdData = templates?.find((data) => data.id === selectedTemplateId);
      if (selectedTemplateIdData != null) {
        setTemplateData(selectedTemplateIdData);
      }
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (Object.keys(templateData).length > 0) {
      setValue("countryMismatch", templateData.countryMismatch);
      setValue("uniqueIp", templateData.uniqueIp);
      setValue("uniqueUser", templateData.uniqueUser);
      setValue("researchDefender", templateData.researchDefender);
      setValue("researchDefenderScore", templateData.researchDefenderScore);
      setValue("researchDefenderOpenEnded", templateData.researchDefenderOpenEnded);
      setValue("researchDefenderOpenEndedId", templateData.researchDefenderOpenEndedId);
      setValue("compositeScore", templateData.compositeScore);
      setValue("surveySecurityTemplateId", templateData.id == null ? -1 : templateData.id);
      setValue("minLoi", templateData.minLoi);
      setValue("maxLoi", templateData.maxLoi);
      setValue("minLoiIsActive", templateData.minLoi > 0 ? true : false);
      setValue("maxLoiIsActive", templateData.maxLoi > 0 ? true : false);
    } else if (Object.keys(templateData).length <= 0) {
      setValue("countryMismatch", false);
      setValue("uniqueIp", false);
      setValue("uniqueUser", false);
      setValue("researchDefender", false);
      setValue("researchDefenderScore", 0);
      setValue("researchDefenderOpenEnded", false);
      setValue("researchDefenderOpenEndedId", 0);
      setValue("compositeScore", 0);
      setValue("surveySecurityTemplateId", -1);
      setValue("minLoi", 0.0);
      setValue("maxLoi", 0.0);
      setValue("minLoiIsActive", false);
      setValue("maxLoiIsActive", false);
    }
  }, [templateData]);

  const GetTemplateList = async (surveyData) => {
    setIsLoading(true);
    try {
      let clientId = surveyData?.clientId;
      let studyTypeId = surveyData?.sampleTypeId;
      let data = await TLinkApi.get(
        `/security/template/?clientFilter=${clientId}&studyTypeFilter=${studyTypeId}`
      );
      if (Array.isArray(data?.data)) setTemplates(data?.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const GetSurveySecurityConfig = async () => {
    setIsLoading(true);
    try {
      setSelectedTemplateId("-1");
      let result = await TLinkApi.get(`survey/${id}/security`);
      setSurveyTemplate(result);
      setSurveySecurityConfig(result);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const setSurveySecurityConfig = async (result) => {
    setIsLoading(true);
    if (result && Object.keys(result).length > 0) {
      setIsEdit({
        disabled: true,
        edit: false,
      });

      setValue("uniqueIp", result.uniqueIp);
      setValue("countryMismatch", result.countryMismatch);
      setValue("researchDefender", result.researchDefender);
      setValue("researchDefenderOpenEnded", result.researchDefenderOpenEnded);
      setValue("uniqueUser", result.uniqueUser);
      setValue("researchDefenderScore", result.researchDefenderScore);
      setValue("researchDefenderOpenEndedId", result.researchDefenderOpenEndedId);
      setValue("compositeScore", result.compositeScore);
      setValue(
        "surveySecurityTemplateId",
        result.surveySecurityTemplateId == null ? -1 : result.surveySecurityTemplateId
      );
      setValue("minLoi", result.minLoi ? result.minLoi : 0);
      setValue("maxLoi", result.maxLoi ? result.maxLoi : 0);
      if (result.minLoi !== null || result.maxLoi !== null) {
        setValue("maxLoiIsActive", true);
        setValue("minLoiIsActive", true);
      } else {
        setValue("maxLoiIsActive", false);
        setValue("minLoiIsActive", false);
      }
      setConfigIsAdd(false);
    } else {
      setValue("countryMismatch", false);
      setValue("uniqueIp", false);
      setValue("uniqueUser", false);
      setValue("researchDefender", false);
      setValue("researchDefenderScore", 0);
      setValue("researchDefenderOpenEnded", false);
      setValue("researchDefenderOpenEndedId", 0);
      setValue("compositeScore", 0);
      setValue("surveySecurityTemplateId", -1);
      setValue("minLoi", 0.0);
      setValue("maxLoi", 0.0);
      setValue("minLoiIsActive", false);
      setValue("maxLoiIsActive", false);
      setConfigIsAdd(true);
      setIsEdit({
        disabled: false,
        edit: false,
      });
    }
    setIsLoading(false);
  };

  const save = async (data) => {
    let changedData = true;
    if (Object.keys(templateData).length > 0) {
      if (
        templateData.countryMismatch == data.countryMismatch &&
        templateData.uniqueIp == data.uniqueIp &&
        templateData.uniqueUser == data.uniqueUser &&
        templateData.researchDefender == data.researchDefender &&
        templateData.researchDefenderScore == data.researchDefenderScore &&
        templateData.researchDefenderOpenEnded == data.researchDefenderOpenEnded &&
        templateData.researchDefenderOpenEndedId == data.researchDefenderOpenEndedId &&
        templateData.compositeScore == data.compositeScore &&
        templateData.minLoi == data.minLoi &&
        templateData.maxLoi == data.maxLoi
      )
        changedData = false;
    }
    setIsLoading(true);
    let payload = { ...data };
    payload.minLoi = payload.minLoiIsActive ? parseFloat(data.minLoi) : null;
    payload.maxLoi = payload.maxLoiIsActive ? parseFloat(data.maxLoi) : null;
    payload.researchDefenderScore = parseInt(data.researchDefenderScore);
    payload.compositeScore = parseInt(data.compositeScore);
    payload.researchDefenderOpenEndedId = parseInt(data.researchDefenderOpenEndedId);
    if (changedData) {
      payload.surveySecurityTemplateId = -1;
    }
    if (confiqIsAdd !== true) {
      try {
        let result = await TLinkApi.put(`survey/${id}/security`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setTemplateData({});
        await GetSurveySecurityConfig();
        setSelectedTemplateId("");
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    } else {
      try {
        payload.surveyId = parseInt(id);
        let result = await TLinkApi.post("survey/security", payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setTemplateData({});
        await GetSurveySecurityConfig();
        setSelectedTemplateId("");
        setIsLoading(false);
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    }
  };

  return (
    <MDBox sx={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ width: "80%" }}>
        <form onSubmit={handleSubmit(save)} noValidate>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            width="100%"
          >
            <Grid
              container
              sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}
            >
              <Grid item xs={12} mb={1}>
                <Typography variant="h6">Security Configuration</Typography>
              </Grid>
            </Grid>
            {isEdit.edit || confiqIsAdd ? (
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexDirection="row"
                gap="10px"
              >
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (!confiqIsAdd) {
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                      GetSurveySecurityConfig();
                      setConfigIsAdd(false);
                    } else {
                      reset({
                        maxLoi: "",
                        minLoi: "",
                        minLoiIsActive: false,
                        maxLoiIsActive: false,
                        researchDefenderScore: 0,
                        researchDefenderOpenEndedId: 0,
                        compositeScore: 0,
                        uniqueIp: false,
                        uniqueUser: false,
                        countryMismatch: false,
                        researchDefender: false,
                        researchDefenderOpenEnded: false,
                      });
                    }
                  }}
                >
                  Cancel
                </MDButton>
                <MDButton
                  size="small"
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={
                    !(minLoiIsActive && maxLoiIsActive) && (minLoiIsActive || maxLoiIsActive)
                  }
                >
                  Save
                </MDButton>
              </MDBox>
            ) : (
              <MDBox display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
                <Tooltip title="Edit" placement="top">
                  <IconButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={() => {
                      setIsEdit({
                        disabled: false,
                        edit: true,
                      });
                    }}
                  >
                    <Icon>border_color_icon</Icon>
                  </IconButton>
                </Tooltip>
              </MDBox>
            )}
          </MDBox>

          <MDBox
            sx={{
              height: isEdit.disabled === true ? `calc(100vh - 312px)` : `calc(100vh - 316px)`,
              overflowY: "auto",
            }}
            mx={2}
          >
            <Deduplication
              uniqueIp={uniqueIp}
              uniqueUser={uniqueUser}
              setValue={setValue}
              errors={errors}
              isEdit={isEdit}
              control={control}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplateId={setSelectedTemplateId}
              templates={templates}
            />
            <OtherSecurity
              researchDefender={researchDefender}
              researchDefenderOpenEnded={researchDefenderOpenEnded}
              researchDefenderOpenEndedId={researchDefenderOpenEndedId}
              compositeScore={compositeScore}
              countryMismatch={countryMismatch}
              errors={errors}
              control={control}
              setValue={setValue}
              isEdit={isEdit}
            />
            <MDBox mt={2}>
              <MDTypography fontSize="14px" fontWeight="medium" mb={1}>
                Validation
              </MDTypography>
              <Grid container spacing={1} px={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={minLoiIsActive}
                        onChange={(e, value) => {
                          setValue("minLoiIsActive", value);
                        }}
                        disabled={isEdit.disabled}
                      />
                    }
                    label={
                      <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                        Minimum LOI (mins)
                      </MDTypography>
                    }
                  />
                  <Controller
                    name="minLoi"
                    control={control}
                    rules={{
                      required: watch("minLoiIsActive") ? "Min LoI (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        type="number"
                        fullWidth
                        required
                        sx={{ width: "200px", marginLeft: "50px" }}
                        {...register("minLoi", {
                          min: minLoiIsActive && 1,
                          max: maxLoi,
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only integer value is allowed!",
                          },
                        })}
                        autoComplete="off"
                        variant="outlined"
                        disabled={isEdit.disabled || !minLoiIsActive}
                        error={watch("minLoiIsActive") && errors?.minLoi ? true : false}
                        helperText={
                          errors?.minLoi && errors.minLoi.message !== ""
                            ? errors.minLoi.message
                            : errors?.minLoi?.type === "min"
                            ? "Minimum value should be 1!"
                            : errors?.minLoi?.type === "max"
                            ? "Check Min Value should be smaller than max!"
                            : maxLoiIsActive && !minLoiIsActive
                            ? "Fill the value of min!"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={maxLoiIsActive}
                        onChange={(e, value) => {
                          setValue("maxLoiIsActive", value);
                        }}
                        disabled={isEdit.disabled}
                      />
                    }
                    label={
                      <MDTypography fontWeight="regular" color="dark" fontSize="12px">
                        Maximum LOI (mins)
                      </MDTypography>
                    }
                  />
                  <Controller
                    name="maxLoi"
                    control={control}
                    rules={{
                      required: watch("maxLoiIsActive") ? "Max LoI (mins) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        sx={{ width: "200px", marginLeft: "50px" }}
                        type="number"
                        disabled={isEdit.disabled || !maxLoiIsActive}
                        required
                        autoComplete="off"
                        variant="outlined"
                        {...register("maxLoi", {
                          min: maxLoiIsActive && minLoi,
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only integer value is allowed!",
                          },
                          validate: (value) => {
                            if (maxLoiIsActive && watch("minLoi") >= value) {
                              return "Max LOI should be greater than Min LOI!";
                            }
                            return true;
                          },
                        })}
                        error={watch("maxLoiIsActive") && errors?.maxLoi ? true : false}
                        helperText={
                          errors?.maxLoi && errors.maxLoi.message !== ""
                            ? errors.maxLoi.message
                            : errors?.maxLoi?.type === "min"
                            ? "Check Max Value should be greater than min!"
                            : minLoiIsActive && !maxLoiIsActive
                            ? "Fill the value of max!"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    </MDBox>
  );
};

export default SecurityConfig;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReportHistoryList = () => {
    const [reportHistory, setReportHistory] = useState([]);
    const [reportHistoryMap, setReportHistoryMap] = useState();
    const [page, setPage] = useState(0);
    const [totalLength, setTotalLength] = useState(0);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [entriesPerPage, setEntriesPerPage] = useState(50);
    const [sortBy, setSortBy] = useState("id");
    const [sortOrder, setSortOrder] = useState("desc");

    const getProjects = async () => {
        if (search === "") {
            setIsLoading(true);
        }
        try {
            let data = await TLinkApi.get(
                `/reports/history?search=${search}&page=${page + 1
                }&limit=${entriesPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}`)
            console.log(data.data)
            setReportHistory(data.data);
            setTotalLength(data.data[0].totalLength);
            setIsLoading(false);
            let projectsMap = [];
            data?.data?.projectData?.map((value) => {
                projectsMap[value.id] = value.name;
                return true;
            });
            setReportHistoryMap(projectsMap);
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };


    useEffect(() => {
        getProjects();
    }, [page, search, entriesPerPage, sortBy, sortOrder]);

    return {
        reportHistory,
        setReportHistory,
        reportHistoryMap,
        isLoading,
        totalLength,
        setPage,
        page,
        setSearch,
        search,
        setEntriesPerPage,
        entriesPerPage,
        setSortOrder,
        setSortBy,
        sortBy,
        sortOrder,
    };
};

export default GetReportHistoryList;
